// App Components
import ErrorHandler from "../components/error/ErrorHandler";
import ProtectedRoute from "../hoc/ProtectedRoute";

// Constants
import PageURLs from "../constants/pageUrls";
import { DataPreLoadObj } from "../constants/dataPreLoadConstants";

// Pages :: Dashboard
import DashboardPage from "../pages/dashboard/DashboardPage";

// Pages :: Jobs
import JobListPage from "../pages/job/job-list/JobListPage";
import JobViewPage from "../pages/job/job-view/JobViewPage";

// Pages :: Role
import RoleListPage from "../pages/role/role-list/RoleListPage";
import RoleCreatePage from "../pages/role/role-create/RoleCreatePage";
import RoleEditPage from "../pages/role/role-edit/RoleEditPage";

// Pages :: Organization
import OrganizationListPage from "../pages/organization/organization-list/OrganizationListPage";
import OrganizationCreatePage from "../pages/organization/organization-create/OrganizationCreatePage";
import OrganizationViewPage from "../pages/organization/organization-view/OrganizationViewPage";

// Pages :: Organization - User
import UserListPage from "../pages/user/user-list/UserListPage";
import UserCreatePage from "../pages/user/user-create/UserCreatePage";
import UserViewPage from "../pages/user/user-view/UserViewPage";

// Pages :: Organization - Plant
import PlantListPage from "../pages/plant/plant-list/PlantListPage";
import PlantCreatePage from "../pages/plant/plant-create/PlantCreatePage";
import PlantViewPage from "../pages/plant/plant-view/PlantViewPage";

// Pages :: Audit
import AuditListPage from "../pages/audit/audit-list/AuditListPage";
import AuditListByAuditor from "../pages/audit-list-by-auditor/AuditListByAuditor";
import AuditLibraryPage from "../pages/audit/audit-library/AuditLibraryPage";
import AuditCreatePage from "../pages/audit/audit-create/AuditCreatePage";

// Page :: Unit Covnversion Tool Page
import UnitConversionToolPage from "../pages/unit-conversion/UnitConversionToolPage";

// Layout
import MainPageLayout from "../components/layout/MainPageLayout";

// Main Pages and Layouts
export const MainPageRoutes = {
  path: PageURLs.DashboardPageUrl,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute dataPreLoadObj={DataPreLoadObj}>
        {/* Layout */}
        <MainPageLayout topbar={true} sidebar={true} sidebarCollapsible={true} />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      path: PageURLs.DashboardPageUrl,
      element: <DashboardPage />,
    },

    {
      path: PageURLs.UnitConversionToolPageUrl,
      element: <UnitConversionToolPage />,
    },

    {
      path: PageURLs.JobListPageUrl,
      element: <JobListPage />,
    },
    {
      path: PageURLs.JobViewPageUrl,
      element: <JobViewPage />,
    },

    {
      path: PageURLs.RoleListPageUrl,
      element: <RoleListPage />,
    },
    {
      path: PageURLs.RoleCreatePageUrl,
      element: <RoleCreatePage />,
    },
    {
      path: PageURLs.RoleEditPageUrl,
      element: <RoleEditPage />,
    },

    {
      path: PageURLs.OrganizationListPageUrl,
      element: <OrganizationListPage />,
    },
    {
      path: PageURLs.OrganizationViewPageUrl,
      element: <OrganizationViewPage />,
    },
    {
      path: PageURLs.OrganizationCreatePageUrl,
      element: <OrganizationCreatePage />,
    },

    {
      path: PageURLs.UserListPageUrl,
      element: <UserListPage />,
    },
    {
      path: PageURLs.UserViewPageUrl,
      element: <UserViewPage />,
    },
    {
      path: PageURLs.UserCreatePageUrl,
      element: <UserCreatePage />,
    },

    {
      path: PageURLs.PlantListPageUrl,
      element: <PlantListPage />,
    },
    {
      path: PageURLs.PlantViewPageUrl,
      element: <PlantViewPage />,
    },
    {
      path: PageURLs.PlantCreatePageUrl,
      element: <PlantCreatePage />,
    },
    {
      path: PageURLs.AuditListPageUrl,
      element: <AuditListPage />,
    },
    {
      path: PageURLs.AuditListByAuditorPageUrl,
      element: <AuditListByAuditor />,
    },
    {
      path: PageURLs.AuditLibraryPageUrl,
      element: <AuditLibraryPage />,
    },
    {
      path: PageURLs.AuditCreatePageUrl,
      element: <AuditCreatePage />,
    },
  ],
};
