// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { ContentType } from "../../constants/httpConstants";
import { AuditReportUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Report List
async function getReportList(auditId) {
  const Url = URLs.format(AuditReportUrls.getReportList, { auditId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(Url);
}

// Create Report
async function createReport(auditId, data) {
  const url = URLs.format(AuditReportUrls.createReport, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, data);
}

// Update Report
async function updateReport(auditId, reportId, data) {
  const url = URLs.format(AuditReportUrls.updateReport, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, data);
}

// Delete Report
async function deleteReport(auditId, reportId) {
  const url = URLs.format(AuditReportUrls.deleteReport, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.delete(url);
}

// Get HTML
async function getReportHTML(auditId, reportId) {
  const url = URLs.format(AuditReportUrls.getReportHTML, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Download PDF
async function downloadPDF(auditId, reportId, data) {
  const url = URLs.format(AuditReportUrls.downloadPDF, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, data, ContentType.APP_JSON, "blob");
}

// Update Status
async function updateReportStatus(auditId, reportId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(AuditReportUrls.updateReportStatus, { auditId, reportId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Get Data
async function getReportData(auditId, reportType) {
  const params = {};

  if (reportType) {
    params.ty = reportType;
  }

  const url = URLs.format(AuditReportUrls.getReportData, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url, params);
}

const AuditReportService = {
  getReportList,
  createReport,
  updateReport,
  deleteReport,
  getReportHTML,
  downloadPDF,
  updateReportStatus,
  getReportData,
};

export default AuditReportService;
