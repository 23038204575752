import { combineReducers } from "redux";

// Error Reducer
import { ErrorReducer } from "./error/errorSlice";

// DataPreLoad Reducer
import { DataPreLoadReducer } from "./data-pre-load/dataPreLoadSlice";

// Auth Reducer
import { AuthReducer } from "./auth/authSlice";

// Definitions :: Product Reducer
import { ProductReducer } from "./product/productSlice";

// Definitions :: Material Reducer
import { MaterialReducer } from "./material/materialSlice";

// Definitions :: Job Reducer
import { JobReducer } from "./job/jobSlice";

// Definitions :: Constant Reducer
import { ConstantReducer } from "./constant/constantSlice";

// Definitions :: Component Type Reducer
import { ComponentTypeReducer } from "./component-type/componentTypeSlice";

// Definitions :: Measurement Type Reducer
import { MeasurementTypeReducer } from "./measurement-type/measurementTypeSlice";

// Definitions :: HSN Code Reducer
import { HSNCodeReducer } from "./hsn-code/HSNCodeSlice";

// Definitions :: NIC Code Reducer
import { NICCodeReducer } from "./nic-code/NICCodeSlice";

// Definitions :: Instrument Reducer
import { InstrumentReducer } from "./instrument/instrumentSlice";

// Deinitions :: Template Reducer
import { TemplateReducer } from "./template/templateSlice";

// Organization Reducer
import { OrganizationReducer } from "./organization/organizationSlice";

// User Reducer
import { UserReducer } from "./user/userSlice";

// Role Reducer
import { RoleReducer } from "./role/roleSlice";

// Plant Reducer
import { PlantReducer } from "./plant/plantSlice";

// Plant Setup :: Component Reducer
import { PlantComponentReducer } from "./plant/componentSlice";

// Plant Setup :: Component Link Reducer
import { PlantComponentLinkReducer } from "./plant/componentLinkSlice";

// Plant Setup :: Component Parts Reducer
import { PlantComponentPartsReducer } from "./plant/partsSlice";

// Plant Setup :: Power Connection Reducer
import { PlantPowerConnectionReducer } from "./plant/powerConnectionSlice";

// Plant Setup :: Electricity Bill Reducer
import { PlantElectricityBillReducer } from "./plant/electricityBillSlice";

// Plant Setup :: Thermal Bill Reducer
import { PlantThermalBillReducer } from "./plant/thermalBillSlice";

// Plant Setup :: File Reducer
import { PlantFileReducer } from "./plant/fileSlice";

// Audit Reducer
import { AuditReducer } from "./audit/auditSlice";

// Audit Reducer - Component
import { AuditComponentReducer } from "./audit/componentSlice";

// Audit Setup :: Parameter Value Reducer
import { AuditParameterValuesReducer } from "./audit/parameterValuesSlice";

// Audit Setup :: Observation and Recommendation Reducer
import { AuditObservationAndRecommendationReducer } from "./audit/observationAndRecommendationSlice";

// Audit Setup :: File Reducer
import { AuditFileReducer } from "./audit/fileSlice";

// Audit Setup :: Data Reducer
import { AuditDataReducer } from "./audit/dataSlice";

// Audit Setup :: Report Reducer
import { AuditReportReducer } from "./audit/reportSlice";

// Audit Setup :: Instrument Reducer
import { AuditInstrumentReducer } from "./audit/instrumentSlice";

// React Flow Reducer :: Node
import { NodeReducer } from "../../reactflow/slice/node/nodeSlice";

// React Flow Reducer :: Edge
import { EdgeReducer } from "../../reactflow/slice/edge/edgeSlice";

const RootReducer = combineReducers({
  // Error
  error: ErrorReducer,

  // Data Pre Load
  dataPreLoad: DataPreLoadReducer,

  // React Flow Reducer :: Node
  node: NodeReducer,

  // React Flow Reducer :: Edge
  edge: EdgeReducer,

  // Auth Reducer
  auth: AuthReducer,

  // Deinitions :: Product Reducer
  product: ProductReducer,

  // Deinitions :: Material Reducer
  material: MaterialReducer,

  // Deinitions :: Job Reducer
  job: JobReducer,

  // Deinitions :: Constant Reducer
  constant: ConstantReducer,

  // Deinitions :: Component Type Reducer
  componentType: ComponentTypeReducer,

  // Deinitions :: Measurement Type Reducer
  measurementType: MeasurementTypeReducer,

  // Deinitions :: NIC Code Reducer
  nicCode: NICCodeReducer,

  // Deinitions :: HSN Code Reducer
  hsnCode: HSNCodeReducer,

  // Deinitions :: Instrument Reducer
  instrument: InstrumentReducer,

  // Deinitions :: Template Reducer
  template: TemplateReducer,

  // Organization Reducer
  organization: OrganizationReducer,

  // User Reducer
  user: UserReducer,

  // Role Reducer
  role: RoleReducer,

  // Plant Reducer
  plant: PlantReducer,

  // Plant Setup :: Component Reducer
  plantComponent: PlantComponentReducer,

  // Plant Setup :: Component Link Reducer
  plantComponentLink: PlantComponentLinkReducer,

  // Plant Setup :: Component Parts Reducer
  plantComponentParts: PlantComponentPartsReducer,

  // Plant Setup :: Power Connection Reducer
  plantPowerConnection: PlantPowerConnectionReducer,

  // Plant Setup :: Electricity Bill Reducer
  plantElectricityBill: PlantElectricityBillReducer,

  // Plant Setup :: Thermal Bill Reducer
  plantThermalBill: PlantThermalBillReducer,

  // Plant Setup :: File Reducer
  plantFile: PlantFileReducer,

  // Audit Reducer
  audit: AuditReducer,

  // Audit Setup :: Component Reducer
  auditComponent: AuditComponentReducer,

  // Audit Setup :: Parameter Value Reducer
  auditParameterValues: AuditParameterValuesReducer,

  // Audit Setup :: Observation and Recommendation Reducer
  auditObsAndRec: AuditObservationAndRecommendationReducer,

  // Audit Setup :: Data Reducer
  auditData: AuditDataReducer,

  // Audit Setup :: Report Reducer
  auditReport: AuditReportReducer,

  // Audit Setup :: File Reducer
  auditFile: AuditFileReducer,

  // Audit Setup :: Instrument Reducer
  auditInstrument: AuditInstrumentReducer,
});

export default RootReducer;
