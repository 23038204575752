// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { HSNCodeUrls } from "../../constants/serviceUrls";

// Get HSN-Code List
async function getHSNCodeList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(HSNCodeUrls.getHSNCodeList, params);
}

const HSNCodeService = {
  getHSNCodeList,
};

export default HSNCodeService;
