import { useState } from "react";

// Constants
import { SIDEBAR_HEADER_CONSTANTS } from "../../constants/layoutConstants";

// URLs
import PageURLs from "../../constants/pageUrls";

// Layouts
import Layout from "../../layouts/Layout";
import SidebarMenu from "./SidebarMenu";
import SiderbarFooter from "./SidebarFooter";

/**
 * Layout Page
 */
export default function MainPageLayout() {
  // State
  const [pageHeader, setPageHeader] = useState(<></>);

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: <div className="d-flex align-items-center justify-content-between">{pageHeader}</div>,
  };

  // Sidebar Config
  const { headerTitle, logoHeight, logoURL, collapsedLogoHeight, collapsedLogoURL } = SIDEBAR_HEADER_CONSTANTS;
  const sidebarConfig = {
    menuComponent: <SidebarMenu />,
    footerComponent: <SiderbarFooter />,
    headerLogo: { height: logoHeight, logoURL },
    collapsedHeaderLogo: { height: collapsedLogoHeight, logoURL: collapsedLogoURL },
    redirectURL: PageURLs.DashboardPageUrl,
    title: headerTitle,
  };

  return (
    <Layout
      sidebarCollapsible={true}
      sidebarConfig={sidebarConfig}
      topbarConfig={topbarConfig}
      outletProps={[setPageHeader]}
    />
  );
}
