// Constants
import { SIDEBAR_HEADER_CONSTANTS, UserLayoutSideBar } from "../../constants/layoutConstants";

// URLs
import PageURLs from "../../constants/pageUrls";

// Layouts
import Layout from "../../layouts/Layout";
import SidebarMenu from "./SidebarMenu";

/**
 * Layout Page
 */
export default function UserLayout() {
  // Sidebar Configuration
  const { sideBarElements = [], backButtonConfig = {} } = UserLayoutSideBar || {};

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: <></>,
  };

  // Sidebar Config
  const { headerTitle, logoHeight, logoURL, collapsedLogoHeight, collapsedLogoURL } = SIDEBAR_HEADER_CONSTANTS;
  const sidebarConfig = {
    menuComponent: <SidebarMenu sidebarMenuList={sideBarElements} backButtonConfig={backButtonConfig} />,
    // footerComponent: <SiderbarFooter />,
    headerLogo: { height: logoHeight, logoURL },
    collapsedHeaderLogo: { height: collapsedLogoHeight, logoURL: collapsedLogoURL },
    redirectURL: PageURLs.DashboardPageUrl,
    title: headerTitle,
  };

  return <Layout sidebarCollapsible={true} topbarConfig={topbarConfig} sidebarConfig={sidebarConfig} />;
}
