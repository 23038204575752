// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { InstrumentUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/generalConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Instrument List
async function getInstrumentList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(InstrumentUrls.getInstrumentList, params);
}

// Get Instrument Info
async function getInstrumentInfo(instrumentId) {
  const url = URLs.format(InstrumentUrls.getInstrumentInfo, { instrumentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Data Column Info
async function getDataColumnInfo(instrumentId) {
  const params = {
    instrument: instrumentId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(InstrumentUrls.getDataColumnInfo, params);
}

// Update Instrument Status
async function updateInstrumentStatus(instrumentId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(InstrumentUrls.updateInstrumentStatus, { instrumentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const InstrumentService = {
  getInstrumentList,
  getInstrumentInfo,
  getDataColumnInfo,
  updateInstrumentStatus,
};

export default InstrumentService;
