import { createSlice } from "@reduxjs/toolkit";

// Transformer
import FileTransformer from "./fileTransformer";

function resetApiSuccessState(state) {
  state.upsertFileSuccess = false;
  state.downloadFileSuccess = {};
}

// Initial State
const initialState = {
  // Get File List
  fileListMap: [],
  fileListPagination: {},
  fileListLoading: false,
  fileListError: "",

  // Create File
  createFileLoading: false,
  createFileError: {},

  // Update File
  updateFileLoading: false,
  updateFileError: {},

  upsertFileSuccess: false,

  // Download File
  downloadFileData: {},
  downloadFileLoading: {},
  downloadFileSuccess: {},
  downloadFileError: {},

  // Delete File
  deleteFileLoading: {},
  deleteFileError: {},
};

// Plant File Slice
const plantFileSlice = createSlice({
  name: "PlantFile",
  initialState,
  reducers: {
    // Get File List
    getFileList: (state) => {
      state.fileListLoading = true;
    },

    getFileListSuccess: (state, action) => {
      const { fileList = [], fileListPagination = {} } = action.payload;
      state.fileListMap = FileTransformer.transformList(fileList);

      state.fileListPagination = fileListPagination;
      state.fileListLoading = false;
    },

    getFileListFailure: (state, action) => {
      state.fileListLoading = false;
      state.fileListError = action.payload.error;
    },

    // Create File
    createFile: (state) => {
      state.upsertFileSuccess = false;
      state.createFileLoading = true;
    },

    createFileSuccess: (state) => {
      state.upsertFileSuccess = true;
      state.createFileLoading = false;
    },

    createFileFailure: (state, action) => {
      state.createFileLoading = false;
      state.createFileError = action.payload.error;
    },

    // Update File
    updateFile: (state) => {
      state.upsertFileSuccess = false;
      state.updateFileLoading = true;
    },

    updateFileSuccess: (state) => {
      state.upsertFileSuccess = true;
      state.updateFileLoading = false;
    },

    updateFileFailure: (state, action) => {
      state.updateFileLoading = false;
      state.updateFileError = action.payload.error;
    },

    // Download File
    downloadFile: (state, action) => {
      const { fileId = "" } = action.payload;
      state.downloadFileLoading[fileId] = true;
      state.downloadFileSuccess[fileId] = false;
      state.downloadFileError = "";
    },

    downloadFileSuccess: (state, action) => {
      const { fileId = "", downloadFileData = {} } = action.payload;
      state.downloadFileData[fileId] = downloadFileData;
      state.downloadFileSuccess[fileId] = true;
      delete state.downloadFileLoading[fileId];
    },

    downloadFileFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;
      delete state.downloadFileLoading[fileId];
      state.downloadFileError = error;
    },

    // Delete File
    deleteFile: (state, action) => {
      const { fileId = "" } = action.payload;
      state.deleteFileLoading[fileId] = true;
      state.deleteFileError = "";
    },

    deleteFileSuccess: (state, action) => {
      const { fileId = "" } = action.payload;
      delete state.deleteFileLoading[fileId];
    },

    deleteFileFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;
      delete state.deleteFileLoading[fileId];
      state.deleteFileError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantFileReducer = plantFileSlice.reducer;

// Actions
export const PlantFileActions = plantFileSlice.actions;
