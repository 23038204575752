// Constants
import { LocalStorageKeys } from "../constants/localStorageConstants";
import PageURLs from "../constants/pageUrls";

// utils
import URLs from "../utils/urlUtils";
import LocalStorage from "./localStorageUtils";

const organizationListPageLabel = {
  label: "Industries",
  navigateTo: URLs.format(PageURLs.OrganizationListPageUrl, { orgType: "INDUSTRY" }),
};

const auditFirmListPageLabel = {
  label: "Audit Firms",
  navigateTo: URLs.format(PageURLs.OrganizationListPageUrl, { orgType: "AUDIT" }),
};

const getPageListUrlLabel = (orgId, orgName) => {
  return { label: `${orgName}`, navigateTo: URLs.format(PageURLs.PlantListPageUrl, { orgId }) };
};

const getUserListUrlLabel = (orgId, orgName) => {
  return {
    label: `${orgName}`,
    navigateTo: URLs.format(PageURLs.UserListPageUrl, { orgId }),
  };
};

export const bcForPlantListPage = (plantName) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [organizationListPageLabel, { label: `${plantName}` }]
    : [{ label: `${plantName}` }];
};

export const bcForPlantViewPage = (orgId, orgName, plantName) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [organizationListPageLabel, getPageListUrlLabel(orgId, orgName), { label: `${plantName}` }]
    : [getPageListUrlLabel(orgId, orgName), { label: `${plantName}` }];
};

export const bcForPlantCreatePage = (orgId, orgName) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [organizationListPageLabel, getPageListUrlLabel(orgId, orgName), { label: "Create new Plant" }]
    : [getPageListUrlLabel(orgId, orgName), { label: "Create new Plant" }];
};

export const bcForOrganizationViewPage = (orgName, orgType) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [orgType === "AUDIT" ? auditFirmListPageLabel : organizationListPageLabel, { label: `${orgName}` }]
    : [{ label: `${orgName}` }];
};

export const bcForOrganizationCreatePage = (orgTypeName, orgType) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [orgType === "AUDIT" ? auditFirmListPageLabel : organizationListPageLabel, { label: `Create New ${orgTypeName}` }]
    : [{ label: `Create New ${orgTypeName}` }];
};

export const bcForUsersListPage = (orgName, orgType) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [orgType === "AUDIT" ? auditFirmListPageLabel : organizationListPageLabel, { label: `${orgName}` }]
    : [{ label: `${orgName}` }];
};

export const bcForUsersViewPage = (orgId, orgName, userName, orgType) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [
        orgType === "AUDIT" ? auditFirmListPageLabel : organizationListPageLabel,
        getUserListUrlLabel(orgId, orgName),
        { label: `${userName}` },
      ]
    : [getUserListUrlLabel(orgId, orgName), { label: `${userName}` }];
};

export const bcForUserCreatePage = (orgId, orgName, orgType) => {
  const authOrgType = LocalStorage.get(LocalStorageKeys.ORG_TYPE);
  return authOrgType === "PLATFORM"
    ? [
        orgType === "AUDIT" ? auditFirmListPageLabel : organizationListPageLabel,
        getUserListUrlLabel(orgId, orgName),
        { label: "Create new User" },
      ]
    : [getUserListUrlLabel(orgId, orgName), { label: "Create new User" }];
};
