import { useNavigate } from "react-router-dom";

//Urls
import PageURLs from "../../../constants/pageUrls";

// Utils
import URLs from "../../../utils/urlUtils";

//Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import ShowDateAndTime from "../../../components/date-time-format/ShowDateAndTime";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TablePagination from "../../../components/table/TablePagination";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  // {
  //   title: {
  //     displayName: "Last Run",
  //   },
  // },
  // {
  //   title: {
  //     displayName: "Next Run",
  //   },
  // },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

//Page Components
function JobListTableCell({ timeStamp }) {
  return (
    <td className="col-2">
      {/* Show Date And Time */}
      <ShowDateAndTime timeStamp={timeStamp} />
    </td>
  );
}

function JobListTableStatusCell({ isActive }) {
  return <td className="col-1">{isActive ? "Active" : "InActive"}</td>;
}

function JobListTableCellAction({ id }) {
  // Navigate
  const navigate = useNavigate();

  // Redirect to job view page
  function navigateToJobViewPage() {
    const redirectUrl = URLs.format(PageURLs.JobViewPageUrl, { jobId: id });
    navigate(redirectUrl);
  }
  return (
    <td className="col-3">
      <Button label="View" color="secondary" size="sm" onClick={navigateToJobViewPage}>
        <i className="fas fa-eye"></i>
      </Button>
    </td>
  );
}

function JobListTableRow({ job }) {
  const {
    id = "",
    name = "",
    description = "",
    isActive = "",
    nextRunTimestamp = "",
    lastRunTimestamp = "",
  } = job || {};

  return (
    <tr key={id}>
      <td className="col-2">{id}</td>
      <td className="col-1">{name}</td>
      <td className="col-3">{description}</td>

      {/* Job List Table Cell */}
      {/* <JobListTableCell timeStamp={nextRunTimestamp} /> */}

      {/* Job List Table Cell */}
      {/* <JobListTableCell timeStamp={lastRunTimestamp} /> */}

      {/* Job List Table Status Cell */}
      <JobListTableStatusCell isActive={isActive} />

      {/* Job List Table Cell Action */}
      <JobListTableCellAction id={id} />
    </tr>
  );
}

function JobListTableBody({ jobListLoading, jobList }) {
  //Loader
  if (jobListLoading) {
    return <TableLoader colSpan={8} />;
  }

  //if there is no job list
  if (jobList.length === 0) {
    return <TableDataNotFound message="No jobs present" colSpan={8} />;
  }

  //Job list table row
  return (
    <>
      {jobList.map((job) => {
        return <JobListTableRow key={job.id} job={job} />;
      })}
    </>
  );
}

/**
 * Job List Table
 */
export default function JobListTable({ jobList = [], jobListLoading = false, pageNumber, jobListPagination = {} }) {
  const { totalCount, pageSize } = jobListPagination;

  return (
    <>
      <table className="table table-bordered border-end mt-4">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />
        <tbody>
          {/* Job List Table Body */}
          <JobListTableBody jobListLoading={jobListLoading} jobList={jobList} />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
