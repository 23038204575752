// Component Type Transfomer
function transformComponentTypeObj(componentTypeObj = {}) {
  const {
    id = "",
    code = "",
    name = "",
    description = "",
    isPart = "",
    subTypeCodes = [],
    isActive = false,
  } = componentTypeObj;

  return {
    id: id,
    code: code,
    name: name,
    description: description,
    isPart: isPart,
    subTypeCodes: subTypeCodes,
    isActive: isActive,
  };
}

function getTransformedComponentTypeList(componentTypeList = []) {
  const transformedComponentTypeMap = {};

  componentTypeList.forEach((obj) => {
    const { id = "" } = obj;

    transformedComponentTypeMap[id] = { key: id, ...transformComponentTypeObj(obj) };
  });

  return transformedComponentTypeMap;
}

// Parameter Transfomer
function transformParameterObj(componentTypeObj = {}) {
  const {
    id = "",
    name = "",
    dataValueType = "",
    unitSymbol = "",
    allowedValues = [],
    noOfDecimals = "",
    isMandatory = "",
    isActive = false,
    formulaStr = "",
    code = "",
  } = componentTypeObj;

  return {
    id: id,
    name: name,
    dataValueType: dataValueType,
    unitSymbol: unitSymbol,
    allowedValues: allowedValues,
    noOfDecimals: noOfDecimals,
    isMandatory: isMandatory,
    isActive: isActive,
    formulaStr: formulaStr,
    code: code,
  };
}

function getTransformedParameterList(parameterList = []) {
  const transformedParameterMap = {};

  parameterList.forEach((obj) => {
    const { id = "" } = obj;

    transformedParameterMap[id] = { key: id, ...transformParameterObj(obj) };
  });

  return transformedParameterMap;
}

// Observation Code Transfomer
function transformObservationCodeObj(observationCodeObj = {}) {
  const {
    id = "",
    version = "",
    isActive = "",
    elementType = "",
    elementTypeId = "",
    code = "",
    description = "",
  } = observationCodeObj;

  return {
    id: id,
    version: version,
    isActive: isActive,
    elementType: elementType,
    elementTypeId: elementTypeId,
    code: code,
    description: description,
  };
}
function getTransformedObservationCodeList(observationCodeList = []) {
  const transformedObservationCodeMap = {};

  observationCodeList.forEach((obj) => {
    const { id = "" } = obj;

    transformedObservationCodeMap[id] = { key: id, ...transformObservationCodeObj(obj) };
  });

  return transformedObservationCodeMap;
}

// Reccomendation Code Transfomer
function transformRecCodeObj(recCodeObj = {}) {
  const {
    id = "",
    version = "",
    isActive = "",
    elementType = "",
    elementTypeId = "",
    code = "",
    description = "",
    logic = "",
    energySavingsFormulaStr = "",
  } = recCodeObj;

  return {
    id: id,
    version: version,
    isActive: isActive,
    elementType: elementType,
    elementTypeId: elementTypeId,
    code: code,
    description: description,
    logic: logic,
    energySavingsFormulaStr: energySavingsFormulaStr,
  };
}

function getTransformedRecCodeList(recCodeList = []) {
  const transformedRecCodeMap = {};

  recCodeList.forEach((obj) => {
    const { id = "" } = obj;

    transformedRecCodeMap[id] = { key: id, ...transformRecCodeObj(obj) };
  });

  return transformedRecCodeMap;
}

const ComponentTypeTransformer = {
  transformComponentTypeObj,
  getTransformedComponentTypeList,
  transformParameterObj,
  getTransformedParameterList,
  transformObservationCodeObj,
  getTransformedObservationCodeList,
  transformRecCodeObj,
  getTransformedRecCodeList,
};

export default ComponentTypeTransformer;
