import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ComponentTypeTransformer from "./componentTypeTransformer";

function resetApiSuccessState(state) {
  state.updateComponentTypeStatusSuccessMap = {};
  state.downloadComponentTypeTemplateSuccessMap = {};
  state.updateObservationCodeStatusSuccessMap = {};
  state.updateRecommendationCodeStatusSuccessMap = {};
  state.updateParameterStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get component type List
  componentTypeMap: {},
  componentTypeListPagination: {},
  componentTypeListError: "",
  componentTypeListLoading: false,

  // Get Parameter List
  parameterMap: {},
  parameterListPagination: {},
  parameterListLoading: false,
  parameterListError: "",

  // Download Component Type Template
  downloadComponentTypeTemplateData: {},
  downloadComponentTypeTemplateLoadingMap: {},
  downloadComponentTypeTemplateSuccessMap: {},
  downloadComponentTypeTemplateError: "",

  // Get Recommendation Code List
  recCodeMap: [],
  recommendationCodeListPagination: {},
  recommendationCodeListLoading: false,
  recommendationCodeListError: "",

  // Get Observation Code List
  observationCodeMap: [],
  observationCodeListPagination: {},
  observationCodeListLoading: false,
  observationCodeListError: "",

  // Get Component Type Info
  componentTypeInfo: {},
  componentTypeInfoLoading: false,
  componentTypeInfoError: "",

  // Update ComponentType Status (Enable/Disable)
  updateComponentTypeStatusLoadingMap: {},
  updateComponentTypeStatusSuccessMap: {},
  updateComponentTypeStatusError: "",

  // Update Observation Code Status (Enable/Disable)
  updateObservationCodeStatusLoadingMap: {},
  updateObservationCodeStatusSuccessMap: {},
  updateObservationCodeStatusError: "",

  // Update Recommnedation Code Status (Enable/Disable)
  updateRecommendationCodeStatusLoadingMap: {},
  updateRecommendationCodeStatusSuccessMap: {},
  updateRecommendationCodeStatusError: "",

  // Update Parameter Status(Enable/Disable)
  updateParameterStatusLoadingMap: {},
  updateParameterStatusSuccessMap: {},
  updateParameterStatusError: "",
};

// Component Type Slice
const componetnTypeSlice = createSlice({
  name: "ComponentType",
  initialState,
  reducers: {
    // Component type list
    getComponentTypeList: (state) => {
      state.componentTypeListLoading = true;
    },

    getComponentTypeListSuccess: (state, action) => {
      const { componentTypeList, componentTypeListPagination } = action.payload;
      state.componentTypeMap = ComponentTypeTransformer.getTransformedComponentTypeList(componentTypeList);

      state.componentTypeListPagination = componentTypeListPagination;
      state.componentTypeListLoading = false;
    },

    getComponentTypeListFailure: (state, action) => {
      state.componentTypeListLoading = false;
      state.componentTypeListError = action.payload.error;
    },

    // Parameter list
    getParameterList: (state) => {
      state.parameterListLoading = true;
    },

    getParameterListSuccess: (state, action) => {
      const { parameterList, parameterListPagination } = action.payload;
      state.parameterMap = ComponentTypeTransformer.getTransformedParameterList(parameterList);

      state.parameterListPagination = parameterListPagination;
      state.parameterListLoading = false;
    },

    getParameterListFailure: (state, action) => {
      state.parameterListLoading = false;
      state.parameterListError = action.payload.error;
    },

    // Download Component Type Template
    downloadComponentTypeTemplate: (state, action) => {
      const { componentTypeCode = "" } = action.payload;

      state.downloadComponentTypeTemplateSuccessMap[componentTypeCode] = false;
      state.downloadComponentTypeTemplateLoadingMap[componentTypeCode] = true;
    },

    downloadComponentTypeTemplateSuccess: (state, action) => {
      const { componentTypeCode = "", data = {} } = action.payload;
      state.downloadComponentTypeTemplateData[componentTypeCode] = data;

      state.downloadComponentTypeTemplateSuccessMap[componentTypeCode] = true;
      delete state.downloadComponentTypeTemplateLoadingMap[componentTypeCode];
    },

    downloadComponentTypeTemplateFailure: (state, action) => {
      const { componentTypeCode = "", error = {} } = action.payload;
      delete state.downloadComponentTypeTemplateLoadingMap[componentTypeCode];

      state.downloadComponentTypeTemplateSuccessMap[componentTypeCode] = false;
      state.downloadComponentTypeTemplateError = error;
    },

    // Recommendation code list
    getRecCodeList: (state) => {
      state.recommendationCodeListLoading = true;
    },

    getRecCodeListSuccess: (state, action) => {
      const { recCodeList, recommendationCodeListPagination } = action.payload;
      state.recCodeMap = ComponentTypeTransformer.getTransformedRecCodeList(recCodeList);

      state.recommendationCodeListPagination = recommendationCodeListPagination;
      state.recommendationCodeListLoading = false;
    },

    getRecCodeListFailure: (state, action) => {
      state.recommendationCodeListLoading = false;
      state.recommendationCodeListError = action.payload.error;
    },

    // Observation code list
    getObsCodeList: (state) => {
      state.observationCodeListLoading = true;
    },

    getObsCodeListSuccess: (state, action) => {
      const { observationCodeList, observationCodeListPagination } = action.payload;
      state.observationCodeMap = ComponentTypeTransformer.getTransformedObservationCodeList(observationCodeList);

      state.observationCodeListPagination = observationCodeListPagination;
      state.observationCodeListLoading = false;
    },

    getObsCodeListFailure: (state, action) => {
      state.observationCodeListLoading = false;
      state.observationCodeListError = action.payload.error;
    },

    // Component Type Info
    getComponentTypeInfo: (state) => {
      state.componentTypeInfoLoading = true;
    },

    getComponentTypeInfoSuccess: (state, action) => {
      const componentTypeInfo = action.payload.componentTypeInfo;
      state.componentTypeInfo = ComponentTypeTransformer.transformComponentTypeObj(componentTypeInfo);

      state.componentTypeInfoLoading = false;
      state.componentTypeInfoError = "";
    },

    getComponentTypeInfoFailure: (state, action) => {
      state.componentTypeInfoLoading = false;
      state.componentTypeInfoError = action.payload.error;
    },

    // Update ComponentType Status (Enable/Disable) By Id
    updateComponentTypeStatus: (state, action) => {
      const { componentTypeId } = action.payload;
      state.updateComponentTypeStatusSuccessMap[componentTypeId] = false;
      state.updateComponentTypeStatusLoadingMap[componentTypeId] = true;
      state.updateComponentTypeStatusError = "";
    },

    updateComponentTypeStatusSuccess: (state, action) => {
      const { componentTypeId } = action.payload;
      state.updateComponentTypeStatusSuccessMap[componentTypeId] = true;
      delete state.updateComponentTypeStatusLoadingMap[componentTypeId];
    },

    updateComponentTypeStatusFailure: (state, action) => {
      const { componentTypeId } = action.payload;
      delete state.updateComponentTypeStatusLoadingMap[componentTypeId];
      state.updateComponentTypeStatusError = action.payload.error;
    },

    // Update Observation Code Status (Enable/Disable) By Id
    updateObservationCodeStatus: (state, action) => {
      const { obsCodeId } = action.payload;
      state.updateObservationCodeStatusSuccessMap[obsCodeId] = false;
      state.updateObservationCodeStatusLoadingMap[obsCodeId] = true;
      state.updateObservationCodeStatusError = "";
    },

    updateObservationCodeStatusSuccess: (state, action) => {
      const { obsCodeId } = action.payload;
      state.updateObservationCodeStatusSuccessMap[obsCodeId] = true;
      delete state.updateObservationCodeStatusLoadingMap[obsCodeId];
    },

    updateObservationCodeStatusFailure: (state, action) => {
      const { obsCodeId } = action.payload;
      delete state.updateObservationCodeStatusLoadingMap[obsCodeId];
      state.updateObservationCodeStatusError = action.payload.error;
    },

    // Update Recommnedation Code Status (Enable/Disable) By Id
    updateRecommendationCodeStatus: (state, action) => {
      const { recCodeId } = action.payload;
      state.updateRecommendationCodeStatusSuccessMap[recCodeId] = false;
      state.updateRecommendationCodeStatusLoadingMap[recCodeId] = true;
      state.updateRecommendationCodeStatusError = "";
    },

    updateRecommendationCodeStatusSuccess: (state, action) => {
      const { recCodeId } = action.payload;
      state.updateRecommendationCodeStatusSuccessMap[recCodeId] = true;
      delete state.updateRecommendationCodeStatusLoadingMap[recCodeId];
    },

    updateRecommendationCodeStatusFailure: (state, action) => {
      const { recCodeId } = action.payload;
      delete state.updateRecommendationCodeStatusLoadingMap[recCodeId];
      state.updateRecommendationCodeStatusError = action.payload.error;
    },

    // Update Parameter Status (Enable/Disable) By Id
    updateParameterTypeStatus: (state, action) => {
      const { parameterId } = action.payload;
      state.updateParameterStatusSuccessMap[parameterId] = false;
      state.updateParameterStatusLoadingMap[parameterId] = true;
      state.updateParameterStatusError = "";
    },

    updateParameterTypeStatusSuccess: (state, action) => {
      const { parameterId } = action.payload;
      state.updateParameterStatusSuccessMap[parameterId] = true;
      delete state.updateParameterStatusLoadingMap[parameterId];
    },

    updateParameterTypeStatusFailure: (state, action) => {
      const { parameterId, error } = action.payload;
      delete state.updateParameterStatusLoadingMap[parameterId];
      state.updateParameterStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const ComponentTypeReducer = componetnTypeSlice.reducer;

// Actions
export const ComponentTypeActions = componetnTypeSlice.actions;
