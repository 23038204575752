import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Action
import { MaterialActions } from "../../../store/slices/material/materialSlice";

// Components
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Type",
    },
  },
  {
    title: {
      displayName: "Measurement Type",
    },
  },
  {
    title: {
      displayName: "Unit",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function MaterialListTableStatusCell({ materialId, isActive, pageNumber, pageSize }) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.material.updateMaterialStatusLoadingMap[materialId]) || false;
  const updateMaterialStatusSuccess =
    useSelector((state) => state.material.updateMaterialStatusSuccessMap[materialId]) || false;

  const status = !isActive;

  function updateMaterialStatus() {
    dispatch(MaterialActions.updateMaterialStatus({ materialId, status, pageNumber, pageSize }));
  }

  useEffect(() => {
    if (updateMaterialStatusSuccess) {
      toast.success("Material Status Updated Successfully");
    }
  }, [updateMaterialStatusSuccess]);

  return (
    <td>
      <ToggleButton isActive={isActive} loading={loading} onClick={updateMaterialStatus} />
    </td>
  );
}

function MaterialListTableRow({ material = {}, pageNumber, pageSize }) {
  const {
    id = "",
    name = "",
    description = "",
    type = "",
    measurementTypeName = "",
    unitSymbol = "",
    isActive = false,
  } = material;

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{description}</td>
      <td>{type}</td>
      <td>{measurementTypeName}</td>
      <td>{unitSymbol}</td>

      {/* Material Table Status Cell */}
      <MaterialListTableStatusCell materialId={id} isActive={isActive} pageNumber={pageNumber} pageSize={pageSize} />
    </tr>
  );
}

function MaterialListTableBody({ materialListLoading, materialList, pageNumber, pageSize }) {
  //Loader
  if (materialListLoading) {
    return <TableLoader colSpan={7} />;
  }

  //if there is no material list
  if (materialList.length === 0) {
    return <TableDataNotFound message="No materials present" colSpan={7} />;
  }

  //Material list table row
  return (
    <>
      {materialList.map((material) => {
        return (
          <MaterialListTableRow key={material.id} material={material} pageNumber={pageNumber} pageSize={pageSize} />
        );
      })}
    </>
  );
}

/**
 * Material List Table
 */
export default function MaterialListTable({
  materialList = [],
  materialListLoading = false,
  materialListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize } = materialListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />
        <tbody>
          {/* Material List Table Body */}
          <MaterialListTableBody
            materialListLoading={materialListLoading}
            materialList={materialList}
            pageNumber={pageNumber}
            pageSize={pageSize}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
