// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { ConstantsUrls } from "../../constants/serviceUrls";

// Get Constant List
async function getConstantList() {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(ConstantsUrls.getConstants);
}

const ConstantService = {
  getConstantList,
};

export default ConstantService;
