// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { AuditObservationRecommendationUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Get Observation And Recommendation List
async function getObsAndRecList(auditId, componentId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem: "COMPONENT",
    elem_id: componentId,
  };

  const url = URLs.format(AuditObservationRecommendationUrls.getObservationAndRecommendationList, { auditId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

// Update Observation And Recommendation
async function updateObsAndRec(auditId, observationData) {
  const url = URLs.format(AuditObservationRecommendationUrls.updateObservationAndRecommendation, { auditId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(url, {}, observationData);
}

// Update Investment
async function updateInvestment(auditId, recommendationId, investment) {
  const url = URLs.format(AuditObservationRecommendationUrls.updateInvestment, { auditId, recommendationId });

  const params = {};

  if (investment) {
    params.investment = investment;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, params);
}

const AuditObservationAndRecommendationService = {
  getObsAndRecList,
  updateObsAndRec,
  updateInvestment,
};

export default AuditObservationAndRecommendationService;
