import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { RoleActions } from "../../../store/slices/role/roleSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";
import { QueryParamsKeys } from "../../../constants/generalConstants";

// Components
import { Button } from "../../../components/button/Button";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import RoleListTable from "./RoleListTable";

// Page Components
function PageHeaderSection() {
  // Navigate
  const navigate = useNavigate();

  // Content
  const pageTitle = "Roles";

  // Navigate to role create page
  function navigateToRoleCreatePage() {
    navigate(PageURLs.RoleCreatePageUrl);
  }

  const pageActions = (
    <div className="btn-cont">
      <Button label="Create Role" onClick={navigateToRoleCreatePage}>
        <i className="fas fa-plus"></i>
      </Button>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} className="w-100" />;
}

export default function RoleListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Search Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Page Number from Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(RoleActions.getRoleList({ pageNumber, pageSize, searchText }));
  }, [dispatch, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  return <RoleListTable />;
}
