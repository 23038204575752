// Job Transformers
function transformObj(jobObj = {}) {
  const { id = "", name = "", group = "", description = "", isActive = false } = jobObj;

  const job = { id: id, name: name, group: group, description: description, isActive: isActive };

  return job;
}

function transformList(jobObjList = []) {
  let jobsListMap = {};

  jobObjList.forEach((obj) => {
    const { id = "" } = obj;

    jobsListMap[id] = { key: id, ...transformObj(obj) };
  });

  return jobsListMap;
}

// Exports
const JobTransformer = {
  transformObj,
  transformList,
};

export default JobTransformer;
