import { createSlice } from "@reduxjs/toolkit";

// Transformer
import PlantTransformer from "./plantTransformer";

function resetApiSuccessState(state) {
  state.addPlantSuccess = false;
  state.updatePlantSuccess = false;
  state.updatePlantStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get plant list
  plantListMap: {},
  plantListPagination: {},
  plantListLoading: false,
  plantListError: "",

  // Get plant information
  plantInfo: {},
  plantInfoLoading: false,
  plantInfoError: "",

  // Add plant
  addPlantLoading: false,
  addPlantSuccess: false,
  addPlantError: "",

  // Update plant
  updatePlantLoading: false,
  updatePlantSuccess: false,
  updatePlantError: "",

  // Update plant status
  updatePlantStatusLoadingMap: {},
  updatePlantStatusSuccessMap: {},
  updatePlantStatusError: "",
};

// Plant Slice
const plantSlice = createSlice({
  name: "Plant",
  initialState,

  reducers: {
    // Get Plant List
    getPlantList: (state) => {
      state.plantListLoading = true;
      state.plantListError = "";
    },

    getPlantListSuccess: (state, action) => {
      const { plantList, plantListPagination } = action.payload;
      state.plantListMap = PlantTransformer.transformList(plantList);

      state.plantListPagination = plantListPagination;
      state.plantListLoading = false;
    },

    getPlantListFailure: (state, action) => {
      state.plantListLoading = false;
      state.plantListError = action.payload.error;
    },

    // Get plant information
    getPlantInfo: (state) => {
      state.plantInfoLoading = true;
    },

    getPlantInfoSuccess: (state, action) => {
      const { plantInfo = {} } = action.payload;
      state.plantInfo = PlantTransformer.transformObj(plantInfo);

      state.plantInfoLoading = false;
    },

    getPlantInfoFailure: (state, action) => {
      state.plantInfoLoading = false;
      state.plantInfoError = action.payload.error;
    },

    // Add plant
    addPlant: (state) => {
      state.addPlantSuccess = false;
      state.addPlantLoading = true;
    },

    addPlantSuccess: (state) => {
      state.addPlantSuccess = true;
      state.addPlantLoading = false;
    },

    addPlantFailure: (state, action) => {
      state.addPlantLoading = false;
      state.addPlantError = action.payload.error;
    },

    // Update plant
    updatePlant: (state) => {
      state.updatePlantSuccess = false;
      state.updatePlantLoading = true;
    },

    updatePlantSuccess: (state) => {
      state.updatePlantSuccess = true;
      state.updatePlantLoading = false;
    },

    updatePlantFailure: (state, action) => {
      state.updatePlantLoading = false;
      state.updatePlantError = action.payload.error;
    },

    // Update Plant status
    updatePlantStatus: (state, action) => {
      const { plantId } = action.payload;
      state.updatePlantStatusSuccessMap[plantId] = false;
      state.updatePlantStatusLoadingMap[plantId] = true;
      state.updatePlantStatusError = "";
    },

    updatePlantStatusSuccess: (state, action) => {
      const { plantId } = action.payload;
      state.updatePlantStatusSuccessMap[plantId] = true;
      delete state.updatePlantStatusLoadingMap[plantId];
    },

    updatePlantStatusFailure: (state, action) => {
      const { plantId } = action.payload;
      delete state.updatePlantStatusLoadingMap[plantId];
      state.updatePlantStatusError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantReducer = plantSlice.reducer;

// Actions
export const PlantActions = plantSlice.actions;
