// Utils
import TableUtils from "../../utils/tableUtils";

function getHeaders(currentPage = "", pageSize = "") {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
  ];
}

const OrganizationListTableHelper = {
  getHeaders,
};

export default OrganizationListTableHelper;
