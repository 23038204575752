import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
import { OrganizationActions } from "../../../../store/slices/organization/organizationSlice";
import { AuditActions } from "../../../../store/slices/audit/auditSlice";
import { UserActions } from "../../../../store/slices/user/userSlice";

// Components
import { Button } from "../../../../components/button/Button";
import SearchDropdown from "../../../../components/search-dropdown/SearchDropdown";

// Sections
import InchargeInfo from "./InchargeInfo";

// Page Constants
const ORG_TYPE = "AUDIT"; // TODO pick this from logged in User ??

// Page Components
// ----------------------------------------------------------------------------

function AddAuditor() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { auditId } = params;

  // State
  const [isEdit, setIsEdit] = useState(false);
  const [organizationId, setOrganizationId] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [error, setError] = useState({});

  // Organization Type List Selector State
  const organizationTypeMap = useSelector((state) => state.organization.organizationTypeMap);
  const organizationListLoading = useSelector((state) => state.organization.organizationListLoading);

  // User List Selector State
  const userMap = useSelector((state) => state.user.userMap);
  const userList = Object.values(userMap);
  const userListLoading = useSelector((state) => state.user.userListLoading);

  // Update Auditor Selector state
  const addAuditorLoading = useSelector((state) => state.audit.addAuditorLoading);
  const addAuditorSuccess = useSelector((state) => state.audit.addAuditorSuccess);

  // Organization List
  const oranizationMap = organizationTypeMap[ORG_TYPE];
  const organizationList = Object.values(oranizationMap) || [];

  // Constructing options for each data list inputs
  function constructOptions(data = []) {
    // Constructing options array for user input
    const optionsArray = data?.reduce((acc, eachObj) => {
      const { id = "", firstName = "", lastName = "", name = "" } = eachObj;

      return [...acc, { id, value: name || `${firstName} ${lastName}` }];
    }, []);

    return optionsArray;
  }

  // organization Search function
  function organizationSearch(target) {
    // Getting from auto complete
    const { id = "", value = "" } = target;

    setOrganizationId(id);
    setOrganizationName(value);
    setUserName("");
    setUserId("");

    // If id is present
    if (id) {
      setError({});

      dispatch(UserActions.getUserList({ orgId: id }));
    }
  }

  // Handle Submit
  function addAuditor() {
    // If the user data does not exist
    if (!organizationId) {
      return setError((prev) => ({ ...prev, orgSearchError: "Please Select organization" }));
    }

    // If the user does not exist
    if (!userId) {
      return setError((prev) => ({ ...prev, userSearchError: "User not found !!" }));
    }

    // If all the values are present, Error is reset
    setError({});

    // Dispatch
    dispatch(AuditActions.addAuditor({ auditId, userId }));

    // Clearing the state
    setUserId("");
    setUserName("");
    setOrganizationId("");
    setOrganizationName("");
  }

  // Cancel Edit Function
  function cancelEdit() {
    setError({});
    setIsEdit(false);
    setOrganizationId("");
  }

  // use Effect
  useEffect(() => {
    // Resetting all the values whenever isEdit value is changed
    setOrganizationId("");
    setError({});

    if (isEdit) {
      dispatch(OrganizationActions.getOrganizationList({ orgType: ORG_TYPE }));
    }
  }, [dispatch, isEdit]);

  useEffect(() => {
    if (addAuditorSuccess) {
      toast.success("Auditor Added Successfully");
      setIsEdit(false);
    }
  }, [dispatch, addAuditorSuccess]);

  return (
    <>
      {isEdit && (
        <>
          <div className="mb-3">
            {/* Auto Complete */}
            <SearchDropdown
              id="user-list"
              value={organizationName}
              options={constructOptions(organizationList)}
              placeholder="Search organization..."
              className={isEdit ? "" : "bg-transparent border border-0 p-0"}
              onChange={organizationSearch}
              isEdit={!isEdit}
              loading={organizationListLoading}
              disabled={organizationListLoading}
              error={error?.orgSearchError}
            />
          </div>

          {/* Auto Complete */}
          <SearchDropdown
            id="add-audit"
            value={userName}
            options={constructOptions(userList)}
            placeholder="Search user..."
            className={isEdit ? "" : "bg-transparent border border-0 p-0"}
            onChange={(target) => {
              const { id, value } = target;
              setUserId(id);
              setUserName(value);
            }}
            isEdit={!isEdit}
            loading={userListLoading}
            disabled={!organizationId || userListLoading}
            error={error?.userSearchError}
          />
        </>
      )}

      {/* If add auditor loading is true, button is not displayed */}
      {isEdit && (
        <div className="mt-2">
          {/* Button */}
          <Button
            label="Update"
            size="sm"
            loading={addAuditorLoading}
            disabled={addAuditorLoading}
            onClick={() => {
              addAuditor({ userList, setIsEdit });
            }}
          />

          {/* Button */}
          <Button
            disabled={addAuditorLoading}
            label="Cancel"
            size="sm"
            color="secondary"
            className="ms-2"
            onClick={cancelEdit}
          />
        </div>
      )}

      {!isEdit && <Button label="+ Add Auditor" color="link" onClick={() => setIsEdit(true)} className="p-0" />}
    </>
  );
}

function AuditorListBody({ auditor = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { auditId } = params;

  // Revalidator Hook
  const revalidator = useRevalidator();

  const { id = "", fullName = "" } = auditor;

  // Selector State
  const removeAuditorLoading = useSelector((state) => state.audit.removeAuditorLoading[id]);
  const removeAuditorSuccess = useSelector((state) => state.audit.removeAuditorSuccess[id]);

  const iconClassName = removeAuditorLoading ? "fa-circle-notch fa-spin " : "fa-circle-xmark text-danger";

  useEffect(() => {
    if (removeAuditorSuccess) {
      // To revalidate audit information
      revalidator.revalidate();
    }
  }, [removeAuditorSuccess]);

  return (
    <li className="mb-2">
      {fullName}

      {/* Button */}
      <Button
        color="light"
        className="p-0 border-0"
        disabled={removeAuditorLoading}
        onClick={() => dispatch(AuditActions.removeAuditor({ auditId, userId: id }))}
      >
        <i className={`fa-solid fa-lg ms-2 ${iconClassName}`}></i>
      </Button>
    </li>
  );
}

function AuditorList({ auditors = [] }) {
  return (
    <>
      <div className="col-4">
        <p className="bold">Audit Team</p>
      </div>
      <div className="col-8">
        {auditors?.length === 0 && "--"}

        <ul className="list-unstyled">
          {auditors?.map((auditor) => {
            const { id } = auditor;

            return <AuditorListBody key={id} auditor={auditor} />;
          })}
        </ul>

        {/* Add Auditor */}
        <AddAuditor />
      </div>
    </>
  );
}

/**
 * Audit Auditor Info Card
 */
export default function AuditAuditorInfoCard({ auditInfo }) {
  // Dispatch
  const dispatch = useDispatch();

  // Revalidator Hook
  const revalidator = useRevalidator();

  // Selector State
  const auditUserMap = useSelector((state) => state.user.auditUserMap);
  const auditUserList = Object.values(auditUserMap);
  const updateAuditInchargeLoading = useSelector((state) => state.audit.updateAuditInchargeLoading);

  const addAuditorSuccess = useSelector((state) => state.audit.addAuditorSuccess);
  const updateAuditInchargeSuccess = useSelector((state) => state.audit.updateAuditInchargeSuccess);
  const updatePlantInchargeSuccess = useSelector((state) => state.audit.updatePlantInchargeSuccess);

  const isRevalidateRequired = addAuditorSuccess || updateAuditInchargeSuccess || updatePlantInchargeSuccess;

  // Getting Audit Org Id
  const { auditOrgId = "", auditOrgName = "", auditInchargeName = "", auditors = [] } = auditInfo;

  // useEffect
  useEffect(() => {
    if (auditOrgId) {
      dispatch(UserActions.getUserList({ orgId: auditOrgId, orgType: ORG_TYPE }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isRevalidateRequired) {
      // To revalidate audit information
      revalidator.revalidate();
    }
  }, [isRevalidateRequired]);

  return (
    <>
      <div className="row">
        <div className="col-4">
          <label className="bold">Audit Firm</label>
        </div>
        <div className="col-8">
          <label>{auditOrgName}</label>
        </div>
      </div>
      <div className="row mt-2">
        {/* Incharge Information */}
        <InchargeInfo
          labelName="Auditor"
          inchargeName={auditInchargeName}
          organizationName={auditOrgName}
          data={auditUserList || []}
          loading={updateAuditInchargeLoading}
          action={AuditActions.updateAuditIncharge}
          toastMessage="Audit incharge updated successfully"
        />
      </div>
      <div className="row mt-2">
        {/* Auditor List */}
        <AuditorList auditors={auditors} />
      </div>
    </>
  );
}
