// User Transformers
function transformObj(userObj = {}) {
  const {
    id = "",
    version = "",
    orgId = "",
    orgType = "",
    plantId = "",
    plantName = "",
    firstName = "",
    lastName = "",
    emailId = "",
    phoneNo = "",
    designation = "",
    isActive = false,
  } = userObj;

  const user = {
    id: id,
    version: version,
    orgId: orgId,
    orgType: orgType,
    plantId: plantId,
    plantName: plantName,
    firstName: firstName,
    lastName: lastName,
    emailId: emailId,
    phoneNo: phoneNo,
    designation: designation,
    isActive: isActive,
  };

  return user;
}

function transformList(userObjList = []) {
  let userListMap = {};

  userObjList.forEach((obj) => {
    const { id = "" } = obj;

    userListMap[id] = { key: id, ...transformObj(obj) };
  });

  return userListMap;
}

// Exports
const UserTransformer = {
  transformObj,
  transformList,
};

export default UserTransformer;
