import React from "react";

// Constants
import { ICON_SIZE } from "../../../constants/generalConstants";

// Utils
import { selectFile } from "../../../utils/fileUtils";

// Components
import Loader from "../../../components/loader/v2/Loader";
import Icon from "../../../components/icon/Icon";

/**
 * Plant Bills Table Action Button
 * @param {*} isFilePresent : Boolean Value
 * @param {*} uploadFile : Function
 * @param {*} fileUploadLoadingStatus : Boolean Value
 */
export default function PlantBillsTableActionIcon({ isFilePresent, uploadFile, fileUploadLoadingStatus }) {
  // Function to select file
  async function selectFilehandler() {
    // Getting files
    const files = await selectFile(false);

    // If files are present, upload function is called and the first file is selected
    if (files.length > 0) {
      uploadFile(files[0]);
    }
  }

  if (fileUploadLoadingStatus) {
    return <Loader />;
  }

  return (
    <>
      {isFilePresent && (
        <Icon iconName="filePen" title="Update File" size={ICON_SIZE.small} onClick={selectFilehandler} />
      )}

      {!isFilePresent && (
        <Icon iconName="upload" title="Upload File" size={ICON_SIZE.small} onClick={selectFilehandler} />
      )}
    </>
  );
}
