// URLs
import { PlantComponentUrls } from "../../constants/serviceUrls";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";

// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// App Constants
import { ContentType } from "../../constants/httpConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Component List
async function getComponentList(plantId, componentTypeId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (componentTypeId) {
    params.component_type = componentTypeId;
  }

  const url = URLs.format(PlantComponentUrls.getComponentList, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url, params);
}

// Add Component
async function addComponent(componentData, plantId) {
  const url = URLs.format(PlantComponentUrls.addComponent, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, componentData);
}

// Update Component
async function updateComponent(componentData, componentId, plantId) {
  const url = URLs.format(PlantComponentUrls.updateComponent, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, componentData);
}

// Upload Component Image
async function uploadComponentImage(formData, plantId, componentId) {
  const url = URLs.format(PlantComponentUrls.uploadComponentImage, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

// Download Component Image
async function downloadComponentImage(url) {
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadPublicFile(url);
}

// Decommission Component
async function decommissionComponent(plantId, componentId) {
  const url = URLs.format(PlantComponentUrls.decommissionComponent, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Update Component Position
async function updateComponentPosition(plantId, componentId, position) {
  const params = {
    ...position,
  };

  const url = URLs.format(PlantComponentUrls.updateComponentPosition, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Upload Bulk Component
async function uploadBulkComponent(plantId, componentCode, formData) {
  const url = URLs.format(PlantComponentUrls.uploadBulkComponent, { plantId, componentCode });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

const PlantComponentService = {
  getComponentList,
  addComponent,
  updateComponent,
  uploadComponentImage,
  downloadComponentImage,
  decommissionComponent,
  updateComponentPosition,
  uploadBulkComponent,
};
export default PlantComponentService;
