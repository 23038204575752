import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect } from "react";

// Actions
import { AuditActions } from "../../store/slices/audit/auditSlice";

// Components
import PageHeader from "../../components/page-header/PageHeader";
import AuditInitiateButton from "../../components/audit/AuditInitiateButton";
import AuditListTable from "../../components/audit/audit-list/AuditListTable";

// Page Components
function PageHeaderSection() {
  return <PageHeader title={"Audits Dashboard"} className="w-100" />;
}

/**
 * Audit List By Auditor
 */
export default function AuditListByAuditor() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { userId = "" } = useParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector State
  const auditByAuditorMap = useSelector((state) => state.audit.auditByAuditorMap);
  const auditListByAuditor = Object.values(auditByAuditorMap);
  const auditListByAuditorLoading = useSelector((state) => state.audit.auditListByAuditorLoading);

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
    dispatch(AuditActions.getAuditListByAuditor({ userId }));
  }, [dispatch, userId]);

  return (
    <div className="page-content">
      {!auditListByAuditorLoading && (
        <div className="my-3 text-end">
          <AuditInitiateButton />
        </div>
      )}

      {/* Audit List Table */}
      <AuditListTable items={auditListByAuditor} loading={auditListByAuditorLoading} />
    </div>
  );
}
