import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

// Actions
import { ComponentTypeActions } from "../../../store/slices/component-type/componentTypeSlice";

//Utils
import TableUtils from "../../../utils/tableUtils";

// Components
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableLoader from "../../../components/table/TableLoader";
import ToggleButton from "../../../components/antd/button/ToggleButton";

//Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Id",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ObservationCodeListActionsCell({ obsCodeId, isActive }) {
  // Params
  const params = useParams();
  const { componentTypeId } = params;

  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateObservationCodeStatusLoading = useSelector(
    (state) => state.componentType.updateObservationCodeStatusLoadingMap[obsCodeId]
  );
  const updateObservationCodeStatusSuccess = useSelector(
    (state) => state.componentType.updateObservationCodeStatusSuccessMap[obsCodeId]
  );

  // Observation Code List Selector State
  const observationCodeListPagination = useSelector((state) => state.componentType.observationCodeListPagination);

  const { pageNo, pageSize } = observationCodeListPagination;

  // Changing Status
  const status = !isActive;

  function updateObservationCodeStatus() {
    dispatch(
      ComponentTypeActions.updateObservationCodeStatus({
        componentTypeId,
        obsCodeId,
        status,
        pageNumber: pageNo,
        pageSize,
      })
    );
  }

  useEffect(() => {
    if (updateObservationCodeStatusSuccess) {
      toast.success("Observation Status Changed Successfully");
    }
  }, [updateObservationCodeStatusSuccess]);

  return (
    <td>
      <ToggleButton
        isActive={isActive}
        loading={updateObservationCodeStatusLoading}
        onClick={updateObservationCodeStatus}
      />
    </td>
  );
}

function ObservationCodeListTableRow({ serialNo, observationCode }) {
  const { id, code, description, isActive } = observationCode;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{code}</td>
      <td>{description}</td>

      {/* Observation Code List Table cell */}
      <ObservationCodeListActionsCell obsCodeId={id} isActive={isActive} />
    </tr>
  );
}

function ObservationCodeListTableBody({ pageNumber, pageSize, observationCodeList, observationCodeListLoading }) {
  // Loader
  if (observationCodeListLoading) {
    return <TableLoader colSpan={5} />;
  }

  // If no observation codes present
  if (observationCodeList.length === 0) {
    return <TableDataNotFound message="No observation codes present" colSpan={5} />;
  }

  return (
    <>
      {observationCodeList.map((observationCode, index) => {
        // Serial number for pagination table
        const serialNo = TableUtils.tableSerialNumber(pageNumber, pageSize, index);

        // Observation Code List Table Row
        return (
          <ObservationCodeListTableRow serialNo={serialNo} key={observationCode.id} observationCode={observationCode} />
        );
      })}
    </>
  );
}

/**
 * Observation Code List Table
 */
export default function ObservationCodeListTable({ pageNumber }) {
  // Observation Code List Selector State
  const observationCodeMap = useSelector((state) => state.componentType.observationCodeMap);
  const observationCodeList = Object.values(observationCodeMap);

  const observationCodeListLoading = useSelector((state) => state.componentType.observationCodeListLoading);
  const observationCodeListPagination = useSelector((state) => state.componentType.observationCodeListPagination);

  // Pagination
  const { totalCount, pageNo, pageSize } = observationCodeListPagination;

  return (
    <>
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Observation Code List Table body */}
          <ObservationCodeListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            observationCodeList={observationCodeList}
            observationCodeListLoading={observationCodeListLoading}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
