// Utils
import { getMonthName } from "../../../../../utils/dateUtils";
import DecimalUtils from "../../../../../utils/decimalUtils";

// Components
import TableDataNotFound from "../../../../../components/table/TableDataNotFound";
import TableHeaders from "../../../../../components/table/TableHeader";

// Page Constants
const TABLE_HEADERS = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Material Name",
    },
  },
  {
    title: {
      displayName: "Fuel GCV",
    },
  },
  {
    title: {
      displayName: "Units Consumed",
    },
  },
  {
    title: {
      displayName: "Unit Cost",
    },
  },
  {
    title: {
      displayName: "Total Cost",
    },
  },
];

// Page Components
function ThermalBillListTableRow({ thermalBill = {}, serialNo = "" }) {
  const {
    year = "",
    month = "",
    materialName = "",
    fuelGCV = "",
    unitsConsumed = "",
    unitCost = "",
    totalCost = "",
  } = thermalBill || {};

  return (
    <tr>
      <td>{serialNo}</td>

      <td>{`${getMonthName(month)} , ${year}`}</td>

      <td>{materialName}</td>
      <td>{fuelGCV}</td>
      <td>{DecimalUtils.fixDecimal(unitsConsumed, 2)}</td>
      <td>{DecimalUtils.fixDecimal(unitCost, 2)}</td>
      <td>{DecimalUtils.fixDecimal(totalCost, 2)}</td>
    </tr>
  );
}

function ThermalBillListTableBody({ thermalBills = [] }) {
  // If there is no thermal bill list
  if (thermalBills.length === 0) {
    return <TableDataNotFound message="No thermal bills present" colSpan={7} />;
  }

  // Thermal bill list table row
  return (
    <>
      {thermalBills.map((thermalBill, idx) => {
        return <ThermalBillListTableRow key={idx} thermalBill={thermalBill} serialNo={idx + 1} />;
      })}
    </>
  );
}

/**
 * Thermal Bill List Table
 * @param {*} thermalBills : Thermal Bill List (array)
 */
export default function ThermalBillListTable({ thermalBills = [] }) {
  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Thermal Bills</h3>

      <div className="table-responsive">
        <table className="table table-bordered border-end">
          {/* Table Headers */}
          <TableHeaders tableHeaders={TABLE_HEADERS} />

          <tbody>
            {/* Thermal Bill List Table Body */}
            <ThermalBillListTableBody thermalBills={thermalBills} />
          </tbody>
        </table>
      </div>
    </section>
  );
}
