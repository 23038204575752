// React Related Imports
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuthActions } from "../../slices/auth/authSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Constants
import { LocalStorageKeys } from "../../../constants/localStorageConstants";

// Utils
import LocalStorage from "../../../utils/localStorageUtils";

// Service
import AuthService from "../../../services/auth/authService";

//
import HttpClientFactory from "../../../utils/httpClientFactory";

// Login
function* login(action) {
  try {
    const { loginFormData } = action.payload;

    const loggedInData = yield AuthService.login(loginFormData);

    const { org: orgDetails = {}, privileges = [], token } = loggedInData;

    //setting token in localstorage
    LocalStorage.setAuthToken(token);

    //store permissions in local storage
    LocalStorage.set(LocalStorageKeys.PERMISSIONS, privileges);

    //set user details in local storage
    LocalStorage.set(LocalStorageKeys.AUTH_USER, JSON.stringify(loggedInData));

    // set org info
    LocalStorage.set(LocalStorageKeys.AUTH_ORG, JSON.stringify(orgDetails));
    LocalStorage.set(LocalStorageKeys.ORG_TYPE, orgDetails.type);

    // Reload HttpClientFactory
    HttpClientFactory.reload();

    yield put(AuthActions.loginSuccess({ loggedInData }));
  } catch (error) {
    yield put(AuthActions.loginFailure({ error: "Login Error" }));
  }
}

// Log Out
function* logOut() {
  try {
    // Clear LocalStorage
    yield localStorage.clear();

    // Reset HttpClientFactory
    yield HttpClientFactory.reset();

    yield put(AuthActions.logOutSuccess());
  } catch (error) {
    // TODO :: Handle error
    console.log(error);
  }
}

// Reset Password
function* resetPassword(action) {
  try {
    const { email = "", newPassword = "" } = action.payload;

    yield AuthService.resetPassword(email, newPassword);

    yield put(AuthActions.resetPasswordSuccess());
  } catch (error) {
    yield put(AuthActions.resetPasswordFailure({ error }));

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update User Password
function* updateUserPassword(action) {
  try {
    const { userId = "", newPassword = "" } = action.payload;

    yield AuthService.updateUserPassword(userId, newPassword);

    yield put(AuthActions.updateUserPasswordSuccess());
  } catch (error) {
    yield put(AuthActions.updateUserPasswordFailure({ error }));

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

/**
 * Auth Sagas
 */
export default function* root() {
  yield all([
    takeLatest(AuthActions.login.type, login),
    takeLatest(AuthActions.logout.type, logOut),
    takeLatest(AuthActions.resetPassword.type, resetPassword),
    takeLatest(AuthActions.updateUserPassword.type, updateUserPassword),
  ]);
}
