import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { JobActions } from "../../slices/job/jobSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Services
import JobService from "../../../services/job/jobService";

// Get Job List
function* getJobList(action) {
  try {
    const { pageNumber = "", pageSize = "", searchText = "" } = action.payload;

    const { items = [], pagination = {} } = yield JobService.getJobList(pageNumber, pageSize, searchText);

    yield put(JobActions.getJobListSuccess({ jobList: items, jobListPagination: pagination }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.getJobListFailure({ error }));
  }
}

// Get Job Info
function* getJobInfo(action) {
  try {
    const { jobId = "" } = action.payload;

    const jobInfo = yield JobService.getJobInfo(jobId);

    yield put(JobActions.getJobInfoSuccess({ jobInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.getJobInfoFailure({ error }));
  }
}

// Get Job Session
function* getJobSession(action) {
  try {
    const { jobId = "" } = action.payload;

    const { items = [] } = yield JobService.getJobSession(jobId);

    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.getJobSessionFailure({ error }));
  }
}

// Run Job
function* runJob(action) {
  try {
    const { job = {}, jobId = "" } = action.payload;

    yield JobService.runJob(job, jobId);

    const jobInfo = yield JobService.getJobInfo(jobId);

    const { items = [] } = yield JobService.getJobSession(jobId);

    yield put(JobActions.getJobInfoSuccess({ jobInfo }));

    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));

    yield put(JobActions.runJobSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.runJobFailure({ error }));
  }
}

// Resume Job By Id
function* resumeJob(action) {
  try {
    const { jobId = "" } = action.payload;

    yield JobService.resumeJob(jobId);

    const jobInfo = yield JobService.getJobInfo(jobId);

    const { items = [] } = yield JobService.getJobSession(jobId);

    yield put(JobActions.getJobInfoSuccess({ jobInfo }));

    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));

    yield put(JobActions.resumeJobSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.resumeJobFailure({ error }));
  }
}

// Pause Job By Id
function* pauseJob(action) {
  try {
    const { jobId = "" } = action.payload;

    yield JobService.pauseJob(jobId);

    const jobInfo = yield JobService.getJobInfo(jobId);

    const { items = [] } = yield JobService.getJobSession(jobId);

    yield put(JobActions.getJobInfoSuccess({ jobInfo }));

    yield put(JobActions.getJobSessionSuccess({ jobSessions: items }));

    yield put(JobActions.pauseJobSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(JobActions.pauseJobFailure({ error }));
  }
}

export default function* root() {
  yield all([
    //
    takeLatest(JobActions.getJobList.type, getJobList),

    //
    takeLatest(JobActions.getJobInfo.type, getJobInfo),
    takeLatest(JobActions.getJobSession.type, getJobSession),

    //
    takeLatest(JobActions.runJob.type, runJob),
    takeLatest(JobActions.resumeJob.type, resumeJob),
    takeLatest(JobActions.pauseJob.type, pauseJob),
  ]);
}
