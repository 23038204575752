import { useCallback, useEffect, useState } from "react";
import { useUpdateNodeInternals } from "@xyflow/react";
import { Avatar } from "antd";

// React Flow
import HandlesCont from "../connection/HandlesCont";

// Utils
import GraphUtil from "../GraphUtils";

// Components
import Icon from "../../components/icon/Icon";

// Page Constants
const PX_SPACING = 25;
const DEFAULT_HANDLE_IDS = { sourceIds: [], targetIds: [] };
const DEFAULT_HANDLE_COUNTS = { sourceCount: 0, targetCount: 0 };

/**
 * Component Node
 * @param {*} id : Node Id
 * @param {*} data : Component Data
 */
export default function ComponentNode({ id = "", data = {} }) {
  const updateNodeInternals = useUpdateNodeInternals();

  // State
  const [handleIds, setHandleIds] = useState(DEFAULT_HANDLE_IDS);
  const [handleCount, setHandleCount] = useState(DEFAULT_HANDLE_COUNTS);
  const [bodyHeight, setBodyHeight] = useState(0);

  // Handle Data
  const { sourceIds = [], targetIds = [] } = handleIds;
  const { sourceCount = 0, targetCount = 0 } = handleCount;

  // Component Data
  const { componentTypeDetails = {}, node = {}, sourceHandleIds = [], targetHandleIds = [] } = data;

  const { name: componentTypeName = "", code: componentTypeCode = "" } = componentTypeDetails;
  const { name = "", internalId = "", imageURL = "" } = node;

  // Callbacks
  const estimateBodyHeight = useCallback(() => {
    const handlerCount = Math.max(sourceCount, targetCount);

    // Update Body Height
    const newBodyHeight = (handlerCount + 1) * PX_SPACING;
    return newBodyHeight;
  }, [sourceCount, targetCount]);

  const addHandle = useCallback(
    (isSource = false) => {
      const [idsKey, countKey] = isSource ? ["sourceIds", "sourceCount"] : ["targetIds", "targetCount"];

      // Body Height
      const newBodyHeight = estimateBodyHeight(isSource);
      setBodyHeight(newBodyHeight);

      setHandleCount((prev) => ({ ...prev, [countKey]: prev[countKey] + 1 }));
      setHandleIds((prev) => {
        const lastHandleId = prev[idsKey].at(-1) || "";
        const nextHandleId = GraphUtil.nextHandleId(isSource, lastHandleId);
        return { ...prev, [idsKey]: [...prev[idsKey], nextHandleId] };
      });

      updateNodeInternals(id);
    },
    [id, estimateBodyHeight, updateNodeInternals]
  );

  // use Effect
  useEffect(() => {
    setBodyHeight(estimateBodyHeight());
  }, [estimateBodyHeight, handleCount]);

  useEffect(() => {
    setHandleIds({ sourceIds: sourceHandleIds, targetIds: targetHandleIds });
    setHandleCount({ sourceCount: sourceHandleIds.length, targetCount: targetHandleIds.length });
  }, []);

  return (
    <div className="bg-white p-2 border border-black rounded-3 node" style={{ minHeight: bodyHeight }}>
      <div className="d-flex fs-7">
        <div>
          <Avatar shape="square" src={imageURL} size={27}>
            {componentTypeCode}
          </Avatar>
        </div>
        <div className="ps-2">
          <div>{componentTypeName}</div>
          <span className="bold">{name} </span>({internalId})
        </div>
      </div>

      <Icon iconName="circlePlus" size={10} className="btn-add-handle right" onClick={() => addHandle(true)} />
      <Icon iconName="circlePlus" size={10} className="btn-add-handle left" onClick={() => addHandle()} />

      <HandlesCont type="source" handles={sourceIds} nodeId={id} />
      <HandlesCont type="target" handles={targetIds} nodeId={id} />
    </div>
  );
}
