import { createSlice } from "@reduxjs/toolkit";

// Transformer
import NICCodeTransformer from "./NICCodeTransformer";

// Initial State
const initialState = {
  // Get NIC Code - List
  nicCodeListMap: [],
  nicCodeListPagination: {},
  nicCodeListLoading: false,
  nicCodeListSuccess: false,
  nicCodeListError: "",
};

// NIC Code List
const NICCodeSlice = createSlice({
  name: "NICCode",
  initialState,
  reducers: {
    // Get NIC Code - List
    getNICCodeList: (state) => {
      state.nicCodeListSuccess = false;
      state.nicCodeListLoading = true;
    },

    getNICCodeListSuccess: (state, action) => {
      const { nicCodeList, nicCodeListPagination } = action.payload;
      state.nicCodeListMap = NICCodeTransformer.transformList(nicCodeList);

      state.nicCodeListPagination = nicCodeListPagination;
      state.nicCodeListSuccess = true;
      state.nicCodeListLoading = false;
    },

    getNICCodeListFailure: (state, action) => {
      state.nicCodeListLoading = false;
      state.nicCodeListError = action.payload.error;
    },
  },
});

// Reducer
export const NICCodeReducer = NICCodeSlice.reducer;

// Actions
export const NICCodeActions = NICCodeSlice.actions;
