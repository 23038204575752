// URLs
import PageURLs, { URL_ORG_LIST_AUDIT_FIRM, URL_ORG_LIST_INDUSTRIES } from "../constants/pageUrls";

// Sidebar Images
import LOGO_KISEM from "../assets/images/branding/KISEM_LOGO_1.png";
import LOGO_KISEM_TEXT from "../assets/images/branding/KISEM_LOGO_2.png";

/**
 * Sidebar Menu
 */

export function constructSidebarMenuConfig(sidebarMenuList) {
  const sidebarMenuConfig = {};

  for (const menuItem of sidebarMenuList) {
    const { id, label, redirectUrl = "" } = menuItem;
    sidebarMenuConfig[label] = {
      menuItemId: id,
      redirectUrl,
    };
  }

  return sidebarMenuConfig;
}

export const SidebarMenus = {
  PLATFORM: [
    {
      title: "Audit Home",
      items: [
        {
          id: "Audit_Home_1",
          label: PageURLs.AuditListPageUrl,
          redirectUrl: PageURLs.AuditListPageUrl,

          itemName: "Audit Dashboard",
          itemIconCls: "far fa-list-alt",
        },
        {
          id: "Audit_Home_2",
          label: URL_ORG_LIST_AUDIT_FIRM,
          redirectUrl: URL_ORG_LIST_AUDIT_FIRM,

          itemName: "Audit Firm",
          itemIconCls: "fas fa-user-tie",
        },
        {
          id: "Audit_Home_3",
          label: URL_ORG_LIST_INDUSTRIES,
          redirectUrl: URL_ORG_LIST_INDUSTRIES,

          itemName: "Industries",
          itemIconCls: "fas fa-warehouse",
        },
      ],
    },
    {
      title: "Tools",
      items: [
        {
          id: "Tools_1",
          label: PageURLs.UnitConversionToolPageUrl,
          redirectUrl: PageURLs.UnitConversionToolPageUrl,

          itemName: "Unit Conversions",
          itemIconCls: "fa fa-calculator",
        },
      ],
    },
    {
      title: "Definitions",
      items: [
        {
          id: "Definitions_1",
          label: PageURLs.MeasurementTypeListPageUrl,
          redirectUrl: PageURLs.MeasurementTypeListPageUrl,

          itemName: "Measurements",
          itemIconCls: "fas fa-ruler",
        },
        {
          id: "Definitions_2",
          label: PageURLs.ComponentTypeListPageUrl,
          redirectUrl: PageURLs.ComponentTypeListPageUrl,

          itemName: "Components Types",
          itemIconCls: "fas fa-project-diagram",
        },
        {
          id: "Definitions_3",
          label: PageURLs.HSNCodeListPageUrl,
          redirectUrl: PageURLs.HSNCodeListPageUrl,

          itemName: "HSN Codes",
          itemIconCls: "fas fa-square-binary",
        },
        {
          id: "Definitions_4",
          label: PageURLs.NICCodeListPageUrl,
          redirectUrl: PageURLs.NICCodeListPageUrl,

          itemName: "NIC Codes",
          itemIconCls: "fas fa-square-binary",
        },
      ],
    },
  ],

  AUDIT: [
    {
      title: "Audit Home",
      items: [
        {
          id: "Audit_Home_1",
          label: PageURLs.AuditListPageUrl,
          redirectUrl: PageURLs.AuditListPageUrl,

          itemName: "All Audits",
          itemIconCls: "far fa-list-alt",
        },
        {
          id: "Audit_Home_2",
          label: PageURLs.AuditListByAuditorPageUrl,
          redirectUrl: PageURLs.AuditListByAuditorPageUrl,

          itemName: "My Audits",
          itemIconCls: "fa fa-clipboard-list",
        },
      ],
    },
    {
      title: "Industry",
      items: [
        {
          id: "Industry_Home_1",
          label: URL_ORG_LIST_INDUSTRIES,
          redirectUrl: URL_ORG_LIST_INDUSTRIES,

          itemName: "Industries",
          itemIconCls: "fas fa-warehouse",
        },
      ],
    },
    {
      title: "Administration",
      items: [
        {
          id: "Administration_Home_1",
          label: PageURLs.UserListPageUrl,
          redirectUrl: PageURLs.UserListPageUrl,

          itemName: "Users",
          itemIconCls: "fas fa-users",
        },
      ],
    },
  ],

  INDUSTRY: [
    {
      title: "Audit Home",
      items: [
        {
          id: "Audit_Home_1",
          label: PageURLs.AuditListPageUrl,
          redirectUrl: PageURLs.AuditListPageUrl,

          itemName: "My Audits",
          itemIconCls: "far fa-list-alt",
        },
      ],
    },
    {
      title: "Administration",
      items: [
        {
          id: "Administration_Home_1",
          label: PageURLs.PlantListPageUrl,
          redirectUrl: PageURLs.PlantListPageUrl,

          itemName: "Plants",
          itemIconCls: "fas fa-industry",
        },
        {
          id: "Administration_Home_2",
          label: PageURLs.UserListPageUrl,
          redirectUrl: PageURLs.UserListPageUrl,

          itemName: "Users",
          itemIconCls: "fas fa-users",
        },
      ],
    },
  ],
};

export const AuditLayoutSideBar = {
  sideBarElements: [
    {
      title: "Audit View",
      items: [
        {
          id: "Audit_View_1",
          label: PageURLs.AuditOverviewPageUrl,
          redirectUrl: PageURLs.AuditOverviewPageUrl,

          itemName: "Overview",
          itemIconCls: "fas fa-tachometer-alt",
        },
        {
          id: "Audit_View_2",
          label: PageURLs.AuditStatusPageUrl,
          redirectUrl: PageURLs.AuditStatusPageUrl,

          itemName: "Status",
          itemIconCls: "fas fa-spinner",
        },
        {
          id: "Audit_View_3",
          label: PageURLs.AuditWalkthroughRemarksPageUrl,
          redirectUrl: PageURLs.AuditWalkthroughRemarksPageUrl,

          itemName: "WalkThrough Remarks",
          itemIconCls: "fas fa-clipboard",
        },
        {
          id: "Audit_View_4",
          label: PageURLs.AuditMeasurementsPageUrl,
          redirectUrl: PageURLs.AuditMeasurementsPageUrl,

          itemName: "Measurements & Results",
          itemIconCls: "fas fa-ruler",
        },
        {
          id: "Audit_View_5",
          label: PageURLs.AuditObservationsPageUrl,
          redirectUrl: PageURLs.AuditObservationsPageUrl,

          itemName: "Observations & Recommendations",
          itemIconCls: "fas fa-eye",
        },
        {
          id: "Audit_View_6",
          label: PageURLs.AuditSavingsCalculationPageUrl,
          redirectUrl: PageURLs.AuditSavingsCalculationPageUrl,

          itemName: "Energy Savings",
          itemIconCls: "fas fa-bolt",
        },
        {
          id: "Audit_View_7",
          label: PageURLs.AuditDataPageUrl,
          redirectUrl: PageURLs.AuditDataPageUrl,

          itemName: "Data",
          itemIconCls: "fas fa-file-excel",
        },
        {
          id: "Audit_View_8",
          label: PageURLs.AuditReportsListPageUrl,
          redirectUrl: PageURLs.AuditReportsListPageUrl,

          itemName: "Reports",
          itemIconCls: "fas fa-file-pdf",
        },
        {
          id: "Audit_View_9",
          label: PageURLs.AuditFileListPageUrl,
          redirectUrl: PageURLs.AuditFileListPageUrl,

          itemName: "Audit Files",
          itemIconCls: "fas fa-file-arrow-up",
        },
      ],
    },
  ],
  backButtonConfig: {
    link: PageURLs.AuditListPageUrl,
  },
};

// Plant Layout Sidebar Menus
export const PlantLayoutSideBar = {
  sideBarElements: [
    {
      title: "Plant Setup",
      items: [
        {
          id: "Plant_Setup_1",
          label: PageURLs.PlantOverviewPageUrl,
          redirectUrl: PageURLs.PlantOverviewPageUrl,

          itemName: "Overview",
          itemIconCls: "fas fa-info",
        },
        {
          id: "Plant_Setup_2",
          label: PageURLs.PlantComponentsPageUrl,
          redirectUrl: PageURLs.PlantComponentsPageUrl,

          itemName: "Components",
          itemIconCls: "fas fa-project-diagram",
        },
        {
          id: "Plant_Setup_3",
          label: PageURLs.PlantComponentConnectionPageUrl,
          redirectUrl: PageURLs.PlantComponentConnectionPageUrl,

          itemName: "Component Connection",
          itemIconCls: "fas fa-circle-nodes",
        },
        {
          id: "Plant_Setup_4",
          label: PageURLs.PlantPowerConnectionListPageUrl,
          redirectUrl: PageURLs.PlantPowerConnectionListPageUrl,

          itemName: "Power Connections",
          itemIconCls: "fas fa-bolt",
        },
        {
          id: "Plant_Setup_5",
          label: PageURLs.PlantElectricityBillPageUrl,
          redirectUrl: PageURLs.PlantElectricityBillPageUrl,

          itemName: "Electricity Bills",
          itemIconCls: "fas fa-file-invoice-dollar",
        },
        {
          id: "Plant_Setup_6",
          label: PageURLs.PlantThermalBillPageUrl,
          redirectUrl: PageURLs.PlantThermalBillPageUrl,

          itemName: "Thermal Bills",
          itemIconCls: "fas fa-file-invoice-dollar",
        },
        {
          id: "Plant_Setup_7",
          label: PageURLs.PlantUserListPageUrl,
          redirectUrl: PageURLs.PlantUserListPageUrl,

          itemName: "Users",
          itemIconCls: "fas fa-user",
        },
        {
          id: "Plant_Setup_8",
          label: PageURLs.PlantFileListPageUrl,
          redirectUrl: PageURLs.PlantFileListPageUrl,

          itemName: "Plant Files",
          itemIconCls: "fas fa-file-arrow-up",
        },
      ],
    },
    {
      title: "Audit",
      items: [
        {
          id: "Audit_1",
          label: PageURLs.PlantAuditListPageUrl,
          redirectUrl: PageURLs.PlantAuditListPageUrl,

          itemName: "Audits",
          itemIconCls: "fas fa-file-pen",
        },
      ],
    },
  ],
  backButtonConfig: {
    link: PageURLs.PlantListPageUrl,
  },
};

// User Layout Sidebar Menus
export const UserLayoutSideBar = {
  sideBarElements: [
    {
      title: "User Settings",
      items: [
        {
          id: "Audit_1",
          label: PageURLs.ProfilePageUrl,
          redirectUrl: PageURLs.ProfilePageUrl,

          itemName: "Profile",
          itemIconCls: "fas fa-user",
        },

        {
          id: "Audit_2",
          label: "logout",
          redirectUrl: "logout",

          itemName: "Log out",
          itemIconCls: "fas fa-power-off",
        },
      ],
    },
  ],
  backButtonConfig: {
    link: PageURLs.DashboardPageUrl,
  },
};

//Sidebar Header Constants
export const SIDEBAR_HEADER_CONSTANTS = {
  headerTitle: "Energy Audit Tool",

  logoHeight: 40,
  logoURL: LOGO_KISEM_TEXT,

  collapsedLogoHeight: 30,
  collapsedLogoURL: LOGO_KISEM,
};
