function getHeaders() {
  return [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Is Part",
      dataIndex: "isPart",
      render: (text, record) => (record.isPart ? "YES" : ""),
    },
    {
      title: "Sub Types",
      dataIndex: "isPart",
      render: (text, record) => record.subTypeCodes.join(", "),
    },
  ];
}

const ComponentTypeListTableHelper = {
  getHeaders,
};

export default ComponentTypeListTableHelper;
