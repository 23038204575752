// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { PlantUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Plant List
async function getPlantList(orgId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    org: orgId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(PlantUrls.getPlantList, params);
}

// Get Plant Info
async function getPlantInfo(plantId) {
  const url = URLs.format(PlantUrls.getPlantInfo, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Add Plant
async function addPlant(plantData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(PlantUrls.addPlant, {}, plantData);
}

// Update Plant
async function updatePlant(plantData, plantId) {
  const url = URLs.format(PlantUrls.updatePlant, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, plantData);
}

// Update Plant status
async function updatePlantStatus(plantId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(PlantUrls.updatePlantStatus, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const PlantService = {
  getPlantList,
  getPlantInfo,
  addPlant,
  updatePlant,
  updatePlantStatus,
};

export default PlantService;
