// Actions
import { AuditDataActions } from "../../store/slices/audit/dataSlice";

const dataTableBody = [
  {
    id: 1,
    description: "Download Performance Analysis Excel",
    loadingState: (state) => state.auditData.downloadPerfAnalysisLoading,
    dispatchAtn: AuditDataActions.downloadPerfAnalysisData,
  },
  {
    id: 2,
    description: "Download Recommendation Excel",
    loadingState: (state) => state.auditData.downloadRecommendationLoading,
    dispatchAtn: AuditDataActions.downloadRecommendationData,
  },
];

function getHeaders() {
  return [
    {
      title: "Description",
      dataIndex: "description",
    },
  ];
}

function constructTableData() {
  const dataRowMap = {};

  for (const data of dataTableBody) {
    const { id } = data;

    const auditRow = {
      key: id,
      ...data,
    };
    dataRowMap[id] = auditRow;
  }

  return dataRowMap;
}

const AuditDataTableHelper = {
  getHeaders,
  constructTableData,
};

export default AuditDataTableHelper;
