// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { TemplateUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Template List
async function getTemplateList(pageNumber, pageSize, filters) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,

    ...filters,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(TemplateUrls.getTemplateList, params);
}

// Get Template Parameter Values
async function getTemplateParamValues(templateId) {
  const url = URLs.format(TemplateUrls.getTemplateParamValues, { templateId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

const TemplateService = {
  getTemplateList,
  getTemplateParamValues,
};

export default TemplateService;
