import { useRouteLoaderData } from "react-router-dom";

// Constants
import { LayoutIds } from "../../../../constants/generalConstants";

// Page Components
export function PlantInfoView({ plantInfo }) {
  const {
    name,
    internalId,
    areaInSqFt,
    estYear,
    workHrsPerDay,
    workDaysPerWeek,
    employeesCount,
    addrLine1,
    addrLine2 = "",
    city,
    state,
    pincode,
  } = plantInfo;

  return (
    <div className="plant-view">
      <div className="card card-info">
        <div className="card-body">
          <h5 className="card-title">Plant Information</h5>

          <div className="row mt-4">
            <div className="col-2">
              <label className="bold">Name</label>
            </div>
            <div className="col-2">
              <label>{name}</label>
            </div>
            <div className="col-2">
              <label className="bold">Internal Id </label>
            </div>
            <div className="col-2">
              <label>{internalId}</label>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2">
              <label className="bold">Established Year</label>
            </div>
            <div className="col-2">
              <label>{estYear}</label>
            </div>
            <div className="col-2">
              <label className="bold">Area (Sq ft)</label>
            </div>
            <div className="col-2">
              <label>{areaInSqFt}</label>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2">
              <label className="bold">Working Hours / Day</label>
            </div>
            <div className="col-2">
              <label>{workHrsPerDay}</label>
            </div>
            <div className="col-2">
              <label className="bold">Working Days / Week</label>
            </div>
            <div className="col-2">
              <label>{workDaysPerWeek}</label>
            </div>
            <div className="col-2">
              <label className="bold">Employee Count</label>
            </div>
            <div className="col-2">
              <label>{employeesCount}</label>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2">
              <label className="bold">Plant Address</label>
            </div>
            <div className="col-4">
              <p className="mb-1">{addrLine1}</p>
              <p className="mb-1">{addrLine2}</p>
              <p className="">
                {city}, {state}, {pincode}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Plant Info Page
 */
export default function PlantInfoPage() {
  // Plant Info Selector State

  // Get saved info from the loader of AuditLayout
  // There is other way to get this through OutLet
  // TODO: Need to find which is the better way to access the data..
  const { plantInfo } = useRouteLoaderData(LayoutIds.PLANT);

  return (
    <div className="card border-0">
      <div className="card-body">
        {/* Plant Info View */}
        <PlantInfoView plantInfo={plantInfo} />
      </div>
    </div>
  );
}
