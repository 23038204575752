import { createSlice } from "@reduxjs/toolkit";

// Utils
import LocalStorage from "../../../utils/localStorageUtils";

// Initial State
const initialState = {
  dataPreLoading: false,
  dataPreLoadError: "",
};

// Data Pre Load Slice
const dataPreLoadSlice = createSlice({
  name: "DataPreLoad",
  initialState,

  reducers: {
    // Data Pre Load
    dataPreLoad: (state) => {
      state.dataPreLoading = true;
    },

    dataPreLoadSuccess: (state, action) => {
      const { key = "", data = {} } = action.payload;
      LocalStorage.set(key, JSON.stringify(data));

      state.dataPreLoading = false;
    },

    dataPreLoadFailure: (state, action) => {
      state.dataPreLoading = false;
      state.dataPreLoadError = action.payload.error;
    },
  },
});

// Actions
export const DataPreLoadActions = dataPreLoadSlice.actions;

// Reducer
export const DataPreLoadReducer = dataPreLoadSlice.reducer;
