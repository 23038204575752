import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ReportTransformer from "./reportTransformer";

function resetApiSuccessState(state) {
  state.updateReportSuccess = false;
  state.createReportSuccess = {};
  state.deleteReportSuccess = {};
  state.downloadPDFSuccess = {};
}

// Initial State
const initialState = {
  // Get Report List
  reportMap: {},
  reportListLoading: false,
  reportListError: "",

  // Create Report
  createdReportInfo: {},
  createReportLoading: {},
  createReportSuccess: {},
  createReportError: "",

  // Update Report
  updateReportLoading: false,
  updateReportSuccess: false,
  updateReportError: "",

  // Delete Report
  deleteReportLoading: {},
  deleteReportSuccess: {},
  deleteReportError: "",

  // Get Report HTML
  reportHTML: "",
  reportHTMLLoading: false,
  reportHTMLError: "",

  // Download PDF
  PDFData: {},
  downloadPDFSuccess: {},
  downloadPDFLoading: {},
  downloadPDFError: "",

  // Update Report Status
  updateReportStatusLoadingMap: {},
  updateReportStatusError: "",

  // Get Report Data
  reportData: {},
  reportDataLoading: false,
  reportDataError: "",
};

// Audit Reports Slice
const auditReportSlice = createSlice({
  name: "AuditReport",
  initialState,

  reducers: {
    // Get Report List
    getReportList: (state) => {
      state.reportListLoading = true;
      state.reportListError = "";
    },

    getReportListSuccess: (state, action) => {
      const { reportList = [] } = action.payload;
      state.reportMap = ReportTransformer.transformList(reportList);

      state.reportListLoading = false;
    },

    getReportListFailure: (state, action) => {
      state.reportListLoading = false;
      state.reportListError = action.payload.error;
    },

    // Create Report
    createReport: (state, action) => {
      const { reportType = "" } = action.payload;
      state.createReportSuccess[reportType] = false;
      state.createReportLoading[reportType] = true;
      state.createReportError = "";
    },

    createReportSuccess: (state, action) => {
      const { reportType = "", createdReportInfo = {} } = action.payload;
      state.createdReportInfo = ReportTransformer.transformObj(createdReportInfo);

      state.createReportSuccess[reportType] = true;
      delete state.createReportLoading[reportType];
    },

    createReportFailure: (state, action) => {
      const { reportType = "", error = {} } = action.payload;
      delete state.createReportLoading[reportType];
      state.createReportError = error;
    },

    // Update Report
    updateReport: (state) => {
      state.updateReportSuccess = false;
      state.updateReportLoading = true;
    },

    updateReportSuccess: (state) => {
      state.updateReportSuccess = true;
      state.updateReportLoading = false;
      state.updateReportError = "";
    },

    updateReportFailure: (state, action) => {
      state.updateReportLoading = false;
      state.updateReportError = action.payload.error;
    },

    // Delete Report
    deleteReport: (state, action) => {
      const { reportId = "" } = action.payload;
      state.deleteReportSuccess[reportId] = false;
      state.deleteReportLoading[reportId] = true;
      state.deleteReportError = "";
    },

    deleteReportSuccess: (state, action) => {
      const { reportId = "" } = action.payload;
      state.deleteReportSuccess[reportId] = true;
      delete state.deleteReportLoading[reportId];
    },

    deleteReportFailure: (state, action) => {
      const { reportId = "", error = "" } = action.payload;
      delete state.deleteReportLoading[reportId];
      state.deleteReportError = error;
    },

    // Get Report HTML
    getReportHTML: (state) => {
      state.reportHTMLLoading = true;
    },

    getReportHTMLSuccess: (state, action) => {
      state.reportHTML = action.payload.reportHTML;
      state.reportHTMLLoading = false;
      state.reportHTMLError = "";
    },

    getReportHTMLFailure: (state, action) => {
      state.reportHTMLLoading = false;
      state.reportHTMLError = action.payload.error;
    },

    // Download PDF
    downloadPDF: (state, action) => {
      const { reportId = "" } = action.payload;
      state.downloadPDFSuccess[reportId] = false;
      state.downloadPDFLoading[reportId] = true;
      state.downloadPDFError = "";
    },

    downloadPDFSuccess: (state, action) => {
      const { reportId = "", PDFData = {} } = action.payload;
      state.PDFData = PDFData;
      state.downloadPDFSuccess[reportId] = true;
      delete state.downloadPDFLoading[reportId];
    },

    downloadPDFFailure: (state, action) => {
      const { reportId = "", error = "" } = action.payload;
      delete state.downloadPDFLoading[reportId];
      state.downloadPDFError = error;
    },

    // Update Report Status
    updateReportStatus: (state, action) => {
      const { reportId = "" } = action.payload;
      state.updateReportStatusLoadingMap[reportId] = true;
    },

    updateReportStatusSuccess: (state, action) => {
      const { reportId = "" } = action.payload;
      delete state.updateReportStatusLoadingMap[reportId];

      state.updateReportStatusError = "";
    },

    updateReportStatusFailure: (state, action) => {
      const { reportId = "", error = "" } = action.payload;
      delete state.updateReportStatusLoadingMap[reportId];

      state.updateReportStatusError = error;
    },

    // Get Report Data
    getReportData: (state) => {
      state.reportDataLoading = true;
    },

    getDataSuccess: (state, action) => {
      const { reportData = {} } = action.payload;
      state.reportData = ReportTransformer.transformDataObj(reportData);

      state.reportDataLoading = false;
      state.reportDataError = "";
    },

    getDataFailure: (state, action) => {
      state.reportDataLoading = false;
      state.reportDataError = action.payload.reportDataError;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditReportReducer = auditReportSlice.reducer;

// Actions
export const AuditReportActions = auditReportSlice.actions;
