import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";

// Actions
import { OrganizationActions } from "../../../store/slices/organization/organizationSlice";
import { PlantActions } from "../../../store/slices/plant/plantSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";
import { QueryParamsKeys } from "../../../constants/generalConstants";

// Utils
import { bcForPlantListPage } from "../../../utils/breadcrumbUtils";
import URLs from "../../../utils/urlUtils";

// Components
import { Button } from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import PlantListTable from "./PlantListTable";

// Page Component
function PageHeaderSection({ organizationInfo }) {
  const { name = "" } = organizationInfo || {};
  return <PageHeader breadcrumbList={bcForPlantListPage(name)} className="w-100 mt-2" />;
}

function TableActionsSection({ organizationInfo }) {
  const navigate = useNavigate();

  const orgId = organizationInfo?.id;

  function navigateToPlantCreatePage() {
    const redirectUrl = URLs.format(PageURLs.PlantCreatePageUrl, { orgId });
    navigate(redirectUrl);
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search Plants...`} isSearchInput={true} />

      <div className="btn-cont">
        <Button className="m-0" label="Add Plant" onClick={navigateToPlantCreatePage}>
          <i className="fas fa-plus"></i>
        </Button>
      </div>
    </div>
  );
}

/**
 * Plant List Page
 */
export default function PlantListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { orgId = "" } = useParams();

  // Search Params
  const [searchParams] = useSearchParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Plant List Selector State
  const plantListMap = useSelector((state) => state.plant.plantListMap);
  const plantList = Object.values(plantListMap);
  const plantListLoading = useSelector((state) => state.plant.plantListLoading);
  const plantListPagination = useSelector((state) => state.plant.plantListPagination);

  // Orgnization Info Selector State
  const organizationInfo = useSelector((state) => state.organization.organizationInfo);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(PlantActions.getPlantList({ pageNumber, pageSize, searchText, orgId }));
  }, [dispatch, orgId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    return () => dispatch(PlantActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection organizationInfo={organizationInfo} />);
  }, [organizationInfo]);

  return (
    <div className="page-content">
      {!plantListLoading && <TableActionsSection organizationInfo={organizationInfo} />}

      {/* Plant List Table */}
      <PlantListTable
        plantList={plantList}
        plantListLoading={plantListLoading}
        plantListPagination={plantListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
