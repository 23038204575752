import { redirect } from "react-router-dom";

// App Components
import ErrorHandler from "../components/error/ErrorHandler";
import ProtectedRoute from "../hoc/ProtectedRoute";

// Constants
import { LayoutIds } from "../constants/generalConstants";
import { DataPreLoadObj } from "../constants/dataPreLoadConstants";

// Utils
import URLs from "../utils/urlUtils";

// URLs
import PageURLs from "../constants/pageUrls";

// Layout
import AuditLayout from "../components/layout/AuditLayout";

// Route Loaders
import { auditInfoLoader } from "../route-loaders/auditViewLoader";

// Sidebar Elements
import AuditInfoPage from "../pages/audit/audit-view/audit-info/AuditInfoPage";
import AuditStatusPage from "../pages/audit/audit-view/audit-status/AuditStatusPage";
import AuditMeasurementsPage from "../pages/audit/audit-view/audit-measurement/AuditMeasurementsPage";
import AuditObservationsPage from "../pages/audit/audit-view/audit-observations/AuditObservationsPage";
import AuditSavingsCalculationPage from "../pages/audit/audit-view/audit-savings-calculation/AuditSavingsCalculationPage";
import AuditDataPage from "../pages/audit/audit-view/audit-data/AuditDataPage";
import AuditReportsListPage from "../pages/audit/audit-view/audit-reports/AuditReportsListPage";
import AuditWalkThroughRemarksPage from "../pages/audit/audit-view/audit-walk-through-remarks/AuditWalkThroughRemarksPage";
import AuditFileListPage from "../pages/audit/audit-view/audit-files/AuditFileListPage";
import AuditReportEditorPage from "../pages/audit/audit-view/audit-report-editor/AuditReportEditorPage";

// Audit Pages and Layouts
export const AuditRoutes = {
  path: PageURLs.AuditViewPageUrl,
  loader: ({ params }) => {
    return auditInfoLoader(params.auditId);
  },
  id: LayoutIds.AUDIT,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute dataPreLoadObj={DataPreLoadObj}>
        {/* Layout */}
        <AuditLayout />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      // This logic selects one of the sideBar by default..
      index: true,
      loader: ({ params }) => {
        const auditId = params.auditId;
        const redirectUrl = URLs.format(PageURLs.AuditOverviewPageUrl, { auditId });
        return redirect(redirectUrl);
      },
    },
    {
      path: PageURLs.AuditOverviewPageUrl,
      element: <AuditInfoPage />,
    },
    {
      path: PageURLs.AuditStatusPageUrl,
      element: <AuditStatusPage />,
    },
    {
      path: PageURLs.AuditWalkthroughRemarksPageUrl,
      element: <AuditWalkThroughRemarksPage />,
    },
    {
      path: PageURLs.AuditMeasurementsPageUrl,
      element: <AuditMeasurementsPage />,
    },
    {
      path: PageURLs.AuditObservationsPageUrl,
      element: <AuditObservationsPage />,
    },
    {
      path: PageURLs.AuditSavingsCalculationPageUrl,
      element: <AuditSavingsCalculationPage />,
    },
    {
      path: PageURLs.AuditFileListPageUrl,
      element: <AuditFileListPage />,
    },
    {
      path: PageURLs.AuditDataPageUrl,
      element: <AuditDataPage />,
    },
    {
      path: PageURLs.AuditReportsListPageUrl,
      element: <AuditReportsListPage />,
    },
    {
      path: PageURLs.AuditReportEditorPageUrl,
      element: <AuditReportEditorPage />,
    },
  ],
};
