import { createSlice } from "@reduxjs/toolkit";

// Transformer
import AuditComponentTransformer from "./componentTransformer";

function resetApiSuccessState(state) {
  state.addWalkthroughRemarksSuccess = false;
  state.addWalkthroughImageSuccess = false;
  state.addRemarksSuccess = {};
}

// Initial State
const initialState = {
  // Get Plant Component List
  plantComponentListMap: [],
  plantComponentListLoading: false,
  plantComponentListError: "",

  // Get Component Information
  componentInfo: {},
  componentInfoLoading: false,
  componentInfoError: {},

  // Get Components Detail
  componentsDetail: {},
  componentsDetailLoading: false,
  componentsDetailError: {},

  // Add Walkthrough Remarks
  addWalkthroughRemarksLoading: false,
  addWalkthroughRemarksSuccess: false,
  addWalkthroughRemarksError: {},

  // Add Walkthrough Remarks Image
  addWalkthroughImageLoading: false,
  addWalkthroughImageSuccess: false,
  addWalkthroughImageError: {},

  // Add Remarks
  addRemarksLoading: {},
  addRemarksSuccess: {},
  addRemarksError: {},
};

// Audit Component Slice
const auditComponentSlice = createSlice({
  name: "AuditComponent",
  initialState,

  reducers: {
    // Get Plant Component List
    getPlantComponentList: (state) => {
      state.plantComponentListLoading = true;
    },

    getPlantComponentListSuccess: (state, action) => {
      const { plantComponentList = [] } = action.payload;
      state.plantComponentListMap = AuditComponentTransformer.transformList(plantComponentList);

      state.plantComponentListLoading = false;
    },

    getPlantComponentListFailure: (state, action) => {
      state.plantComponentListLoading = false;
      state.plantComponentListError = action.payload.error;
    },

    // Get Component Information
    getComponentInfo: (state) => {
      state.componentInfoLoading = true;
    },

    getComponentInfoSuccess: (state, action) => {
      const { componentInfo = {} } = action.payload;
      state.componentInfo = AuditComponentTransformer.transformComponentInfoObj(componentInfo);

      state.componentInfoLoading = false;
      state.componentInfoError = "";
    },

    getComponentInfoFailure: (state, action) => {
      state.componentInfoLoading = false;
      state.componentInfoError = action.payload.error;
    },

    // Get Components Detail
    getComponentsDetail: (state) => {
      state.componentsDetailLoading = true;
    },

    getComponentsDetailSuccess: (state, action) => {
      const { componentsDetail = {} } = action.payload;
      state.componentsDetail = AuditComponentTransformer.transformComponentDetailsObj(componentsDetail);

      state.componentsDetailLoading = false;
      state.componentsDetailError = "";
    },

    getComponentsDetailFailure: (state, action) => {
      state.componentsDetailLoading = false;
      state.componentsDetailError = action.payload.error;
    },

    // Add Walkthrough Remarks
    addWalkthroughRemarks: (state) => {
      state.addWalkthroughRemarksSuccess = false;
      state.addWalkthroughRemarksLoading = true;
    },

    addWalkthroughRemarksSuccess: (state, action) => {
      state.addWalkthroughRemarksLoading = false;
      state.addWalkthroughRemarksSuccess = true;
      state.addWalkthroughRemarksError = "";
    },

    addWalkthroughRemarksFailure: (state, action) => {
      state.addWalkthroughRemarksLoading = false;
      state.addWalkthroughRemarksError = action.payload.error;
    },

    // Add Walkthrough Remarks Image
    addWalkthroughImage: (state) => {
      state.addWalkthroughImageSuccess = false;
      state.addWalkthroughImageLoading = true;
    },

    addWalkthroughImageSuccess: (state) => {
      state.addWalkthroughImageLoading = false;
      state.addWalkthroughImageSuccess = true;
      state.addWalkthroughRemarksError = "";
    },

    addWalkthroughImageFailure: (state, action) => {
      state.addWalkthroughImageLoading = false;
      state.addWalkthroughImageError = action.payload.error;
    },

    // Add Remarks
    addRemarks: (state, action) => {
      const { componentId = "" } = action.payload;
      state.addRemarksSuccess[componentId] = false;
      state.addRemarksLoading[componentId] = true;
      state.addRemarksError = "";
    },

    addRemarksSuccess: (state, action) => {
      const { componentId = "" } = action.payload;
      state.addRemarksSuccess[componentId] = true;
      delete state.addRemarksLoading[componentId];
    },

    addRemarksFailure: (state, action) => {
      const { componentId = "", error = {} } = action.payload;
      delete state.addRemarksLoading[componentId];
      state.addRemarksError = error;
    },

    // Clear Audit Component Info
    clearAuditComponentInfo: (state) => {
      state.componentInfo = {};
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditComponentReducer = auditComponentSlice.reducer;

// Actions
export const AuditComponentActions = auditComponentSlice.actions;
