// Constants
import { DataPreLoadKeys } from "../../constants/generalConstants";
import { NODE_TYPE, PREFIX } from "../../reactflow/constants/reactFlowConstants";

// Utils
import LocalStorage from "../../utils/localStorageUtils";

// Construct Nodes
function constructNodes(nodes = [], edges = []) {
  const { componentTypes = [] } = JSON.parse(LocalStorage.get(DataPreLoadKeys.COMPONENT_TYPES) || "{}") || {};

  return nodes.map((node, index) => {
    const { id = "", componentTypeId = "", posX = "", posY = "" } = node;

    const componentTypeDetails = componentTypes.find(({ id = "" }) => id === componentTypeId);

    const sourceHandleIds = [];
    const targetHandleIds = [];

    edges.forEach(({ fromId = "", toId = "" }, index) => {
      if (fromId === id) {
        sourceHandleIds.push(PREFIX.HandleOutput + (index + 1));
      }

      if (toId === id) {
        targetHandleIds.push(PREFIX.HandleInput + (index + 1));
      }
    });

    const x = posX ? posX : 0;
    const y = posY ? posY : index * 100;

    return {
      id: `${id}`,
      data: { componentTypeDetails, node, sourceHandleIds, targetHandleIds },
      position: { x, y },
      type: NODE_TYPE.COMPONENT,
      deletable: false,
    };
  });
}

// Construct Edges
function constructEdges(edges = []) {
  return edges.map((edge, index) => {
    const { id = "", fromId = "", toId = "" } = edge;

    return {
      id: `${id}`,
      source: `${fromId}`,
      sourceHandle: PREFIX.HandleOutput + (index + 1),
      target: `${toId}`,
      targetHandle: PREFIX.HandleInput + (index + 1),
      animated: true,
    };
  });
}

const ComponentLinkHelper = { constructNodes, constructEdges };

export default ComponentLinkHelper;
