import { redirect } from "react-router-dom";

// App Components
import ErrorHandler from "../components/error/ErrorHandler";
import ProtectedRoute from "../hoc/ProtectedRoute";

// Constants
import PageURLs from "../constants/pageUrls";
import { LayoutIds } from "../constants/generalConstants";
import { DataPreLoadObj } from "../constants/dataPreLoadConstants";

// Utils
import URLs from "../utils/urlUtils";

// Layout
import PlantLayout from "../components/layout/PlantLayout";

// Route Loaders
import { plantInfoLoader } from "../route-loaders/plantViewLoader";

// Sidebar Elements
import PlantInfoPage from "../pages/plant/plant-setup/plant-info/PlantInfoPage";
import PlantComponentsPage from "../pages/plant/plant-setup/plant-components/PlantComponentsPage";
import PlantComponentLinkPage from "../pages/plant/plant-setup/component-link/PlantComponentLinkPage";
import PlantElectricityBillsPage from "../pages/plant/plant-setup/plant-electricity-bills/PlantElectricityBillsPage";
import PlantThermalBillsPage from "../pages/plant/plant-setup/plant-thermal-bills/PlantThermalBillsPage";
import PlantFileListPage from "../pages/plant/plant-setup/plant-files/PlantFileListPage";
import PlantPowerConnectionPage from "../pages/plant/plant-setup/plant-power-connection/PlantPowerConnectionPage";

// User Pages
import UserListPage from "../pages/user/user-list/UserListPage";
import UserViewPage from "../pages/user/user-view/UserViewPage";

// Audit List
import PlantAuditListPage from "../pages/plant/plant-setup/audit-list/PlantAuditListPage";

// Plant Pages and Layouts
export const PlantRoutes = {
  path: PageURLs.PlantSetupPageUrl,
  shouldRevalidate: ({ currentParams, nextParams }) => {
    const { orgId: currentOrgId = "", plantId: currentPlantId = "" } = currentParams;
    const { orgId = "", plantId = "" } = nextParams;

    const isRevalidate = currentOrgId !== orgId || currentPlantId !== plantId;

    if (isRevalidate) {
      return true;
    } else {
      return false;
    }
  },
  loader: ({ params }) => {
    return plantInfoLoader(params.orgId, params.plantId);
  },
  id: LayoutIds.PLANT,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute dataPreLoadObj={DataPreLoadObj}>
        {/* Layout */}
        <PlantLayout />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      // This logic selects one of the sideBar by default..
      index: true,
      loader: ({ params }) => {
        const orgId = params.orgId;
        const plantId = params.plantId;
        const redirectUrl = URLs.format(PageURLs.PlantOverviewPageUrl, { orgId, plantId });
        return redirect(redirectUrl);
      },
    },
    {
      path: PageURLs.PlantOverviewPageUrl,
      element: <PlantInfoPage />,
    },
    {
      path: PageURLs.PlantComponentsPageUrl,
      element: <PlantComponentsPage />,
    },
    {
      path: PageURLs.PlantComponentConnectionPageUrl,
      element: <PlantComponentLinkPage />,
    },
    {
      path: PageURLs.PlantElectricityBillPageUrl,
      element: <PlantElectricityBillsPage />,
    },
    {
      path: PageURLs.PlantThermalBillPageUrl,
      element: <PlantThermalBillsPage />,
    },
    {
      path: PageURLs.PlantUserListPageUrl,
      element: <UserListPage />,
    },
    {
      path: PageURLs.PlantUserViewPageUrl,
      element: <UserViewPage />,
    },
    {
      path: PageURLs.PlantAuditListPageUrl,
      element: <PlantAuditListPage />,
    },
    {
      path: PageURLs.PlantFileListPageUrl,
      element: <PlantFileListPage />,
    },
    {
      path: PageURLs.PlantPowerConnectionListPageUrl,
      element: <PlantPowerConnectionPage />,
    },
  ],
};
