import { useSelector } from "react-redux";

// Components
import TableDataNotFound from "../../../../components/table/TableDataNotFound";
import TableLoader from "../../../../components/table/TableLoader";

// Page Constants
const measurementTypeInfoTitle = ["Id", "Name", "Description", "Symbol"];

// Page Components
function MeasurementTypeInfoTableRow({ measurementTypeInfo }) {
  // Removing Version and isActive key
  const { version, isActive, ...measurementTypeObj } = measurementTypeInfo;

  const measurementTypekeys = Object.keys(measurementTypeObj);

  return (
    <>
      {measurementTypekeys.map((measurementTypekey, index) => {
        return (
          <tr key={index}>
            <td>{measurementTypeInfoTitle[index]}</td>
            <td>{measurementTypeInfo[measurementTypekey]}</td>
          </tr>
        );
      })}
    </>
  );
}

function MeasurementTypeInfoTableBody() {
  // Measurement Type Info Selector State
  const measurementTypeInfo = useSelector((state) => state.measurementType.measurementTypeInfo);
  const measurementTypeInfoLoading = useSelector((state) => state.measurementType.measurementTypeInfoLoading);

  //Loader
  if (measurementTypeInfoLoading) {
    return <TableLoader colSpan={5} />;
  }

  //if there is no measurement type information
  if (Object.keys(measurementTypeInfo || {}).length === 0) {
    return <TableDataNotFound colSpan={5} message="No measurement type information present" />;
  }

  //Measurement type information table row
  return <MeasurementTypeInfoTableRow measurementTypeInfo={measurementTypeInfo} />;
}

/**
 * Measurement Type Information Table
 */
export default function MeasurementTypeInfoTable() {
  return (
    <table className="table">
      <tbody>
        {/* Measurement Type Information Table Body */}
        <MeasurementTypeInfoTableBody />
      </tbody>
    </table>
  );
}
