import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { InstrumentActions } from "../../slices/instrument/instrumentSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Services
import InstrumentService from "../../../services/instrument/instrumentService";

// Get Instrument List
function* getInstrumentList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield InstrumentService.getInstrumentList(pageNumber, pageSize, searchText);

    yield put(
      InstrumentActions.getInstrumentListSuccess({ instrumentList: items, instrumentListPagination: pagination })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.getInstrumentListFailure({ error }));
  }
}

// Get Instrument Info
function* getInstrumentInfo(action) {
  try {
    const { instrumentId } = action.payload;

    const instrumentInfo = yield InstrumentService.getInstrumentInfo(instrumentId);

    yield put(InstrumentActions.getInstrumentInfoSuccess({ instrumentInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.getInstrumentInfoFailure({ error }));
  }
}

// Get Data Column Info
function* getDataColumnInfo(action) {
  try {
    const { instrumentId } = action.payload;

    const dataColumnInfo = yield InstrumentService.getDataColumnInfo(instrumentId);

    yield put(InstrumentActions.getDataColumnInfoSuccess({ dataColumnInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.getDataColumnInfoFailure({ error }));
  }
}

// Update Instrument Status
function* updateInstrumentStatus(action) {
  const { instrumentId, status, pageNumber, pageSize } = action.payload;

  try {
    yield InstrumentService.updateInstrumentStatus(instrumentId, status);

    const { items, pagination } = yield InstrumentService.getInstrumentList("", pageNumber, pageSize);

    yield put(
      InstrumentActions.getInstrumentListSuccess({ instrumentList: items, instrumentListPagination: pagination })
    );

    yield put(InstrumentActions.updateInstrumentStatusSuccess({ instrumentId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(InstrumentActions.updateInstrumentStatusFailure({ instrumentId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(InstrumentActions.getInstrumentList.type, getInstrumentList),
    takeLatest(InstrumentActions.getInstrumentInfo.type, getInstrumentInfo),
    takeLatest(InstrumentActions.getDataColumnInfo.type, getDataColumnInfo),
    takeEvery(InstrumentActions.updateInstrumentStatus.type, updateInstrumentStatus),
  ]);
}
