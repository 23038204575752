// Constants
import { PREFIX } from "./constants/reactFlowConstants";

// Node Utils
function nextNodeId(lastNodeIndex = 0) {
  // Get Node Id
  const nextNodeIdx = lastNodeIndex + 1;
  const nextNodeId = PREFIX.Node + "" + nextNodeIdx;

  return nextNodeId;
}

function prepareNewNode(nodeType = "", lastNodeIndex = 0, exNodesCount = 0) {
  // Generate New Node Id
  const nodeId = nextNodeId(lastNodeIndex);

  // Prepare New Node Object
  const newNode = {
    id: nodeId,
    type: nodeType,
    position: {
      x: exNodesCount * 50,
      y: exNodesCount * 50,
    },
    data: {
      name: nodeId,
      handles: {
        source: ["s1"],
        target: ["t1"],
      },
    },
  };

  return newNode;
}

// Node Handle Utils
const toHandleUid = (nodeId = "", handleId = "") => {
  const handleUid = [nodeId, handleId].join(PREFIX.DemiliterId);
  return handleUid;
};

const nextHandleId = (isInput = true, lastHandleId = "") => {
  // Prefix
  const prefix = isInput ? PREFIX.HandleInput : PREFIX.HandleOutput;

  // get Handle id
  const handleId = lastHandleId === "" ? 10 : Number(lastHandleId.substring(1));
  const nextHandleIdValue = handleId + 1;
  const nextHandleId = prefix + nextHandleIdValue;

  return nextHandleId;
};

// Edge Utilities
function toEdgeId(source, sourcePort, target, targetPort) {
  const edgeId = [source, sourcePort, target, targetPort].join(PREFIX.DemiliterId);
  return edgeId;
}

const GraphUtil = {
  // Node
  nextNodeId,
  prepareNewNode,

  // Node : Handle
  toHandleUid,
  nextHandleId,

  // Edge
  toEdgeId,
};

export default GraphUtil;
