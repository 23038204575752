import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { toast } from "react-toastify";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/useUpdateQueryParams";

// Actions
import { PlantElectricityBillActions } from "../../../../store/slices/plant/electricityBillSlice";
import { PlantPowerConnectionActions } from "../../../../store/slices/plant/powerConnectionSlice";

// Constants
import {
  getElectricityBillFormSchema,
  ElectricityBillFormValidationSchema,
} from "../../../../constants/electricityBillConstants";
import { QueryParamsKeys } from "../../../../constants/generalConstants";

// Components
import { Button } from "../../../../components/button/Button";
import Form, { FormTypes } from "../../../../components/form/Form";

// page Components
import PlantElectricityBillListTable from "./components/PlantElectricityBillListTable";

const MODAL_SIZE = 1000;

function addUnitCostToFormData(formData = {}) {
  const { unitsConsumed = 0, totalCost = 0 } = formData;

  const unitCost = totalCost / unitsConsumed;

  return {
    ...formData,
    unitCost,
  };
}
// Page Component
function PlantElectricityBillsPageHeader({
  setSelectedElectricityBill = () => {},
  setShowCreateElectricityBillModal = () => {},
}) {
  function openAdddElectricityBillModal() {
    setSelectedElectricityBill({});
    setShowCreateElectricityBillModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between my-3">
      <div className="col-3">
        <h4>Monthly Electricity Bills</h4>
      </div>

      {/* Button */}
      <Button label="Add Electricity Bill" color="dark" onClick={openAdddElectricityBillModal}>
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Plant Electricity Bills Tab Conent
 */
export default function PlantElectricityBillsTabContent() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // State
  const [selectedElectricityBill, setSelectedElectricityBill] = useState({});
  const [showViewElectricityBillModal, setShowViewElectricityBillModal] = useState(false);
  const [showCreateElectricityBillModal, setShowCreateElectricityBillModal] = useState(false);

  const { id = "" } = selectedElectricityBill;

  // Upsert Electricity Bill Selector State
  const upsertElectricityBillLoading = useSelector((state) => state.plantElectricityBill.upsertElectricityBillLoading);
  const upsertElectricityBillSuccess = useSelector((state) => state.plantElectricityBill.upsertElectricityBillSuccess);

  // power connection list Selector State
  const powerConnectionListMap = useSelector((state) => state.plantPowerConnection.powerConnectionListMap);
  const serviceNos = Object.values(powerConnectionListMap).map((bill) => ({
    label: bill.serviceNo,
    value: bill.serviceNo,
  }));

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // Loading states for modals
  const createElectricityBillLoading = upsertElectricityBillLoading && !id;
  const updateElectricityBillLoading = upsertElectricityBillLoading && id;

  // Success conditions
  const createElectricityBillSuccess = upsertElectricityBillSuccess && !id;
  const updateElectricityBillSuccess = upsertElectricityBillSuccess && id;

  // use Effect
  useEffect(() => {
    dispatch(PlantElectricityBillActions.getElectricityBillList({ plantId, pageNumber, pageSize, searchText }));
  }, [dispatch, plantId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    return () => dispatch(PlantElectricityBillActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    if (createElectricityBillSuccess) {
      setShowCreateElectricityBillModal(false);
      toast.success("Electricity Bill Added Successfully");
    }
  }, [createElectricityBillSuccess]);

  useEffect(() => {
    if (updateElectricityBillSuccess) {
      setShowViewElectricityBillModal(false);
      toast.success("Electricity Bill Updated Successfully");
    }
  }, [updateElectricityBillSuccess]);

  useEffect(() => {
    dispatch(
      PlantPowerConnectionActions.getPowerConnectionList({
        plantId,
        searchText,
      })
    );
  }, [dispatch, plantId, searchText]);

  // Function for adding electricity bills
  function createElectricityBill(electricityBillData) {
    const formData = { ...addUnitCostToFormData(electricityBillData), plantId };

    // Dispatch
    dispatch(PlantElectricityBillActions.createElectricityBill({ formData, plantId }));
  }

  // Function for editing electricity bills
  function editElectricityBill(electricityBillData) {
    const formData = { ...addUnitCostToFormData(electricityBillData), plantId };

    // Dispatch
    dispatch(
      PlantElectricityBillActions.updateElectricityBill({
        formData,
        plantId,
        electricityBillId: selectedElectricityBill.id,
      })
    );
  }

  return (
    <div className="page-content">
      {/* Plant Electricity Bills Page Header */}
      <PlantElectricityBillsPageHeader
        setSelectedElectricityBill={setSelectedElectricityBill}
        setShowCreateElectricityBillModal={setShowCreateElectricityBillModal}
      />

      <div className="mt-4 table-responsive">
        {/* Plant Electricity Bill List Table */}
        <PlantElectricityBillListTable
          setSelectedElectricityBill={setSelectedElectricityBill}
          setShowViewElectricityBillModal={setShowViewElectricityBillModal}
        />
      </div>

      {/* Create Electricity Bill Modal */}
      <Modal
        title="Create Electricity Bill"
        open={showCreateElectricityBillModal}
        width={MODAL_SIZE}
        onCancel={() => setShowCreateElectricityBillModal(false)}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          type={FormTypes.CREATE}
          formInputItems={getElectricityBillFormSchema(serviceNos)}
          formValidationSchema={ElectricityBillFormValidationSchema}
          formSubmit={createElectricityBill}
          loading={createElectricityBillLoading}
          showCancelButton={false}
          showModal={showCreateElectricityBillModal}
        />
      </Modal>

      {/* View Electricity Bill Modal */}
      <Modal
        title="View Electricity Bill"
        open={showViewElectricityBillModal}
        width={MODAL_SIZE}
        onCancel={() => setShowViewElectricityBillModal(false)}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          data={selectedElectricityBill}
          formInputItems={getElectricityBillFormSchema}
          formValidationSchema={ElectricityBillFormValidationSchema}
          formSubmit={editElectricityBill}
          loading={updateElectricityBillLoading}
          showModal={showViewElectricityBillModal}
        />
      </Modal>
    </div>
  );
}
