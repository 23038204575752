// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { AuditComponentUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/generalConstants";
import { ContentType } from "../../constants/httpConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Plant Component List
async function getPlantComponentList(auditId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: 1000 || DefaultPagination.pageSize, // TODO
  };

  const url = URLs.format(AuditComponentUrls.getPlantComponentList, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url, params);
}

// Get Component Info
async function getComponentInfo(auditId, componentId) {
  const url = URLs.format(AuditComponentUrls.getComponentInfo, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Components Detail
async function getComponentsDetail(auditId) {
  const url = URLs.format(AuditComponentUrls.getComponentsDetail, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Add Walkthorugh Remarks
async function addWalkthroughRemarks(auditId, componentId, formData) {
  const url = URLs.format(AuditComponentUrls.addWalkthroughRemarks, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}

// Add Walkthrough Image
async function addWalkthroughImage(auditId, componentId, formData) {
  const url = URLs.format(AuditComponentUrls.addWalkthroughImage, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.MULTIPART_FORM_DATA);
}

// Add Component Remarks
async function addRemarks(auditId, componentId, formData) {
  const url = URLs.format(AuditComponentUrls.addRemarks, { auditId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}

const AuditComponentService = {
  getPlantComponentList,
  getComponentInfo,
  getComponentsDetail,
  addWalkthroughRemarks,
  addWalkthroughImage,
  addRemarks,
};

export default AuditComponentService;
