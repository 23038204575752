import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../../hooks/useUpdateQueryParams";

// Action
import { AuditObservationAndRecommendationActions } from "../../../../../store/slices/audit/observationAndRecommendationSlice";

// Constants
import { QueryParamsKeys } from "../../../../../constants/generalConstants";

// Components
import Input from "../../../../../components/input/Input";

/**
 * Savings Recommendation List Investment Input
 * @param {*} investment
 * @param {*} id
 */
export default function SavingsRecommendationListInvestmentInput({ investment = "", id = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // State
  const [investmentVal, setInvestmentVal] = useState(investment);

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.componentInfo);

  const updateRecInvestmentLoading = useSelector((state) => state.auditObsAndRec.updateInvestmentLoading[id]) || false;
  const updateRecInvestmentSuccess = useSelector((state) => state.auditObsAndRec.updateInvestmentSuccess[id]) || false;

  // Component Info
  const { id: selectedComponentId = "" } = componentInfo;

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) || "";
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) || "";

  // use Effect
  useEffect(() => {
    if (updateRecInvestmentSuccess) {
      toast.success("Investment Updated Successfully");
    }
  }, [updateRecInvestmentSuccess]);

  // Submit Investment
  function submitInvestment() {
    dispatch(
      AuditObservationAndRecommendationActions.updateInvestment({
        auditId,
        recommendationId: id,
        investment: investmentVal,
        componentId: selectedComponentId,
        pageNumber,
        pageSize,
      })
    );
  }

  return (
    <Input
      value={investmentVal}
      onChange={({ target }) => setInvestmentVal(target.value)}
      onBlur={submitInvestment}
      placeholder="Enter Investment Amount"
      loading={updateRecInvestmentLoading}
      disabled={updateRecInvestmentLoading}
      spinnerClassName="spinner-border-sm"
    />
  );
}
