// Utils
import TableUtils from "../../utils/tableUtils";

function getHeaders(currentPage = "", pageSize = "", fileTypes = {}) {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "File Type",
      dataIndex: "type",
      render: (type) => <div>{fileTypes[type]}</div>,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (name) => <div>{name}</div>,
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (size) => <div>{size} bytes</div>,
    },
  ];
}

const FileListTableHelper = {
  getHeaders,
};

export default FileListTableHelper;
