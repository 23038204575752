// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { UserUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get User List
async function getUserList(orgId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    org: orgId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(UserUrls.getUserList, params);
}

// Add User
async function addUser(userData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(UserUrls.addUser, {}, userData);
}

// Assign Role To User
async function assignRole(userId, roleId) {
  const url = URLs.format(UserUrls.assignRole, { userId, roleId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Get User Info
async function getUserInfo(userId) {
  const url = URLs.format(UserUrls.getUserInfo, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update User
async function updateUser(userData, userId) {
  const url = URLs.format(UserUrls.updateUser, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, userData);
}

// Update status
async function updateUserStatus(userId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(UserUrls.updateUserStatus, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const UserService = {
  getUserList,
  addUser,
  assignRole,
  getUserInfo,
  updateUser,
  updateUserStatus,
};

export default UserService;
