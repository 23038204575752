import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ErrorActions } from "../../slices/error/errorSlice";
import { PlantPowerConnectionActions } from "../../slices/plant/powerConnectionSlice";

// Services
import PlantPowerConnectionsService from "../../../services/plant/powerConnectionService";

// Get Power Connection List
function* getPowerConnectionList(action) {
  try {
    const { searchText, plantId } = action.payload;

    const powerConnectionList = yield PlantPowerConnectionsService.getPowerConnectionList(plantId, searchText);

    yield put(
      PlantPowerConnectionActions.getPowerConnectionListSuccess({
        powerConnectionList,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantPowerConnectionActions.getPowerConnectionListFailure({ error }));
  }
}

// Create Power Connection
function* createPowerConnection(action) {
  try {
    const { formData, plantId } = action.payload;

    yield PlantPowerConnectionsService.createPowerConnection(formData, plantId);

    const powerConnectionList = yield PlantPowerConnectionsService.getPowerConnectionList(plantId);

    yield put(
      PlantPowerConnectionActions.getPowerConnectionListSuccess({
        powerConnectionList,
      })
    );

    yield put(PlantPowerConnectionActions.createPowerConnectionSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantPowerConnectionActions.createPowerConnectionFailure({ error }));
  }
}

// Update Power Connection
function* updatePowerConnection(action) {
  try {
    const { formData, plantId, connectionId } = action.payload;

    yield PlantPowerConnectionsService.updatePowerConnection(formData, plantId, connectionId);

    const powerConnectionList = yield PlantPowerConnectionsService.getPowerConnectionList(plantId);

    yield put(
      PlantPowerConnectionActions.getPowerConnectionListSuccess({
        powerConnectionList,
      })
    );

    yield put(PlantPowerConnectionActions.updatePowerConnectionSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantPowerConnectionActions.updatePowerConnectionFailure({ error }));
  }
}

// Update Power Connection Status
function* updatePowerConnectionStatus(action) {
  const { plantId, connectionId, status } = action.payload;

  try {
    yield PlantPowerConnectionsService.updatePowerConnectionStatus(plantId, connectionId, status);

    const powerConnectionList = yield PlantPowerConnectionsService.getPowerConnectionList(plantId);

    yield put(
      PlantPowerConnectionActions.getPowerConnectionListSuccess({
        powerConnectionList,
      })
    );

    yield put(PlantPowerConnectionActions.updatePowerConnectionStatusSuccess({ connectionId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantPowerConnectionActions.updatePowerConnectionStatusFailure({ error, connectionId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantPowerConnectionActions.getPowerConnectionList.type, getPowerConnectionList),
    takeLatest(PlantPowerConnectionActions.createPowerConnection.type, createPowerConnection),
    takeLatest(PlantPowerConnectionActions.updatePowerConnection.type, updatePowerConnection),
    takeLatest(PlantPowerConnectionActions.updatePowerConnectionStatus.type, updatePowerConnectionStatus),
  ]);
}
