// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { AuditDataUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Download Performance Analysis Data
async function downloadPerfAnalysisData(auditId) {
  const url = URLs.format(AuditDataUrls.downloadPerfAnalysisData, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadFile(url);
}

// Download Recommendation Data
async function downloadRecommendationData(auditId, w_obv = true) {
  const params = {
    w_obv,
  };

  const url = URLs.format(AuditDataUrls.downloadRecommendationData, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadFile(url, params);
}

const AuditDataService = {
  downloadPerfAnalysisData,
  downloadRecommendationData,
};

export default AuditDataService;
