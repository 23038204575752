import { createSlice } from "@reduxjs/toolkit";

// Constants
import { OrgTypes } from "../../../constants/generalConstants";

// Transformer
import OrganizationTransformer from "./organizationTransformer";

function resetApiSuccessState(state) {
  state.upsertOrganizationSuccesss = false;
  state.updateOrganizationStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get organization list
  organizationMap: {},
  organizationTypeMap: { INDUSTRY: {}, AUDIT: {} },
  organizationListPagination: {},
  organizationListLoading: false,
  organizationListError: "",

  // Add organization
  addOrganizationLoading: false,
  addOrganizationError: "",

  // Update organization
  updateOrganizationLoading: false,
  updateOrganizationError: "",

  upsertOrganizationSuccesss: false,

  // Get organization information
  organizationInfo: {},
  organizationInfoLoading: false,
  organizationInfoError: "",

  // Update organization status
  updateOrganizationStatusLoadingMap: {},
  updateOrganizationStatusSuccessMap: {},
  updateOrganizationStatusError: "",
};

// Organization Slice
const organizationSlice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    // Get organization list
    getOrganizationList: (state) => {
      state.organizationListLoading = true;
    },

    getOrganizationListSuccess: (state, action) => {
      // Action Payload
      const { orgType, organizationList, organizationListPagination } = action.payload;

      // Organization Type
      const { id: industry } = OrgTypes.INDUSTRY;
      const { id: audit } = OrgTypes.AUDIT;

      const organizationMap = OrganizationTransformer.transformList(organizationList);

      state.organizationListPagination = organizationListPagination;
      state.organizationListLoading = false;

      // If organization type is industry
      if (orgType === industry) {
        state.organizationTypeMap[industry] = organizationMap;
        return;
      }

      // If organization type is audit
      if (orgType === audit) {
        state.organizationTypeMap[audit] = organizationMap;
        return;
      }
    },

    getOrganizationListFailure: (state, action) => {
      state.organizationListLoading = false;
      state.organizationListError = action.payload.error;
    },

    // Add organization
    addOrganization: (state) => {
      state.upsertOrganizationSuccesss = false;
      state.addOrganizationLoading = true;
    },

    addOrganizationSuccess: (state) => {
      state.upsertOrganizationSuccesss = true;
      state.addOrganizationLoading = false;
    },

    addOrganizationFailure: (state, action) => {
      state.addOrganizationLoading = false;
      state.addOrganizationError = action.payload.error;
    },

    // Update organization
    updateOrganization: (state) => {
      state.upsertOrganizationSuccesss = false;
      state.updateOrganizationLoading = true;
    },

    updateOrganizationSuccess: (state) => {
      state.upsertOrganizationSuccesss = true;
      state.updateOrganizationLoading = false;
    },

    updateOrganizationFailure: (state, action) => {
      state.updateOrganizationLoading = false;
      state.updateOrganizationStatusError = action.payload.error;
    },

    // Get organization information
    getOrganizationInfo: (state) => {
      state.organizationInfoLoading = true;
    },

    getOrganizationInfoSuccess: (state, action) => {
      const { organizationInfo } = action.payload;
      state.organizationInfo = OrganizationTransformer.transformObj(organizationInfo);

      state.organizationInfoLoading = false;
    },

    getOrganizationInfoFailure: (state, action) => {
      state.organizationInfoLoading = false;
      state.organizationInfoError = action.payload.error;
    },

    // Update organization status (Enable/Disable)
    updateOrganizationStatus: (state, action) => {
      const { orgId } = action.payload;
      state.updateOrganizationStatusSuccessMap[orgId] = false;
      state.updateOrganizationStatusLoadingMap[orgId] = true;
      state.updateOrganizationStatusError = "";
    },

    updateOrganizationStatusSuccess: (state, action) => {
      const { orgId } = action.payload;
      state.updateOrganizationStatusSuccessMap[orgId] = true;
      delete state.updateOrganizationStatusLoadingMap[orgId];
    },

    updateOrganizationStatusFailure: (state, action) => {
      const { orgId, error } = action.payload;
      delete state.updateOrganizationStatusLoadingMap[orgId];
      state.updateOrganizationStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const OrganizationReducer = organizationSlice.reducer;

// Actions
export const OrganizationActions = organizationSlice.actions;
