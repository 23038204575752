import { useNavigate } from "react-router-dom";
import { Eye } from "lucide-react";

// Constants
import PageURLs from "../../../constants/pageUrls";

// Utils
import URLs from "../../../utils/urlUtils";

// Helper
import AuditListTableHelper from "../../../helpers/audit/auditListTableHelper";

// Components
import DataTable from "../../../components/antd/table/DataTable";
import SkeletonTable from "../../../components/antd/table/SkeletonTable";
import TablePagination from "../../../components/table/TablePagination";

// Css
import "./AuditListTable.scss";

function TableActions({ record }) {
  const navigate = useNavigate();
  const { key: auditId } = record;

  // Initiate new audit
  function navigateToAuditViewPage() {
    const auditViewPageURL = URLs.format(PageURLs.AuditViewPageUrl, { auditId });
    navigate(auditViewPageURL);
  }

  return (
    <Eye size={16} className="cursor-pointer" onClick={navigateToAuditViewPage}>
      <title>View</title>
    </Eye>
  );
}

export default function AuditListTable({ items = [], loading = false, pagination = {}, showPagination = false }) {
  // Pagination
  const { totalCount, pageNo, pageSize } = pagination || {};
  const tableHeaders = AuditListTableHelper.getHeaders(pageNo, pageSize);

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => {
      return <TableActions record={record} />;
    },
  };
  const columns = [...tableHeaders, actionColumn];

  return (
    <div>
      {loading && <SkeletonTable columnCount={7} rowCount={10} />}

      {!loading && <DataTable rows={items} columns={columns} className="audit-table" applyTableBorder={true} />}

      {/* Table Pagination */}
      {showPagination && <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />}
    </div>
  );
}
