import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentPartsActions } from "../../slices/plant/partsSlice";
import { ErrorActions } from "../../slices/error/errorSlice";
import { PlantComponentActions } from "../../slices/plant/componentSlice";

// Service
import PlantComponentPartsService from "../../../services/plant/partsService";
import PlantComponentService from "../../../services/plant/componentService";

// Function
function* updatePlantComponentListFn(plantId = "", componentTypeId = "", pageNumber = "", pageSize = "") {
  const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId, pageNumber, pageSize);

  yield put(PlantComponentActions.getComponentListSuccess({ componentList }));
}

// Add Part
function* addPart(action) {
  try {
    const { partData, plantId, componentId, componentTypeId, pageNumber, pageSize } = action.payload;

    yield PlantComponentPartsService.addPart(partData, plantId, componentId);

    yield updatePlantComponentListFn(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentPartsActions.addPartSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentPartsActions.addPartFailure({ error }));
  }
}

// Update Part
function* updatePart(action) {
  try {
    const {
      componentData: partData,
      plantId,
      componentId,
      partId,
      componentTypeId,
      pageNumber,
      pageSize,
    } = action.payload;

    yield PlantComponentPartsService.updatePart(partData, plantId, componentId, partId);

    yield updatePlantComponentListFn(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentPartsActions.updatePartSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentPartsActions.updatePartFailure({ error }));
  }
}

// Delete Part
function* deletePart(action) {
  const { plantId, componentId, partId, componentTypeId, pageNumber, pageSize } = action.payload;

  try {
    yield PlantComponentPartsService.deletePart(plantId, componentId, partId);

    yield updatePlantComponentListFn(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentPartsActions.deletePartSuccess({ partId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentPartsActions.deletePartFailure({ error, partId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantComponentPartsActions.addPart.type, addPart),
    takeLatest(PlantComponentPartsActions.updatePart.type, updatePart),
    takeLatest(PlantComponentPartsActions.deletePart.type, deletePart),
  ]);
}
