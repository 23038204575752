import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ErrorActions } from "../../slices/error/errorSlice";
import { AuditComponentActions } from "../../slices/audit/componentSlice";

// Service
import AuditComponentService from "../../../services/audit/componentService";

// Get Plant Component List
function* getPlantComponentList(action) {
  try {
    const { auditId } = action.payload;

    const plantComponentList = yield AuditComponentService.getPlantComponentList(auditId);

    yield put(AuditComponentActions.getPlantComponentListSuccess({ plantComponentList }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.getPlantComponentListFailure({ error }));
  }
}

// Get Component Info
function* getComponentInfo(action) {
  try {
    const { auditId, componentId } = action.payload;

    const componentInfo = yield AuditComponentService.getComponentInfo(auditId, componentId);

    yield put(AuditComponentActions.getComponentInfoSuccess({ componentInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.getComponentInfoFailure({ error }));
  }
}

// Get Components Detail
function* getComponentsDetail(action) {
  try {
    const { auditId } = action.payload;

    const componentsDetail = yield AuditComponentService.getComponentsDetail(auditId);

    yield put(AuditComponentActions.getComponentsDetailSuccess({ componentsDetail }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.getComponentsDetailFailure({ error }));
  }
}

// Add Walkthorugh remarks
function* addWalkthroughRemarks(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield AuditComponentService.addWalkthroughRemarks(auditId, componentId, formData);

    const componentInfo = yield AuditComponentService.getComponentInfo(auditId, componentId);

    yield put(AuditComponentActions.getComponentInfoSuccess({ componentInfo }));

    yield put(AuditComponentActions.addWalkthroughRemarksSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.addWalkthroughRemarksFailure({ error }));
  }
}

// Add Walkthrough Remarks Image
function* addWalkthroughImage(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield AuditComponentService.addWalkthroughImage(auditId, componentId, formData);

    const componentInfo = yield AuditComponentService.getComponentInfo(auditId, componentId);

    yield put(AuditComponentActions.getComponentInfoSuccess({ componentInfo }));

    yield put(AuditComponentActions.addWalkthroughImageSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.addWalkthroughImageFailure({ error }));
  }
}

// Add Component Remarks
function* addRemarks(action) {
  const { auditId, componentId, formData } = action.payload;

  try {
    yield AuditComponentService.addRemarks(auditId, componentId, formData);

    yield put(AuditComponentActions.addRemarksSuccess({ componentId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.addRemarksFailure({ error, componentId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditComponentActions.getPlantComponentList.type, getPlantComponentList),
    takeLatest(AuditComponentActions.getComponentInfo.type, getComponentInfo),
    takeLatest(AuditComponentActions.getComponentsDetail.type, getComponentsDetail),
    takeLatest(AuditComponentActions.addWalkthroughRemarks.type, addWalkthroughRemarks),
    takeLatest(AuditComponentActions.addWalkthroughImage.type, addWalkthroughImage),
    takeLatest(AuditComponentActions.addRemarks.type, addRemarks),
  ]);
}
