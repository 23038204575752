// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { ComponentTypeUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Component list
async function getComponentTypeList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(ComponentTypeUrls.getComponentTypeList, params);
}

// Get Parameter list
async function getParameterList(componentTypeId, parameterType, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem_type: "COMPONENT",
    elem_type_id: componentTypeId,
    type: parameterType,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(ComponentTypeUrls.getParameterList, params);
}

// Download Component Type Template
async function downloadComponentTypeTemplate(componentTypeCode) {
  const url = URLs.format(ComponentTypeUrls.downloadComponentTypeTemplate, { componentTypeCode });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.downloadFile(url);
}

// Get Recommendation Code list
async function getRecommendationCodeList(componentTypeId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem_type: "COMPONENT",
    elem_type_id: componentTypeId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(ComponentTypeUrls.getRecommendationCodeList, params);
}

// Get Observation Code list
async function getObservationCodeList(componentTypeId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    elem_type: "COMPONENT",
    elem_type_id: componentTypeId,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(ComponentTypeUrls.getObservationCodeList, params);
}

// Get Component Type Info
async function getComponentTypeInfo(componentTypeId) {
  const url = URLs.format(ComponentTypeUrls.getComponentTypeInfo, { componentTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update ComponentType Status
async function updateComponentTypeStatus(componentTypeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(ComponentTypeUrls.updateComponentTypeStatus, { componentTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Update Observation Code Status
async function updateObservationCodeStatus(obsCodeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(ComponentTypeUrls.updateObservationCodeStatus, { obsCodeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Update Recommendation Code Status
async function updateRecommendationCodeStatus(recCodeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(ComponentTypeUrls.updateRecommendationCodeStatus, { recCodeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Update Parameter status
async function updateParameterStatus(parameterId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(ComponentTypeUrls.updateParameterStatus, { parameterId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const ComponentTypeService = {
  getComponentTypeList,
  getParameterList,
  downloadComponentTypeTemplate,
  getRecommendationCodeList,
  getObservationCodeList,

  getComponentTypeInfo,

  updateComponentTypeStatus,
  updateObservationCodeStatus,
  updateRecommendationCodeStatus,
  updateParameterStatus,
};

export default ComponentTypeService;
