import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertValuesSuccess = false;
}

// Initial State
const initialState = {
  // Upsert Values
  upsertValuesLoading: false,
  upsertValuesSuccess: false,
  upsertValuesError: "",
};

// Audit Parameter Values Slice
const auditParameterValuesSlice = createSlice({
  name: "AuditParameterValues",
  initialState,
  reducers: {
    // Upsert Values
    upsertValues: (state) => {
      state.upsertValuesSuccess = false;
      state.upsertValuesLoading = true;
    },

    upsertValuesSuccess: (state) => {
      state.upsertValuesSuccess = true;
      state.upsertValuesLoading = false;
    },

    upsertValuesFailure: (state, action) => {
      state.upsertValuesLoading = false;
      state.upsertValuesError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditParameterValuesReducer = auditParameterValuesSlice.reducer;

// Actions
export const AuditParameterValuesActions = auditParameterValuesSlice.actions;
