export function selectFile(isMultiSelect = false, fileExtensions = []) {
  return new Promise((resolve) => {
    function handleFileSelect(e) {
      const file = e.target.files;
      resolve(file);
    }

    const input = document.createElement("input");
    input.type = "file";
    input.multiple = isMultiSelect;
    if (fileExtensions && fileExtensions.length) {
      input.accept = fileExtensions.join(",");
    }
    input.onchange = handleFileSelect;
    input.click();
  });
}

export function saveFile(filename, data) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = filename;
  link.href = URL.createObjectURL(data);
  link.click();
}

// Function to convert blob to base64 string
export async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    // Creates a FileReader instance
    const reader = new FileReader();

    // This will give the converted base64 string
    reader.onload = () => resolve(reader.result);

    // Error Handling
    reader.onerror = (err) => reject(err);

    // Reads the content of the blob or file
    // Once the read operation is completed, onload function is triggered
    reader.readAsDataURL(blob);
  });
}

/**
 * -------------------------------------------------------------------
 * saveExcelFile
 * @param {*} filename : save file
 * @param {*} data : file data
 */
export function saveExcelFile(filename, data) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = filename;
  link.href = URL.createObjectURL(
    new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  link.click();
}
