import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ElectricityBillTransformer from "./electricityBillTransformer";

function resetApiSuccessState(state) {
  state.upsertElectricityBillSuccess = false;
  state.uploadElectricityBillSuccess = {};
}

// Initial State
const initialState = {
  // Get Electricity bill list
  electricityBillMap: {},
  electricityBillListPagination: {},
  electricityBillListLoading: false,
  electricityBillListError: "",

  // Upsert electricty bill
  upsertElectricityBillLoading: false,
  upsertElectricityBillSuccess: false,
  upsertElectricityBillError: {},

  // Upload electricty bill
  uploadElectricityBillLoading: {},
  uploadElectricityBillSuccess: {},
  uploadElectrictyBillError: "",

  // Download electricty bill
  downloadElectricityBillData: {},
  downloadElectricityBillLoading: {},
  downloadElectricityBillSuccess: {},
  downloadElectrictyBillError: "",
};

// Plant Electricity Bill Slice
const plantElectricityBill = createSlice({
  name: "ElectricityBill",
  initialState,
  reducers: {
    // Get Electricity bill list
    getElectricityBillList: (state) => {
      state.electricityBillListLoading = true;
      state.electricityBillListError = "";
    },

    getElectricityBillListSuccess: (state, action) => {
      const { electricityBillList, electricityBillListPagination } = action.payload;
      state.electricityBillMap = ElectricityBillTransformer.transformList(electricityBillList);

      state.electricityBillListPagination = electricityBillListPagination;
      state.electricityBillListLoading = false;
    },

    getElectricityBillListFailure: (state, action) => {
      state.electricityBillListLoading = false;
      state.electricityBillListError = action.payload.error;
    },

    // Create electricty bill
    createElectricityBill: (state) => {
      state.upsertElectricityBillSuccess = false;
      state.upsertElectricityBillLoading = true;
    },

    createElectricityBillSuccess: (state) => {
      state.upsertElectricityBillSuccess = true;
      state.upsertElectricityBillLoading = false;
    },

    createElectricityBillFailure: (state, action) => {
      state.upsertElectricityBillLoading = false;
      state.upsertElectricityBillError = action.payload.error;
    },

    // Update electricty bill
    updateElectricityBill: (state) => {
      state.upsertElectricityBillSuccess = false;
      state.upsertElectricityBillLoading = true;
    },

    updateElectricityBillSuccess: (state) => {
      state.upsertElectricityBillSuccess = true;
      state.upsertElectricityBillLoading = false;
    },

    updateElectricityBillFailure: (state, action) => {
      state.upsertElectricityBillLoading = false;
      state.upsertElectricityBillError = action.payload.error;
    },

    // Upload electricty bill
    uploadElectricityBill: (state, action) => {
      const { electricityBillId } = action.payload;
      state.uploadElectricityBillSuccess[electricityBillId] = false;
      state.uploadElectricityBillLoading[electricityBillId] = true;
      state.uploadElectrictyBillError = "";
    },

    uploadElectricityBillSuccess: (state, action) => {
      const { electricityBillId } = action.payload;
      state.uploadElectricityBillSuccess[electricityBillId] = true;
      delete state.uploadElectricityBillLoading[electricityBillId];
    },

    uploadElectricityBillFailure: (state, action) => {
      const { electricityBillId, error } = action.payload;
      delete state.uploadElectricityBillLoading[electricityBillId];
      state.uploadElectrictyBillError = error;
    },

    // Download electricty bill
    downloadElectricityBill: (state, action) => {
      const { electricityBillId = "" } = action.payload;
      state.downloadElectricityBillSuccess[electricityBillId] = false;
      state.downloadElectricityBillLoading[electricityBillId] = true;
      state.uploadElectrictyBillError = "";
    },

    downloadElectricityBillSuccess: (state, action) => {
      const { electricityBillId = "", downloadElectricityBillData = {} } = action.payload;
      state.downloadElectricityBillData = downloadElectricityBillData;
      state.downloadElectricityBillSuccess[electricityBillId] = true;
      delete state.downloadElectricityBillLoading[electricityBillId];
    },

    downloadElectricityBillFailure: (state, action) => {
      const { electricityBillId = "", error } = action.payload;
      delete state.downloadElectricityBillLoading[electricityBillId];
      state.uploadElectrictyBillError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantElectricityBillReducer = plantElectricityBill.reducer;

// Actions
export const PlantElectricityBillActions = plantElectricityBill.actions;
