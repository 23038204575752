// Template Transformers
function transformParamObj(paramObj = {}) {
  const {
    id = "",
    version = "",
    updatedOn = "",
    isActive = false,
    templateId = "",
    parameterType = "",
    parameterId = "",
    parameterCode = "",
    parameterName = "",
    dataValueType = "",
    value = "",
    unitId = "",
    unitSymbol = "",
  } = paramObj;

  return {
    id: id,
    version: version,
    updatedOn: updatedOn,
    isActive: isActive,
    templateId: templateId,
    parameterType: parameterType,
    parameterId: parameterId,
    parameterCode: parameterCode,
    parameterName: parameterName,
    dataValueType: dataValueType,
    value: value,
    unitId: unitId,
    unitSymbol: unitSymbol,
  };
}

function transformParamValues(paramValues = []) {
  let paramValuesMap = {};

  paramValues.forEach((obj) => {
    const { id = "" } = obj;

    paramValuesMap[id] = { key: id, ...transformParamObj(obj) };
  });

  return paramValuesMap;
}

function transformObj(templateObj = {}) {
  const {
    id = "",
    version = "",
    updatedOn = "",
    isActive = "",
    element = "",
    elementTypeId = "",
    make = "",
    modelNo = "",
  } = templateObj;

  return {
    id: id,
    version: version,
    updatedOn: updatedOn,
    isActive: isActive,
    element: element,
    elementTypeId: elementTypeId,
    make: make,
    modelNo: modelNo,
  };
}

function transformList(templateList = []) {
  let templateListMap = {};

  templateList.forEach((obj) => {
    const { id = "" } = obj;

    templateListMap[id] = { key: id, ...transformObj(obj) };
  });

  return templateListMap;
}

// Exports
const TemplateTransformer = {
  transformList,
  transformObj,

  transformParamValues,
  transformParamObj,
};

export default TemplateTransformer;
