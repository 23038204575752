import { useEffect, useState } from "react";

// Hooks
import { useUpdateQueryParams } from "../../hooks/useUpdateQueryParams";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../constants/generalConstants";

// Components
import { Button } from "../button/Button";
import ErrorMessage from "../../components/error-message/ErrorMessage";
import Loader from "../loader/Loader";

// Page Component
function PasswordIcon({ isInputTypePassword, showPassword, setShowPassword }) {
  // If input type is password
  if (isInputTypePassword) {
    return (
      <span className="input-group-text bg-body-secondary" onClick={() => setShowPassword((prev) => !prev)}>
        <i className={`fa-regular ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
      </span>
    );
  }

  return "";
}

/**
 * Input Component
 * @param {*} type
 * @param {*} id
 * @param {*} value : Input Value
 * @param {*} name
 * @param {*} className
 * @param {*} onChange : Function
 * @param {*} placeholder
 * @param {*} disabled : Boolean Value
 * @param {*} error : Formik error
 * @param {*} loading : Boolean Value
 * @param {*} spinnerClassName : Class for spinner
 * @param {*} isSearchInput : Boolean Value
 * @param {*} isResetButtonPresent : Boolean Value
 */
export default function Input({
  type = "text",
  id,
  value,
  name,
  className = "",
  containerClassName = "",
  onChange = () => {},
  placeholder = "Enter text..",
  disabled = false,
  error = "",
  loading = false,
  spinnerClassName = "",
  isSearchInput = false,
  inputRef = null,
  ...rest
}) {
  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Getting Search Text From Url
  const search = searchParams.get(QueryParamsKeys.searchText);

  // State
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // useEffect
  useEffect(() => {
    setSearchText(search || "");
  }, [search]);

  // Setting Value For Input
  const inputValue = isSearchInput ? searchText : value;

  // Setting onChange Function
  const inputOnChange = isSearchInput
    ? ({ target }) => {
        setSearchText(target.value);
      }
    : onChange;

  // Showing Password Icon, if input type is password
  const isInputTypePassword = type === "password";

  // Container Class
  const inputContainerClassName = isSearchInput
    ? `d-flex justify-content-start w-25${containerClassName}`
    : containerClassName;

  // Search
  function handleSearch(e) {
    if (!isSearchInput || e.key !== "Enter") {
      return e;
    }

    const searchText = e.target?.value || "";

    const params = {
      search: searchText,
      pn: DefaultPagination.pageNumber,
      ps: DefaultPagination.pageSize,
    };

    // Updating Url
    updateQueryParams({ params });
  }

  function clearInput() {
    setSearchText("");

    updateQueryParams({ params: { search: "" } });
  }

  return (
    <div className={inputContainerClassName}>
      <div className="input-group">
        <input
          ref={inputRef}
          type={!showPassword || !isInputTypePassword ? type : "text"}
          id={id}
          className={`form-control shadow-none ${className} ${error && "border-danger"}`}
          placeholder={placeholder}
          value={inputValue}
          name={name}
          onChange={inputOnChange}
          onKeyDown={handleSearch}
          disabled={disabled}
          {...rest}
        />

        {isSearchInput && (
          <Button className="btn-secondary" onClick={clearInput}>
            <i className="fas fa-x fa-sm m-0"></i>
          </Button>
        )}

        {loading && <Loader containerClassName="input-group-text" spinnerClassName={spinnerClassName} />}

        {/*Password Icon */}
        <PasswordIcon
          isInputTypePassword={isInputTypePassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </div>

      {/* To show error message */}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}
