// Constants
import { ValueTypes } from "../../constants/generalConstants";

// Utils
import { getFormattedDateTime } from "../../utils/dateUtils";

const POWER_CONNECTION_TYPE = {
  HT: "High Tension (HT)",
  LT: "Low Tension (LT)",
};
function getHeaders() {
  const tableHeaders = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Service Number",
      dataIndex: "serviceNo",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text) => POWER_CONNECTION_TYPE[text],
    },
    {
      title: "Updated On",
      dataIndex: "updatedOn",
      render: (text) => getFormattedDateTime(ValueTypes.DATE, text),
    },
  ];

  return tableHeaders;
}

const PowerConnectionListTableHelper = { getHeaders };

export default PowerConnectionListTableHelper;
