import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { useParams } from "react-router-dom";

// Actions
import { PlantComponentActions } from "../../../../../store/slices/plant/componentSlice";
import { PlantComponentPartsActions } from "../../../../../store/slices/plant/partsSlice";

// Utils
import { formatText } from "../../../../../utils/stringUtils";
import { getComponentParts, getComponentTypeParameters } from "../../../../../utils/dataPreLoadUtils";
import { saveFile } from "../../../../../utils/fileUtils";

// Components
import { Button } from "../../../../../components/button/Button";
import Loader from "../../../../../components/loader/Loader";

// Section
import UpsertPlantComponentImage from "./UpsertPlantComponentImage";
import PartsList from "./PartsList";
import ViewPlantComponentPartModal from "./PlantComponentPartModal";
import ViewComponentModal from "./ViewComponentModal";

// Image
import ComponentImage from "../../../../../assets/images/component/Component_Image.png";

// Page Components
function ComponentTypeListCardPropertyBody({ properties = [], propertyList = [] }) {
  return properties.map((eachProperty, index) => {
    const { parameterId = "", value = "" } = eachProperty || {};

    const { name = "", unitSymbol = "" } =
      propertyList.find(({ id = "" }) => parseInt(id) === parseInt(parameterId)) || {};

    // Displaying only the first five properties
    if (index > 5) {
      return null;
    }

    return (
      <div key={index} className="row mb-2">
        <div className="col-8 bold">
          {name} <small className="text-secondary fst-italic">{unitSymbol && `(${unitSymbol})`}</small>
        </div>

        <div className={"col-4"}>{formatText(value)}</div>
      </div>
    );
  });
}

function ComponentTypeListPropertyCardBody({
  properties = [],
  propertyList = [],
  setSelectedComponentIndex,
  componentIndex,
  setShowViewComponentModal = () => {},
}) {
  // Open Component Type List View More Function
  function openViewPlantComponentModal() {
    setSelectedComponentIndex(componentIndex);
    setShowViewComponentModal(true);
  }

  return (
    <div className="col-4">
      <div className="card-body">
        <h5 className="card-title">Properties</h5>

        {/* ComponentType List Card Property Body */}
        <ComponentTypeListCardPropertyBody properties={properties} propertyList={propertyList} />

        <div className="cursor-pointer text-primary" onClick={openViewPlantComponentModal}>
          + view more
        </div>
      </div>
    </div>
  );
}

function ImageActions({
  id = "",
  imageURL = "",
  openAddPlantComponentImageModal = () => {},
  downloadComponentImage = () => {},
}) {
  // Selector State
  const downloadPlantComponentImageLoading = useSelector(
    (state) => state.plantComponent.downloadComponentImageLoading[id]
  );

  if (!imageURL) {
    return (
      <div className="text-center pt-2">
        {/* Add Image Button */}
        <Button label="Add Image" color="link" onClick={openAddPlantComponentImageModal}>
          <i className="fas fa-plus" />
        </Button>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-between p-2">
      {/* Download Image Button */}
      <Button
        label="Download"
        color="secondary"
        size="sm"
        onClick={downloadComponentImage}
        loading={downloadPlantComponentImageLoading}
        disabled={downloadPlantComponentImageLoading}
      >
        <i className="fas fa-download" />
      </Button>

      {/* Update Image Button */}
      <Button label="Update" color="secondary" size="sm" onClick={openAddPlantComponentImageModal}>
        <i className="fas fa-pen" />
      </Button>
    </div>
  );
}

function ComponentTypeListHorizontalCardImage({
  component = {},
  setSelectedComponentIndex,
  index,
  setShowComponentImageModal = () => {},
  isDecommisionApiSucess = false,
  isFrozenOrDecommissioned = false,
}) {
  // Component Information
  const { imageURL = ComponentImage, id = "", name = "" } = component;

  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const plantComponentImageData = useSelector((state) => state.plantComponent.componentImageData[id]);
  const downloadPlantComponentImageSuccess = useSelector(
    (state) => state.plantComponent.downloadComponentImageSuccess[id]
  );

  // Download Component Image
  function downloadComponentImage() {
    dispatch(PlantComponentActions.downloadComponentImage({ componentId: id, name, apiUrl: imageURL }));
  }

  // Open Component Type List Image Upload Modal Function
  function openAddPlantComponentImageModal() {
    setSelectedComponentIndex(index);
    setShowComponentImageModal(true);
  }

  // use Effect
  useEffect(() => {
    if (isDecommisionApiSucess) {
      toast.success("Component decommissioned successfully");
    }
  }, [isDecommisionApiSucess]);

  useEffect(() => {
    if (downloadPlantComponentImageSuccess) {
      saveFile(name, plantComponentImageData);
    }
  }, [downloadPlantComponentImageSuccess]);

  return (
    <>
      <img src={imageURL} className="img-fluid rounded-start p-2 component-image" alt="" />

      {!isFrozenOrDecommissioned && (
        <ImageActions
          id={id}
          imageURL={imageURL}
          downloadComponentImage={downloadComponentImage}
          openAddPlantComponentImageModal={openAddPlantComponentImageModal}
        />
      )}
    </>
  );
}

function DecommisisonActionBtn({ isActive = false, componentTypeId = "", componentId = "", loading = false }) {
  const { plantId = "" } = useParams();

  const dispatch = useDispatch();

  function decommisisonComponent() {
    dispatch(PlantComponentActions.decommissionComponent({ plantId, componentId, componentTypeId }));
  }

  if (!isActive) {
    return (
      <div className="text-danger">
        <i className="fas fa-ban me-1"></i>Decommisioned
      </div>
    );
  }

  return (
    <Button
      label="Decommisison"
      size="sm"
      color="danger"
      onClick={decommisisonComponent}
      loading={loading}
      disabled={loading}
    >
      <i className="fas fa-ban"></i>
    </Button>
  );
}

function ComponentTypeListHorizontalCard({
  componentTypeId = "",
  parts = [],
  setSelectedComponentIndex,
  setSelectedPartId,
  isFrozenOrDecommissioned = false,
  setShowAddPartModal = () => {},
  setShowViewComponentModal = () => {},
  setShowComponentImageModal = () => {},
  setShowViewPartModal = () => {},
}) {
  const dispatch = useDispatch();

  // Plant Component List Selector State
  const plantComponentListMap = useSelector((state) => state.plantComponent.componentListMap);
  const plantComponentList = Object.values(plantComponentListMap);

  const decommissionComponentLoadingMap = useSelector((state) => state.plantComponent.decommissionComponentLoading);
  const decommissionComponentSuccessMap = useSelector((state) => state.plantComponent.decommissionComponentSuccess);

  // Checking whether data is there or not
  const isDataAvailable = plantComponentList.length !== 0 && componentTypeId;

  // Getting property list for the component type
  const propertyList = getComponentTypeParameters(componentTypeId) || [];

  useEffect(() => {
    return () => {
      dispatch(PlantComponentActions.resetAllApiSuccessState());
      dispatch(PlantComponentPartsActions.resetAllApiSuccessState());
    };
  }, [dispatch]);

  // If there is no data
  if (!isDataAvailable) {
    return <div className="text-center p-3">No Components available</div>;
  }

  return plantComponentList.map((eachComponent, index) => {
    // Getting component information
    const { component = {}, properties = [] } = eachComponent || {};
    const { id = "", name = "", internalId = "", remarks = "", isActive = true, frozen = false } = component;

    const loading = decommissionComponentLoadingMap[id] || false;
    const isDecommisionApiSucess = decommissionComponentSuccessMap[id] || false;

    return (
      <div key={index} className={`card mb-3 ${!isActive ? "bg-danger-subtle" : ""}`}>
        <div className="row g-0">
          <div className="col-2">
            {/* Component Type List Horizontal Card Image */}
            <ComponentTypeListHorizontalCardImage
              component={component}
              setSelectedComponentIndex={setSelectedComponentIndex}
              index={index}
              setShowComponentImageModal={setShowComponentImageModal}
              isDecommisionApiSucess={isDecommisionApiSucess}
              isFrozenOrDecommissioned={isFrozenOrDecommissioned}
            />
          </div>

          <div className="col-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <div className="fw-medium fs-5">
                    {name} ({internalId})
                  </div>
                  {frozen && (
                    <div className="bg-primary-subtle p-1 rounded-3 w-50 text-center mt-1">
                      <i class="fa-solid fa-snowflake me-1"></i>frozen
                    </div>
                  )}
                </div>

                <DecommisisonActionBtn
                  isActive={isActive}
                  componentId={id}
                  componentTypeId={componentTypeId}
                  loading={loading}
                />
              </div>
              <p className="card-text">{remarks}</p>
            </div>
          </div>

          {/* ComponentType List Property Card Body */}
          <ComponentTypeListPropertyCardBody
            propertyList={propertyList}
            properties={properties}
            componentIndex={index}
            setSelectedComponentIndex={setSelectedComponentIndex}
            setShowViewComponentModal={setShowViewComponentModal}
          />

          <div className="col-3">
            <div className="card-body">
              <h5 className="card-title">Parts</h5>

              {/* Parts List */}
              <PartsList
                isFrozenOrDecommissioned={isFrozenOrDecommissioned}
                parts={parts}
                setSelectedPartId={setSelectedPartId}
                componentIndex={index}
                setSelectedComponentIndex={setSelectedComponentIndex}
                setShowViewPartModal={setShowViewPartModal}
                setShowAddPartModal={setShowAddPartModal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  });
}

function UpsertPlantComponentImageModal({
  showComponentImageModal = false,
  setShowComponentImageModal = () => {},
  componentId = "",
  componentTypeId = "",
  imageURL = "",
}) {
  const uploadPlantComponentImageSuccess = useSelector((state) => state.plantComponent.uploadComponentImageSuccess);

  const upsertImageModalTitle = imageURL ? "Update Image" : "Add Image";

  useEffect(() => {
    if (uploadPlantComponentImageSuccess) {
      setShowComponentImageModal(false);
      toast.success("Uploaded Plant Component Image Successfully");
    }
  }, [uploadPlantComponentImageSuccess]);

  return (
    <Modal
      title={upsertImageModalTitle}
      open={showComponentImageModal}
      width={700}
      footer={null}
      onCancel={() => setShowComponentImageModal(false)}
      destroyOnClose={true}
    >
      <UpsertPlantComponentImage componentId={componentId} componentTypeId={componentTypeId} imageURL={imageURL} />
    </Modal>
  );
}

/**
 * Component Type List Card
 */
export default function ComponentTypeListCardView({
  componentTypeId = "",
  tableHeaders = [],
  formInputItems = [],
  selectedComponentIndex,
  setSelectedComponentIndex,
}) {
  // State
  const [selectedPartId, setSelectedPartId] = useState("");
  const [showAddPartModal, setShowAddPartModal] = useState(false);
  const [showViewPartModal, setShowViewPartModal] = useState(false);
  const [showComponentImageModal, setShowComponentImageModal] = useState(false);
  const [showViewComponentModal, setShowViewComponentModal] = useState(false);

  // Plant Component List Selector State
  const plantComponentListMap = useSelector((state) => state.plantComponent.componentListMap);
  const plantComponentList = Object.values(plantComponentListMap);

  const plantComponentListLoading = useSelector((state) => state.plantComponent.componentListLoading);

  // Update Plant Component Selector State
  const updatePlantComponentLoading = useSelector((state) => state.plantComponent.updateComponentLoading);
  const updatePlantComponentSuccess = useSelector((state) => state.plantComponent.updateComponentSuccess);

  // Getting parts for that component
  const parts = getComponentParts(componentTypeId) || [];

  // Getting component info
  const {
    id: componentId = "",
    name = "",
    internalId = "",
    imageURL = "",
    frozen = false,
    isActive = false,
  } = plantComponentList[selectedComponentIndex]?.component || {};
  const viewComponentModalTitle = `${name} (${internalId})`;

  // Checking whether the component is frozen or decommisioned
  const isFrozenOrDecommissioned = frozen || !isActive;

  // use Effect
  useEffect(() => {
    if (updatePlantComponentSuccess) {
      setShowViewComponentModal(false);
      toast.success("Updated Plant Component Successfully");
    }
  }, [updatePlantComponentSuccess]);

  // Loader
  if (plantComponentListLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          {/* Component Type List Horizontal Card */}
          <ComponentTypeListHorizontalCard
            componentTypeId={componentTypeId}
            parts={parts}
            selectedComponentIndex={selectedComponentIndex}
            setSelectedComponentIndex={setSelectedComponentIndex}
            isFrozenOrDecommissioned={isFrozenOrDecommissioned}
            setSelectedPartId={setSelectedPartId}
            setShowComponentImageModal={setShowComponentImageModal}
            setShowViewComponentModal={setShowViewComponentModal}
            setShowViewPartModal={setShowViewPartModal}
            setShowAddPartModal={setShowAddPartModal}
          />
        </div>
      </div>

      {/* View Plant Component Modal */}
      <ViewComponentModal
        isFrozenOrDecommissioned={isFrozenOrDecommissioned}
        title={viewComponentModalTitle}
        componentInfo={plantComponentList[selectedComponentIndex]}
        tableHeaders={tableHeaders}
        loading={updatePlantComponentLoading}
        dispatchAtn={PlantComponentActions.updateComponent}
        formInputItems={formInputItems}
        showModal={showViewComponentModal}
        setShowModal={setShowViewComponentModal}
      />

      {/* View Plant Component Part Modal */}
      <ViewPlantComponentPartModal
        isFrozenOrDecommissioned={isFrozenOrDecommissioned}
        selectedComponentIndex={selectedComponentIndex}
        tableHeaders={tableHeaders}
        selectedPartId={selectedPartId}
        componentTypeId={componentTypeId}
        showViewPartModal={showViewPartModal}
        setShowViewPartModal={setShowViewPartModal}
        showAddPartModal={showAddPartModal}
        setShowAddPartModal={setShowAddPartModal}
      />

      <UpsertPlantComponentImageModal
        showComponentImageModal={showComponentImageModal}
        setShowComponentImageModal={setShowComponentImageModal}
        componentId={componentId}
        componentTypeId={componentTypeId}
        imageURL={imageURL}
      />
    </>
  );
}
