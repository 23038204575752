import { createSlice } from "@reduxjs/toolkit";

// Transformers
import MeasurementTypeTransformer from "./measurementTypeTransformer";

function resetApiSuccessState(state) {
  state.updateStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get Measurement List
  measurementTypeMap: {},
  measurementTypeListPagination: {},
  measurementTypeListError: "",
  measurementTypeListLoading: false,

  // Get Measurement units list
  measurementUnitsMap: {},
  measurementUnitsListPagination: {},
  measurementUnitsListLoading: false,
  measurementUnitsListError: "",

  // Get Measurement type information
  measurementTypeInfo: {},
  measurementTypeInfoLoading: false,
  measurementTypeInfoError: "",

  // Update Measurement Type Status (Enable/Disable)
  updateStatusLoadingMap: {},
  updateStatusSuccessMap: {},
  updateStatusError: "",
};

// Measurement Type Slice
const measurementTypeSlice = createSlice({
  name: "MeasurementType",
  initialState,
  reducers: {
    // Get Measurement Type List
    getMeasurementTypeList: (state) => {
      state.measurementTypeListLoading = true;
      state.measurementTypeListError = "";
    },

    getMeasurementTypeListSuccess: (state, action) => {
      const { measurementTypeList, measurementTypeListPagination } = action.payload;

      state.measurementTypeMap = MeasurementTypeTransformer.getTransformedMeasurementTypeMap(measurementTypeList);
      state.measurementTypeListPagination = measurementTypeListPagination;
      state.measurementTypeListLoading = false;
    },

    getMeasurementTypeListFailure: (state, action) => {
      state.measurementTypeListLoading = false;
      state.measurementTypeListError = action.payload.error;
    },

    // Get Measurement Units List
    getMeasurementUnitsList: (state) => {
      state.measurementUnitsListLoading = true;
      state.measurementUnitsListError = "";
    },

    getMeasurementUnitsListSuccess: (state, action) => {
      const { measurementUnitsList, measurementUnitsListPagination } = action.payload;

      state.measurementUnitsMap = MeasurementTypeTransformer.getTransformedMeasurementUnitsMap(measurementUnitsList);
      state.measurementUnitsListPagination = measurementUnitsListPagination;
      state.measurementUnitsListLoading = false;
    },

    getMeasurementUnitsListFailure: (state, action) => {
      state.measurementUnitsListLoading = false;
      state.measurementUnitsListError = action.payload.error;
    },

    // Get Measurement Info
    getMeasurementTypeInfo: (state) => {
      state.measurementTypeInfoLoading = true;
    },

    getMeasurementTypeInfoSuccess: (state, action) => {
      const measurementTypeInfo = action.payload.measurementTypeInfo;
      state.measurementTypeInfo = MeasurementTypeTransformer.transformMeasurementTypeObj(measurementTypeInfo);

      state.measurementTypeInfoLoading = false;
    },

    getMeasurementTypeInfoFailure: (state, action) => {
      state.measurementTypeInfoLoading = false;
      state.measurementTypeInfoError = action.payload.error;
    },

    // Update Measurement Type Status (Enable/Disable)
    updateMeasurementTypeStatus: (state, action) => {
      const { measurementTypeId } = action.payload;
      state.updateStatusSuccessMap[measurementTypeId] = false;
      state.updateStatusLoadingMap[measurementTypeId] = true;
      state.updateStatusError = "";
    },

    updateMeasurementTypeStatusSuccess: (state, action) => {
      const { measurementTypeId } = action.payload;
      state.updateStatusSuccessMap[measurementTypeId] = true;
      delete state.updateStatusLoadingMap[measurementTypeId];
    },

    updateMeasurementTypeStatusFailure: (state, action) => {
      const { measurementTypeId, error } = action.payload;
      delete state.updateStatusLoadingMap[measurementTypeId];
      state.updateStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const MeasurementTypeReducer = measurementTypeSlice.reducer;

// Actions
export const MeasurementTypeActions = measurementTypeSlice.actions;
