import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { DataPreLoadActions } from "../../slices/data-pre-load/dataPreLoadSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import DataPreLoadService from "../../../services/data-pre-load/dataPreLoadService";

// Data Pre Load
function* dataPreLoad(action) {
  const { key = "", apiUrl = "" } = action.payload;

  try {
    const data = yield DataPreLoadService.dataPreLoad(apiUrl);

    yield put(DataPreLoadActions.dataPreLoadSuccess({ key, data }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(DataPreLoadActions.dataPreLoadFailure({ error }));
  }
}

export default function* root() {
  yield all([takeEvery(DataPreLoadActions.dataPreLoad.type, dataPreLoad)]);
}
