import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";

// Action
import { ProductActions } from "../../../store/slices/product/productSlice";

// Constants
import { DefaultPagination } from "../../../constants/generalConstants";

// Helpers
import ProductListTableHelper from "../../../helpers/product/productListTableHelper";

// Components
import TablePagination from "../../../components/table/TablePagination";
import ToggleButton from "../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../components/antd/table/SkeletonTable";
import DataTable from "../../../components/antd/table/DataTable";

// Page Components
function TableActions({ record = {}, pageNumber, pageSize }) {
  const { id: productId = "", isActive = false } = record;

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.product.updateProductStatusLoadingMap[productId]) || false;
  const updateStatusSuccess = useSelector((state) => state.product.updateProductStatusSuccessMap[productId]) || false;

  const status = !isActive;

  function updateStatus() {
    dispatch(ProductActions.updateProductStatus({ productId, status, pageNumber, pageSize }));
  }

  useEffect(() => {
    if (updateStatusSuccess) {
      toast.success("Product Status Updated Successfully");
    }
  }, [updateStatusSuccess]);

  return <ToggleButton isActive={isActive} loading={loading} onClick={updateStatus} />;
}

/**
 * Product List Table
 */
export default function ProductListTable() {
  // Selector States
  const productListMap = useSelector((state) => state.product.productListMap);
  const productList = Object.values(productListMap);

  const productListLoading = useSelector((state) => state.product.productListLoading);
  const productListPagination = useSelector((state) => state.product.productListPagination);

  // Pagination
  const {
    totalCount = 1,
    pageNo = DefaultPagination.pageNumber,
    pageSize = DefaultPagination.pageSize,
  } = productListPagination || {};

  const tableHeaders = ProductListTableHelper.getHeaders(pageNo, pageSize);

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => <TableActions record={record} pageNumber={pageNo} pageSize={pageSize} />,
  };

  const columns = [...tableHeaders, actionColumn];

  if (productListLoading) {
    return <SkeletonTable columnCount={7} rowCount={10} />;
  }

  return (
    <>
      <DataTable rows={productList} columns={columns} applyTableBorder={true} />

      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
