import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { OrganizationActions } from "../../slices/organization/organizationSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import OrganizationService from "../../../services/organization/organizationService";

// Get Organization List
function* getOrganizationList(action) {
  try {
    const { orgType, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield OrganizationService.getOrganizationList(
      orgType,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      OrganizationActions.getOrganizationListSuccess({
        orgType,
        organizationList: items,
        organizationListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(OrganizationActions.getOrganizationListFailure({ error }));
  }
}

// Add Organization
function* addOrganization(action) {
  try {
    const { organizationData } = action.payload;

    yield OrganizationService.addOrganization(organizationData);

    yield put(OrganizationActions.addOrganizationSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(OrganizationActions.addOrganizationFailure({ error }));
  }
}

// Update Organization
function* updateOrganization(action) {
  try {
    const { orgId, organizationData } = action.payload;

    yield OrganizationService.updateOrganization(orgId, organizationData);

    const organizationInfo = yield OrganizationService.getOrganizationInfo(orgId);

    yield put(OrganizationActions.getOrganizationInfoSuccess({ organizationInfo }));

    yield put(OrganizationActions.updateOrganizationSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(OrganizationActions.updateOrganizationFailure({ error }));
  }
}

// Get Organization Info
function* getOrganizationInfo(action) {
  try {
    const { orgId } = action.payload;

    const organizationInfo = yield OrganizationService.getOrganizationInfo(orgId);

    yield put(OrganizationActions.getOrganizationInfoSuccess({ organizationInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(OrganizationActions.getOrganizationInfoFailure({ error }));
  }
}

// Update Organization Status
function* updateOrganizationStatus(action) {
  try {
    const { orgId, orgType, status, pageNumber, pageSize } = action.payload;

    yield OrganizationService.updateOrganizationStatus(orgId, status);

    const { items, pagination } = yield OrganizationService.getOrganizationList(orgType, pageNumber, pageSize);

    yield put(
      OrganizationActions.getOrganizationListSuccess({
        orgType,
        organizationList: items,
        organizationListPagination: pagination,
      })
    );

    yield put(OrganizationActions.updateOrganizationStatusSuccess({ orgId }));
  } catch (error) {
    const { orgId } = action.payload;

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(OrganizationActions.updateOrganizationStatusFailure({ orgId, error }));
  }
}

export default function* root() {
  yield all([
    takeEvery(OrganizationActions.getOrganizationList.type, getOrganizationList),
    takeLatest(OrganizationActions.addOrganization.type, addOrganization),
    takeLatest(OrganizationActions.getOrganizationInfo.type, getOrganizationInfo),
    takeLatest(OrganizationActions.updateOrganization.type, updateOrganization),
    takeEvery(OrganizationActions.updateOrganizationStatus.type, updateOrganizationStatus),
  ]);
}
