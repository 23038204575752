// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { OrganizationUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Organization List
async function getOrganizationList(orgType, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    type: orgType,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(OrganizationUrls.getOrganizationList, params);
}

// Add Organization
async function addOrganization(organizationData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(OrganizationUrls.addOrganization, {}, organizationData);
}

// Update Organization
async function updateOrganization(orgId, organizationData) {
  const url = URLs.format(OrganizationUrls.updateOrganization, { orgId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, organizationData);
}

// Get Organization Info
async function getOrganizationInfo(orgId) {
  const url = URLs.format(OrganizationUrls.getOrganizationInfo, { orgId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update Organization status
async function updateOrganizationStatus(orgId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(OrganizationUrls.updateOrganizationStatus, { orgId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const OrganizationService = {
  getOrganizationList,
  addOrganization,
  updateOrganization,
  getOrganizationInfo,
  updateOrganizationStatus,
};

export default OrganizationService;
