import TableUtils from "../../utils/tableUtils";

function getHeders(currentPage, pageSize) {
  const tableHeaders = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Plant Id",
      dataIndex: "internalId",
    },
    {
      title: "Area",
      dataIndex: "areaInSqFt",
    },
    {
      title: "Employee Count",
      dataIndex: "employeesCount",
    },

    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "Established Year",
      dataIndex: "estYear",
    },
  ];

  return tableHeaders;
}

const PlantListTableHelper = { getHeders };

export default PlantListTableHelper;
