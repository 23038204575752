// Plant Transformer
function transformObj(plantObj = {}) {
  const {
    id = "",
    orgId = "",
    name = "",
    internalId = "",
    areaInSqFt = "",
    estYear = "",
    workHrsPerDay = "",
    workDaysPerWeek = "",
    employeesCount = "",
    addrLine1 = "",
    addrLine2 = "",
    city = "",
    state = "",
    pincode = "",
    geoLocation = "",
    isActive = false,
  } = plantObj;

  return {
    id: id,
    orgId: orgId,
    name: name,
    internalId: internalId,
    areaInSqFt: areaInSqFt,
    workHrsPerDay: workHrsPerDay,
    workDaysPerWeek: workDaysPerWeek,
    employeesCount: employeesCount,
    addrLine1: addrLine1,
    addrLine2: addrLine2,
    state: state,
    pincode: pincode,
    geoLocation: geoLocation,
    city: city,
    estYear: estYear,
    isActive: isActive,
  };
}

function transformList(plantList = []) {
  let plantListMap = {};

  plantList.forEach((obj) => {
    const { id = "" } = obj;

    plantListMap[id] = { key: id, ...transformObj(obj) };
  });

  return plantListMap;
}

// Exports
const PlantTransformer = {
  transformObj,
  transformList,
};

export default PlantTransformer;
