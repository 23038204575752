import { createSlice } from "@reduxjs/toolkit";

// Transformer
import AuditObservationAndRecommendationTransformer from "./observationAndRecommendationTransformer";

function resetApiSuccessState(state) {
  state.updateObsAndRecSuccess = {};
  state.updateInvestmentSuccess = {};
}

// Initial State
const initialState = {
  // Get Observation And Recommendation List
  obsAndRecListMap: {},
  obsAndRecListPagination: {},
  obsAndRecListLoading: false,
  obsAndRecListError: "",

  // Update Observation And Recommendation
  updateObsAndRecLoading: {},
  updateObsAndRecSuccess: {},
  updateObsAndRecError: "",

  // Update Investment
  updateInvestmentLoading: {},
  updateInvestmentSuccess: {},
  updateInvestmentError: "",
};

// Audit Observation And Recommendation Slice
const auditObsAndRecSlice = createSlice({
  name: "AuditObservationAndRecommendation",
  initialState,

  reducers: {
    // Get Observation And Recommendation List
    getObsAndRecList: (state) => {
      state.obsAndRecListLoading = true;
    },

    getObsAndRecListSuccess: (state, action) => {
      const { auditObsAndRecList = [], obsAndRecListPagination = {} } = action.payload;
      state.obsAndRecListMap = AuditObservationAndRecommendationTransformer.transformList(auditObsAndRecList);

      state.obsAndRecListLoading = false;
      state.obsAndRecListPagination = obsAndRecListPagination;
    },

    getObsAndRecListFailure: (state, action) => {
      state.obsAndRecListLoading = false;
      state.obsAndRecListError = action.payload.error;
    },

    // Update Observation And Recommendation
    updateObsAndRec: (state, action) => {
      const { observationId } = action.payload;
      state.updateObsAndRecLoading[observationId] = true;
      state.updateObsAndRecSuccess[observationId] = false;
      state.updateObsAndRecError = "";
    },

    updateObsAndRecSuccess: (state, action) => {
      const { observationId } = action.payload;
      state.updateObsAndRecSuccess[observationId] = true;
      delete state.updateObsAndRecLoading[observationId];
    },

    updateObsAndRecFailure: (state, action) => {
      const { observationId, error } = action.payload;
      delete state.updateObsAndRecLoading[observationId];
      state.updateObsAndRecError = error;
    },

    // Update Investment
    updateInvestment: (state, action) => {
      const { recommendationId } = action.payload;
      state.updateInvestmentSuccess[recommendationId] = false;
      state.updateInvestmentLoading[recommendationId] = true;
      state.updateInvestmentError = "";
    },

    updateInvestmentSuccess: (state, action) => {
      const { recommendationId } = action.payload;
      state.updateInvestmentSuccess[recommendationId] = true;
      delete state.updateInvestmentLoading[recommendationId];
    },

    updateInvestmentFailure: (state, action) => {
      const { recommendationId, error } = action.payload;
      delete state.updateInvestmentLoading[recommendationId];
      state.updateInvestmentError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditObservationAndRecommendationReducer = auditObsAndRecSlice.reducer;

// Actions
export const AuditObservationAndRecommendationActions = auditObsAndRecSlice.actions;
