import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentActions } from "../../slices/plant/componentSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Constants
import { DefaultPagination } from "../../../constants/generalConstants";

// Service
import PlantComponentService from "../../../services/plant/componentService";

// Get Component List
function* getComponentList(action) {
  try {
    const { pageNumber, pageSize, plantId, componentTypeId } = action.payload;

    const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentActions.getComponentListSuccess({ componentList }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.getComponentListFailure({ error }));
  }
}

// Add Component
function* addComponent(action) {
  try {
    const { componentData, plantId, componentTypeId, pageNumber, pageSize = 100 } = action.payload;

    yield PlantComponentService.addComponent(componentData, plantId);

    const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentActions.getComponentListSuccess({ componentList }));

    yield put(PlantComponentActions.addComponentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.addComponentFailure({ error }));
  }
}

// Update Component
function* updateComponent(action) {
  try {
    const { componentData, componentId, plantId, pageNumber, pageSize = 100 } = action.payload;
    const { componentTypeId } = componentData;

    yield PlantComponentService.updateComponent(componentData, componentId, plantId);

    const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentActions.getComponentListSuccess({ componentList }));

    yield put(PlantComponentActions.updateComponentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.updateComponentFailure({ error }));
  }
}

// Upload Component Image
function* uploadComponentImage(action) {
  try {
    const { formData, componentTypeId, componentId, plantId } = action.payload;

    yield PlantComponentService.uploadComponentImage(formData, plantId, componentId);

    const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId);

    yield put(PlantComponentActions.getComponentListSuccess({ componentList }));

    yield put(PlantComponentActions.uploadComponentImageSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.uploadComponentImageFailure({ error }));
  }
}

// Download Component Image
function* downloadComponentImage(action) {
  const { componentId = "", apiUrl = "" } = action.payload;

  try {
    const componentImageData = yield PlantComponentService.downloadComponentImage(apiUrl);

    yield put(PlantComponentActions.downloadComponentImageSuccess({ componentId, componentImageData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.downloadComponentImageFailure({ error, componentId }));
  }
}
// Decommission Component
function* decommissionComponent(action) {
  const { componentId = "", plantId = "", componentTypeId = "", pageNumber = "", pageSize = 100 } = action.payload;

  try {
    yield PlantComponentService.decommissionComponent(plantId, componentId);

    const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentActions.getComponentListSuccess({ componentList }));

    yield put(PlantComponentActions.decommissionComponentSuccess({ componentId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.decommissionComponentFailure({ error, componentId }));
  }
}

// Update Component Position
function* updateComponentPosition(action) {
  const { componentId = "", plantId = "", position = {} } = action.payload;

  try {
    yield PlantComponentService.updateComponentPosition(plantId, componentId, position);

    yield put(PlantComponentActions.updateComponentPositionSuccess({ componentId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.updateComponentPositionFailure({ error, componentId }));
  }
}

// Upload Bulk Component
function* uploadBulkComponent(action) {
  try {
    const {
      plantId = "",
      componentTypeCode = "",
      componentTypeId = "",
      pageNumber = DefaultPagination.maxPageSize,
      formData = {},
    } = action.payload;

    yield PlantComponentService.uploadBulkComponent(plantId, componentTypeCode, formData);

    const componentList = yield PlantComponentService.getComponentList(plantId, componentTypeId, pageNumber);

    yield put(PlantComponentActions.getComponentListSuccess({ componentList }));

    yield put(PlantComponentActions.uploadBulkComponentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.uploadBulkComponentFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantComponentActions.getComponentList.type, getComponentList),
    takeLatest(PlantComponentActions.addComponent.type, addComponent),
    takeLatest(PlantComponentActions.updateComponent.type, updateComponent),
    takeLatest(PlantComponentActions.uploadComponentImage.type, uploadComponentImage),
    takeLatest(PlantComponentActions.downloadComponentImage.type, downloadComponentImage),
    takeLatest(PlantComponentActions.decommissionComponent.type, decommissionComponent),
    takeLatest(PlantComponentActions.updateComponentPosition.type, updateComponentPosition),
    takeLatest(PlantComponentActions.uploadBulkComponent.type, uploadBulkComponent),
  ]);
}
