import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { NICCodeActions } from "../../../store/slices/nic-code/NICCodeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/generalConstants";
import { NIC_CODE_TABLE_HEADER } from "../../../constants/NICCodeConstants";

// Utils
import TableUtils from "../../../utils/tableUtils";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import SkeletonTable from "../../../components/antd/table/SkeletonTable";
import DataTable from "../../../components/antd/table/DataTable";
import TablePagination from "../../../components/table/TablePagination";

/**
 * NIC Code List Page
 */
export default function NICCodeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector States
  const nicCodeListMap = useSelector((state) => state.nicCode.nicCodeListMap);
  const nicCodeList = Object.values(nicCodeListMap);

  const nicCodeListLoading = useSelector((state) => state.nicCode.nicCodeListLoading);
  const nicCodeListPagination = useSelector((state) => state.nicCode.nicCodeListPagination);
  const { totalCount, pageNo, pageSize } = nicCodeListPagination;

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? "";
  const searchText = searchParams.get(QueryParamsKeys.searchText) ?? "";

  const serialNoHeader = {
    title: "S.No",
    dataIndex: "sno",
    align: "center",
    render: (text, record, index) => TableUtils.tableSerialNumber(pageNo, pageSize, index),
  };
  const columns = [serialNoHeader, ...NIC_CODE_TABLE_HEADER];

  // useEffect
  useEffect(() => {
    dispatch(NICCodeActions.getNICCodeList({ pageNumber, searchText }));
  }, [dispatch, pageNumber, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="NIC Code List" />);
  }, []);

  return (
    <div className="page-content">
      {nicCodeListLoading && <SkeletonTable columnCount={columns.length} rowCount={10} />}

      {!nicCodeListLoading && (
        <>
          <DataTable rows={nicCodeList} columns={columns} />
          <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
        </>
      )}
    </div>
  );
}
