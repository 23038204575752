// Constants
import { NODE_TYPE } from "./constants/reactFlowConstants";

// Components
// Node
import ComponentNode from "./nodes/ComponentNode";

const NodeTypeComponentMap = {
  [NODE_TYPE.COMPONENT]: ComponentNode,
};

const GraphConfig = {
  // Node
  NodeTypeComponentMap,
};

export default GraphConfig;
