import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { RoleActions } from "../../../store/slices/role/roleSlice";
import { OrganizationActions } from "../../../store/slices/organization/organizationSlice";
import { UserActions } from "../../../store/slices/user/userSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";
import { QueryParamsKeys } from "../../../constants/generalConstants";
import { ModalKeys } from "../../../constants/modalConstants";

// Utils
import { getOrgType } from "../../../utils/authUtils";
import { bcForUsersListPage } from "../../../utils/breadcrumbUtils";
import URLs from "../../../utils/urlUtils";

// Components
import { Button } from "../../../components/button/Button";
import AutoComplete from "../../../components/auto-complete/AutoComplete";
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";
import Modal, { ModalActions } from "../../../components/modal/Modal";

// page components
import UserListTable from "./components/UserListTable";

// Page Component
function AssignRoleAction({ userId, orgId }) {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [error, setError] = useState("");

  // Role List Selector State
  const roleList = useSelector((state) => state.role.roleList);

  // Assign Role Selector State
  const assignRoleSuccess = useSelector((state) => state.user.assignRoleSuccess);
  const assignRoleLoading = useSelector((state) => state.user.assignRoleLoading);

  // Getting organization Type
  const orgType = getOrgType();

  // Construct Options Array
  function constructOptions(roleList = []) {
    const optionArray = roleList.reduce((acc, role) => {
      const { id, name } = role;

      return [...acc, { id, value: name }];
    }, []);

    return optionArray;
  }

  // Handle Submit
  function handleSubmit() {
    // If id is not present
    if (!selectedRoleId) {
      return setError("Please Select Role!!!");
    }

    // Clearing errors
    setError("");

    // Dispatch
    dispatch(UserActions.assignRole({ orgId, orgType, userId, roleId: selectedRoleId }));
  }

  useEffect(() => {
    if (assignRoleSuccess) {
      toast.success("Role Assigned Successfully");
      ModalActions.close(ModalKeys.assignRoleComponentModal);
    }
  }, [assignRoleSuccess]);

  return (
    <>
      {/* Auto Complete */}
      <AutoComplete
        id="role-list"
        options={constructOptions(roleList)}
        placeholder="Search Role..."
        onChange={({ target }) => {
          setSelectedRoleId(target.value);
        }}
        loading={assignRoleLoading}
        disabled={assignRoleLoading}
        error={error}
      />

      <div className="mt-2">
        {/* Button */}
        <Button label="Assign" size="sm" color="secondary" onClick={handleSubmit} disabled={assignRoleLoading} />
      </div>
    </>
  );
}

function PageHeaderSection({ orgInfo }) {
  const { orgType = "", name = "" } = orgInfo || {};
  return <PageHeader breadcrumbList={bcForUsersListPage(name, orgType)} className="w-100 mt-2" />;
}

function TableActionsSection({ orgInfo }) {
  const navigate = useNavigate();

  const { id: orgId = "" } = orgInfo;

  // Navigate to user create page
  function navigateToUserCreatePage() {
    const redirectUrl = URLs.format(PageURLs.UserCreatePageUrl, { orgId });
    navigate(redirectUrl);
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search Users...`} isSearchInput={true} />

      <div className="btn-cont">
        <Button className="m-0" label="Add User" onClick={navigateToUserCreatePage}>
          <i className="fas fa-plus"></i>
        </Button>
      </div>
    </div>
  );
}

/**
 * User List Page
 */
export default function UserListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const { orgId = "", plantId = "" } = useParams();

  // Update Query Params Hook
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent = () => {}] = useOutletContext();

  // State
  const [userId, setUserId] = useState("");

  // User List Selector State
  const userMap = useSelector((state) => state.user.userMap);
  const userList = Object.values(userMap);
  const userListLoading = useSelector((state) => state.user.userListLoading);
  const userListPagination = useSelector((state) => state.user.userListPagination);

  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(RoleActions.getRoleList({}));
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    dispatch(UserActions.getUserList({ orgId, pageNumber, pageSize, searchText }));
  }, [dispatch, orgId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    return () => dispatch(UserActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection orgInfo={orgInfo} />);
  }, [orgInfo]);

  // use Memo
  const filteredUserList = useMemo(() => {
    // For plant specific users, first, the list of all users for that industry is taken
    // Form that users only to the selected plant is filtered out
    if (plantId) {
      return userList.filter((user) => parseInt(user?.plantId || 0) === parseInt(plantId)) || [];
    }

    return userList;
  }, [plantId, userList]);

  return (
    <>
      {/* Page Content */}
      <div className="page-content">
        {/* Table Actions Section */}
        {!userListLoading && <TableActionsSection orgInfo={orgInfo} />}

        {/* User List Table */}
        <UserListTable
          userList={filteredUserList}
          userListLoading={userListLoading}
          userListPagination={userListPagination}
          pageNumber={pageNumber}
          setUserId={setUserId}
        />
      </div>

      {/* Modal */}
      <Modal id={ModalKeys.assignRoleComponentModal} title="Assign Role" className="modal-dialog-centered">
        {/* Assign Role Action */}
        <AssignRoleAction userId={userId} orgId={orgId} />
      </Modal>
    </>
  );
}
