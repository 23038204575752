// Utils
import DecimalUtils from "../utils/decimalUtils";

// Components
import SavingsRecommendationListInvestmentInput from "../pages/audit/audit-view/audit-savings-calculation/components/SavingsRecommendationListInvestmentInput";

export const AUDIT_SAVINGS_RECOMMENDATION_LIST_TABLE_HEADER = [
  {
    title: "Recommendation Code",
    dataIndex: "code",
    render: (code, { energySavingsFormulaStr = "" }) => (
      <>
        {code}
        {energySavingsFormulaStr && (
          <i className="fa-solid fa-circle-info fa-sm ms-2" title={energySavingsFormulaStr}></i>
        )}
      </>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    ellipsis: true,
  },
  {
    title: "Energy Savings",
    dataIndex: "savingsEnergy",
    render: (savingsEnergy) => DecimalUtils.fixDecimal(savingsEnergy, 0),
  },
  {
    title: "Cost Savings (INR)",
    dataIndex: "savingsCost",
    render: (savingsCost) => DecimalUtils.fixDecimal(savingsCost, 0),
  },
  {
    title: "Investment (INR)",
    dataIndex: "investment",
    width: 220,
    render: (investment, { id = "" }) => (
      <>
        <SavingsRecommendationListInvestmentInput investment={investment} id={id} />
      </>
    ),
  },
  {
    title: "Investment Returns in (Months)",
    dataIndex: "investmentReturns",
    render: (investmentReturns) => DecimalUtils.fixDecimal(investmentReturns, 0),
  },
];
