import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditReportActions } from "../../slices/audit/reportSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Utils
import { saveFile } from "../../../utils/fileUtils";

// Service
import AuditReportService from "../../../services/audit/reportService";

// Get Reports List
function* getReportList(action) {
  try {
    const { auditId = "" } = action.payload;

    const reportList = yield AuditReportService.getReportList(auditId);

    yield put(AuditReportActions.getReportListSuccess({ reportList }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.getReportListFailure({ error }));
  }
}

// Create Report
function* createReport(action) {
  const { auditId = "", reportData = {} } = action.payload;
  const { reportType = "" } = reportData;

  try {
    const createdReportInfo = yield AuditReportService.createReport(auditId, reportData);

    yield put(AuditReportActions.createReportSuccess({ reportType, createdReportInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.createReportFailure({ error, reportType }));
  }
}

// Update Report
function* updateReport(action) {
  const { auditId = "", reportId = "", reportData = {} } = action.payload;

  try {
    yield AuditReportService.updateReport(auditId, reportId, reportData);

    const html = yield AuditReportService.getReportHTML(auditId, reportId);

    yield put(AuditReportActions.getReportHTMLSuccess({ html }));

    yield put(AuditReportActions.updateReportSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.updateReportFailure({ error }));
  }
}

// Delete Report
function* deleteReport(action) {
  const { auditId = "", reportId = "" } = action.payload;

  try {
    yield AuditReportService.deleteReport(auditId, reportId);

    yield put(AuditReportActions.deleteReportSuccess({ reportId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.deleteReportFailure({ error, reportId }));
  }
}

// Get Report HTML
function* getReportHTML(action) {
  const { auditId = "", reportId = "" } = action.payload;

  try {
    const reportHTML = yield AuditReportService.getReportHTML(auditId, reportId);

    yield put(AuditReportActions.getReportHTMLSuccess({ reportHTML }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.getReportHTMLFailure({ error }));
  }
}

// Download PDF
function* downloadPDF(action) {
  const { auditId = "", reportId = "", data = {} } = action.payload;

  try {
    const PDFData = yield AuditReportService.downloadPDF(auditId, reportId, data);

    yield put(AuditReportActions.downloadPDFSuccess({ reportId, PDFData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.downloadPDFFailure({ error, reportId }));
  }
}

// Update Report Status
function* updateReportStatus(action) {
  const { auditId = "", reportId = "", status = false } = action.payload;

  try {
    yield AuditReportService.updateReportStatus(auditId, reportId, status);

    yield put(AuditReportActions.updateReportStatusSuccess({ reportId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.updateReportStatusFailure({ error, reportId }));
  }
}

// Get Report Data
function* getReportData(action) {
  const { auditId = "", reportType = "" } = action.payload;

  try {
    const reportData = yield AuditReportService.getReportData(auditId, reportType);

    yield put(AuditReportActions.getDataSuccess({ reportData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditReportActions.getDataFailure({ error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(AuditReportActions.getReportList.type, getReportList),
    takeLatest(AuditReportActions.createReport.type, createReport),
    takeLatest(AuditReportActions.updateReport.type, updateReport),
    takeLatest(AuditReportActions.deleteReport.type, deleteReport),
    takeLatest(AuditReportActions.getReportHTML.type, getReportHTML),
    takeLatest(AuditReportActions.downloadPDF.type, downloadPDF),
    takeLatest(AuditReportActions.updateReportStatus.type, updateReportStatus),
    takeLatest(AuditReportActions.getReportData.type, getReportData),
  ]);
}
