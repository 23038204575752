// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { AuditInstrumentUrls } from "../../constants/serviceUrls";
import { ContentType } from "../../constants/httpConstants";
import { DefaultPagination } from "../../constants/generalConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Audit Instrument Data List
async function getInstrumentDataList(auditId, componentId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    audit: auditId,
    component: componentId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(AuditInstrumentUrls.getInstrumentDataList, params);
}

// Get Audit Instrument Data Info
async function getInstrumentDataInfo(instrumentDataId) {
  const url = URLs.format(AuditInstrumentUrls.getInstrumentDataInfo, { instrumentDataId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// select Audit Instrument Data
async function selectInstrumentData(instrumentDataId, sheetNo, lineNo) {
  const params = {
    sheetNo,
    lineNo,
  };

  const url = URLs.format(AuditInstrumentUrls.selectInstrumentData, { instrumentDataId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, params);
}

// Upload Audit Instrument Data
async function uploadInstrumentDataFile(formData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(AuditInstrumentUrls.uploadInstrumentDataFile, {}, formData, ContentType.APP_FORM_URL_ENCODED);
}

const AuditInstrumentService = {
  getInstrumentDataList,
  getInstrumentDataInfo,
  selectInstrumentData,
  uploadInstrumentDataFile,
};

export default AuditInstrumentService;
