import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { OrganizationActions } from "../../../../store/slices/organization/organizationSlice";

// Constants
import PageURLs from "../../../../constants/pageUrls";
import { ICON_SIZE, OrgTypes } from "../../../../constants/generalConstants";

// Utils
import URLs from "../../../../utils/urlUtils";

// Components
import TablePagination from "../../../../components/table/TablePagination";
import ToggleButton from "../../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";
import Icon from "../../../../components/icon/Icon";

// Helper
import OrganizationListTableHelper from "../../../../helpers/organization-list/organizationListTableHelper";

// Page Components
function OrganizationStatus({ organization }) {
  const dispatch = useDispatch();
  const { id: orgId, orgType, isActive } = organization;

  const loading = useSelector((state) => state.organization.updateOrganizationStatusLoadingMap[orgId]) || false;
  const organizationListPagination = useSelector((state) => state.organization.organizationListPagination);
  const updateOrganizationStatusSuccess =
    useSelector((state) => state.organization.updateOrganizationStatusSuccessMap[orgId]) || false;

  const { pageSize, pageNo } = organizationListPagination;

  const status = !isActive;

  function updateOrganizationStatus() {
    dispatch(OrganizationActions.updateOrganizationStatus({ orgId, orgType, status, pageNumber: pageNo, pageSize }));
  }

  useEffect(() => {
    if (updateOrganizationStatusSuccess) {
      toast.success("Organization Status Updated Successfully");
    }
  }, [updateOrganizationStatusSuccess]);

  return <ToggleButton isActive={isActive} loading={loading} onClick={updateOrganizationStatus} />;
}

function TableActions({ organization }) {
  // Navigate
  const navigate = useNavigate();
  const { id: orgId, orgType = "", isActive } = organization;
  const { nameLowercase = "" } = OrgTypes[orgType];
  const iconClassName = isActive ? "cursor-pointer" : "cursor-disabled";

  function navigateToViewUserPage() {
    const url = URLs.format(PageURLs.OrganizationViewPageUrl, { orgType: nameLowercase, orgId });
    navigate(url);
  }

  function navigateToUserListPage() {
    const url = URLs.format(PageURLs.UserListPageUrl, { orgId });
    navigate(url);
  }

  function navigateToPlantsListPage() {
    const url = URLs.format(PageURLs.PlantListPageUrl, { orgId });
    navigate(url);
  }

  return (
    <div className="d-flex gap-4">
      <Icon
        iconName="eye"
        title="View"
        size={ICON_SIZE.small}
        className={iconClassName}
        onClick={navigateToViewUserPage}
      />

      <Icon
        iconName="users"
        title="View users"
        size={ICON_SIZE.small}
        className={iconClassName}
        onClick={navigateToUserListPage}
      />

      {orgType === "INDUSTRY" && (
        <Icon
          iconName="factory"
          title="View Plants"
          size={ICON_SIZE.small}
          className={iconClassName}
          onClick={navigateToPlantsListPage}
        />
      )}
    </div>
  );
}

/**
 * Organization List Table
 */
export default function OrganizationListTable({
  organizationList = [],
  organizationListLoading = false,
  organizationListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize, pageNo } = organizationListPagination;

  const websiteColumn = {
    title: "Website",
    key: "website",
    render: (record) => {
      const { website } = record;
      return (
        <a href={website} target="_blank" className="text-decoration-none" rel="noreferrer">
          {website}
        </a>
      );
    },
  };

  const statusColumn = {
    title: "Status",
    key: "isActive",
    align: "center",
    render: (record) => {
      return <OrganizationStatus organization={record} pageSize={pageSize} pageNumber={pageNo} />;
    },
  };

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (record) => {
      return <TableActions organization={record} />;
    },
  };

  const columns = [
    ...OrganizationListTableHelper.getHeaders(pageNo, pageSize),
    websiteColumn,
    statusColumn,
    actionColumn,
  ];

  return (
    <>
      {organizationListLoading && <SkeletonTable columnCount={7} rowCount={10} />}

      {!organizationListLoading && (
        <>
          <DataTable rows={organizationList} columns={columns} applyTableBorder={true} />
          <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />{" "}
        </>
      )}
    </>
  );
}
