import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Utils
import { getOrgType } from "../../utils/authUtils";

// Components
import { Button } from "../../components/button/Button";
import Input from "../../components/input/Input";
import ErrorMessage from "../../components/error-message/ErrorMessage";
import CheckBox from "../../components/checkbox/CheckBox";

// Page Constants
const NAME_INPUT_ERROR = "Please enter name !!!";
const DESCRIPTION_INPUT_ERROR = "Please enter description !!!";

// Page Components
function SelectRolePrivilegesCheckBox({ roleInformation, setRoleInformation, privilegeStatusList, privileges }) {
  // Function
  function handleChange({ target }) {
    const { id, checked } = target;

    const { privilegeStatusList } = roleInformation;

    // Cloning privilege status list array
    const clonedPrivilegeStatusList = [...privilegeStatusList];

    // Is checkbox selected or not
    const isChecked = checked;

    // Finding the index position of the changed privilege
    const privilegeStatusIndex = clonedPrivilegeStatusList.findIndex(
      (eachPrivilege) => eachPrivilege.displayText === id
    );

    // Updating the object by spreading the old value and changing only the ischecked value
    clonedPrivilegeStatusList[privilegeStatusIndex] = { ...clonedPrivilegeStatusList[privilegeStatusIndex], isChecked };

    // Updating the state
    setRoleInformation((prev) => ({ ...prev, privilegeStatusList: clonedPrivilegeStatusList }));
  }

  return (
    <div className="my-2">
      {privileges.map((eachPrivilege, index) => {
        const { privilege, displayText } = eachPrivilege;

        const { isChecked = false } = privilegeStatusList.find((value) => value.privilege === privilege) || {};

        return (
          // CheckBox
          <CheckBox
            key={index}
            containerClassName="form-check-inline"
            id={displayText}
            label={displayText}
            onChange={handleChange}
            checked={isChecked}
          />
        );
      })}
    </div>
  );
}

function SelectRolePrivilegesInput({
  roleInformation,
  privilegesList,
  setRoleInformation,
  privilegeStatusList,
  error,
}) {
  return (
    <>
      <h4>Privileges</h4>

      {/* Error Message */}
      <ErrorMessage error={error?.privileges} />

      {privilegesList.map((eachManagement, index) => {
        const { title = "", privileges = [] } = eachManagement;
        return (
          <div key={index}>
            <label>{title}</label>

            {/* SelectRolePrivilegesCheckBox */}
            <SelectRolePrivilegesCheckBox
              privileges={privileges}
              roleInformation={roleInformation}
              setRoleInformation={setRoleInformation}
              privilegeStatusList={privilegeStatusList}
            />
          </div>
        );
      })}
    </>
  );
}

function RoleEditForm({ roleInformation, privilegesList, setRoleInformation, error }) {
  const { name = "", description = "", privilegeStatusList = [] } = roleInformation;

  // Handle Change Function
  function handleChange(e, inputName) {
    const { target } = e;

    // Updating the state
    setRoleInformation((prev) => ({ ...prev, [inputName]: target.value }));
  }

  return (
    <>
      <div className="d-flex mb-4">
        <div className="me-4">
          <label>Name</label>

          {/* Input */}
          <Input
            className="input-search"
            placeholder="Enter Name..."
            value={name}
            onChange={(e) => handleChange(e, "name")}
            error={error?.name}
          />
        </div>

        <div>
          <label>Description</label>

          {/* Input */}
          <Input
            className="input-search"
            placeholder="Enter Description..."
            value={description}
            onChange={(e) => handleChange(e, "description")}
            error={error?.description}
          />
        </div>
      </div>

      {/* Select Role Privileges Input */}
      <SelectRolePrivilegesInput
        privilegesList={privilegesList}
        privilegeStatusList={privilegeStatusList}
        roleInformation={roleInformation}
        setRoleInformation={setRoleInformation}
        error={error}
      />
    </>
  );
}

/**
 * Role Form
 */
export default function RoleForm({ data = {}, roleId = "", loading = false, dispatchAction, buttonName = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // State
  const [roleInformation, setRoleInformation] = useState({});
  const [error, setError] = useState({});

  // Getting Organization Type
  const orgType = getOrgType();

  // Privileges Selector State
  const privileges = useSelector((state) => state.role.privilegesList);

  const privilegesList = privileges[orgType] || [];

  // use Effect
  useEffect(() => {
    setRoleInformation(constructRoleInformationArray({ data, privilegesList }));
  }, []);

  // Construct Role Information Array
  function constructRoleInformationArray({ data, privilegesList }) {
    const { name = "", description = "", privileges = [] } = data;

    // Constructing privilege status list array with ischecked boolean value
    const privilegeStatusList = privilegesList.reduce((acc, eachObj) => {
      const { privileges: privilegesData } = eachObj;

      const privilegeArray = privilegesData.reduce((acc, eachPrivilege) => {
        const { privilege, displayText } = eachPrivilege;

        // Checks whether that checkbox is already selected or not
        const isChecked = privileges.includes(privilege);

        return [...acc, { displayText, privilege, isChecked }];
      }, []);

      return [...acc, ...privilegeArray];
    }, []);

    return { name, description, privilegeStatusList };
  }

  // Checks whether atleast one privilege is selected
  function checkPrivilegeSelected(privilegeStatusList) {
    const isPrivilegeSelected = privilegeStatusList.some((privilege) => privilege.isChecked === true);

    return isPrivilegeSelected;
  }

  // Construct selected privilege string
  function constructPrivilegesString(privilegeStatusList) {
    // Constructing string of selected privileges
    const privilegesStr = privilegeStatusList?.reduce((acc, eachObj, index) => {
      const { privilege, isChecked } = eachObj;

      if (isChecked) {
        // For first privilege comma is not required
        if (index === 0) {
          return `${privilege}`;
        }
        return `${acc}, ${privilege}`;
      }

      return acc;
    }, "");

    return privilegesStr;
  }

  // Handle Form Submit
  function handleSubmit() {
    const { name, description, privilegeStatusList } = roleInformation;

    // If both the input fields are empty
    if (!name && !description) {
      return setError({ name: NAME_INPUT_ERROR, description: DESCRIPTION_INPUT_ERROR });
    }

    setError({});

    // If name input field is empty
    if (!name) {
      return setError({ name: NAME_INPUT_ERROR });
    }

    setError({});

    // If description input field is empty
    if (!description) {
      return setError({ description: DESCRIPTION_INPUT_ERROR });
    }

    setError({});

    // If no privilege is selected
    if (!checkPrivilegeSelected(privilegeStatusList)) {
      return setError({ privileges: "Please select atleast one privilege" });
    }

    // If all the fields are filled, error is removed
    setError({});

    const roleData = {
      orgTypeStr: orgType,
      name,
      description,
      privilegesCSV: constructPrivilegesString(privilegeStatusList),
    };

    // Dispatch
    dispatch(dispatchAction({ roleId, roleData, navigate }));
  }

  return (
    <>
      {/* Role Edit Form */}
      <RoleEditForm
        data={data}
        privilegesList={privilegesList}
        roleInformation={roleInformation}
        setRoleInformation={setRoleInformation}
        error={error}
      />

      {/* Button */}
      <Button
        label={buttonName}
        className="mt-3"
        color="primary"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading}
      />
    </>
  );
}
