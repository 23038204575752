import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { TemplateActions } from "../../slices/template/templateSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import TemplateService from "../../../services/template/templateService";

// Get Template List
function* getTemplateList(action) {
  try {
    const { pageNumber = "", pageSize = "", filters = {} } = action.payload;

    const { items = [], pagination = {} } = yield TemplateService.getTemplateList(pageNumber, pageSize, filters);

    yield put(
      TemplateActions.getTemplateListSuccess({
        templateList: items,
        templateListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(TemplateActions.getTemplateListFailure({ error }));
  }
}

// Get Template Parameter Values
function* getTemplateParamValues(action) {
  try {
    const { templateId = "" } = action.payload;

    const templateParamValues = yield TemplateService.getTemplateParamValues(templateId);

    yield put(TemplateActions.getTemplateParamValuesSuccess({ templateParamValues }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(TemplateActions.getTemplateParamValuesFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(TemplateActions.getTemplateList.type, getTemplateList),
    takeLatest(TemplateActions.getTemplateParamValues.type, getTemplateParamValues),
  ]);
}
