import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { ComponentTypeActions } from "../../../../store/slices/component-type/componentTypeSlice";

// Constants
import PageURLs from "../../../../constants/pageUrls";

// Utils
import { saveFile } from "../../../../utils/fileUtils";
import URLs from "../../../../utils/urlUtils";

// Helper
import ComponentTypeListTableHelper from "../../../../helpers/component-type/componentTypeListTableHelper";

// Components
import { OutlineButton } from "../../../../components/button/Button";
import TablePagination from "../../../../components/table/TablePagination";
import ToggleButton from "../../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";

// Css
import "./ComponentTypeListTable.scss";

// Page Components
function ActionCell({ componentType = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  const { id: componentTypeId = "", name = "", isActive = "", code = "" } = componentType;

  // Selector State
  const downloadTemplateData = useSelector((state) => state.componentType.downloadComponentTypeTemplateData[code]);
  const downloadTemplateLoading = useSelector(
    (state) => state.componentType.downloadComponentTypeTemplateLoadingMap[code]
  );
  const downloadTemplateSuccess = useSelector(
    (state) => state.componentType.downloadComponentTypeTemplateSuccessMap[code]
  );

  // Action Butttons
  const actionButtons = [
    {
      label: "Parameters",
      redirectUrl: URLs.format(PageURLs.ParameterListPageUrl, { componentTypeId }),
    },
    {
      label: "Observation Codes",
      redirectUrl: URLs.format(PageURLs.ObservationCodeListPageurl, { componentTypeId }),
    },
    {
      label: "Recommendation Codes",
      redirectUrl: URLs.format(PageURLs.RecommendationCodeListPageUrl, { componentTypeId }),
    },
  ];

  // Functions
  function downloadComponentTypeTemplate() {
    dispatch(ComponentTypeActions.downloadComponentTypeTemplate({ componentTypeCode: code }));
  }

  // use Effect
  useEffect(() => {
    if (downloadTemplateSuccess) {
      saveFile(`${name}_Template.xlsx`, downloadTemplateData);
    }
  }, [downloadTemplateSuccess, downloadTemplateData, name]);

  return (
    <div className="btn-cont">
      {actionButtons.map((btn, idx) => {
        const { label = "", redirectUrl = "" } = btn || {};

        return (
          <OutlineButton key={idx} size="sm" label={label} onClick={() => navigate(redirectUrl)} disabled={!isActive}>
            <i className="fas fa-eye"></i>
          </OutlineButton>
        );
      })}

      <OutlineButton
        size="sm"
        label={"Download Template"}
        onClick={downloadComponentTypeTemplate}
        loading={downloadTemplateLoading}
        disabled={!isActive || downloadTemplateLoading}
      >
        <i className="fas fa-download"></i>
      </OutlineButton>
    </div>
  );
}

function ComponentTypeStatus({ componentType, pageSize, pageNumber }) {
  // Dispatch
  const dispatch = useDispatch();

  const { id: componentTypeId, isActive } = componentType;

  // Selector State
  const loading = useSelector((state) => state.componentType.updateComponentTypeStatusLoadingMap[componentTypeId]);
  const updateComponentTypeStatusSuccess = useSelector(
    (state) => state.componentType.updateComponentTypeStatusSuccessMap[componentTypeId]
  );

  // Changing Status
  const status = !isActive;

  function updateComponentTypeStatus() {
    dispatch(ComponentTypeActions.updateComponentTypeStatus({ componentTypeId, status, pageNumber, pageSize }));
  }

  useEffect(() => {
    if (updateComponentTypeStatusSuccess) {
      toast.success("Status Changed Successfully");
    }
  }, [updateComponentTypeStatusSuccess]);

  return <ToggleButton isActive={isActive} loading={loading} onClick={updateComponentTypeStatus} />;
}

/**
 * Component Type List Table
 */
export default function ComponentTypeListTable({
  componentTypeList = [],
  componentTypeListLoading = false,
  componentTypeListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageNo, pageSize } = componentTypeListPagination;

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (record) => {
      return <ActionCell componentType={record} />;
    },
  };

  const statusColumn = {
    title: "Status",
    key: "isActive",
    align: "center",
    render: (record) => {
      return <ComponentTypeStatus componentType={record} pageSize={pageSize} pageNumber={pageNo} />;
    },
  };

  const columns = [...ComponentTypeListTableHelper.getHeaders(), actionColumn, statusColumn];

  return (
    <>
      {componentTypeListLoading && <SkeletonTable columnCount={7} rowCount={10} />}
      {!componentTypeListLoading && (
        <DataTable className="component-type-list-table" rows={componentTypeList} columns={columns} />
      )}

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
