// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { MaterialUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Material List
async function getMaterialList(pageNumber, pageSize, searchText, type) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  if (type) {
    params.type = type;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(MaterialUrls.getMaterialList, params);
}

async function updateMaterialStatus(materialId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(MaterialUrls.updateMaterialStatus, { materialId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const MaterialService = {
  getMaterialList,
  updateMaterialStatus,
};

export default MaterialService;
