import { createSlice } from "@reduxjs/toolkit";

// Transformer
import TemplateTransformer from "./templateTransformer";

// Initial State
const initialState = {
  // Get Template List
  templateListMap: [],
  templateListPagination: {},
  templateListLoading: false,
  templateListSuccess: false,
  templateListError: {},

  // Get Template Parameter Values
  templateParamValuesMap: {},
  templateParamValuesLoading: false,
  templateParamValuesSuccess: false,
  templateParamValuesError: {},
};

// Template List
const TemplateSlice = createSlice({
  name: "Template",
  initialState,

  reducers: {
    // Get Template List
    getTemplateList: (state) => {
      state.templateListSuccess = false;
      state.templateListLoading = true;
      state.templateListError = {};
    },

    getTemplateListSuccess: (state, action) => {
      const { templateList = [], templateListPagination = {} } = action.payload;
      state.templateListMap = TemplateTransformer.transformList(templateList);

      state.templateListPagination = templateListPagination;
      state.templateListSuccess = true;
      state.templateListLoading = false;
    },

    getTemplateListFailure: (state, action) => {
      state.templateListLoading = false;
      state.templateListError = action.payload.error;
    },

    // Get Template Parameter Values
    getTemplateParamValues: (state) => {
      state.templateParamValuesLoading = true;
      state.templateParamValuesSuccess = false;
      state.templateParamValuesError = {};
    },

    getTemplateParamValuesSuccess: (state, action) => {
      const { templateParamValues = [] } = action.payload;
      state.templateParamValuesMap = TemplateTransformer.transformParamValues(templateParamValues);

      state.templateParamValuesLoading = false;
      state.templateParamValuesSuccess = true;
    },

    getTemplateParamValuesFailure: (state, action) => {
      state.templateParamValuesLoading = false;
      state.templateParamValuesError = action.payload.error;
    },

    // Reset Template Param Values
    resetParamValues: (state) => {
      state.templateParamValuesMap = {};
    },
  },
});

// Reducer
export const TemplateReducer = TemplateSlice.reducer;

// Actions
export const TemplateActions = TemplateSlice.actions;
