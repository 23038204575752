import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { OrganizationActions } from "../../../store/slices/organization/organizationSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";
import { OrgTypes } from "../../../constants/generalConstants";
import { OrganizationFormSchema, OrganizationFormValidationSchema } from "../../../constants/organizationConstants";

// Utils
import { bcForOrganizationCreatePage } from "../../../utils/breadcrumbUtils";
import URLs from "../../../utils/urlUtils";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Form, { FormTypes } from "../../../components/form/Form";

// Page Component
function PageHeaderSection({ orgType = "" }) {
  // OrgType Info
  const { name: orgTypeName } = OrgTypes[orgType] || [];

  return (
    <PageHeader breadcrumbList={bcForOrganizationCreatePage(orgTypeName, orgType)} className="mt-2" actions={<></>} />
  );
}

/**
 * Add Organization Page
 */
export default function AddOrganizationPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Params
  const params = useParams();
  const { orgType: orgName = "" } = params;
  const orgType = orgName.toUpperCase();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector State
  const addOrganizationLoading = useSelector((state) => state.organization.addOrganizationLoading);
  const upsertOrganizationSuccesss = useSelector((state) => state.organization.upsertOrganizationSuccesss);

  // Updating default input value of the first input item according to organization type from search params
  const clonedOrganizationInputItems = [...OrganizationFormSchema];
  clonedOrganizationInputItems[0].inputItems[0] =
    {
      ...(clonedOrganizationInputItems[0].inputItems[0] || {}),
      defaultInputValue: orgType || "",
    } || {};

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeaderSection orgType={orgType} />);
  }, [orgType]);

  useEffect(() => {
    if (upsertOrganizationSuccesss) {
      toast.success("Organization Added Successfully");

      const redirectURL = URLs.format(PageURLs.OrganizationListPageUrl, { orgType });
      navigate(redirectURL);
    }
  }, [dispatch, upsertOrganizationSuccesss]);

  // Formik onSubmit function
  function formSubmit(organizationData) {
    dispatch(OrganizationActions.addOrganization({ organizationData }));
  }

  return (
    <div className="page-content">
      {/* Form */}
      <Form
        type={FormTypes.CREATE}
        showCancelButton={false}
        loading={addOrganizationLoading}
        formInputItems={clonedOrganizationInputItems}
        formSubmit={formSubmit}
        formValidationSchema={OrganizationFormValidationSchema}
      />
    </div>
  );
}
