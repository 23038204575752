// App Components
import ErrorHandler from "../components/error/ErrorHandler";
import ProtectedRoute from "../hoc/ProtectedRoute";

// Constants
import PageURLs from "../constants/pageUrls";
import { DataPreLoadObj } from "../constants/dataPreLoadConstants";

// Pages :: Definitions
import MeasurementTypeListPage from "../pages/measurement-type/measurement-type-list/MeasurementTypeListPage";
import MeasurementTypeViewPage from "../pages/measurement-type/measurement-type-view/MeasurementTypeViewPage";

import ComponentTypeListPage from "../pages/component-type/component-type-list/ComponentTypeListPage";
import ParameterListPage from "../pages/component-type/parameter-list/ParameterListPage";
import ObservationCodeListPage from "../pages/component-type/observation-code-list/ObservationCodeListPage";
import RecommendationCodeListPage from "../pages/component-type/recommendation-code-list/RecommendationCodeListPage";

import InstrumentListPage from "../pages/instrument/instrument-list/InstrumentListPage";
import InstrumentViewPage from "../pages/instrument/instrument-view/InstrumentViewPage";

import MaterialListPage from "../pages/material/material-list/MaterialListPage";
import ProductListPage from "../pages/product/product-list/ProductListPage";

import ConstantListPage from "../pages/constant/ConstantListPage";

import HSNCodeListPage from "../pages/hsn-code/hsn-code-list/HSNCodeListPage";
import NICCodeListPage from "../pages/nic-code/nic-code-list/NICCodeListPage";

// Layout
import MainPageLayout from "../components/layout/MainPageLayout";

// Main Pages and Layouts
const DefPageRoutes = {
  path: PageURLs.DashboardPageUrl,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute dataPreLoadObj={DataPreLoadObj}>
        {/* Layout */}
        <MainPageLayout topbar={true} sidebar={true} sidebarCollapsible={true} />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      path: PageURLs.MeasurementTypeListPageUrl,
      element: <MeasurementTypeListPage />,
    },
    {
      path: PageURLs.MeasurementTypeViewPageUrl,
      element: <MeasurementTypeViewPage />,
    },

    {
      path: PageURLs.ComponentTypeListPageUrl,
      element: <ComponentTypeListPage />,
    },

    {
      path: PageURLs.ParameterListPageUrl,
      element: <ParameterListPage />,
    },
    {
      path: PageURLs.ObservationCodeListPageurl,
      element: <ObservationCodeListPage />,
    },
    {
      path: PageURLs.RecommendationCodeListPageUrl,
      element: <RecommendationCodeListPage />,
    },

    {
      path: PageURLs.InstrumentListPageUrl,
      element: <InstrumentListPage />,
    },
    {
      path: PageURLs.InstrumentViewPageUrl,
      element: <InstrumentViewPage />,
    },

    {
      path: PageURLs.MaterialListPageUrl,
      element: <MaterialListPage />,
    },

    {
      path: PageURLs.ProductListPageUrl,
      element: <ProductListPage />,
    },
    {
      path: PageURLs.ConstantsListPageUrl,
      element: <ConstantListPage />,
    },
    {
      path: PageURLs.HSNCodeListPageUrl,
      element: <HSNCodeListPage />,
    },

    {
      path: PageURLs.NICCodeListPageUrl,
      element: <NICCodeListPage />,
    },
  ],
};

export default DefPageRoutes;
