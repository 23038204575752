import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { ComponentTypeActions } from "../../../store/slices/component-type/componentTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/generalConstants";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import ComponentTypeListTable from "./components/ComponentTypeListTable";

// Page Components
function ComponentTypeListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search shadow-none" placeholder={`Search Components...`} isSearchInput={true} />
    </div>
  );
}

/**
 * Component Type List page
 */
export default function ComponentTypeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector State
  const componentTypeMap = useSelector((state) => state.componentType.componentTypeMap);
  const componentTypeList = Object.values(componentTypeMap);
  const componentTypeListLoading = useSelector((state) => state.componentType.componentTypeListLoading);
  const componentTypeListPagination = useSelector((state) => state.componentType.componentTypeListPagination);

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // use Effect
  useEffect(() => {
    dispatch(ComponentTypeActions.getComponentTypeList({ pageNumber, pageSize, searchText }));
  }, [dispatch, pageNumber, pageSize, searchText]);

  useEffect(() => {
    return () => dispatch(ComponentTypeActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={"Component Types"} />);
  }, []);

  return (
    <div className="page-content">
      {/* Component Type List Table Actions Section */}
      <ComponentTypeListTableActionsSection />

      {/* Component Type List Table */}
      <ComponentTypeListTable
        pageNumber={pageNumber}
        componentTypeList={componentTypeList}
        componentTypeListLoading={componentTypeListLoading}
        componentTypeListPagination={componentTypeListPagination}
      />
    </div>
  );
}
