// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { ProductUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Product List
async function getProductList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(ProductUrls.getProductList, params);
}

// Update Status
async function updateProductStatus(productId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(ProductUrls.updateProductStatus, { productId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const ProductService = {
  getProductList,
  updateProductStatus,
};

export default ProductService;
