export const NIC_CODE_TABLE_HEADER = [
  {
    title: "Name",
    dataIndex: "name",
    width: 300,
  },
  {
    title: "Description",
    dataIndex: "description",
    ellipsis: true,
    width: 300,
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Section Code",
    dataIndex: "sectionCode",
  },
  {
    title: "Division Code",
    dataIndex: "divisionCode",
  },
  {
    title: "Group Code",
    dataIndex: "groupCode",
  },
  {
    title: "Class Code",
    dataIndex: "classCode",
  },
  {
    title: "SubClass Code",
    dataIndex: "subClassCode",
  },
  {
    title: "Updated On",
    dataIndex: "updatedOnDate",
  },
];
