import { Link } from "react-router-dom";

/**
 * Logo Component
 * @param {*} redirect : Boolean value
 * @param {*} pageUrl : Redirect Page Url
 * @param {*} containerClassName
 * @param {*} imageHeight : Image height
 * @param {*} imageUrl : Image url
 */
export default function Logo({ redirect = false, pageUrl, containerClassName, imageHeight = 40, imageUrl = "" }) {
  return (
    <div className={containerClassName}>
      <Link to={redirect ? pageUrl : ""}>
        <img src={imageUrl} alt="Logo" height={imageHeight} />
      </Link>
    </div>
  );
}
