// Constant Transformers
function transformObj(constantObj = {}) {
  const { code = "", name = "", description = "", symbol = "", value = "", unit = "" } = constantObj;

  const constant = {
    code: code,
    name: name,
    description: description,
    symbol: symbol,
    value: value,
    unit: unit,
  };

  return constant;
}

function transformList(constantMap = {}) {
  let constantListMap = {};

  for (const key in constantMap) {
    constantListMap[key] = { key, ...transformObj(constantMap[key]) };
  }

  return constantListMap;
}

// Exports
const ConstantTransformer = {
  transformObj,
  transformList,
};

export default ConstantTransformer;
