// Constants
import { OrgTypes } from "../constants/generalConstants";
import { LocalStorageKeys } from "../constants/localStorageConstants";

// Utils
import LocalStorage from "./localStorageUtils";

export const getOrgInfo = () => {
  const authOrgStr = LocalStorage.get(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  return authOrg;
};

export const getUserInfo = () => {
  const authUserStr = LocalStorage.get(LocalStorageKeys.AUTH_USER) || "{}";
  const authUser = JSON.parse(authUserStr);
  return authUser;
};

export const getOrgId = () => {
  const authOrgStr = LocalStorage.get(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  return authOrg.id;
};

export const getOrgType = () => {
  const authOrgStr = LocalStorage.get(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  const authOrgType = authOrg?.type;
  return authOrgType;
};

export const getIndustryOrAuditOrgId = () => {
  const authOrgStr = LocalStorage.get(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  const authOrgType = authOrg?.type;
  const orgId = authOrgType === OrgTypes.PLATFORM.id ? null : authOrg.id;
  return orgId;
};
