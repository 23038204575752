import { useNavigate } from "react-router-dom";

// Constants
import PageURLs from "../../constants/pageUrls";

// Components
import { Button } from "../button/Button";

/**
 * Audit Initiate Button
 */
export default function AuditInitiateButton() {
  // Navigate
  const navigate = useNavigate();

  // Navigate to audit create page
  function navigateToAuditCreatePage() {
    navigate(PageURLs.AuditCreatePageUrl);
  }

  return (
    <div className="btn-cont">
      <Button className="m-0" label="Initiate New Audit" onClick={navigateToAuditCreatePage}>
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}
