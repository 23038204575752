import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nodesMap: {},
};

const nodeSlice = createSlice({
  name: "Node",
  initialState,
  reducers: {
    //Set Nodes Map
    setNodes: (state, action) => {
      const { nodes } = action.payload;
      state.nodesMap = nodes.reduce((acc, node) => {
        const { id } = node;
        acc[id] = node;
        return acc;
      }, {});
    },

    setNodeHandles: (state, action) => {
      const { handles, nodeId } = action.payload;
      const node = state.nodesMap[nodeId] || {};

      node.data.handles = handles;
      state.nodesMap[nodeId] = node;
    },

    createNode: (state, action) => {
      const { node } = action.payload;
      state.nodesMap[node.id] = node;
    },

    deleteNode: (state, action) => {
      const { nodeUid = "" } = action.payload;
      delete state.nodesMap[nodeUid];
    },
  },
});

export const NodeReducer = nodeSlice.reducer;

export const NodeActions = nodeSlice.actions;
