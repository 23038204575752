/**
 * Main Page
 * @param {*} auditFirmName : Audit Firm Name
 */
export default function MainPage({ auditFirmName = "" }) {
  return (
    <section className="text-center">
      <div className="mt-5">
        <h1 className="fs-4 fw-bold">ENERGY ASSESSMENT & SUSTAINABILITY STUDY</h1>
        <p className="fst-italic fw-semibold text-center py-5">Conducted by</p>
        <h1 className="fs-4 fw-bold">Kotak - IIT Madras Save Energy Mission</h1>

        {/* Audit Firm Name */}
        <h1 className="fs-4 fw-bold py-5">{auditFirmName}</h1>

        {/* Logo */}
        <img src="https://ieac-assets.s3.ap-south-1.amazonaws.com/branding/KISEM_LOGO.png" alt="KISEM" height={130} />
      </div>
    </section>
  );
}
