import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { PlantComponentPartsActions } from "../../../../../store/slices/plant/partsSlice";

// Components
import { Button } from "../../../../../components/button/Button";

// Page Constants
function PartActionButtons({
  partId = "",
  parentId = "",
  componentTypeId = "",
  isFrozenOrDecommissioned = false,
  openViewPlantPartModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Component Part Selector State
  const deletePlantComponentPartLoading = useSelector((state) => state.plantComponentParts.deletePartLoading[partId]);
  const deletePlantComponentPartSuccess = useSelector((state) => state.plantComponentParts.deletePartSuccess[partId]);

  useEffect(() => {
    if (deletePlantComponentPartSuccess) {
      toast.success("Deleted Plant Component Part Successfully");
    }
  }, [deletePlantComponentPartSuccess]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Button
        label="View"
        size="sm"
        className="ms-2"
        color="secondary"
        onClick={() => {
          openViewPlantPartModal(partId);
        }}
      >
        <i className="fas fa-eye"></i>
      </Button>

      {!isFrozenOrDecommissioned && (
        <Button
          label="Delete"
          size="sm"
          className="ms-2"
          color="secondary"
          onClick={() => {
            dispatch(
              PlantComponentPartsActions.deletePart({
                plantId,
                partId,
                componentId: parentId,
                componentTypeId,
              })
            );
          }}
          loading={deletePlantComponentPartLoading}
          disabled={deletePlantComponentPartLoading}
        >
          <i className="fas fa-trash"></i>
        </Button>
      )}
    </div>
  );
}

function ComponentTypeListAddedParts({
  componentIndex = 0,
  id = "",
  setSelectedPartId,
  setSelectedComponentIndex,
  isFrozenOrDecommissioned = false,
  setShowViewPartModal = () => {},
}) {
  // Plant Component List Selector State
  const plantComponentListMap = useSelector((state) => state.plantComponent.componentListMap);
  const plantComponentList = Object.values(plantComponentListMap);

  // Getting component info
  const { component = {}, parts: allAddedParts = [] } = plantComponentList[componentIndex] || {};
  const { componentTypeId = "" } = component;

  // Get added parts of the current Part Type
  const addedParts = allAddedParts?.filter(({ component = {} }) => id === component?.componentTypeId);

  // Open View Plant Component Part Modal Function
  function openViewPlantPartModal(partId) {
    setSelectedComponentIndex(componentIndex);
    setSelectedPartId(partId);
    setShowViewPartModal(true);
  }

  return (
    <div className="ps-3 mb-3">
      {addedParts.map(({ component = {} }) => {
        const { name = "", internalId = "", id: partId = "", parentId = "" } = component;

        return (
          <div className="d-flex align-items-center my-2" key={partId}>
            <p className="mb-1">
              - {name} ({internalId})
            </p>

            <PartActionButtons
              partId={partId}
              parentId={parentId}
              componentTypeId={componentTypeId}
              openViewPlantPartModal={openViewPlantPartModal}
              isFrozenOrDecommissioned={isFrozenOrDecommissioned}
            />
          </div>
        );
      })}
    </div>
  );
}

/**
 * Parts List
 * @param {*} parts : Array
 * @param {*} componentIndex
 * @param {*} setSelectedPartId
 * @param {*} setSelectedComponentIndex
 */
export default function PartsList({
  parts = [],
  isFrozenOrDecommissioned = false,
  componentIndex,
  setSelectedPartId,
  setSelectedComponentIndex,
  setShowAddPartModal = () => {},
  setShowViewPartModal = () => {},
}) {
  // Getting component info

  // Checking whether parts is there or not
  const isPartsAvailable = parts.length === 0;

  // Open add part modal function
  function openAddPartModal(id) {
    setSelectedPartId(id);
    setShowAddPartModal(true);

    setSelectedComponentIndex(componentIndex);
  }

  // If there are no parts
  if (isPartsAvailable) {
    return <div className="text-center">No parts available</div>;
  }

  return parts.map((eachPart, index) => {
    const { id = "", name = "", code = "" } = eachPart || {};

    return (
      <div key={index}>
        <div className="mb-1 d-flex justify-content-between">
          <span>
            {name} ( {code} )
          </span>

          {/* Add Parts Button */}
          {!isFrozenOrDecommissioned && (
            <Button
              label={`Add`}
              size="sm"
              onClick={() => {
                openAddPartModal(id);
              }}
            >
              <i className="fa-solid fa-plus me-2"></i>
            </Button>
          )}
        </div>

        {/* Component Type List Added Parts */}
        <ComponentTypeListAddedParts
          componentIndex={componentIndex}
          id={id}
          setSelectedPartId={setSelectedPartId}
          setSelectedComponentIndex={setSelectedComponentIndex}
          setShowViewPartModal={setShowViewPartModal}
          isFrozenOrDecommissioned={isFrozenOrDecommissioned}
        />
      </div>
    );
  });
}
