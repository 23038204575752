import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ProductTransformer from "./productTransformer";

function resetApiSuccessState(state) {
  state.updateProductStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get product list
  productListMap: [],
  productListPagination: {},
  productListLoading: false,
  productListError: "",

  // Update Product Status (Enable/Disable)
  updateProductStatusLoadingMap: {},
  updateProductStatusSuccessMap: {},
  updateProductStatusError: "",
};

// Product Slice
const productSlice = createSlice({
  name: "Product",

  initialState,

  reducers: {
    // Get Product List
    getProductList: (state) => {
      state.productListLoading = true;
    },

    getProductListSuccess: (state, action) => {
      const { productList = [], productListPagination = {} } = action.payload;

      state.productListMap = ProductTransformer.transformList(productList);
      state.productListPagination = productListPagination;

      state.productListLoading = false;
    },

    getProductListFailure: (state, action) => {
      state.productListLoading = false;
      state.productListError = action.payload.error;
    },

    // Update Product Status (Enable/Disable)
    updateProductStatus: (state, action) => {
      const { productId } = action.payload;
      state.updateProductStatusSuccessMap[productId] = false;
      state.updateProductStatusLoadingMap[productId] = true;
      state.updateProductStatusError = "";
    },

    updateProductStatusSuccess: (state, action) => {
      const { productId } = action.payload;
      state.updateProductStatusSuccessMap[productId] = true;
      delete state.updateProductStatusLoadingMap[productId];
    },

    updateProductStatusFailure: (state, action) => {
      const { productId, error } = action.payload;
      delete state.updateProductStatusLoadingMap[productId];
      state.updateProductStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const ProductReducer = productSlice.reducer;

// Actions
export const ProductActions = productSlice.actions;
