import { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// Utils
import FormUtils from "./FormUtils";

// Components
import { Button } from "../button/Button";
import TableHeaders from "../table/TableHeader";

// Section
import FormInput from "./FormInput";

// Page Components
function FormInputsLabel({ input = {} }) {
  // Input
  const { label = "", subText = "", title = "", isMandotary = false, key = "" } = input;

  // If label is not present
  if (!label) {
    return null;
  }

  return (
    <div className="mx-1">
      {label} {isMandotary && <span className="text-danger">*</span>}
      {/* Sub text */}
      <small className="text-secondary fst-italic">{subText}</small>
      {/* Show title */}
      {!key && title && <i className="fa-solid fa-circle-info fa-sm ms-1" title={title}></i>}
    </div>
  );
}

function FormInputs({ formInputItems = [], formik, isSelected = {}, setIsSelected, isFormDisabled = false }) {
  // Formik
  const { touched, errors } = formik;

  return formInputItems.map((input, index) => {
    // Input
    const { key = "" } = input || {};

    // Error
    const isError = touched[key] && errors[key];

    function handleClick() {
      setIsSelected({ [key]: true });
    }

    return (
      <td
        className={`${isError ? "form-error" : ""} ${key && isSelected[key] ? "selected" : ""}`}
        key={index}
        onClick={handleClick}
      >
        {/* Form Inputs Label */}
        <FormInputsLabel input={input} />

        {/* Form Input */}
        {key && (
          <FormInput
            input={input}
            formik={formik}
            showError={false}
            isTabularForm={true}
            isFormDisabled={isFormDisabled}
          />
        )}
      </td>
    );
  });
}

function TabularFormTitleRow({ title = "", tableHeaders = [] }) {
  // If title is not present
  if (!title) {
    return null;
  }

  // If title is present, then the entire row is used to display only the title
  return (
    <tr>
      <td colSpan={tableHeaders.length} className="text-center fw-bold bg-secondary text-light title">
        {title}
      </td>
    </tr>
  );
}

function TabularFormBody({ formInputItems = [], tableHeaders = [], formik, isFormDisabled = false }) {
  // State
  const [isSelected, setIsSelected] = useState({});

  return formInputItems.map((formGroup, index) => {
    const { title = "", inputItems = [] } = formGroup || {};

    return (
      <Fragment key={index}>
        {/* Tabular Form Title Row */}
        <TabularFormTitleRow title={title} tableHeaders={tableHeaders} />

        <tr>
          {/* Form Inputs */}
          <FormInputs
            formInputItems={inputItems}
            formik={formik}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            isFormDisabled={isFormDisabled}
          />
        </tr>
      </Fragment>
    );
  });
}

function FormActionsBtn({ showSubmitButton, isFormDisabled, formik, loading, resetFormFunction }) {
  // Reset Form
  function resetForm() {
    formik.resetForm();
    resetFormFunction();
  }

  // If show submit button is false
  if (!showSubmitButton || isFormDisabled) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end">
      {/* Reset Button */}
      <Button label="Reset" color="secondary" onClick={resetForm} disabled={loading} className="me-2" />

      {/* Submit Button */}
      <Button type="submit" label="Submit" color="primary" loading={loading} disabled={loading} />
    </div>
  );
}

/**
 * Form
 * @param {*} tableHeaders : Array of objects
 * @param {*} data : Object
 * @param {*} formInputItems : Array of Objects
 * @param {*} formValidationSchema : Validation Schema for form
 * @param {*} formSubmit : onSubmit function for formik
 * @param {*} showSubmitButton : Boolean
 * @param {*} loading : Boolean
 */
export default function TabularForm({
  tableHeaders = [],
  data = {},
  formInputItems = [],
  formValidationSchema = yup.object({}),
  formSubmit = () => {},
  onChange = () => {},
  showSubmitButton = true,
  loading = false,
  showModal = false,
  isFormDisabled = false,
  resetFormFunction = () => {},
}) {
  // Formik
  const formik = useFormik({
    initialValues: FormUtils.constructFormikValues({ formInputItems, data }),
    validationSchema: formValidationSchema,
    enableReinitialize: true, // To change initial values
    onSubmit: formSubmit,
  });

  const { handleSubmit } = formik;

  useEffect(() => {
    if (!showModal) {
      formik.resetForm();
    }
  }, [showModal]);

  return (
    <form className="tabular-form" onSubmit={handleSubmit} onChange={(e) => onChange(e, formik)}>
      <div className="table-responsive">
        <table className="table table-bordered table-compact">
          {/* Table Headers */}
          <TableHeaders tableHeaders={tableHeaders} />

          {/* Table Body */}
          <tbody>
            {/* Tabular Form Body */}
            <TabularFormBody
              formInputItems={formInputItems}
              tableHeaders={tableHeaders}
              formik={formik}
              isFormDisabled={isFormDisabled}
            />
          </tbody>
        </table>
      </div>

      {/* Form Actions Button */}
      <FormActionsBtn
        formik={formik}
        loading={loading}
        showSubmitButton={showSubmitButton}
        isFormDisabled={isFormDisabled}
        resetFormFunction={resetFormFunction}
      />
    </form>
  );
}
