// Hooks
import { useUpdateQueryParams } from "../../hooks/useUpdateQueryParams";

// Constants
import { QueryParamsKeys } from "../../constants/generalConstants";

/**
 * Table Data Not Found Component
 * @param {*} message : Message for empty data table
 */
export default function TableDataNotFound({ message, colSpan }) {
  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // Getting search text from url
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  return (
    <tr className="text-center">
      <td colSpan={colSpan}>
        <span>{message}</span>
        {searchText && " with "}
        <span className="fst-italic">{searchText}</span>
      </td>
    </tr>
  );
}
