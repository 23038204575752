// Product Transformers
function transformObj(productObj = {}) {
  const {
    id = "",
    version = "",
    isActive = false,
    name = "",
    description = "",
    measurementTypeId = "",
    measurementTypeName = "",
    unitId = "",
    unitSymbol = "",
    unitName = "",
  } = productObj;

  const product = {
    id: id,
    version: version,
    isActive: isActive,
    name: name,
    description: description,
    measurementTypeId: measurementTypeId,
    measurementTypeName: measurementTypeName,
    unitId: unitId,
    unitSymbol: unitSymbol,
    unitName: unitName,
  };

  return product;
}

function transformList(productObjList = []) {
  let productsListMap = {};

  productObjList.forEach((obj) => {
    const { id = "" } = obj;

    productsListMap[id] = { key: id, ...transformObj(obj) };
  });

  return productsListMap;
}

// Exports
const ProductTransformer = {
  transformObj,
  transformList,
};

export default ProductTransformer;
