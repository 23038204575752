import { createSlice } from "@reduxjs/toolkit";

// Transformer
import PowerConnectionTransformer from "./powerConnectionTransformer";

function resetApiSuccessState(state) {
  state.createPowerConnectionSuccess = false;
  state.updatePowerConnectionSuccess = false;
  state.updatePowerConnectionStatusSuccess = {};
}
// Initial State
const initialState = {
  // Get Power Connections List
  powerConnectionListMap: {},
  powerConnectionListLoading: false,
  powerConnectionListError: "",

  // Create Power Connection
  createPowerConnectionLoading: false,
  createPowerConnectionSuccess: false,
  createPowerConnectionError: {},

  // Update Power Connection
  updatePowerConnectionLoading: false,
  updatePowerConnectionSuccess: false,
  updatePowerConnectionError: {},

  // Update Power Connection Status
  updatePowerConnectionStatusLoading: {},
  updatePowerConnectionStatusSuccess: {},
  updatePowerConnectionStatusError: {},
};

// Power Connection Slice
const powerConnectionSlice = createSlice({
  name: "PowerConnection",
  initialState,
  reducers: {
    // Get Power Connections List
    getPowerConnectionList: (state) => {
      state.powerConnectionListLoading = true;
      state.powerConnectionListError = "";
    },

    getPowerConnectionListSuccess: (state, action) => {
      const { powerConnectionList } = action.payload;

      state.powerConnectionListMap = PowerConnectionTransformer.transformList(powerConnectionList);
      state.powerConnectionListLoading = false;
    },

    getPowerConnectionListFailure: (state, action) => {
      state.powerConnectionListLoading = false;
      state.powerConnectionListError = action.payload.error;
    },

    // Create Power Connection
    createPowerConnection: (state) => {
      state.createPowerConnectionLoading = true;
      state.createPowerConnectionSuccess = false;
      state.createPowerConnectionError = {};
    },

    createPowerConnectionSuccess: (state) => {
      state.createPowerConnectionSuccess = true;
      state.createPowerConnectionLoading = false;
    },

    createPowerConnectionFailure: (state, action) => {
      state.createPowerConnectionLoading = false;
      state.createPowerConnectionError = action.payload.error;
    },

    // Update Power Connection
    updatePowerConnection: (state) => {
      state.updatePowerConnectionLoading = true;
      state.updatePowerConnectionSuccess = false;
      state.updatePowerConnectionError = {};
    },

    updatePowerConnectionSuccess: (state) => {
      state.updatePowerConnectionSuccess = true;
      state.updatePowerConnectionLoading = false;
    },

    updatePowerConnectionFailure: (state, action) => {
      state.updatePowerConnectionLoading = false;
      state.updatePowerConnectionError = action.payload.error;
    },

    // Update Power Connection Status
    updatePowerConnectionStatus: (state, action) => {
      const { connectionId } = action.payload;
      state.updatePowerConnectionStatusLoading[connectionId] = true;
      state.updatePowerConnectionStatusSuccess[connectionId] = false;
      state.updatePowerConnectionStatusError[connectionId] = "";
    },

    updatePowerConnectionStatusSuccess: (state, action) => {
      const { connectionId } = action.payload;
      state.updatePowerConnectionStatusSuccess[connectionId] = true;
      delete state.updatePowerConnectionStatusLoading[connectionId];
    },

    updatePowerConnectionStatusFailure: (state, action) => {
      const { connectionId, error } = action.payload;
      delete state.updatePowerConnectionStatusLoading[connectionId];
      state.updatePowerConnectionStatusError[connectionId] = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantPowerConnectionReducer = powerConnectionSlice.reducer;

// Actions
export const PlantPowerConnectionActions = powerConnectionSlice.actions;
