import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { UserActions } from "../../../../store/slices/user/userSlice";

// Constants
import PageURLs from "../../../../constants/pageUrls";
import { ICON_SIZE } from "../../../../constants/generalConstants";
import { LocalStorageKeys } from "../../../../constants/localStorageConstants";

// Utils
import URLs from "../../../../utils/urlUtils";
import LocalStorage from "../../../../utils/localStorageUtils";

// Helper
import UserListTableHelper from "../../../../helpers/user/userListTableHelper";

// Components
import TablePagination from "../../../../components/table/TablePagination";
import ToggleButton from "../../../../components/antd/button/ToggleButton";
import DataTable from "../../../../components/antd/table/DataTable";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import Icon from "../../../../components/icon/Icon";

function UserStatus({ user, pageSize, pageNumber }) {
  const dispatch = useDispatch();
  const { orgId = "" } = useParams();

  const { key: id, isActive } = user;
  const status = !isActive;
  const { userId } = JSON.parse(LocalStorage.get(LocalStorageKeys.AUTH_USER));

  const isToggleDisabled = userId === id ? true : false;
  const loading = useSelector((state) => state.user.updateUserStatusLoadingMap[id]) || false;

  function enableDisableUser() {
    dispatch(UserActions.updateUserStatus({ orgId, userId: id, status, pageSize, pageNumber }));
  }

  return (
    <ToggleButton
      containerClassName="mt-1"
      isActive={isActive}
      disabled={isToggleDisabled}
      onClick={enableDisableUser}
      size="small"
      loading={loading}
    />
  );
}

function TableActions({ user }) {
  // Navigate
  const navigate = useNavigate();

  // Page Params
  const { orgId = "", plantId = "" } = useParams();

  const { id, isActive } = user;
  const iconClassName = isActive ? "cursor-pointer" : "cursor-disabled";

  // For plant users, different redirect url
  // Because that comes under plant routes
  const url = plantId ? PageURLs.PlantUserViewPageUrl : PageURLs.UserViewPageUrl;

  // Navigate to user view page
  function navigateToUserViewPage() {
    const redirectUrl = URLs.format(url, { orgId, userId: id, plantId });
    navigate(redirectUrl);
  }

  return (
    <Icon
      iconName="eye"
      title="View"
      size={ICON_SIZE.small}
      className={iconClassName}
      onClick={navigateToUserViewPage}
    />
  );
}

/**
 * User List Table
 */
export default function UserListTable({ userList = [], userListLoading = false, userListPagination = {}, pageNumber }) {
  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Pagination
  const { totalCount, pageSize, pageNo } = userListPagination;

  // Organization Type
  const { orgType = "" } = orgInfo || {};

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => {
      return <TableActions user={record} />;
    },
  };

  const statusColumn = {
    title: "Status",
    key: "isActive",
    align: "center",
    render: (record) => {
      return <UserStatus user={record} pageSize={pageSize} pageNumber={pageNo} />;
    },
  };

  const columns = [...UserListTableHelper.getHeders(orgType, pageNo, pageSize), statusColumn, actionColumn];

  return (
    <>
      {userListLoading && <SkeletonTable columnCount={7} rowCount={10} />}

      {!userListLoading && <DataTable columns={columns} rows={userList} applyTableBorder={true} />}

      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
