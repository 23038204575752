import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// Constants
import { ICON_SIZE } from "../../../../../constants/generalConstants";

// Utils
import { saveFile } from "../../../../../utils/fileUtils";

// Helper
import AuditDataTableHelper from "../../../../../helpers/audit/auditDataTableHelper";

// Components
import Loader from "../../../../../components/loader/v2/Loader";
import DataTable from "../../../../../components/antd/table/DataTable";
import Icon from "../../../../../components/icon/Icon";

// Css
import "./AuditDataTable.scss";

// Page Components
function TableActions({ data }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Each row
  const { loadingState = () => {}, dispatchAtn = () => {} } = data || {};

  // Loading Selector State
  const loadingStatus = useSelector(loadingState) || false;

  return (
    <>
      {!loadingStatus[auditId] && (
        <Icon
          iconName="arrowDownToLine"
          title="Download"
          size={ICON_SIZE.small}
          onClick={() => {
            dispatch(dispatchAtn({ auditId }));
          }}
        />
      )}

      {loadingStatus[auditId] && <Loader />}
    </>
  );
}

/**
 * Audit Data Page
 */
export default function AuditDataTable() {
  const rows = Object.values(AuditDataTableHelper.constructTableData());

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => {
      return <TableActions data={record} />;
    },
  };

  const columns = [...AuditDataTableHelper.getHeaders(), actionColumn];

  // Params
  const { auditId = "" } = useParams();

  // Download Data Selector State
  const downloadPerfAnalysisData = useSelector((state) => state.auditData.downloadPerfAnalysisData[auditId]) || "";
  const downloadPerfAnalysisSuccess =
    useSelector((state) => state.auditData.downloadPerfAnalysisSuccess[auditId]) || false;

  const downloadRecommendationData = useSelector((state) => state.auditData.downloadRecommendationData[auditId]) || "";
  const downloadRecommendationSuccess =
    useSelector((state) => state.auditData.downloadRecommendationSuccess[auditId]) || false;

  // Use Effect
  useEffect(() => {
    if (downloadPerfAnalysisSuccess) {
      saveFile("Audit_Performance_Analysis_Data.xlsx", downloadPerfAnalysisData);
    }
  }, [downloadPerfAnalysisSuccess]);

  useEffect(() => {
    if (downloadRecommendationSuccess) {
      saveFile("Audit_Recommendation_Data.xlsx", downloadRecommendationData);
    }
  }, [downloadRecommendationSuccess]);

  return <DataTable className="audit-data-table" columns={columns} rows={rows} applyTableBorder={true} />;
}
