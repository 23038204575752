function getHeaders() {
  return [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 600,
    },
    {
      title: "Symbol",
      align: "center",
      dataIndex: "symbol",
    },
  ];
}

const MeasurementTypeListTableHelper = {
  getHeaders,
};

export default MeasurementTypeListTableHelper;
