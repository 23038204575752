import { OrgType } from "../../constants/orgTypeConstants";
import TableUtils from "../../utils/tableUtils";

const headerIndustryOrgType = {
  title: "Assigned Plant",
  dataIndex: "plantName",
};

function getHeders(orgType = "", currentPage, pageSize) {
  const tableHeaders = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Mail",
      dataIndex: "emailId",
    },
    {
      title: "Contact Number",
      dataIndex: "phoneNo",
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
  ];

  if (orgType === OrgType.INDUSTRY) {
    tableHeaders.push(headerIndustryOrgType);
    return tableHeaders;
  }

  return tableHeaders;
}

const UserListTableHelper = { getHeders };

export default UserListTableHelper;
