// Constants
import { DateTimeFormat, ValueTypes } from "../../../constants/generalConstants";

// Utils
import { getFormattedDateTime } from "../../../utils/dateUtils";

// HSN Code Transformers
function transformObj(hsnCodeObj = {}) {
  const {
    id = "",
    version = "",
    updatedOn = "",
    isActive = false,
    type = "",
    code = "",
    chapter = "",
    heading = "",
    subHeading = "",
    name = "",
  } = hsnCodeObj;

  return {
    id: id,
    version: version,
    updatedOn: updatedOn,
    updatedOnDate: getFormattedDateTime(ValueTypes.DURATION, updatedOn, DateTimeFormat.date_time),
    isActive: isActive,
    type: type,
    code: code,
    chapter: chapter,
    heading: heading,
    subHeading: subHeading,
    name: name,
  };
}

function transformList(hsnCodeObjList = []) {
  let hsnCodeListMap = {};

  hsnCodeObjList.forEach((obj) => {
    const { id = "" } = obj;

    hsnCodeListMap[id] = { key: id, ...transformObj(obj) };
  });

  return hsnCodeListMap;
}

// Exports
const HSNCodeTransformer = {
  transformObj,
  transformList,
};

export default HSNCodeTransformer;
