// File Transformers
function transformObj(fileObj = {}) {
  const {
    id = "",
    version = "",
    plantId = "",
    isActive = true,
    type = "",
    name = "",
    contentType = "",
    size = "",
    url = "",
    notes = "",
  } = fileObj;

  const transformedfile = {
    id: id,
    version: version,
    plantId: plantId,
    isActive: isActive,
    type: type,
    name: name,
    contentType: contentType,
    size: size,
    url: url,
    notes: notes,
  };

  return transformedfile;
}

function transformList(fileList = []) {
  let fileListMap = {};

  fileList.forEach((obj) => {
    const { id = "" } = obj;

    fileListMap[id] = { key: id, ...transformObj(obj) };
  });

  return fileListMap;
}

// Exports
const FileTransformer = {
  transformObj,
  transformList,
};

export default FileTransformer;
