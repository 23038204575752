import { createSlice } from "@reduxjs/toolkit";
function resetApiSuccessState(state) {
  state.downloadPerfAnalysisSuccess = {};
  state.downloadRecommendationSuccess = {};
}
// Initial State
const initialState = {
  // Download performance analysis data
  downloadPerfAnalysisData: {},
  downloadPerfAnalysisLoading: {},
  downloadPerfAnalysisSuccess: {},
  downloadPerfAnalysisError: "",

  // Download recommendation data
  downloadRecommendationData: {},
  downloadRecommendationLoading: {},
  downloadRecommendationSuccess: {},
  downloadRecommendationError: "",
};

// Audit Data Slice
const auditDataSlice = createSlice({
  // Name
  name: "AuditData",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Download performance analysis data
    downloadPerfAnalysisData: (state, action) => {
      const { auditId = "" } = action.payload;
      state.downloadPerfAnalysisSuccess[auditId] = false;
      state.downloadPerfAnalysisLoading[auditId] = true;
      state.downloadPerfAnalysisError = "";
    },

    downloadPerfAnalysisDataSuccess: (state, action) => {
      const { auditId = "", downloadPerfAnalysisData = {} } = action.payload;
      state.downloadPerfAnalysisData[auditId] = downloadPerfAnalysisData;
      state.downloadPerfAnalysisSuccess[auditId] = true;
      delete state.downloadPerfAnalysisLoading[auditId];
    },

    downloadPerfAnalysisDataFailure: (state, action) => {
      const { auditId = "", error = "" } = action.payload;
      delete state.downloadPerfAnalysisLoading[auditId];
      state.downloadPerfAnalysisError = error;
    },

    // Download recommendation data
    downloadRecommendationData: (state, action) => {
      const { auditId = "" } = action.payload;
      state.downloadRecommendationSuccess[auditId] = false;
      state.downloadRecommendationLoading[auditId] = true;
      state.downloadRecommendationError = "";
    },

    downloadRecommendationDataSuccess: (state, action) => {
      const { auditId = "", downloadRecommendationData = {} } = action.payload;
      state.downloadRecommendationData[auditId] = downloadRecommendationData;
      state.downloadRecommendationSuccess[auditId] = true;
      delete state.downloadRecommendationLoading[auditId];
    },

    downloadRecommendationDataFailure: (state, action) => {
      const { auditId = "", error = "" } = action.payload;
      delete state.downloadRecommendationLoading[auditId];
      state.downloadRecommendationError = error;
    },
    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditDataReducer = auditDataSlice.reducer;

// Actions
export const AuditDataActions = auditDataSlice.actions;
