import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ErrorActions } from "../../slices/error/errorSlice";
import { PlantThermalBillActions } from "../../slices/plant/thermalBillSlice";

// Utils
import { saveFile } from "../../../utils/fileUtils";

// Service
import PlantThermalBillService from "../../../services/plant/thermalBillService";

// Get Plant Thermal Bill List
function* getThermalBillList(action) {
  try {
    const { pageNumber, pageSize, searchText, plantId } = action.payload;

    const { items, pagination } = yield PlantThermalBillService.getThermalBillList(
      plantId,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      PlantThermalBillActions.getThermalBillListSuccess({
        thermalBillList: items,
        thermalBillListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.getThermalBillListFailure({ error }));
  }
}

// Create Plant Thermal Bill
function* createThermalBill(action) {
  try {
    const { formData, plantId } = action.payload;

    yield PlantThermalBillService.createThermalBill(formData, plantId);

    const { items, pagination } = yield PlantThermalBillService.getThermalBillList(plantId);

    yield put(
      PlantThermalBillActions.getThermalBillListSuccess({
        thermalBillList: items,
        thermalBillListPagination: pagination,
      })
    );

    yield put(PlantThermalBillActions.upsertThermalBillSucess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.createThermalBillFailure({ error }));
  }
}

// Update Plant Thermal Bill
function* updateThermalBill(action) {
  try {
    const { formData, plantId, thermalBillId } = action.payload;

    yield PlantThermalBillService.updateThermalBill(formData, plantId, thermalBillId);

    const { items, pagination } = yield PlantThermalBillService.getThermalBillList(plantId);

    yield put(
      PlantThermalBillActions.getThermalBillListSuccess({
        thermalBillList: items,
        thermalBillListPagination: pagination,
      })
    );

    yield put(PlantThermalBillActions.upsertThermalBillSucess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.updateThermalBillFailure({ error }));
  }
}

// Upload Plant Thermal Bill
function* uploadThermalBill(action) {
  const { formData, plantId, thermalBillId } = action.payload;

  try {
    yield PlantThermalBillService.uploadThermalBill(formData, plantId, thermalBillId);

    const { items, pagination } = yield PlantThermalBillService.getThermalBillList(plantId);

    yield put(
      PlantThermalBillActions.getThermalBillListSuccess({
        thermalBillList: items,
        thermalBillListPagination: pagination,
      })
    );

    yield put(PlantThermalBillActions.uploadThermalBillSuccess({ thermalBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.uploadThermalBillFailure({ thermalBillId, error }));
  }
}

// Download Plant Thermal Bill
function* downloadThermalBill(action) {
  const { apiUrl = "", thermalBillId } = action.payload;

  try {
    const data = yield PlantThermalBillService.downloadThermalBill(apiUrl);

    saveFile("Thermal Bill", data);

    yield put(PlantThermalBillActions.downloadThermalBillSuccess({ thermalBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.downloadThermalBillFailure({ thermalBillId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantThermalBillActions.getThermalBillList.type, getThermalBillList),
    takeLatest(PlantThermalBillActions.createThermalBill.type, createThermalBill),
    takeLatest(PlantThermalBillActions.updateThermalBill.type, updateThermalBill),
    takeLatest(PlantThermalBillActions.uploadThermalBill.type, uploadThermalBill),
    takeLatest(PlantThermalBillActions.downloadThermalBill.type, downloadThermalBill),
  ]);
}
