import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { HSNCodeActions } from "../../slices/hsn-code/HSNCodeSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import HSNCodeService from "../../../services/hsn-code/HSNCodeService";

// Get HSN Code List
function* getHSNCodeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield HSNCodeService.getHSNCodeList(pageNumber, pageSize, searchText);

    yield put(
      HSNCodeActions.getHSNCodeListSuccess({
        hsnCodeList: items,
        hsnCodeListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(HSNCodeActions.getHSNCodeListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(HSNCodeActions.getHSNCodeList.type, getHSNCodeList)]);
}
