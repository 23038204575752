// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { RoleUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Role List
async function getRoleList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(RoleUrls.getRoleList, params);
}

// Get Privileges List
async function getPrivilegesList() {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(RoleUrls.getPrivilegesList);
}

// Get Role Information
async function getRoleInfo(roleId) {
  const url = URLs.format(RoleUrls.getRoleInfo, { roleId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Create Role
async function createRole(roleData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(RoleUrls.createRole, {}, roleData);
}

// Update Role
async function updateRole(roleId, roleData) {
  const url = URLs.format(RoleUrls.updateRole, { roleId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, roleData);
}

const RoleService = {
  getRoleList,
  getPrivilegesList,
  getRoleInfo,
  createRole,
  updateRole,
};
export default RoleService;
