// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { MeasurementTypeUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Measurement Type List
async function getMeasurementTypeList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(MeasurementTypeUrls.getMeasurementTypeList, params);
}

// Get Measurement Units
async function getMeasurementUnitsList(measurementTypeId) {
  const params = {
    measurement_type: measurementTypeId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(MeasurementTypeUrls.getMeasurementUnitsList, params);
}

// Get Measurement Type Info
async function getMeasurementTypeInfo(measurementTypeId) {
  const url = URLs.format(MeasurementTypeUrls.getMeasurementTypeInfo, { measurementTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update Measurement Type status
async function updateMeasurementTypeStatus(measurementTypeId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(MeasurementTypeUrls.updateMeasurementTypeStatus, { measurementTypeId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const MeasurementTypeService = {
  getMeasurementTypeList,
  getMeasurementUnitsList,
  getMeasurementTypeInfo,
  updateMeasurementTypeStatus,
};

export default MeasurementTypeService;
