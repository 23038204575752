// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { PlantElectricityBillUrls } from "../../constants/serviceUrls";
import { ContentType } from "../../constants/httpConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Plant Electricity Bill List
async function getElectricityBillList(plantId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const url = URLs.format(PlantElectricityBillUrls.getElectricityBillList, { plantId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

// Create Plant Electricity Bill
async function createElectricityBill(formData, plantId) {
  const url = URLs.format(PlantElectricityBillUrls.createElectricityBill, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData);
}

// Update Plant Electricity Bill By Id
async function updateElectricityBill(formData, plantId, electricityBillId) {
  const url = URLs.format(PlantElectricityBillUrls.updateElectricityBill, { plantId, electricityBillId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}

// Upload Plant Electricity Bill By Id
async function uploadElectricityBill(formData, plantId, electricityBillId) {
  const url = URLs.format(PlantElectricityBillUrls.uploadElectricityBill, { plantId, electricityBillId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

// Download Plant Electricity Bill By Id
async function downloadElectricityBill(url) {
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadPublicFile(url);
}

const PlantElectricityBillService = {
  getElectricityBillList,
  createElectricityBill,
  updateElectricityBill,
  uploadElectricityBill,
  downloadElectricityBill,
};

export default PlantElectricityBillService;
