import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { JobActions } from "../../../store/slices/job/jobSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/generalConstants";

// Components
import Input from "../../../components/input/Input";

// Section
import JobListTable from "./JobListTable";

// Page Component
function JobListPageHeader() {
  return (
    <div className="mt-1">
      <h2 className="title text-truncate mx-4">Job Information</h2>

      <div className="my-4 d-none">
        {/* Input Component */}
        <Input placeholder="Search Job..." isSearchInput={true} />
      </div>
    </div>
  );
}

/**
 * Job List Page
 */
export default function JobListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector States
  const jobListMap = useSelector((state) => state.job.jobListMap);
  const jobList = Object.values(jobListMap);

  const jobListLoading = useSelector((state) => state.job.jobListLoading);
  const jobListPagination = useSelector((state) => state.job.jobListPagination);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(JobActions.getJobList({ pageNumber, pageSize, searchText }));
  }, [dispatch, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<JobListPageHeader />);
  }, []);

  return (
    <div className="page-content">
      {/* Job List Page Header */}
      {/* <JobListPageHeader /> */}

      {/* Job List Table */}
      <JobListTable
        jobList={jobList}
        jobListLoading={jobListLoading}
        jobListPagination={jobListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
