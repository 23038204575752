// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { NICCodeUrls } from "../../constants/serviceUrls";

// Get NIC-Code List
async function getNICCodeList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(NICCodeUrls.getNICCodeList, params);
}

const NICCodeService = {
  getNICCodeList,
};

export default NICCodeService;
