// Role Transformers
function transformRoleObj(roleObj = {}) {
  return { ...roleObj };
}

function transformRoleList(roleList = []) {
  let roleListMap = {};

  roleList.forEach((obj) => {
    const { id = "" } = obj;

    roleListMap[id] = { key: id, ...transformRoleObj(obj) };
  });

  return roleListMap;
}

// Privilege Transformers
function transformPrivilegeObj(privilegeObj = {}) {
  return { ...privilegeObj };
}

function transformPrivilegesList(privilegesList = []) {
  let privilegesListMap = {};

  privilegesList.forEach((obj) => {
    const { id = "" } = obj;

    privilegesListMap[id] = { key: id, ...transformPrivilegeObj(obj) };
  });

  return privilegesListMap;
}

// Exports
const RoleTransformer = {
  transformRoleObj,
  transformRoleList,
  transformPrivilegeObj,
  transformPrivilegesList,
};

export default RoleTransformer;
