// URLs
import { DataPreLoadUrls } from "./serviceUrls";

export const DataPreLoadObj = {
  MEASUREMENTS_AND_UNITS: DataPreLoadUrls.getMeasurementsAndUnitsData,
  COMPONENT_TYPES: DataPreLoadUrls.getComponentTypesData,
  RECOMMENDATION_CODES: DataPreLoadUrls.getRecommendationCodesData,
  MATERIALS: DataPreLoadUrls.getMaterialsData,
  PRODUCTS: DataPreLoadUrls.getProductsData,
  OBSERVATION_CODES: DataPreLoadUrls.getObservationCodesData,
  CONSTANTS: DataPreLoadUrls.getConstantData,
};
