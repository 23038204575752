// Thermal Bill Transformers
function transformObj(thermalBillObj = {}) {
  const {
    id = "",
    fileUrl = "",
    year = "",
    month = "",
    thermalBillName = "",
    fuelGCV = "",
    unitsConsumed = "",
    unitCost = "",
    totalCost = "",
    materialId = "",
  } = thermalBillObj;

  const transformedThermalBill = {
    id: id,
    fileUrl: fileUrl,
    year: year,
    month: month,
    thermalBillName: thermalBillName,
    fuelGCV: fuelGCV,
    unitsConsumed: unitsConsumed,
    unitCost: unitCost,
    totalCost: totalCost,
    materialId: materialId,
  };

  return transformedThermalBill;
}

function transformList(thermalBillList = []) {
  let thermalBillListMap = {};

  thermalBillList.forEach((obj) => {
    const { id = "" } = obj;

    thermalBillListMap[id] = { key: id, ...transformObj(obj) };
  });

  return thermalBillListMap;
}

// Exports
const ThermalBillTransformer = {
  transformObj,
  transformList,
};

export default ThermalBillTransformer;
