import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "antd";

// Hooks
import { useUpdateQueryParams } from "../../../../../hooks/useUpdateQueryParams";

// Constants
import { QueryParamsKeys } from "../../../../../constants/generalConstants";

// Utils
import { constructFormDataObject } from "../../../../../utils/componentUtils";
import { getComponentTypeParameters } from "../../../../../utils/dataPreLoadUtils";
import { constructParameterValuesArray } from "../../../../../utils/componentUtils";

// Components
import { DefaultFormValidationSchema } from "../../../../../components/form/Form";
import TabularForm from "../../../../../components/form/TabularForm";

/**
 * View Component Modal
 */
export default function ViewComponentModal({
  isFrozenOrDecommissioned = false,
  title = "",
  componentInfo = {},
  tableHeaders = [],
  loading = false,
  dispatchAtn = () => {},
  formInputItems = [],
  partId = "",
  isPart = false,
  showModal = false,
  setShowModal = () => {},
  footer = null,
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // Selected Component Type Id
  const selectedComponentTypeId = searchParams.get(QueryParamsKeys.componentTypeId) || 0;

  // Component information
  const { component = {}, properties = [] } = componentInfo;
  const { name = "", internalId = "", remarks = "", id = "", parentId = "", componentTypeId = "" } = component;

  // For parts, parent id is the component id
  // For component, parent id will not be present, so id is the component id
  const componentId = parentId || id;

  // Component String
  const componentInfoStr = JSON.stringify(componentInfo);

  // Getting property list for that component
  const propertyList = getComponentTypeParameters(componentTypeId) || [];

  // View component form data
  const viewComponentFormData = useMemo(() => {
    return { name, internalId, remarks, ...constructFormDataObject(propertyList, properties, id) };
  }, [componentInfoStr, componentTypeId, partId]);

  // Update component form submit function
  function updateComponentTypeFormSubmit(componentPropertyFormData) {
    // Form Data
    const { name = "", internalId = "", remarks = "" } = componentPropertyFormData || {};

    // Constructing properties array
    const propertiesArray = constructParameterValuesArray(propertyList, componentPropertyFormData, id);

    // Component object
    const componentData = {
      componentTypeId,
      name,
      internalId,
      remarks,
      properties: propertiesArray,
    };

    // For component, isParent field is added
    if (!isPart) {
      Object.assign(componentData, { isParent: true });
    }

    // Dispatch
    dispatch(dispatchAtn({ componentData, plantId, componentId, componentTypeId: selectedComponentTypeId, partId }));
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <Modal title={title} width={1000} open={showModal} onCancel={closeModal} footer={footer}>
      <div className="mt-3">
        <TabularForm
          tableHeaders={tableHeaders}
          loading={loading}
          data={viewComponentFormData}
          formInputItems={formInputItems}
          formValidationSchema={DefaultFormValidationSchema}
          formSubmit={updateComponentTypeFormSubmit}
          showModal={showModal}
          isFormDisabled={isFrozenOrDecommissioned}
        />
      </div>
    </Modal>
  );
}
