import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { OrganizationActions } from "../../../store/slices/organization/organizationSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";

// Utils
import URLs from "../../../utils/urlUtils";

// Constants
import { OrgTypes, QueryParamsKeys } from "../../../constants/generalConstants";

// Components
import { Button } from "../../../components/button/Button";
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Page Components
import OrganizationListTable from "./components/OrganizationListTable";

// Page Components
function PageHeaderSection({ orgType }) {
  // OrgType Info
  const { nameOfList: orgTypeNameOfList } = OrgTypes[orgType.toUpperCase()];
  const pageTitle = orgTypeNameOfList;

  return <PageHeader title={pageTitle} className="w-100" />;
}

function OrganizationListTableActionsSection({ orgType }) {
  // Navigate
  const navigate = useNavigate();

  // OrgType Info
  const { name: orgTypeName, nameOfList: orgTypeNameOfList, nameLowercase = "" } = OrgTypes[orgType];

  function navigateToAddOrgPage() {
    const redirectUrl = URLs.format(PageURLs.OrganizationCreatePageUrl, { orgType: nameLowercase });
    navigate(redirectUrl);
  }

  return (
    <div className="d-flex justify-content-between align-items-center my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search ${orgTypeNameOfList}...`} isSearchInput={true} />

      <div className="btn-cont">
        <Button className="m-0" label={`Add new ${orgTypeName}`} onClick={navigateToAddOrgPage}>
          <i className="fa-solid fa-plus"></i>
        </Button>
      </div>
    </div>
  );
}

/**
 * Organization List Page
 */
export default function OrganizationListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { orgType: orgName = "" } = params;
  const orgType = orgName.toUpperCase();

  // Page Params
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector State
  const organizationTypeMap = useSelector((state) => state.organization.organizationTypeMap);
  const organizationListLoading = useSelector((state) => state.organization.organizationListLoading);
  const organizationListPagination = useSelector((state) => state.organization.organizationListPagination);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationList({ orgType, pageNumber, pageSize, searchText }));
  }, [dispatch, orgType, pageNumber, pageSize, searchText]);

  useEffect(() => {
    return () => dispatch(OrganizationActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection orgType={orgType} />);
  }, [orgType]);

  //Organization List
  const organizationMap = organizationTypeMap[orgType] || [];
  const organizationList = Object.values(organizationMap);

  return (
    <div className="page-content">
      {/* Organization List Table Actions Section */}
      {!organizationListLoading && <OrganizationListTableActionsSection orgType={orgType} />}

      {/* Organization List Table */}
      <OrganizationListTable
        organizationList={organizationList}
        organizationListLoading={organizationListLoading}
        organizationListPagination={organizationListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
