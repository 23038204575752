import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  edgesMap: {},
};

const edgeSlice = createSlice({
  name: "Edge",
  initialState,
  reducers: {
    // Set Edges
    setEdges: (state, action) => {
      const { edges } = action.payload;
      state.edgesMap = edges.reduce((acc, edge) => {
        const { id } = edge;
        acc[id] = edge;
        return acc;
      }, {});
    },

    createEdge: (state, action) => {
      const { edge } = action.payload;
      state.edgesMap[edge.id] = edge;
    },

    deleteEdge: (state, action) => {
      const { edgeUid = "" } = action.payload;
      delete state.edgesMap[edgeUid];
    },
  },
});

export const EdgeReducer = edgeSlice.reducer;

export const EdgeActions = edgeSlice.actions;
