// Constants
import { DataPreLoadKeys } from "../constants/generalConstants";

// Utils
import LocalStorage from "./localStorageUtils";

// Function to get property list of the component
export function getComponentTypeParameters(componentTypeId = "", parameterKey = "PROPERTY") {
  // Getting parameters and component types from local storage
  const { parametersByCompType = {} } = JSON.parse(LocalStorage.get(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  // Getting property list for that component
  const propertyList = parametersByCompType[componentTypeId]?.[parameterKey] || [];

  return propertyList;
}

// Function to get component parts
export function getComponentParts(componentTypeId) {
  // Getting parameters and component types from local storage
  const { partsByCompType = {} } = JSON.parse(LocalStorage.get(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  return partsByCompType[componentTypeId] || [];
}
