// Constants
import { PlantComponentLinkUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Get Summary
async function getSummary(plantId) {
  const url = URLs.format(PlantComponentLinkUrls.getSummary, { plantId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

// Create Component Link
async function createComponentLink(componentLinkData, plantId) {
  const url = URLs.format(PlantComponentLinkUrls.createLink, { plantId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(url, {}, componentLinkData);
}

// Update Component Link
async function updateComponentLink(componentLinkData, linkId, plantId) {
  const url = URLs.format(PlantComponentLinkUrls.updateLink, { plantId, linkId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, {}, componentLinkData);
}

// Delete Component Link
async function deleteComponentLink(linkId, plantId) {
  const url = URLs.format(PlantComponentLinkUrls.deleteLink, { plantId, linkId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(url);
}

const PlantComponentLinkService = {
  getSummary,
  createComponentLink,
  updateComponentLink,
  deleteComponentLink,
};

export default PlantComponentLinkService;
