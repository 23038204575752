// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { AuthUrls } from "../../constants/serviceUrls";
import { ContentType } from "../../constants/httpConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Login
async function login(loginFormData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(AuthUrls.login, {}, loginFormData, ContentType.APP_FORM_URL_ENCODED);
}

// Reset Password
async function resetPassword(email = "", newPassword = "") {
  const params = {
    userEmail: email,
  };

  const bodyFormData = new FormData();
  bodyFormData.set("newPassword", newPassword);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(AuthUrls.resetPassword, params, bodyFormData, ContentType.APP_FORM_URL_ENCODED);
}

// Update User Password
async function updateUserPassword(userId, newPassword) {
  const params = {
    newPassword,
  };

  const url = URLs.format(AuthUrls.updateUserPassword, { userId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, params);
}

const AuthService = {
  login,
  resetPassword,
  updateUserPassword,
};

export default AuthService;
