import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select } from "antd";

// Actions
import { TemplateActions } from "../../../../../store/slices/template/templateSlice";

// Constants
import { Button } from "../../../../../components/button/Button";

// Page Constants
const MAKE_KEY = "make";
const MODEL_KEY = "model";

// Helper functions
function constructMakeAndModalObj(templateList = []) {
  return templateList.reduce((acc, curr) => {
    const { make = "", modelNo = "" } = curr || {};

    acc[make] = [...(acc[make] || []), modelNo];
    return acc;
  }, {});
}

function constructOptions(list = []) {
  return list.map((option) => ({ label: option, value: option }));
}

function getTemplateIdAndGetData(model = "", templateList = [], dispatch) {
  const { id: templateId = "" } = templateList.find(({ modelNo = "" }) => modelNo === model);

  if (!templateId) {
    return;
  }

  dispatch(TemplateActions.getTemplateParamValues({ templateId }));
}

/**
 * Component Make And Modal Select
 * @param {*} componentTypeId : Component type id
 */
export default function ComponentMakeAndModalSelect({ componentTypeId = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Form
  const [form] = Form.useForm();

  // State
  const [makeAndModelMap, setMakeAndModelMap] = useState({});
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [make, setMake] = useState("");

  // Selector State
  const templateListMap = useSelector((state) => state.template.templateListMap);
  const templateListLoading = useSelector((state) => state.template.templateListLoading);
  const templateParamValuesLoading = useSelector((state) => state.template.templateParamValuesLoading);

  // use Memo
  const templateList = useMemo(() => Object.values(templateListMap), [templateListMap]);

  // Functions
  function findTemplateIdAndGetData() {
    form
      .validateFields()
      .then((values) => {
        const { model = "" } = values;

        getTemplateIdAndGetData(model, templateList, dispatch);
      })
      .catch((err) => console.log(err));
  }

  function constructModelOptions(_, allValues) {
    const { make = "" } = allValues;
    setMake(make);

    const makeKeysList = makeAndModelMap[make] || [];
    const options = constructOptions(makeKeysList);
    setModelOptions(options);
  }

  // useEffect
  useEffect(() => {
    dispatch(TemplateActions.getTemplateList({ element: "COMPONENT", elem_type_id: componentTypeId }));
  }, [dispatch, componentTypeId]);

  useEffect(() => {
    const makeAndModelObj = constructMakeAndModalObj(templateList);
    setMakeAndModelMap(makeAndModelObj);
  }, [templateList]);

  useEffect(() => {
    const makeKeysList = Object.keys(makeAndModelMap);
    const options = constructOptions(makeKeysList);
    setMakeOptions(options);
  }, [makeAndModelMap]);

  useEffect(() => {
    form.setFieldValue(MODEL_KEY, null);
  }, [make]);

  return (
    <Form
      form={form}
      layout="inline"
      className="d-flex justify-content-center my-3 pb-2"
      onValuesChange={constructModelOptions}
    >
      <Form.Item className="w-25" name={MAKE_KEY} rules={[{ required: true, message: "Please select make !!" }]}>
        <Select
          placeholder="Select make"
          options={makeOptions}
          loading={templateListLoading}
          disabled={templateListLoading}
        />
      </Form.Item>

      <Form.Item name={MODEL_KEY} className="w-25" rules={[{ required: true, message: "Please select model !!" }]}>
        <Select placeholder="Select model" options={modelOptions} disabled={!make} />
      </Form.Item>

      <Form.Item>
        <Button
          onClick={findTemplateIdAndGetData}
          disabled={templateParamValuesLoading}
          loading={templateParamValuesLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
