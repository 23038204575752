// Utils
import { getRecommendationCodesByComponentType } from "../../../../../utils/componentUtils";
import DecimalUtils from "../../../../../utils/decimalUtils";

// Components
import TableHeaders from "../../../../../components/table/TableHeader";
import TableDataNotFound from "../../../../../components/table/TableDataNotFound";

// Page Constants
const TABLE_HEADERS = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Energy Savings",
    },
  },
  {
    title: {
      displayName: "Cost Savings (INR)",
    },
  },
  {
    title: {
      displayName: "Investment (INR)",
    },
  },
  {
    title: {
      displayName: "Investment Returns",
    },
  },
];

// Page Components
function RecommendationTableRow({ recCode = {}, preLoadedRecCodeList = [], serialNo = "" }) {
  // Added Recommendation Information for a component
  const {
    codeId = "",
    savingsEnergy = "--",
    savingsCost = "--",
    investment = "--",
    investmentReturns = "--",
  } = recCode;

  // Finding code and desccription for that recommendation from local storage data
  const { code = "", description = "" } = preLoadedRecCodeList.find(({ id = "" }) => id === codeId) || {};

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{code}</td>
      <td>{description}</td>
      <td>{DecimalUtils.fixDecimal(savingsEnergy, 0)}</td>
      <td>{DecimalUtils.fixDecimal(savingsCost, 0)}</td>
      <td>{DecimalUtils.fixDecimal(investment, 2)}</td>
      <td>{DecimalUtils.fixDecimal(investmentReturns, 0)}</td>
    </tr>
  );
}

function RecommendationListTableBody({ recCodeList = [], preLoadedRecCodeList = [] }) {
  // If there is no recommendation codes
  if (recCodeList.length === 0) {
    return <TableDataNotFound message="No recommendation added" colSpan={16} />;
  }

  // Observation Table Row
  return (
    <>
      {recCodeList.map((recCode, idx) => {
        return (
          <RecommendationTableRow
            key={idx}
            recCode={recCode}
            serialNo={idx + 1}
            preLoadedRecCodeList={preLoadedRecCodeList}
          />
        );
      })}
    </>
  );
}

/**
 * Recommendation List Table
 * @param {*} componentTypeId : Component Type Id
 * @param {*} recCodeList : Array of recommendations codes
 */
export default function RecommendationListTable({ componentTypeId = "", recCodeList = [] }) {
  // Getting recommendation codes from local storage
  const preLoadedRecCodeList = getRecommendationCodesByComponentType(componentTypeId) || [];

  return (
    <section>
      <h3 className="fw-semibold text-center mb-5">Recommendations</h3>

      <div className="table-responsive">
        <table className="table table-bordered border-end">
          {/* Table Headers */}
          <TableHeaders tableHeaders={TABLE_HEADERS} />

          <tbody>
            {/* Recommendation List Table Body */}
            <RecommendationListTableBody recCodeList={recCodeList} preLoadedRecCodeList={preLoadedRecCodeList} />
          </tbody>
        </table>
      </div>
    </section>
  );
}
