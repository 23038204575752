import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Actions
import { AuditReportActions } from "../../store/slices/audit/reportSlice";

// Utils
import { blobToBase64, saveFile } from "../../utils/fileUtils";

// Components
import { Button } from "../button/Button";

// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Report Header and Footer Logo URLs
const KISEM_LOGO_URL = "https://ieac-assets.s3.ap-south-1.amazonaws.com/branding/KISEM_LOGO_LITE.png";
const IIT_M_LOGO_URL = "https://ieac-assets.s3.ap-south-1.amazonaws.com/branding/IIT_M_LOGO_LITE.png";
const LOGO_HEIGHT = 55;

// Helper Functions
async function getImageAsBlob(url = "") {
  const httpClient = HttpClientFactory.getInstance();
  const blob = await httpClient.downloadPublicFile(url);

  return blob;
}

async function getBase64ImageTag(url = "", imageHeight = 50) {
  if (!url) {
    return "";
  }

  try {
    const blob = await getImageAsBlob(url);
    const base64 = await blobToBase64(blob);

    return `<img src="${base64}" alt="Image" height=${imageHeight} />`;
  } catch (error) {
    return "";
  }
}

async function getHeaderHtmlString() {
  const logo1 = await getBase64ImageTag(KISEM_LOGO_URL, LOGO_HEIGHT);
  const logo2 = await getBase64ImageTag(IIT_M_LOGO_URL, LOGO_HEIGHT);

  return `<div style = "padding: 0px 10px;width: 100%;display: flex;justify-content:space-between;align-items: center;">
            ${logo1} ${logo2}
          </div>`;
}

function getFooterHtmlString() {
  return `<div style="font-size: 12px;text-align: center; width: 100%;"><span class="pageNumber"></span></div>`;
}

/**
 * Audit Report PDF Download Btn
 */
export default function AuditReportPDFDownloadBtn({ reportId = "", auditId = "", btnSize = "sm", btnClassName = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Download Report Selector State
  const PDFData = useSelector((state) => state.auditReport.PDFData);
  const downloadPDFLoading = useSelector((state) => state.auditReport.downloadPDFLoading[reportId]);
  const downloadPDFSuccess = useSelector((state) => state.auditReport.downloadPDFSuccess[reportId]);

  const iconClassName = downloadPDFLoading ? "fas fa-circle-notch fa-spin" : "fas fa-download";

  // Download Report PDF Function
  async function downloadReportPDF() {
    // Header Content
    const headerContent = await getHeaderHtmlString();

    // Footer Content
    const footerContent = getFooterHtmlString();

    // Report Data
    const data = {
      headerContent,
      footerContent,
      excludeHeaderFooterPageRange: "1",
    };

    // Dispatch
    dispatch(AuditReportActions.downloadPDF({ auditId, reportId, data }));
  }

  // use Effect
  useEffect(() => {
    if (downloadPDFSuccess) {
      saveFile(`Audit_Report.pdf`, PDFData);
    }
  }, [downloadPDFSuccess, PDFData]);

  return (
    <Button
      label="Download"
      disabled={downloadPDFLoading}
      onClick={downloadReportPDF}
      size={btnSize}
      color="secondary"
      className={btnClassName}
    >
      <i className={iconClassName} />
    </Button>
  );
}
