import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

// Actions
import { PlantThermalBillActions } from "../../../../store/slices/plant/thermalBillSlice";

// Helper
import ThermalBillListTableHelper from "../../../../helpers/plant/thermalBillListTableHelper";

// Constants
import { ICON_SIZE } from "../../../../constants/generalConstants";

// Components
import TablePagination from "../../../../components/table/TablePagination";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";
import Loader from "../../../../components/loader/v2/Loader";
import Icon from "../../../../components/icon/Icon";

// Page Components
import PlantBillsTableActionIcon from "../../components/PlantBillsTableActionIcon";

// Page Components
function TableActions({ setSelectedThermalBill, thermalBill = {}, setShowViewThermalBillModal = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId = "" } = params;

  // Thermal Bill Information
  const { id: thermalBillId = "", fileUrl = "" } = thermalBill;

  // Upload Thermal Bill Selector State
  const uploadThermalBillLoading = useSelector(
    (state) => state.plantThermalBill.uploadThermalBillLoading[thermalBillId]
  );
  const uploadThermalBillSuccess = useSelector(
    (state) => state.plantThermalBill.uploadThermalBillSuccess[thermalBillId]
  );

  // Download Thermal Bill Selector State
  const downloadThermalBillLoading = useSelector(
    (state) => state.plantThermalBill.downloadThermalBillLoading[thermalBillId]
  );

  // Checking whether file is present or not
  const isFilePresent = fileUrl || "";

  // After selecting the file dispatch is called
  function uploadFile(file) {
    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    dispatch(PlantThermalBillActions.uploadThermalBill({ formData, plantId, thermalBillId }));
  }

  // Download Thermal Bill Function
  function downloadThermalBill() {
    dispatch(PlantThermalBillActions.downloadThermalBill({ thermalBillId, apiUrl: fileUrl }));
  }

  // Open Thermal Bill Edit Modal Function
  function openThermalBillEditModal() {
    setSelectedThermalBill(thermalBill);
    setShowViewThermalBillModal(true);
  }

  useEffect(() => {
    if (uploadThermalBillSuccess) {
      toast.success("Thermal Bill Uploaded Successfully");
    }
  }, [uploadThermalBillSuccess]);

  return (
    <div className="d-flex gap-3">
      <Icon iconName="eye" title="View" size={ICON_SIZE.small} onClick={openThermalBillEditModal} />

      {/* Plant Bills Table Action Icon */}
      <PlantBillsTableActionIcon
        uploadFile={uploadFile}
        isFilePresent={isFilePresent}
        fileUploadLoadingStatus={uploadThermalBillLoading}
      />

      {/* Download Icon */}
      {isFilePresent && (
        <>
          {!downloadThermalBillLoading && (
            <Icon
              iconName="arrowDownToLine"
              title="Download File"
              size={ICON_SIZE.small}
              onClick={downloadThermalBill}
            />
          )}
          {downloadThermalBillLoading && <Loader />}
        </>
      )}
    </div>
  );
}

/**
 * Plant Thermal Bill List Table
 */
export default function PlantThermalBillListTable({ setSelectedThermalBill, setShowViewThermalBillModal = () => {} }) {
  // Thermal Bill Selector State
  const thermalBillMap = useSelector((state) => state.plantThermalBill.thermalBillMap);
  const thermalBillList = Object.values(thermalBillMap);

  const thermalBillListLoading = useSelector((state) => state.plantThermalBill.thermalBillListLoading);
  const thermalBillListPagination = useSelector((state) => state.plantThermalBill.thermalBillListPagination);

  // Material List Selector State
  const materialListLoading = useSelector((state) => state.material.materialListLoading);
  const loading = materialListLoading || thermalBillListLoading;

  const { totalCount, pageNo, pageSize } = thermalBillListPagination;

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (record) => {
      return (
        <TableActions
          setSelectedThermalBill={setSelectedThermalBill}
          thermalBill={record}
          setShowViewThermalBillModal={setShowViewThermalBillModal}
        />
      );
    },
  };

  const columns = [...ThermalBillListTableHelper.getHeders(pageNo, pageSize), actionColumn];

  return (
    <>
      {loading && <SkeletonTable columnCount={6} rowCount={9} />}
      {!loading && <DataTable rows={thermalBillList} columns={columns} />}

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
