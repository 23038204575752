import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditActions } from "../../slices/audit/auditSlice";
import { ErrorActions } from "../../slices/error/errorSlice";
import { PlantActions } from "../../slices/plant/plantSlice";

// Service
import AuditService from "../../../services/audit/auditService";
import PlantService from "../../../services/plant/plantService";

// Get Audit List
function* getAuditList(action) {
  try {
    const { orgId, plantId = "", pageNumber, pageSize } = action.payload;
    const { items, pagination } = yield AuditService.getAuditList(orgId, plantId, pageNumber, pageSize);

    yield put(
      AuditActions.getAuditListSuccess({
        auditList: items,
        auditListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditListFailure({ error }));
  }
}

// Get Audit List By Auditor
function* getAuditListByAuditor(action) {
  try {
    const { userId = "" } = action.payload;

    const auditListByAuditor = yield AuditService.getAuditListByAuditor(userId);

    yield put(AuditActions.getAuditListByAuditorSuccess({ auditListByAuditor }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditListByAuditorFailure({ error }));
  }
}

// Initiate Audit
function* initiateAudit(action) {
  try {
    const { auditData } = action.payload;

    yield AuditService.initiateAudit(auditData);

    yield put(AuditActions.initiateAuditSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.initiateAuditFailure({ error }));
  }
}

// Get Audit Info
function* getAuditInfo(action) {
  try {
    const { auditId } = action.payload;

    const auditInfo = yield AuditService.getAuditInfo(auditId);

    const { plantId } = auditInfo;

    const plantInfo = yield PlantService.getPlantInfo(plantId);

    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));

    yield put(AuditActions.getAuditInfoSuccess({ auditInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditInfoFailure({ error }));
  }
}

// Get Detailed Progress
function* getDetailedProgress(action) {
  try {
    const { auditId } = action.payload;

    const detailedProgress = yield AuditService.getDetailedProgress(auditId);

    yield put(AuditActions.getDetailedProgressSuccess({ detailedProgress }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getDetailedProgressFailure({ error }));
  }
}

// Update Plant Incharge
function* updatePlantIncharge(action) {
  try {
    const { auditId, userId } = action.payload;

    yield AuditService.updatePlantIncharge(auditId, userId);

    yield put(AuditActions.updatePlantInchargeSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updatePlantInchargeFailure({ error }));
  }
}

// Update Audit Incharge
function* updateAuditIncharge(action) {
  try {
    const { auditId, userId } = action.payload;

    yield AuditService.updateAuditIncharge(auditId, userId);

    yield put(AuditActions.updateAuditInchargeSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updateAuditInchargeFailure({ error }));
  }
}

// Start Pre Assessment
function* startPreAssessment(action) {
  try {
    const { auditId } = action.payload;

    yield AuditService.startPreAssessment(auditId);

    yield put(AuditActions.startPreAssessmentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.startPreAssessmentFailure({ error }));
  }
}

// Start Pre Audit
function* startPreAudit(action) {
  try {
    const { auditId } = action.payload;

    yield AuditService.startPreAudit(auditId);

    yield put(AuditActions.startPreAuditSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.startPreAuditFailure({ error }));
  }
}

// Add Auditor
function* addAuditor(action) {
  try {
    const { auditId, userId } = action.payload;

    yield AuditService.addAuditor(auditId, userId);

    yield put(AuditActions.addAuditorSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.addAuditorFailure({ error }));
  }
}

// Remove Auditor
function* removeAuditor(action) {
  const { auditId, userId } = action.payload;

  try {
    yield AuditService.removeAuditor(auditId, userId);

    yield put(AuditActions.removeAuditorSuccess({ userId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.removeAuditorFailure({ error, userId }));
  }
}

// Update Start Date
function* updateStartDate(action) {
  const { auditId, date = "" } = action.payload;

  try {
    yield AuditService.updateStartDate(auditId, date);

    yield put(AuditActions.updateStartDateSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updateStartDateFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditActions.getAuditList.type, getAuditList),
    takeLatest(AuditActions.getAuditListByAuditor.type, getAuditListByAuditor),
    takeLatest(AuditActions.initiateAudit.type, initiateAudit),
    takeLatest(AuditActions.getAuditInfo.type, getAuditInfo),
    takeLatest(AuditActions.getDetailedProgress.type, getDetailedProgress),
    takeLatest(AuditActions.updatePlantIncharge.type, updatePlantIncharge),
    takeLatest(AuditActions.updateAuditIncharge.type, updateAuditIncharge),
    takeLatest(AuditActions.startPreAssessment.type, startPreAssessment),
    takeLatest(AuditActions.startPreAudit.type, startPreAudit),
    takeLatest(AuditActions.addAuditor.type, addAuditor),
    takeLatest(AuditActions.removeAuditor.type, removeAuditor),
    takeLatest(AuditActions.updateStartDate.type, updateStartDate),
  ]);
}
