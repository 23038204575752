import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { MaterialActions } from "../../slices/material/materialSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import MaterialService from "../../../services/material/materialService";

// Get Material List
function* getMaterialList(action) {
  try {
    const { pageNumber, pageSize, searchText, type } = action.payload;

    const { items, pagination } = yield MaterialService.getMaterialList(pageNumber, pageSize, searchText, type);

    // Dispatching Action
    yield put(MaterialActions.getMaterialListSuccess({ materialList: items, materialListPagination: pagination }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MaterialActions.getMaterialListFailure({ error }));
  }
}

function* updateMaterialStatus(action) {
  const { materialId, status, pageNumber, pageSize } = action.payload;

  try {
    yield MaterialService.updateMaterialStatus(materialId, status);

    const { items, pagination } = yield MaterialService.getMaterialList(pageNumber, pageSize);

    yield put(
      MaterialActions.getMaterialListSuccess({
        materialList: items,
        materialListPagination: pagination,
      })
    );

    yield put(MaterialActions.updateMaterialStatusSuccess({ materialId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MaterialActions.updateMaterialStatusFailure({ materialId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(MaterialActions.getMaterialList.type, getMaterialList),
    takeEvery(MaterialActions.updateMaterialStatus.type, updateMaterialStatus),
  ]);
}
