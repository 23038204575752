import { Handle } from "@xyflow/react";

// Constants
import { NODE_TYPE, POSITION } from "../constants/reactFlowConstants";

/**
 * Handles Component
 * @param {*} nodeId : Integer
 * @param {*} type : String
 * @param {*} handles : Array
 * @param {*} spacing : Integer
 * @param {*} offset : Integer
 * @returns
 */
function HandlesCont({ nodeId, type, handles = [], spacing = 15, topOffset = 35 }) {
  // Position
  const position = type === NODE_TYPE.SOURCE ? POSITION.Right : POSITION.Left;

  return (
    <>
      {handles.map((handleId, idx) => {
        const handleUid = nodeId + "-" + handleId;
        const topPx = topOffset + idx * spacing;
        const styleObj = {
          top: topPx,
        };

        return <Handle key={handleUid} type={type} id={handleId} position={position} style={styleObj} />;
      })}
    </>
  );
}

export default HandlesCont;
