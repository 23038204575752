import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { PlantComponentActions } from "../../../../../store/slices/plant/componentSlice";

// Components
import { Button } from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";

/**
 * Upsert Plant Component Image
 * @param {*} componentId
 * @param {*} componentTypeId
 */
export default function UpsertPlantComponentImage({ imageURL = "", componentId = "", componentTypeId = "" }) {
  // Dispatch
  const disptach = useDispatch();

  const { plantId = "" } = useParams();

  // State
  const [selectedImage, setSelectedImage] = useState({ src: "", file: "" });
  const [error, setError] = useState("");

  const fileInputRef = useRef(null);

  // Selector State
  const uploadPlantComponentImageLoading = useSelector((state) => state.plantComponent.uploadComponentImageLoading);

  const { src = "", file = "" } = selectedImage || {};
  const isImagePresent = src || imageURL;

  // Handle Change Function
  function handleChange({ target }) {
    const file = target.files[0];

    const src = URL.createObjectURL(file);

    setSelectedImage({ src, file });
  }

  // Clear Function
  function clear() {
    setSelectedImage({});
    setError("");
  }

  // Handle Submit Function
  function handleSubmit() {
    if (!file?.name || !file?.type) {
      setError("Please Select a image !!!");
      return;
    }

    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    disptach(PlantComponentActions.uploadComponentImage({ componentTypeId, componentId, plantId, formData }));
  }

  // Reset Form Modal Function
  function resetFormModal() {
    setSelectedImage({ src: "", file: "" });

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }

  useEffect(() => {
    resetFormModal();
    return () => disptach(PlantComponentActions.resetAllApiSuccessState());
  }, [disptach]);

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-7">
          {/* Image Input */}
          <Input
            inputRef={fileInputRef}
            type="file"
            accept="image/*"
            className="input-search"
            onChange={handleChange}
            error={error}
          />
        </div>
        <div className="col-5">
          {/* Clear Button */}
          <Button label="clear" color="secondary" onClick={clear} disabled={uploadPlantComponentImageLoading} />

          {/* Submit Button */}
          <Button
            label="submit"
            className="ms-3"
            color="primary"
            onClick={handleSubmit}
            loading={uploadPlantComponentImageLoading}
            disabled={uploadPlantComponentImageLoading}
          />
        </div>
      </div>

      <h5 className="my-3">Preview :</h5>

      {!isImagePresent && <div>Please select a image for preview</div>}

      <img id="frame" alt="" className="img-fluid" src={src || imageURL} />
    </div>
  );
}
