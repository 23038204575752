// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { ContentType } from "../../constants/httpConstants";
import { DefaultPagination } from "../../constants/generalConstants";
import { PlantFileUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Plant File List
async function getFileList(plantId, pageNumber, pageSize, type) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (type) {
    params.type = type;
  }

  // Api Url
  const apiUrl = URLs.format(PlantFileUrls.getFileList, { plantId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiUrl, params);
}

// Create Plant File
async function createFile(plantId, formData) {
  // Api Url
  const apiUrl = URLs.format(PlantFileUrls.createFile, { plantId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiUrl, {}, formData, ContentType.MULTIPART_FORM_DATA);
}

// Update Plant File By Id
async function updateFile(plantId, fileId, formData) {
  // Api Url
  const apiUrl = URLs.format(PlantFileUrls.updateFile, { plantId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiUrl, {}, formData);
}

// Download Plant File By Id
async function downloadFile(apiUrl) {
  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.downloadPublicFile(apiUrl);
}

// Delete Plant File By Id
async function deleteFile(plantId, fileId) {
  // Api Url
  const apiUrl = URLs.format(PlantFileUrls.deleteFile, { plantId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiUrl);
}

const PlantFileService = {
  getFileList,
  createFile,
  updateFile,
  downloadFile,
  deleteFile,
};

export default PlantFileService;
