import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { ComponentTypeActions } from "../../slices/component-type/componentTypeSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Services
import ComponentTypeService from "../../../services/component-type/componentTypeService";

// Get Component Type List
function* getComponentTypeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield ComponentTypeService.getComponentTypeList(pageNumber, pageSize, searchText);

    yield put(
      ComponentTypeActions.getComponentTypeListSuccess({
        componentTypeList: items,
        componentTypeListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.getComponentTypeListFailure({ error }));
  }
}

// Get Parameter List
function* getParameterList(action) {
  try {
    const { componentTypeId, parameterType, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield ComponentTypeService.getParameterList(
      componentTypeId,
      parameterType,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      ComponentTypeActions.getParameterListSuccess({
        parameterList: items,
        parameterListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.getParameterListFailure({ error }));
  }
}

// Download Component Type Template
function* downloadComponentTypeTemplate(action) {
  const { componentTypeCode = "" } = action.payload;

  try {
    const data = yield ComponentTypeService.downloadComponentTypeTemplate(componentTypeCode);

    yield put(ComponentTypeActions.downloadComponentTypeTemplateSuccess({ data, componentTypeCode }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.downloadComponentTypeTemplateFailure({ error, componentTypeCode }));
  }
}

// Get Recommendation Code List
function* getRecommendationCodeList(action) {
  try {
    const { componentTypeId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield ComponentTypeService.getRecommendationCodeList(
      componentTypeId,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      ComponentTypeActions.getRecCodeListSuccess({
        recCodeList: items,
        recommendationCodeListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.getRecCodeListFailure({ error }));
  }
}

// Get Observation Code List
function* getObservationCodeList(action) {
  try {
    const { componentTypeId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield ComponentTypeService.getObservationCodeList(
      componentTypeId,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      ComponentTypeActions.getObsCodeListSuccess({
        observationCodeList: items,
        observationCodeListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.getObsCodeListFailure({ error }));
  }
}

// Get Component Information
function* getComponentTypeInfo(action) {
  const { componentTypeId } = action.payload;
  try {
    const componentTypeInfo = yield ComponentTypeService.getComponentTypeInfo(componentTypeId);

    yield put(ComponentTypeActions.getComponentTypeInfoSuccess({ componentTypeInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.getComponentTypeInfoFailure({ error }));
  }
}

// Update Component Type Status
function* updateComponentTypeStatus(action) {
  const { componentTypeId, status, pageNumber, pageSize } = action.payload;
  try {
    yield ComponentTypeService.updateComponentTypeStatus(componentTypeId, status);

    const { items, pagination } = yield ComponentTypeService.getComponentTypeList(pageNumber, pageSize);

    yield put(
      ComponentTypeActions.getComponentTypeListSuccess({
        componentTypeList: items,
        componentTypeListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateComponentTypeStatusSuccess({ componentTypeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateComponentTypeStatusFailure({ componentTypeId, error }));
  }
}

// Update Observation Code Status
function* updateObservationCodeStatus(action) {
  const { componentTypeId, obsCodeId, status, pageNumber, pageSize } = action.payload;
  try {
    yield ComponentTypeService.updateObservationCodeStatus(obsCodeId, status);

    const { items, pagination } = yield ComponentTypeService.getObservationCodeList(
      componentTypeId,
      pageNumber,
      pageSize
    );

    yield put(
      ComponentTypeActions.getObsCodeListSuccess({
        observationCodeList: items,
        observationCodeListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateObservationCodeStatusSuccess({ obsCodeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateObservationCodeStatusFailure({ obsCodeId, error }));
  }
}

// Update Recommendation Code Status
function* updateRecommendationCodeStatus(action) {
  const { componentTypeId, recCodeId, status, pageNumber, pageSize } = action.payload;
  try {
    yield ComponentTypeService.updateRecommendationCodeStatus(recCodeId, status);

    const { items, pagination } = yield ComponentTypeService.getRecommendationCodeList(
      componentTypeId,
      pageNumber,
      pageSize
    );

    yield put(
      ComponentTypeActions.getRecCodeListSuccess({
        recCodeList: items,
        recommendationCodeListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateRecommendationCodeStatusSuccess({ recCodeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateRecommendationCodeStatusFailure({ recCodeId, error }));
  }
}

// Update Parameter Status
function* updateParameterStatus(action) {
  const { componentTypeId, parameterType, parameterId, status, pageNumber, pageSize, searchText } = action.payload;
  try {
    yield ComponentTypeService.updateParameterStatus(parameterId, status);

    const { items, pagination } = yield ComponentTypeService.getParameterList(
      componentTypeId,
      parameterType,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      ComponentTypeActions.getParameterListSuccess({
        parameterList: items,
        parameterListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateParameterTypeStatusSuccess({ parameterId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateParameterTypeStatusFailure({ parameterId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(ComponentTypeActions.getComponentTypeList.type, getComponentTypeList),
    takeLatest(ComponentTypeActions.getParameterList.type, getParameterList),
    takeEvery(ComponentTypeActions.downloadComponentTypeTemplate.type, downloadComponentTypeTemplate),
    takeLatest(ComponentTypeActions.getRecCodeList.type, getRecommendationCodeList),
    takeLatest(ComponentTypeActions.getObsCodeList.type, getObservationCodeList),
    takeEvery(ComponentTypeActions.getComponentTypeInfo.type, getComponentTypeInfo),
    takeEvery(ComponentTypeActions.updateComponentTypeStatus.type, updateComponentTypeStatus),
    takeEvery(ComponentTypeActions.updateObservationCodeStatus.type, updateObservationCodeStatus),
    takeEvery(ComponentTypeActions.updateRecommendationCodeStatus.type, updateRecommendationCodeStatus),
    takeEvery(ComponentTypeActions.updateParameterTypeStatus.type, updateParameterStatus),
  ]);
}
