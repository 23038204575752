// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { JobUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Get Job List
async function getJobList(pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(JobUrls.getJobList, params);
}

// Get Job Info
async function getJobInfo(jobId) {
  const url = URLs.format(JobUrls.getJobInfo, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Job Session
async function getJobSession(jobId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  const url = URLs.format(JobUrls.getJobSession, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

// Run job
async function runJob(job, jobId) {
  const url = URLs.format(JobUrls.runJob, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, job);
}

// Resume job
async function resumeJob(jobId) {
  const url = URLs.format(JobUrls.resumeJob, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Pause job
async function pauseJob(jobId) {
  const url = URLs.format(JobUrls.pauseJob, { jobId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

const JobService = {
  getJobList,
  getJobInfo,
  getJobSession,
  runJob,
  resumeJob,
  pauseJob,
};

export default JobService;
