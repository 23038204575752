import {
  Eye,
  User,
  Users,
  Factory,
  Pencil,
  Upload,
  ArrowDownToLine,
  BookOpenText,
  FilePen,
  CirclePlus,

  //
  ChartArea,
  ChevronDown,
  ChevronRight,
  CircleX,
  Code,
  File,
  FolderOpenDot,
  Info,
  Library,
  LoaderCircle,
  Lock,
  LogIn,
  LogOut,
  Menu,
  Minus,
  OctagonAlert,
  Play,
  Plus,
  Settings,
  Shapes,
  Square,
  Table,
  Table2,
  TriangleAlert,
  Workflow,
  Download,
} from "lucide-react";

// Page Constants
const ICONS = {
  eye: Eye,
  user: User,
  users: Users,
  factory: Factory,
  pencil: Pencil,
  upload: Upload,
  arrowDownToLine: ArrowDownToLine,
  bookOpenText: BookOpenText,
  filePen: FilePen,
  circlePlus: CirclePlus,

  //
  folderOpenDot: FolderOpenDot,
  logIn: LogIn,
  logout: LogOut,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  play: Play,
  square: Square,
  bars: Menu,
  plus: Plus,
  minus: Minus,
  circleX: CircleX,
  lock: Lock,
  settings: Settings,
  chartArea: ChartArea,
  code: Code,
  table: Table,
  table2: Table2,
  shapes: Shapes,
  triangleAlert: TriangleAlert,
  octagonAlert: OctagonAlert,
  workflow: Workflow,
  loaderCircle: LoaderCircle,
  files: File,
  info: Info,
  library: Library,
  download: Download,
};

/**
 * Icon
 * @param {*} iconName
 * @param {*} color
 * @param {*} size
 * @param {*} strokeWidth
 */
export default function Icon({
  iconName = "",
  className = "",
  color = "black",
  size = 24,
  strokeWidth = 2,
  title = "",
  ...rest
}) {
  const IconWrapper = ICONS[iconName] || "";

  if (!IconWrapper) {
    return <></>;
  }

  return (
    <IconWrapper
      className={`cursor-pointer ${className}`}
      color={color}
      size={size}
      strokeWidth={strokeWidth}
      {...rest}
    >
      {title && <title>{title}</title>}
    </IconWrapper>
  );
}
