// MeasurementType Transformers
function transformMeasurementTypeObj(measurementTypeObj = {}) {
  const { id = "", name = "", description = "", symbol = "", isActive = false } = measurementTypeObj;

  const measurementType = {
    id: id,
    name: name,
    description: description,
    symbol: symbol,
    isActive: isActive,
  };

  return measurementType;
}

function getTransformedMeasurementTypeMap(measurementTypeList = []) {
  const transformedMeasurementTypeMap = {};

  measurementTypeList.forEach((obj) => {
    const { id = "" } = obj;

    transformedMeasurementTypeMap[id] = { key: id, ...transformMeasurementTypeObj(obj) };
  });

  return transformedMeasurementTypeMap;
}

// Units Transformers
function transformUnitsObj(unitsObj = {}) {
  const {
    id = "",
    name = "",
    symbol = "",
    shortForm = "",
    isBase = "",
    srcOffset = "",
    srcMultiplier = "",
    destOffset = "",
  } = unitsObj;

  const units = {
    id: id,
    name: name,
    symbol: symbol,
    shortForm: shortForm,
    isBase: isBase,
    srcOffset: srcOffset,
    srcMultiplier: srcMultiplier,
    destOffset: destOffset,
  };

  return units;
}

function getTransformedMeasurementUnitsMap(measurementUnitsList = []) {
  const transformedMeasurementUnitsMap = {};

  measurementUnitsList.forEach((obj) => {
    const { id = "" } = obj;

    transformedMeasurementUnitsMap[id] = { key: id, ...transformUnitsObj(obj) };
  });

  return transformedMeasurementUnitsMap;
}

//
const MeasurementTypeTransformer = {
  transformMeasurementTypeObj,
  getTransformedMeasurementTypeMap,
  transformUnitsObj,
  getTransformedMeasurementUnitsMap,
};

export default MeasurementTypeTransformer;
