import { createSlice } from "@reduxjs/toolkit";

// Transformer
import InstrumentTransformer from "./instrumentTransformer";

// Initial State
const initialState = {
  // Get instrument Data List
  instrumentDataListMap: [],
  instrumentDataListPagination: {},
  instrumentDataListLoading: false,
  instrumentDataListError: "",

  // Get instrument Data Info
  instrumentDataInfo: {},
  instrumentDataInfoLoading: false,
  instrumentDataInfoError: "",

  // Select Instrument Data
  selectInstrumentDataLoading: {},
  selectInstrumentDataError: "",

  // Upload Instrument Data File
  uploadInstrumentDataFileLoading: false,
  uploadInstrumentDataFileError: "",
};

const auditInstrumentSlice = createSlice({
  name: "AuditInstrument",
  initialState,
  reducers: {
    // Get instrument Data List
    getInstrumentDataList: (state) => {
      state.instrumentDataListLoading = true;
    },

    getInstrumentDataListSuccess: (state, action) => {
      const { instrumentDataList, instrumentDataListPagination } = action.payload;
      state.instrumentDataListMap = InstrumentTransformer.transformList(instrumentDataList);

      state.instrumentDataListPagination = instrumentDataListPagination;
      state.instrumentDataListLoading = false;
    },

    getInstrumentDataListFailure: (state, action) => {
      state.instrumentDataListLoading = false;
      state.instrumentDataListError = action.payload.error;
    },

    // Get instrument Data Info
    getInstrumentDataInfo: (state) => {
      state.instrumentDataInfoLoading = true;
    },

    getInstrumentDataInfoSuccess: (state, action) => {
      state.instrumentDataInfoLoading = false;
      state.instrumentDataInfo = action.payload.instrumentDataInfo;
    },

    getInstrumentDataInfoFailure: (state, action) => {
      state.instrumentDataInfoLoading = false;
      state.instrumentDataInfoError = action.payload.error;
    },

    // Select Instrument Data
    selectInstrumentData: (state, action) => {
      const { lineNo } = action.payload;
      state.selectInstrumentDataLoading[lineNo] = true;
      state.selectInstrumentDataError = "";
    },

    selectInstrumentDataSuccess: (state, action) => {
      const { lineNo } = action.payload;
      delete state.selectInstrumentDataLoading[lineNo];
    },

    selectInstrumentDataFailure: (state, action) => {
      const { lineNo, error } = action.payload;
      delete state.selectInstrumentDataLoading[lineNo];
      state.selectInstrumentDataError = error;
    },

    // Upload Instrument Data File
    uploadInstrumentDataFile: (state) => {
      state.uploadInstrumentDataFileLoading = true;
    },

    uploadInstrumentDataFileSuccess: (state) => {
      state.uploadInstrumentDataFileLoading = false;
    },

    uploadInstrumentDataFileFailure: (state, action) => {
      state.uploadInstrumentDataFileLoading = false;
      state.uploadInstrumentDataFileError = action.payload.error;
    },
  },
});

// Reducer
export const AuditInstrumentReducer = auditInstrumentSlice.reducer;

// Actions
export const AuditInstrumentActions = auditInstrumentSlice.actions;
