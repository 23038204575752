import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ConstantTransformer from "./constantTransformer";

// Initial State
const initialState = {
  // Get constant list
  constantMap: [],
  constantListLoading: false,
  constantListError: "",
};

// Constant List Slice
const constantSlice = createSlice({
  name: "Constant",
  initialState,
  reducers: {
    // Get Constant List
    getConstantList: (state) => {
      state.constantListLoading = true;
    },

    getConstantListSuccess: (state, action) => {
      state.constantMap = ConstantTransformer.transformList(action.payload.constantMap);

      state.constantListError = "";
      state.constantListLoading = false;
    },

    getConstantListFailure: (state, action) => {
      state.constantListLoading = false;
      state.constantListError = action.payload.error;
    },
  },
});

// Reducer
export const ConstantReducer = constantSlice.reducer;

// Actions
export const ConstantActions = constantSlice.actions;
