import { useNavigate, useParams } from "react-router-dom";

//Urls
import PageURLs from "../../constants/pageUrls";

// Components
import { Button } from "../../components/button/Button";

// Images
import error404Image from "../../assets/images/error-page/error-img.png";
import error500Image from "../../assets/images/error-page/error-img.png";

//Page Constants
const errorsInfo = {
  401: {
    imageUrl: error404Image,
    errorCode: "401",
    errorMessage: "Oops! You are not authorized",
  },
  404: {
    imageUrl: error404Image,
    errorCode: "404",
    errorMessage: "Oops! The page your looking for does not exist",
  },
  500: {
    imageUrl: error500Image,
    errorCode: "500",
    errorMessage: "Oops! Something went wrong",
  },
  502: {
    imageUrl: error500Image,
    errorCode: "502",
    errorMessage: "Oops! Something went wrong",
  },
  503: {
    imageUrl: error500Image,
    errorCode: "503",
    errorMessage: "Oops! Something went wrong",
  },
};

/**
 * ErrorPage
 */
export default function ErrorPage() {
  // Navigate
  const navigate = useNavigate();

  //Params
  const params = useParams();
  const { errorStatusCode } = params;

  const { imageUrl, errorMessage, errorCode } = errorsInfo[errorStatusCode];

  // Navigate to dashboard
  function navigateToDashboard() {
    navigate(PageURLs.DashboardPageUrl);
  }

  return (
    <div className="my-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center mb-5">
              <h1 className="display-2 fw-medium">
                {errorCode[0]}
                <i className="fa-solid fa-gear fa-spin text-primary"></i>
                {errorCode[2]}
              </h1>
              <h4 className="text-uppercase">{errorMessage}</h4>
              <div className="mt-5 text-center">
                <Button label="Back to Dashboard" onClick={navigateToDashboard} />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-6">
            <div>
              <img src={imageUrl} alt="Error" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
