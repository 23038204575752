import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { HSNCodeActions } from "../../../store/slices/hsn-code/HSNCodeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/generalConstants";
import { HSN_CODE_TABLE_HEADER } from "../../../constants/HSNCodeConstants";

// Utils
import TableUtils from "../../../utils/tableUtils";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import DataTable from "../../../components/antd/table/DataTable";
import TablePagination from "../../../components/table/TablePagination";
import SkeletonTable from "../../../components/antd/table/SkeletonTable";

/**
 * HSN Code List Page
 */
export default function HSNCodeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector States
  const hsnCodeListMap = useSelector((state) => state.hsnCode.hsnCodeListMap);
  const hsnCodeList = Object.values(hsnCodeListMap);

  const hsnCodeListLoading = useSelector((state) => state.hsnCode.hsnCodeListLoading);
  const hsnCodeListPagination = useSelector((state) => state.hsnCode.hsnCodeListPagination);
  const { totalCount, pageSize, pageNo } = hsnCodeListPagination;

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? "";
  const searchText = searchParams.get(QueryParamsKeys.searchText) ?? "";

  const serialNoHeader = {
    title: "S.No",
    dataIndex: "sno",
    align: "center",
    render: (text, record, index) => TableUtils.tableSerialNumber(pageNo, pageSize, index),
  };
  const columns = [serialNoHeader, ...HSN_CODE_TABLE_HEADER];

  // use Effect
  useEffect(() => {
    dispatch(HSNCodeActions.getHSNCodeList({ pageNumber, searchText }));
  }, [dispatch, pageNumber, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="HSN Code List" />);
  }, []);

  return (
    <div className="page-content">
      {hsnCodeListLoading && <SkeletonTable columnCount={columns.length} rowCount={10} />}

      {!hsnCodeListLoading && (
        <>
          <DataTable rows={hsnCodeList} columns={columns} />
          <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
        </>
      )}
    </div>
  );
}
