// Observation and Recommendation Transformers
function transformObj(obsAndRecObj = {}) {
  const { observation = {}, recommendations = [] } = obsAndRecObj;

  const obsAndRec = { observation: observation, recommendations: recommendations };

  return obsAndRec;
}

function transformList(obsAndRecList = []) {
  let obsAndRecListMap = {};

  obsAndRecList.forEach((obj) => {
    const { observation = {} } = obj;
    const { id = "" } = observation;

    obsAndRecListMap[id] = { key: id, ...transformObj(obj) };
  });

  return obsAndRecListMap;
}

// Exports
const AuditObservationAndRecommendationTransformer = {
  transformObj,
  transformList,
};

export default AuditObservationAndRecommendationTransformer;
