// Constants
import { DateTimeFormat, ValueTypes } from "../../../constants/generalConstants";

// Utils
import { getFormattedDateTime } from "../../../utils/dateUtils";

// NIC Code Transformers
function transformObj(nicCodeObj = {}) {
  const {
    id = "",
    version = "",
    updatedOn = "",
    isActive = false,
    type = "",
    sectionCode = "",
    divisionCode = "",
    groupCode = "",
    classCode = "",
    subClassCode = "",
    name = "",
    description = "",
  } = nicCodeObj;

  return {
    id: id,
    version: version,
    updatedOn: updatedOn,
    updatedOnDate: getFormattedDateTime(ValueTypes.DURATION, updatedOn, DateTimeFormat.date_time),
    isActive: isActive,
    type: type,
    sectionCode: sectionCode,
    divisionCode: divisionCode,
    groupCode: groupCode,
    classCode: classCode,
    subClassCode: subClassCode,
    name: name,
    description: description,
  };
}

function transformList(nicCodeObjList = []) {
  let nicCodeListMap = {};

  nicCodeObjList.forEach((obj) => {
    const { id = "" } = obj;

    nicCodeListMap[id] = { key: id, ...transformObj(obj) };
  });

  return nicCodeListMap;
}

// Exports
const NICCodeTransformer = {
  transformObj,
  transformList,
};

export default NICCodeTransformer;
