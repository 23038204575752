// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Data Pre Load
async function dataPreLoad(url) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

const DataPreLoadService = {
  dataPreLoad,
};

export default DataPreLoadService;
