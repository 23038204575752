import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Action
import { PlantActions } from "../../../store/slices/plant/plantSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";
import { ICON_SIZE } from "../../../constants/generalConstants";

// Utils
import URLs from "../../../utils/urlUtils";

// Helper
import PlantListTableHelper from "../../../helpers/plant/plantListTableHelper";

// Components
import TablePagination from "../../../components/table/TablePagination";
import ToggleButton from "../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../components/antd/table/SkeletonTable";
import DataTable from "../../../components/antd/table/DataTable";
import Icon from "../../../components/icon/Icon";

// Page Components
function PlantStatus({ plant, pageNumber, pageSize }) {
  const { id, orgId, isActive } = plant;

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.plant.updatePlantStatusLoadingMap[id]) || false;
  const updatePlantStatusSuccessMap = useSelector((state) => state.plant.updatePlantStatusSuccessMap[id]) || false;
  const status = !isActive;

  function updatePlantStatus() {
    dispatch(PlantActions.updatePlantStatus({ orgId, plantId: id, status, pageNumber, pageSize }));
  }

  useEffect(() => {
    if (updatePlantStatusSuccessMap) {
      toast.success("Plant Status Updated Successfully");
    }
  }, [updatePlantStatusSuccessMap]);

  return (
    <td>
      {/* Status Toggle */}
      <ToggleButton isActive={isActive} loading={loading} onClick={updatePlantStatus} />
    </td>
  );
}
function TableActions({ plant }) {
  const navigate = useNavigate();

  // Info
  const { id, orgId, isActive } = plant;
  const iconClassName = isActive ? "cursor-pointer" : "cursor-disabled";

  function navigateToPlantViewPage() {
    const url = URLs.format(PageURLs.PlantViewPageUrl, { orgId, plantId: id });
    navigate(url);
  }

  function navigateToAuditListPage() {
    const url = URLs.format(PageURLs.PlantAuditListPageUrl, { orgId, plantId: id });
    navigate(url);
  }

  function navigateToPlantSetupPage() {
    const url = URLs.format(PageURLs.PlantSetupPageUrl, { orgId, plantId: id });
    navigate(url);
  }

  return (
    <div className="d-flex gap-4">
      <Icon
        iconName="pencil"
        title="Edit"
        size={ICON_SIZE.small}
        className={iconClassName}
        onClick={navigateToPlantViewPage}
      />

      <Icon
        iconName="bookOpenText"
        title="Audits"
        size={ICON_SIZE.small}
        className={iconClassName}
        onClick={navigateToAuditListPage}
      />

      <Icon
        iconName="eye"
        title="Manage"
        size={ICON_SIZE.small}
        className={iconClassName}
        onClick={navigateToPlantSetupPage}
      />
    </div>
  );
}

/**
 * Plant List Table
 */
export default function PlantListTable({ plantList, plantListLoading, plantListPagination, pageNumber }) {
  const { totalCount, pageSize, pageNo } = plantListPagination;

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (record) => {
      return <TableActions plant={record} />;
    },
  };

  const statusColumn = {
    title: "Status",
    key: "isActive",
    render: (record) => {
      return <PlantStatus plant={record} pageSize={pageSize} pageNumber={pageNo} />;
    },
  };

  const columns = [...PlantListTableHelper.getHeders(pageNo, pageSize), statusColumn, actionColumn];

  return (
    <>
      {plantListLoading && <SkeletonTable columnCount={7} rowCount={10} />}

      {!plantListLoading && <DataTable rows={plantList} columns={columns} applyTableBorder={true} />}

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
