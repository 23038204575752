// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { PlantComponentPartsUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Add Part
async function addPart(partData, plantId, componentId) {
  const url = URLs.format(PlantComponentPartsUrls.addPart, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, partData);
}

// Update Part
async function updatePart(partData, plantId, componentId, partId) {
  const url = URLs.format(PlantComponentPartsUrls.updatePart, { plantId, componentId, partId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, partData);
}

// Delete Part
async function deletePart(plantId, componentId, partId) {
  const url = URLs.format(PlantComponentPartsUrls.deletePart, { plantId, componentId, partId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.delete(url);
}

const PlantComponentPartsService = {
  addPart,
  updatePart,
  deletePart,
};

export default PlantComponentPartsService;
