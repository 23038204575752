function getHeaders() {
  return [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
    },

    {
      title: "Short Form",
      dataIndex: "shortForm",
    },
    {
      title: "isBase",
      dataIndex: "isBase",
      render: (text, record) => (record.isBase ? "YES" : "NO"),
    },
    {
      title: "Src Offset",
      dataIndex: "srcOffset",
    },
    {
      title: "Src Multiplier",
      dataIndex: "srcMultiplier",
    },
    {
      title: "Dest Offset",
      dataIndex: "destOffset",
    },
  ];
}

const MeasurementUnitsTableHelper = {
  getHeaders,
};

export default MeasurementUnitsTableHelper;
