import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { MeasurementTypeActions } from "../../slices/measurement-type/measurementTypeSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import MeasurementTypeService from "../../../services/measurement-type/measurementTypeService";

// Get Measurement Type List
function* getMeasurementTypeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield MeasurementTypeService.getMeasurementTypeList(pageNumber, pageSize, searchText);

    yield put(
      MeasurementTypeActions.getMeasurementTypeListSuccess({
        measurementTypeList: items,
        measurementTypeListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeActions.getMeasurementTypeListFailure({ error }));
  }
}

// Get Measurement Units
function* getMeasurementUnitList(action) {
  try {
    const { measurementTypeId } = action.payload;

    const { items, pagination } = yield MeasurementTypeService.getMeasurementUnitsList(measurementTypeId);

    yield put(
      MeasurementTypeActions.getMeasurementUnitsListSuccess({
        measurementUnitsList: items,
        measurementUnitsListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeActions.getMeasurementUnitsListFailure({ error }));
  }
}

// Get Measurement Type Info
function* getMeasurementTypeInfo(action) {
  try {
    const { measurementTypeId } = action.payload;

    const measurementTypeInfo = yield MeasurementTypeService.getMeasurementTypeInfo(measurementTypeId);

    yield put(MeasurementTypeActions.getMeasurementTypeInfoSuccess({ measurementTypeInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeActions.getMeasurementTypeInfoFailure({ error }));
  }
}

// Update Measurement Type Status
function* updateMeasurementTypeStatus(action) {
  const { measurementTypeId, status, pageNumber, pageSize } = action.payload;

  try {
    yield MeasurementTypeService.updateMeasurementTypeStatus(measurementTypeId, status);

    const { items, pagination } = yield MeasurementTypeService.getMeasurementTypeList(pageNumber, pageSize);

    yield put(
      MeasurementTypeActions.getMeasurementTypeListSuccess({
        measurementTypeList: items,
        measurementTypeListPagination: pagination,
      })
    );

    yield put(MeasurementTypeActions.updateMeasurementTypeStatusSuccess({ measurementTypeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MeasurementTypeActions.updateMeasurementTypeStatusFailure({ measurementTypeId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(MeasurementTypeActions.getMeasurementTypeList.type, getMeasurementTypeList),
    takeLatest(MeasurementTypeActions.getMeasurementUnitsList.type, getMeasurementUnitList),
    takeLatest(MeasurementTypeActions.getMeasurementTypeInfo.type, getMeasurementTypeInfo),
    takeEvery(MeasurementTypeActions.updateMeasurementTypeStatus.type, updateMeasurementTypeStatus),
  ]);
}
