import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../hooks/useUpdateQueryParams";

// Actions
import { PlantFileActions } from "../../../../store/slices/plant/fileSlice";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../../constants/generalConstants";
import { ModalKeys } from "../../../../constants/modalConstants";
import { PlantFileTypes } from "../../../../constants/selectOptionConstants";

// Page Components
import FileUploadAndListSection from "../../../../components/file-list/FileListSection";

/**
 * Plant File List Page
 */
export default function PlantFileListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Selector State
  const fileListPagination = useSelector((state) => state.plantFile.fileListPagination) || {};

  // From Url
  const type = searchParams.get(QueryParamsKeys.type) || "";
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) || "";

  // Pagination
  const { pageNo = DefaultPagination.pageNumber, pageSize = DefaultPagination.pageSize } = fileListPagination || {};

  // Modal id
  const modalId = ModalKeys.fileUploadModal;

  // use Effect
  useEffect(() => {
    dispatch(PlantFileActions.getFileList({ plantId, pageNumber, pageSize, type }));
  }, [dispatch, plantId, pageNumber, pageSize, type]);

  useEffect(() => {
    return () => dispatch(PlantFileActions.resetAllApiSuccessState());
  }, [dispatch]);

  // Download Plant File
  function downloadFile(fileId = "", fileName = "", url = "") {
    dispatch(PlantFileActions.downloadFile({ fileId, fileName, apiUrl: url }));
  }

  // Delete Plant File
  function deleteFile(fileId = "") {
    dispatch(PlantFileActions.deleteFile({ plantId, fileId, pageNumber: pageNo, pageSize, type }));
  }

  // Upload Plant File
  function uploadFile(formData = "", selectedFileId = "") {
    // Plant dispatch action for update and create file
    const plantDispatchAction = selectedFileId ? "updateFile" : "createFile";

    dispatch(
      PlantFileActions[plantDispatchAction]({
        plantId,
        pageNumber: pageNo,
        pageSize,
        formData,
        type,
        modalId,
        fileId: selectedFileId,
      })
    );
  }

  // Dispatch Actions
  const dispatchActions = {
    downloadFileFn: downloadFile,
    deleteFileFn: deleteFile,
    uploadFileFn: uploadFile,
  };

  return (
    <div>
      {/* File Upload And List Section */}
      <FileUploadAndListSection
        fileReduceKey="plantFile"
        dispatchActions={dispatchActions}
        fileTypes={PlantFileTypes}
      />
    </div>
  );
}
