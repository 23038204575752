// Organization Transformers
function transformObj(organizationObj = {}) {
  const {
    id = "",
    version = "",
    orgType = "",
    name = "",
    description = "",
    website = "",
    emailId = "",
    line1 = "",
    line2 = "",
    city = "",
    state = "",
    pincode = "",
    isActive = false,
  } = organizationObj;

  const organization = {
    id: id,
    version: version,
    orgType: orgType,
    name: name,
    description: description,
    website: website,
    emailId: emailId,
    line1: line1,
    line2: line2,
    city: city,
    state: state,
    pincode: pincode,
    isActive: isActive,
  };

  return organization;
}

function transformList(organizationObjList = []) {
  let organizationListMap = {};

  organizationObjList.forEach((obj) => {
    const { id = "" } = obj;

    organizationListMap[id] = { key: id, ...transformObj(obj) };
  });

  return organizationListMap;
}

// Exports
const OrganizationTransformer = {
  transformObj,
  transformList,
};

export default OrganizationTransformer;
