import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantElectricityBillActions } from "../../slices/plant/electricityBillSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Utils
import { saveFile } from "../../../utils/fileUtils";

// APIs
import PlantElectricityBillService from "../../../services/plant/electricityBillService";

// Get Plant Electricity Bill List
function* getElectricityBillList(action) {
  try {
    const { pageNumber, pageSize, searchText, plantId } = action.payload;

    const { items, pagination } = yield PlantElectricityBillService.getElectricityBillList(
      plantId,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      PlantElectricityBillActions.getElectricityBillListSuccess({
        electricityBillList: items,
        electricityBillListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.getElectricityBillListFailure({ error }));
  }
}

// Create Plant Electricity Bill
function* createElectricityBill(action) {
  try {
    const { formData, plantId } = action.payload;

    yield PlantElectricityBillService.createElectricityBill(formData, plantId);

    const { items, pagination } = yield PlantElectricityBillService.getElectricityBillList(plantId);

    yield put(
      PlantElectricityBillActions.getElectricityBillListSuccess({
        electricityBillList: items,
        electricityBillListPagination: pagination,
      })
    );

    yield put(PlantElectricityBillActions.createElectricityBillSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.createElectricityBillFailure({ error }));
  }
}

// Update Plant Electricity Bill
function* updateElectricityBill(action) {
  try {
    const { formData, plantId, electricityBillId } = action.payload;

    yield PlantElectricityBillService.updateElectricityBill(formData, plantId, electricityBillId);

    const { items, pagination } = yield PlantElectricityBillService.getElectricityBillList(plantId);

    yield put(
      PlantElectricityBillActions.getElectricityBillListSuccess({
        electricityBillList: items,
        electricityBillListPagination: pagination,
      })
    );

    yield put(PlantElectricityBillActions.updateElectricityBillSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.updatelectricityBillFailure({ error }));
  }
}

// Upload Plant Electricity Bill
function* uploadElectricityBill(action) {
  const { formData, plantId, electricityBillId } = action.payload;

  try {
    yield PlantElectricityBillService.uploadElectricityBill(formData, plantId, electricityBillId);

    const { items, pagination } = yield PlantElectricityBillService.getElectricityBillList(plantId);

    yield put(
      PlantElectricityBillActions.getElectricityBillListSuccess({
        electricityBillList: items,
        electricityBillListPagination: pagination,
      })
    );

    yield put(PlantElectricityBillActions.uploadElectricityBillSuccess({ electricityBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.uploadElectricityBillFailure({ electricityBillId, error }));
  }
}

// Download Plant Electricity Bill
function* downloadElectricityBill(action) {
  const { apiUrl = "", electricityBillId = "" } = action.payload;

  try {
    const downloadElectricityBillData = yield PlantElectricityBillService.downloadElectricityBill(apiUrl);

    yield put(
      PlantElectricityBillActions.downloadElectricityBillSuccess({ electricityBillId, downloadElectricityBillData })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.downloadElectricityBillFailure({ electricityBillId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantElectricityBillActions.getElectricityBillList.type, getElectricityBillList),
    takeLatest(PlantElectricityBillActions.createElectricityBill.type, createElectricityBill),
    takeLatest(PlantElectricityBillActions.updateElectricityBill.type, updateElectricityBill),
    takeLatest(PlantElectricityBillActions.uploadElectricityBill.type, uploadElectricityBill),
    takeLatest(PlantElectricityBillActions.downloadElectricityBill.type, downloadElectricityBill),
  ]);
}
