import { getMonthName } from "../../utils/dateUtils";
import TableUtils from "../../utils/tableUtils";

function getHeders(currentPage, pageSize) {
  const tableHeaders = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => {
        const { month, year } = record;
        return `${getMonthName(month)} , ${year}`;
      },
    },
    {
      title: "Demand Sanctioned",
      dataIndex: "demandSanctioned",
    },
    {
      title: "Demand Recorded",
      dataIndex: "demandRecorded",
    },
    {
      title: "Units Consumed ( Solar )",
      dataIndex: "unitsConsumedSolar",
    },
    {
      title: "Units Consumed ( IEX )",
      dataIndex: "unitsConsumedIEX",
    },
    {
      title: "Units Consumed ( Electricity Board )",
      dataIndex: "unitsConsumedElectricityBoard",
    },
    {
      title: "Units Consumed",
      dataIndex: "unitsConsumed",
    },
    {
      title: "Unit Cost",
      dataIndex: "unitCost",
      render: (unitCost = 0) => <div>{unitCost.toFixed(2)}</div>,
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      render: (totalCost = 0) => <div>{totalCost.toFixed(0)}</div>,
    },
    {
      title: "Power Factor",
      dataIndex: "powerFactor",
    },
  ];

  return tableHeaders;
}

const ElectrcityBillListTableHelper = { getHeders };

export default ElectrcityBillListTableHelper;
