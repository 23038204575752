// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";
import { PlantThermalBillUrls } from "../../constants/serviceUrls";
import { ContentType } from "../../constants/httpConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Plant Thermal Bill List
async function getThermalBillList(plantId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const url = URLs.format(PlantThermalBillUrls.getThermalBillList, { plantId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

// Create Plant Thermal Bill
async function createThermalBill(formData, plantId) {
  const url = URLs.format(PlantThermalBillUrls.createThermalBill, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData);
}

// Update Plant Thermal Bill
async function updateThermalBill(formData, plantId, thermalBillId) {
  const url = URLs.format(PlantThermalBillUrls.updateThermalBill, { plantId, thermalBillId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, {}, formData);
}

// Upload Plant Thermal Bill
async function uploadThermalBill(formData, plantId, thermalBillId) {
  const url = URLs.format(PlantThermalBillUrls.uploadThermalBill, { plantId, thermalBillId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

// Download Plant Thermal Bill
async function downloadThermalBill(url) {
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadPublicFile(url);
}

const PlantThermalBillService = {
  getThermalBillList,
  createThermalBill,
  updateThermalBill,
  uploadThermalBill,
  downloadThermalBill,
};

export default PlantThermalBillService;
