import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ThermalBillTransformer from "./thermalBillTransformer";

function resetApiSuccessState(state) {
  state.upsertThermalBillSucess = false;
  state.uploadThermalBillSuccess = {};
}

// Initial State
const initialState = {
  // Get thermal bill list
  thermalBillMap: {},
  thermalBillListPagination: {},
  thermalBillListLoading: false,
  thermalBillListError: "",

  // Upsert thermal bill
  upsertThermalBillLoading: false,
  upsertThermalBillSucess: false,
  upsertThermalBillError: {},

  // Upload thermal bill
  uploadThermalBillLoading: {},
  uploadThermalBillSuccess: {},
  uploadThermalBillError: "",

  // Download thermal bill
  downloadThermalBillLoading: {},
  downloadThermalBillError: "",
};

// Plant Thermal Bill Slice
const plantThermalBill = createSlice({
  name: "ThermalBill",
  initialState,
  reducers: {
    // Get Thermal bill list
    getThermalBillList: (state) => {
      state.thermalBillListLoading = true;
    },

    getThermalBillListSuccess: (state, action) => {
      const { thermalBillList, thermalBillListPagination } = action.payload;
      state.thermalBillMap = ThermalBillTransformer.transformList(thermalBillList);

      state.thermalBillListPagination = thermalBillListPagination;
      state.thermalBillListLoading = false;
    },

    getThermalBillListFailure: (state, action) => {
      state.thermalBillListLoading = false;
      state.thermalBillListError = action.payload.error;
    },

    // Create thermal bill
    createThermalBill: (state) => {
      state.upsertThermalBillSucess = false;
      state.upsertThermalBillLoading = true;
    },

    createThermalBillSucess: (state) => {
      state.upsertThermalBillSucess = true;
      state.upsertThermalBillLoading = false;
    },

    createThermalBillFailure: (state, action) => {
      state.upsertThermalBillLoading = false;
      state.upsertThermalBillError = action.payload.error;
    },

    // Update thermal bill
    updateThermalBill: (state) => {
      state.upsertThermalBillSucess = false;
      state.upsertThermalBillLoading = true;
    },

    upsertThermalBillSucess: (state) => {
      state.upsertThermalBillSucess = true;
      state.upsertThermalBillLoading = false;
    },

    updateThermalBillFailure: (state, action) => {
      state.upsertThermalBillLoading = false;
      state.upsertThermalBillError = action.payload.error;
    },

    // Upload thermal bill
    uploadThermalBill: (state, action) => {
      const { thermalBillId } = action.payload;
      state.uploadThermalBillSuccess[thermalBillId] = false;
      state.uploadThermalBillLoading[thermalBillId] = true;
      state.uploadThermalBillError = "";
    },

    uploadThermalBillSuccess: (state, action) => {
      const { thermalBillId } = action.payload;
      state.uploadThermalBillSuccess[thermalBillId] = true;
      delete state.uploadThermalBillLoading[thermalBillId];
    },

    uploadThermalBillFailure: (state, action) => {
      const { thermalBillId, error } = action.payload;
      delete state.uploadThermalBillLoading[thermalBillId];
      state.uploadThermalBillError = error;
    },

    // Download thermal bill
    downloadThermalBill: (state, action) => {
      const { thermalBillId = "" } = action.payload;
      state.downloadThermalBillLoading[thermalBillId] = true;
      state.uploadThermalBillError = "";
    },

    downloadThermalBillSuccess: (state, action) => {
      const { thermalBillId = "" } = action.payload;
      delete state.downloadThermalBillLoading[thermalBillId];
    },

    downloadThermalBillFailure: (state, action) => {
      const { thermalBillId = "", error } = action.payload;
      delete state.downloadThermalBillLoading[thermalBillId];
      state.uploadThermalBillError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantThermalBillReducer = plantThermalBill.reducer;

// Actions
export const PlantThermalBillActions = plantThermalBill.actions;
