// Constants
import { DateTimeFormat, ValueTypes } from "../../../constants/generalConstants";

// Utils
import { getFormattedDateTime } from "../../../utils/dateUtils";

// Audit Transformers
function transformAuditInfoObj(auditInfo = {}) {
  const {
    id = "",
    version = "",
    industryOrgId = "",
    industryOrgName = "",
    plantId = "",
    plantName = "",
    plantInternalId = "",
    plantAddress = "",
    auditOrgId = "",
    auditOrgName = "",
    auditInchargeId = "",
    auditInchargeName = "",
    auditStatus = "",
    startTimestamp = "",
    auditors = [],
  } = auditInfo;

  return {
    id: id,
    version: version,
    industryOrgId: industryOrgId,
    industryOrgName: industryOrgName,
    plantId: plantId,
    plantName: plantName,
    plantInternalId: plantInternalId,
    plantAddress: plantAddress,
    auditOrgId: auditOrgId,
    auditOrgName: auditOrgName,
    auditInchargeId: auditInchargeId,
    auditInchargeName: auditInchargeName,
    auditStatus: auditStatus,
    startTimestamp: startTimestamp,
    createdOn: getFormattedDateTime(ValueTypes.DURATION, startTimestamp, DateTimeFormat.date_time),
    auditors: auditors,
  };
}

function transformObj(auditObj = {}) {
  const {
    id = "",
    plantName = "",
    industryOrgName = "",
    plantInchargeName = "",
    plantAddress = "",
    auditInchargeName = "",
    auditOrgName = "",
    auditStatus = "",
    startTimestamp = "",
  } = auditObj;

  const audit = {
    id: id,
    plantName: plantName,
    industryOrgName: industryOrgName,
    plantInchargeName: plantInchargeName,
    plantAddress: plantAddress,
    auditInchargeName: auditInchargeName,
    auditOrgName: auditOrgName,
    auditStatus: auditStatus,
    startTimestamp: startTimestamp,
    createdOn: getFormattedDateTime(ValueTypes.DURATION, startTimestamp, DateTimeFormat.date_time),
  };

  return audit;
}

function transformList(auditList = []) {
  let auditListMap = {};

  auditList.forEach((obj) => {
    const { id = "" } = obj;

    auditListMap[id] = { key: id, ...transformObj(obj) };
  });

  return auditListMap;
}

// Exports
const AuditTransformer = {
  transformAuditInfoObj,
  transformObj,
  transformList,
};

export default AuditTransformer;
