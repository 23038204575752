// Constants
import { LocalStorageKeys } from "../../constants/localStorageConstants";

// Utils
import LocalStorage from "../../utils/localStorageUtils";

/**
 * Pre defined APP Themes.
 */
const Themes = {
  PLATFORM: {
    id: "PLATFORM",
    name: "IEAC",
    custom: true,
  },
  INDUSTRY: {
    id: "INDUSTRY",
    name: "IEAC",
    custom: true,
  },
  AUDIT: {
    id: "AUDIT",
    name: "IEAC",
    custom: true,
  },
};

/**
 * App theme selector
 */
export default function getTheme() {
  //Theme Id from Local Storage
  const themeId = LocalStorage.get(LocalStorageKeys.ORG_TYPE);

  //Get the Theme from existing defs
  const theme = Themes[themeId];
  if (theme) {
    return theme;
  }

  //Return platform
  return Themes.PLATFORM;
}
