// Service
import OrganizationService from "../services/organization/organizationService";
import PlantService from "../services/plant/plantService";

export async function plantInfoLoader(orgId, plantId) {
  //TODO: How do we add the loader (Spinner)
  try {
    const [plantInfo, plantOrgInfo] = await Promise.all([
      PlantService.getPlantInfo(plantId),
      OrganizationService.getOrganizationInfo(orgId),
    ]);

    return { plantInfo, plantOrgInfo };
  } catch (error) {
    // TODO :: How do we handle the Errors in the Loaders.
    // dispatch(setErrorInfo({ errorInfo: error, showToaster: true }));
    return {};
  }
}
