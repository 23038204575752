import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditParameterValuesActions } from "../../slices/audit/parameterValuesSlice";
import { AuditComponentActions } from "../../slices/audit/componentSlice";
import { AuditObservationAndRecommendationActions } from "../../slices/audit/observationAndRecommendationSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import AuditParameterValuesService from "../../../services/audit/parameterValueService";
import AuditComponentService from "../../../services/audit/componentService";
import AuditObservationAndRecommendationService from "../../../services/audit/observationAndRecommendationService";

// Upsert Values
function* upsertValues(action) {
  try {
    const { parameterValuesData, auditId, componentId, pageNumber = "", pageSize = "", type = "" } = action.payload;

    yield AuditParameterValuesService.upsertValues(parameterValuesData, auditId, componentId);

    const componentInfo = yield AuditComponentService.getComponentInfo(auditId, componentId);

    if (type === "savings") {
      const { items, pagination } = yield AuditObservationAndRecommendationService.getObsAndRecList(
        auditId,
        componentId,
        pageNumber,
        pageSize
      );

      yield put(
        AuditObservationAndRecommendationActions.getObsAndRecListSuccess({
          auditObsAndRecList: items,
          obsAndRecListPagination: pagination,
        })
      );
    }

    yield put(AuditComponentActions.getComponentInfoSuccess({ componentInfo }));

    yield put(AuditParameterValuesActions.upsertValuesSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditParameterValuesActions.upsertValuesFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(AuditParameterValuesActions.upsertValues.type, upsertValues)]);
}
