import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.addPartSuccess = false;
  state.updatePartSuccess = false;
  state.deletePartSuccess = {};
}

// Initial Slice
const initialState = {
  // Add part
  addPartLoading: false,
  addPartSuccess: false,
  addPartError: {},

  // Update part
  updatePartLoading: false,
  updatePartSuccess: false,
  updatePartError: {},

  // Delete part
  deletePartLoading: {},
  deletePartSuccess: {},
  deletePartError: {},
};

// Plant Component Parts
const plantComponentParts = createSlice({
  name: "PlantComponentParts",
  initialState,

  reducers: {
    // Add part
    addPart: (state) => {
      state.addPartSuccess = false;
      state.addPartLoading = true;
    },

    addPartSuccess: (state) => {
      state.addPartSuccess = true;
      state.addPartLoading = false;
    },

    addPartFailure: (state, action) => {
      state.addPartLoading = false;
      state.addPartError = action.payload.error;
    },

    // Update part
    updatePart: (state) => {
      state.updatePartSuccess = false;
      state.updatePartLoading = true;
    },

    updatePartSuccess: (state) => {
      state.updatePartSuccess = true;
      state.updatePartLoading = false;
    },

    updatePartFailure: (state, action) => {
      state.updatePartLoading = false;
      state.updatePartError = action.payload.error;
    },

    // Delete part
    deletePart: (state, action) => {
      const { partId } = action.payload;
      state.deletePartSuccess[partId] = false;
      state.deletePartLoading[partId] = true;
      state.deletePartError = "";
    },

    deletePartSuccess: (state, action) => {
      const { partId } = action.payload;
      state.deletePartSuccess[partId] = true;
      delete state.deletePartLoading[partId];
    },

    deletePartFailure: (state, action) => {
      const { partId } = action.payload;
      delete state.deletePartLoading[partId];
      state.deletePartError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantComponentPartsReducer = plantComponentParts.reducer;

// Actions
export const PlantComponentPartsActions = plantComponentParts.actions;
