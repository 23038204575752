export const HSN_CODE_TABLE_HEADER = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Chapter",
    dataIndex: "chapter",
  },
  {
    title: "Heading",
    dataIndex: "heading",
  },
  {
    title: "Sub Heading",
    dataIndex: "subHeading",
  },
  {
    title: "Updated On",
    dataIndex: "updatedOnDate",
  },
];
