import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Constants
import PageURLs from "../../../constants/pageUrls";

// Utils
import TableUtils from "../../../utils/tableUtils";
import URLs from "../../../utils/urlUtils";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Organization Type",
    },
  },
  {
    title: {
      displayName: "Privileges",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function RoleListTableActionCell({ roleId }) {
  // Navigate
  const navigate = useNavigate();

  // Navigate to edit role page
  function navigateToEditRolePage() {
    const redirectUrl = URLs.format(PageURLs.RoleEditPageUrl, { roleId });
    navigate(redirectUrl);
  }

  return (
    <td>
      <Button label="Edit" color="secondary" onClick={navigateToEditRolePage}>
        <i className="fas fa-pencil"></i>
      </Button>
    </td>
  );
}
function RoleListTablePrivilegesCell({ privileges }) {
  // Constructing string of privileges from array
  const privilegesString = privileges?.reduce((acc, value, index) => {
    // For first value comma is not required
    if (index === 0) {
      return `${value}`;
    }
    return `${acc} , ${value}`;
  }, "");

  return <td>{privilegesString}</td>;
}

function RoleListTableRow({ serialNo, role }) {
  const { id, name, description, orgType, privileges } = role;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>
      <td>{description}</td>
      <td>{orgType}</td>

      {/* Role List Table Privileges Cell */}
      <RoleListTablePrivilegesCell privileges={privileges} />

      {/* Role List Table Action Cell */}
      <RoleListTableActionCell roleId={id} />
    </tr>
  );
}

function RoleListTableBody({ pageNumber, pageSize, roleListLoading = false, roleList = [] }) {
  // Loader
  if (roleListLoading) {
    return <TableLoader colSpan={6} />;
  }

  // If there is no role list
  if (roleList.length === 0) {
    return <TableDataNotFound message="No roles present" colSpan={6} />;
  }

  // Role list table row
  return (
    <>
      {roleList.map((role, index) => {
        // Serial number for pagination table
        const serialNo = TableUtils.tableSerialNumber(pageNumber, pageSize, index);

        return (
          <RoleListTableRow key={role.id} serialNo={serialNo} role={role} pageNumber={pageNumber} pageSize={pageSize} />
        );
      })}
    </>
  );
}

/**
 * Role List Table
 */
export default function RoleListTable() {
  // Selector State
  const roleList = useSelector((state) => state.role.roleListMap);
  const roleListLoading = useSelector((state) => state.role.roleListLoading);
  const roleListPagination = useSelector((state) => state.role.roleListPagination);

  const { totalCount, pageNo, pageSize } = roleListPagination || {};

  return (
    <div className="page-content">
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Role List Table Body */}
          <RoleListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            roleListLoading={roleListLoading}
            roleList={roleList}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </div>
  );
}
