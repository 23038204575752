import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { DatePicker } from "antd";

// Actions
import { AuditActions } from "../../../store/slices/audit/auditSlice";
import { OrganizationActions } from "../../../store/slices/organization/organizationSlice";
import { UserActions } from "../../../store/slices/user/userSlice";
import { PlantActions } from "../../../store/slices/plant/plantSlice";

// Constants
import { OrgTypes, DefaultPagination } from "../../../constants/generalConstants";
import PageURLs from "../../../constants/pageUrls";

// Utils
import { toTimestamp } from "../../../utils/dateUtils";

// Components
import { Button } from "../../../components/button/Button";
import SearchDropdown from "../../../components/search-dropdown/SearchDropdown";
import PageHeader from "../../../components/page-header/PageHeader";
import Loader from "../../../components/loader/Loader";
import Label from "../../../components/label/Label";
import ErrorMessage from "../../../components/error-message/ErrorMessage";

// Page Constants
const DEFAULT_INPUT_VALUES = {
  orgId: "",
  plantId: "",
  auditOrgId: "",
  auditInchargeId: "",
  startTimestamp: "",
};

const DEFAULT_STATE_FORM_ERRORS = {
  orgId: false,
  plantId: false,
  auditOrgId: false,
  auditInchargeId: false,
  startTimestamp: false,
};

// Page Components
function AuditCreatePageAutoComplete({ dataListInputItems }) {
  return dataListInputItems.map((dataListInputItem, index) => {
    const { label, value, options, id, placeholder, disabled, onChange, loading, error } = dataListInputItem;

    return (
      <div key={index} className="col-md-6 p-2">
        <label className="form-label">{label}</label>
        {/* Auto Complete */}
        <SearchDropdown
          options={options}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          loading={loading}
          error={error}
        />
      </div>
    );
  });
}

/**
 * Audit Create Page
 */
export default function AuditCreatePage() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // State
  const [auditData, setAuditData] = useState(DEFAULT_INPUT_VALUES);
  const [error, setError] = useState(DEFAULT_STATE_FORM_ERRORS);
  const { startTimestamp = "" } = error;

  const [industryName, setIndutryName] = useState("");
  const [plantName, setPlantName] = useState("");
  const [auditFirm, setAuditFirm] = useState("");
  const [auditor, setAuditor] = useState("");

  // Organization Selector State
  const organizationTypeMap = useSelector((state) => state.organization.organizationTypeMap);
  const organizationListLoading = useSelector((state) => state.organization.organizationListLoading);

  //Plant Selector State
  const plantListMap = useSelector((state) => state.plant.plantListMap);
  const plantList = Object.values(plantListMap);

  const plantListLoading = useSelector((state) => state.plant.plantListLoading);

  // User Selector State
  const userMap = useSelector((state) => state.user.userMap);
  const userList = Object.values(userMap);
  const userListLoading = useSelector((state) => state.user.userListLoading);

  // Audit Create Selector State
  const initiateAuditLoading = useSelector((state) => state.audit.initiateAuditLoading);
  const initiateAuditSuccess = useSelector((state) => state.audit.initiateAuditSuccess);

  // Organization Type
  const { id: industry } = OrgTypes.INDUSTRY;
  const { id: audit } = OrgTypes.AUDIT;

  const industryOrganizationMap = organizationTypeMap[industry];
  const industryOrgList = Object.values(industryOrganizationMap) || [];

  const auditOrganizationMap = organizationTypeMap[audit];
  const auditOrgList = Object.values(auditOrganizationMap) || [];

  //const backbutton config
  const backButtonConfig = { redirectUrl: PageURLs.AuditListPageUrl };

  // use Effect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationList({ orgType: industry, pageSize: DefaultPagination.maxPageSize }));
    dispatch(OrganizationActions.getOrganizationList({ orgType: audit, pageSize: DefaultPagination.maxPageSize }));
  }, [dispatch, audit, industry]);

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeader title={"Create New Audit"} backButtonConfig={backButtonConfig} />);
  }, []);

  useEffect(() => {
    if (initiateAuditSuccess) {
      toast.success("Audit Created Successfully");
      navigate(PageURLs.AuditListPageUrl);
    }
  }, [dispatch, initiateAuditSuccess]);

  // Data list input items
  const dataListInputItems = [
    {
      label: "Industry Name",
      value: industryName,
      options: constructOptions(industryOrgList),
      id: "industry-list",
      placeholder: "Search Industry...",
      disabled: false,
      loading: false,
      error: error.orgId && "Please select organization",
      onChange: industrySearch,
    },
    {
      label: "Plant Name",
      value: plantName,
      options: constructOptions(plantList),
      id: "plant-list",
      placeholder: "Search Plant...",
      disabled: !auditData.orgId,
      loading: plantListLoading,
      error: error.plantId && "Please select plant",
      onChange: plantSearch,
    },
    {
      label: "Audit Firm",
      value: auditFirm,
      options: constructOptions(auditOrgList),
      id: "audit-list",
      placeholder: "Search Audit Firm...",
      disabled: false,
      loading: false,
      error: error.auditOrgId && "Please select audit",
      onChange: auditSearch,
    },
    {
      label: "Auditor",
      value: auditor,
      options: constructOptions(userList, true),
      id: "incharge-list",
      placeholder: "Search Auditor...",
      disabled: !auditData.auditOrgId,
      loading: userListLoading,
      error: error.auditInchargeId && "Please select audit incharge",
      onChange: auditInchargeSearch,
    },
  ];

  //Constructing options for each data list inputs
  function constructOptions(dataList, isInchargeOption = false) {
    //For audit incharge option
    if (isInchargeOption) {
      const optionsArray = dataList?.reduce((acc, data) => {
        const { id = "", firstName = "" } = data;

        return [...acc, { id, value: firstName }];
      }, []);

      return optionsArray;
    }

    //Constructing options array
    const optionsArray = dataList?.reduce((acc, data) => {
      const { id = "", name = "" } = data;

      return [...acc, { id, value: name }];
    }, []);

    return optionsArray;
  }

  function industrySearch(target) {
    // Getting from auto complete
    const { id = "", value = "" } = target;
    setIndutryName(value);

    //If organization is there, api is called to get plant list
    if (id) {
      setAuditData({ ...auditData, orgId: id });
      dispatch(PlantActions.getPlantList({ orgId: id, pageSize: DefaultPagination.maxPageSize }));
    } else {
      setAuditData({ ...auditData, orgId: "" });
    }
  }

  function plantSearch(target) {
    // Getting from auto complete
    const { id = "", value = "" } = target;
    setPlantName(value);

    //If plant is there, plant id stored in state
    if (id) {
      setAuditData({ ...auditData, plantId: id });
    } else {
      setAuditData({ ...auditData, plantId: "" });
    }
  }

  function auditSearch(target) {
    // Getting from auto complete
    const { id = "", value = "" } = target;
    setAuditFirm(value);

    //If organization is there, api is called to get user list and org id is stored in state
    if (id) {
      setAuditData({ ...auditData, auditOrgId: id });
      dispatch(UserActions.getUserList({ orgId: id, pageSize: DefaultPagination.maxPageSize }));
    } else {
      setAuditData({ ...auditData, auditOrgId: "" });
    }
  }

  function auditInchargeSearch(target) {
    // Getting from auto complete
    const { id = "", value = "" } = target;
    setAuditor(value);

    //If user is there, user is stored in state
    if (id) {
      setAuditData({ ...auditData, auditInchargeId: id });
    } else {
      setAuditData({ ...auditData, auditInchargeId: "" });
    }
  }

  function handleDateSelection(date = "") {
    setAuditData((prev) => ({ ...prev, startTimestamp: toTimestamp(date) }));
  }

  //Error check for audit data
  function checkError() {
    const cloneDefaultErrors = { ...DEFAULT_STATE_FORM_ERRORS };

    //Checking for empty input
    Object.keys(error).forEach((eachKey) => {
      if (!auditData[eachKey]) {
        cloneDefaultErrors[eachKey] = true;
        return;
      }

      cloneDefaultErrors[eachKey] = false;
      return;
    });

    setError(cloneDefaultErrors);

    //If any one of the input has error, it will return true
    const isError = Object.keys(cloneDefaultErrors)?.some((eachKey) => cloneDefaultErrors[eachKey]);

    return isError;
  }

  //Inintiate new audit submit button
  function intiateNewAudit() {
    const isError = checkError();

    //If there is no error, dispatch is called
    if (!isError) {
      //Dispatch
      dispatch(AuditActions.initiateAudit({ auditData }));
    }
  }

  // Loader
  if (organizationListLoading) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      <div className="row w-50 mb-2">
        <div className="col-6">
          <Label title="Start Date" />

          <DatePicker
            status={startTimestamp ? "error" : ""}
            className="w-100 rounded-0 shadow-none cursor-pointer"
            placeholder="Start date"
            onChange={handleDateSelection}
          />

          {startTimestamp && <ErrorMessage error="Select audit start date" />}
        </div>
      </div>
      <div className="row w-50">
        {/* Audit Create Page Auto Complete */}
        <AuditCreatePageAutoComplete dataListInputItems={dataListInputItems} />

        <div className="btn-cont mt-4">
          {/* Button */}
          <Button
            type="submit"
            label="Initiate new Audit"
            onClick={intiateNewAudit}
            loading={initiateAuditLoading}
            disabled={initiateAuditLoading}
          />
        </div>
      </div>
    </div>
  );
}
