import { createSlice } from "@reduxjs/toolkit";

// Transformer
import HSNCodeTransformer from "./HSNCodeTransformer";

// Initial State
const initialState = {
  // Get HSN Code - List
  hsnCodeListMap: [],
  hsnCodeListPagination: {},
  hsnCodeListLoading: false,
  hsnCodeListSuccess: false,
  hsnCodeListError: "",
};

// HSN Code
const HSNCodeSlice = createSlice({
  name: "HSNCode",
  initialState,

  reducers: {
    // Get HSN Code List
    getHSNCodeList: (state) => {
      state.hsnCodeListSuccess = false;
      state.hsnCodeListLoading = true;
    },

    getHSNCodeListSuccess: (state, action) => {
      const { hsnCodeList = [], hsnCodeListPagination = {} } = action.payload;
      state.hsnCodeListMap = HSNCodeTransformer.transformList(hsnCodeList);

      state.hsnCodeListPagination = hsnCodeListPagination;
      state.hsnCodeListLoading = false;
      state.hsnCodeListSuccess = true;
    },

    getHSNCodeListFailure: (state, action) => {
      state.hsnCodeListLoading = false;
      state.hsnCodeListError = action.payload.error;
    },
  },
});

// Reducer
export const HSNCodeReducer = HSNCodeSlice.reducer;

// Actions
export const HSNCodeActions = HSNCodeSlice.actions;
