import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// Actions
import { DataPreLoadActions } from "../store/slices/data-pre-load/dataPreLoadSlice";

// Constants
import PageURLs from "../constants/pageUrls";
import { LocalStorageKeys } from "../constants/localStorageConstants";

// Utils
import LocalStorage from "../utils/localStorageUtils";
import TokenUtils from "../utils/tokenUtils";
import getTheme from "../assets/themes/AppThemes";

// Components
import DataPreLoader from "../components/data-pre-loader/DataPreLoader";

// Private Methods
// --------------------------------------------------------------------------

function _isUserAuthenticated(user) {
  if (Object.keys(user).length < 1) {
    return false;
  }
  const userPrivileges = user.privileges || [];

  // TODO : For Now userPriviliges.length >= 0 because its empty.
  const isUserAuthenticated = user.token && userPrivileges.length >= 0 && !TokenUtils.isTokenExpired(user.token);
  return isUserAuthenticated;
}

/**
 * Routes which needs the USER Authenticated.
 */
function ProtectedRoute({
  children,
  preLoaderTitle = "KISEM",
  loginPageUrl = PageURLs.LoginPageUrl,
  dataPreLoadObj = {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Location
  const { pathname } = useLocation();

  // Get Authenticated User from local storage
  const user = JSON.parse(LocalStorage.get(LocalStorageKeys.AUTH_USER) || "{}") || {};

  // State
  const dataPreLoading = useSelector((state) => state.dataPreLoad.dataPreLoading);

  useEffect(() => {
    //TODO : Clear error state in reducer here

    // Checking For Pre Loaded Data In Local Storage
    Object.keys(dataPreLoadObj).map((eachKey) => {
      // Getting pre loaded data from local storage
      const value = JSON.parse(LocalStorage.get(eachKey) || '""');

      /** If user is authenticated,
      then it will check whether the corresponding data is already present in localstorage or not, 
      if the data is not present, then that api is called and data is stored in local storage **/
      if (_isUserAuthenticated(user) && (!value || Object.values(value).length === 0)) {
        const apiUrl = dataPreLoadObj[eachKey] || "";

        dispatch(DataPreLoadActions.dataPreLoad({ key: eachKey, apiUrl }));
      }
    });
  }, [dispatch, pathname]);

  //If user is not authenticated
  if (!_isUserAuthenticated(user)) {
    localStorage.clear();
    return <Navigate to={loginPageUrl} />; // TODO
  }

  //Loader
  if (dataPreLoading) {
    return <DataPreLoader title={preLoaderTitle} />; //TODO
  }

  // Theme
  const themeObj = getTheme();
  const themeId = themeObj?.id;

  return <div data-theme={themeId}>{children}</div>;
}

export default ProtectedRoute;
