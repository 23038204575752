import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// Constants
import PageURLs from "./constants/pageUrls";

// CSS and JS
import "./App.scss";
import "bootstrap/dist/js/bootstrap.js";

// Pages
import LoginPage from "./pages/auth/LoginPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ErrorPage from "./pages/error/ErrorPage";

// Dev Pages
import BootstrapThemePage from "./pages/dev/BootstrapThemePage";
import CustomComponentsPage from "./pages/dev/CustomComponentsPage";

// Routes
import { MainPageRoutes } from "./routes/mainPageRoutes";
import DefPageRoutes from "./routes/defPageRoutes";
import { AuditRoutes } from "./routes/auditRoutes";
import { PlantRoutes } from "./routes/plantRoutes";
import { UserRoutes } from "./routes/userRoutes";

//Page Components
const router = createBrowserRouter([
  // Main Page Routes
  MainPageRoutes,

  // Definitions
  DefPageRoutes,

  // Audit Routes
  AuditRoutes,

  // Plant Routes
  PlantRoutes,

  // User Routes
  UserRoutes,

  {
    path: PageURLs.LoginPageUrl,
    element: <LoginPage />,
  },
  {
    path: PageURLs.ForgotPasswordPageUrl,
    element: <ForgotPasswordPage />,
  },
  {
    path: PageURLs.ErrorPageUrl,
    element: <ErrorPage />,
  },

  // Development Pages
  {
    path: "/dev/theme",
    element: <BootstrapThemePage />,
  },
  {
    path: "/dev/component",
    element: <CustomComponentsPage />,
  },
]);

function App() {
  const logOutSuccess = useSelector((state) => state.auth.logOutSuccess);

  // use Effect
  useEffect(() => {
    if (logOutSuccess) {
      router.navigate(PageURLs.LoginPageUrl);
    }
  }, [logOutSuccess]);

  return <RouterProvider router={router} />;
}

export default App;
