import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.createComponentLinkSuccess = false;
  state.updateComponentLinkSuccess = false;
}

// Initial State
const initialState = {
  summary: {},
  getSummarySuccess: false,
  getSummaryLoading: false,
  getSummaryError: "",

  // Create Component link
  createComponentLinkLoading: false,
  createComponentLinkSuccess: false,
  createComponentLinkError: "",

  // Update Component link
  updateComponentLinkLoading: false,
  updateComponentLinkSuccess: false,
  updateComponentLinkError: "",

  // Delete Component link
  deleteComponentLinkLoading: false,
  deleteComponentLinkSuccess: false,
  deleteComponentLinkError: "",

  // Reset API Success State
  resetApiSuccessState,
};

// Plant Component Link Slice
const plantComponentLinkSlice = createSlice({
  name: "PlantComponentLink",
  initialState,

  reducers: {
    // Get Summary
    getSummary: (state) => {
      state.getSummarySuccess = false;
      state.getSummaryLoading = true;
      state.getSummaryError = {};
    },

    getSummarySuccess: (state, action) => {
      const { summary = {} } = action.payload;

      state.summary = summary;
      state.getSummarySuccess = true;
      state.getSummaryLoading = false;
    },

    getSummaryFailure: (state, action) => {
      state.getSummaryLoading = false;
      state.getSummaryError = action.payload.error;
    },

    // Create Component Link
    createComponentLink: (state) => {
      state.createComponentLinkSuccess = false;
      state.createComponentLinkLoading = true;
      state.createComponentLinkError = {};
    },

    createComponentLinkSuccess: (state) => {
      state.createComponentLinkSuccess = true;
      state.createComponentLinkLoading = false;
    },

    createComponentLinkFailure: (state, action) => {
      state.createComponentLinkLoading = false;
      state.createComponentLinkError = action.payload.error;
    },

    // Update Component Link
    updateComponentLink: (state) => {
      state.updateComponentLinkSuccess = false;
      state.updateComponentLinkLoading = true;
      state.updateComponentLinkError = {};
    },

    updateComponentLinkSuccess: (state) => {
      state.updateComponentLinkSuccess = true;
      state.updateComponentLinkLoading = false;
    },

    updateComponentLinkFailure: (state, action) => {
      state.updateComponentLinkLoading = false;
      state.updateComponentLinkError = action.payload.error;
    },

    // Delete Component Link
    deleteComponentLink: (state) => {
      state.deleteComponentLinkSuccess = false;
      state.deleteComponentLinkLoading = true;
      state.deleteComponentLinkError = {};
    },

    deleteComponentLinkSuccess: (state) => {
      state.deleteComponentLinkSuccess = true;
      state.deleteComponentLinkLoading = false;
    },

    deleteComponentLinkFailure: (state, action) => {
      state.deleteComponentLinkLoading = false;
      state.deleteComponentLinkError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantComponentLinkReducer = plantComponentLinkSlice.reducer;

// Actions
export const PlantComponentLinkActions = plantComponentLinkSlice.actions;
