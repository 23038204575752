import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { toast } from "react-toastify";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/useUpdateQueryParams";

// Actions
import { PlantPowerConnectionActions } from "../../../../store/slices/plant/powerConnectionSlice";

// Constants
import {
  PowerConnectionFormSchema,
  PowerConnectionFormValidationSchema,
} from "../../../../constants/powerConnectionConstants";
import { QueryParamsKeys } from "../../../../constants/generalConstants";

// Components
import { Button } from "../../../../components/button/Button";
import Form, { FormTypes } from "../../../../components/form/Form";

// page Components
import PlantPowerConnectionListTable from "./PlantPowerConnectionListTable";

const MODAL_SIZE = 400;

// Page Component
function PlantPowerConnectionPageHeader({ setSelectedPowerConnection, setShowCreatePowerConnectionModal }) {
  function openAddElectricityBillModal() {
    setSelectedPowerConnection({});
    setShowCreatePowerConnectionModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between my-3">
      <div className="col-3">
        <h4>Power Connection</h4>
      </div>
      <Button label="Add Power Connection" color="dark" onClick={openAddElectricityBillModal}>
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Plant Power Connection Tab Content
 */
export default function PlantPowerConnectionTabContent() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // State
  const [selectedPowerConnection, setSelectedPowerConnection] = useState({});
  const [showViewPowerConnectionModal, setShowViewPowerConnectionModal] = useState(false);
  const [showCreatePowerConnectionModal, setShowCreatePowerConnectionModal] = useState(false);

  const { id = "" } = selectedPowerConnection;

  // Create Power Connection Selector State
  const createPowerConnectionLoading = useSelector((state) => state.plantPowerConnection.createPowerConnectionLoading);
  const createPowerConnectionSuccess = useSelector((state) => state.plantPowerConnection.createPowerConnectionSuccess);

  // Update Power Connection Selector State
  const updatePowerConnectionLoading = useSelector((state) => state.plantPowerConnection.updatePowerConnectionLoading);
  const updatePowerConnectionSuccess = useSelector((state) => state.plantPowerConnection.updatePowerConnectionSuccess);

  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // Function for adding Power Connection
  function createPowerConnection(powerConnectionData) {
    const formData = { ...powerConnectionData, plantId };

    // Dispatch
    dispatch(PlantPowerConnectionActions.createPowerConnection({ formData, plantId }));
  }

  // Function for editing Power Connection
  function editPowerConnection(powerConnectionData) {
    const formData = { ...powerConnectionData, plantId };

    // Dispatch
    dispatch(
      PlantPowerConnectionActions.updatePowerConnection({
        formData,
        plantId,
        connectionId: id,
      })
    );
  }

  // Use Effect
  useEffect(() => {
    dispatch(
      PlantPowerConnectionActions.getPowerConnectionList({
        plantId,
        searchText,
      })
    );
  }, [dispatch, plantId, searchText]);

  useEffect(() => {
    return () => dispatch(PlantPowerConnectionActions.resetAllApiSuccessState());
  }, [dispatch]);

  useEffect(() => {
    if (createPowerConnectionSuccess && !id) {
      setShowCreatePowerConnectionModal(false);
      toast.success("Power Connection Added Successfully");
    }
  }, [dispatch, id, createPowerConnectionSuccess]);

  useEffect(() => {
    if (updatePowerConnectionSuccess && id) {
      setShowViewPowerConnectionModal(false);
      toast.success("Power Connection Updated Successfully");
    }
  }, [dispatch, id, updatePowerConnectionSuccess]);

  return (
    <div className="page-content">
      {/* Power Connection Bills Page Header */}
      <PlantPowerConnectionPageHeader
        setSelectedPowerConnection={setSelectedPowerConnection}
        setShowCreatePowerConnectionModal={setShowCreatePowerConnectionModal}
      />

      <div className="mt-4 table-responsive">
        {/* Power Connection Bill List Table */}
        <PlantPowerConnectionListTable
          setSelectedPowerConnection={setSelectedPowerConnection}
          setShowViewPowerConnectionModal={setShowViewPowerConnectionModal}
        />
      </div>

      {/* Create Power Connection Modal */}
      <Modal
        title="Create Power Connection"
        open={showCreatePowerConnectionModal}
        width={MODAL_SIZE}
        onCancel={() => setShowCreatePowerConnectionModal(false)}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          type={FormTypes.CREATE}
          formInputItems={PowerConnectionFormSchema}
          formValidationSchema={PowerConnectionFormValidationSchema}
          formSubmit={createPowerConnection}
          loading={createPowerConnectionLoading && !id}
          showCancelButton={false}
          showModal={showCreatePowerConnectionModal}
        />
      </Modal>

      {/* View Power Connection Modal */}
      <Modal
        title="View Power Connection"
        open={showViewPowerConnectionModal}
        width={MODAL_SIZE}
        onCancel={() => setShowViewPowerConnectionModal(false)}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          data={selectedPowerConnection}
          formInputItems={PowerConnectionFormSchema}
          formValidationSchema={PowerConnectionFormValidationSchema}
          formSubmit={editPowerConnection}
          loading={updatePowerConnectionLoading && id}
          showModal={showViewPowerConnectionModal}
        />
      </Modal>
    </div>
  );
}
