import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Background, Controls, Panel, ReactFlow, ReactFlowProvider } from "@xyflow/react";

// Actions
import { NodeActions } from "./slice/node/nodeSlice";
import { EdgeActions } from "./slice/edge/edgeSlice";

// Custom Hooks
import { useOnNodesChanges } from "./hooks/useUpdateNodes";
import { useOnEdgesChanges } from "./hooks/useUpdateEdges";

// Constants
import { NODE_TYPE, POSITION } from "./constants/reactFlowConstants";

// Utils
import GraphUtil from "./GraphUtils";

// Config :: Graphs
import GraphConfig from "./GraphConfig";

// Page Constants
const nodeTypes = GraphConfig.NodeTypeComponentMap;

/**
 * React Flow Wrapper
 */
export default function ReactFlowWrapper({ panelConfig = {}, reactFlowFunctions = {} }) {
  const { position = POSITION.TopLeft, panelComponent = <></> } = panelConfig;
  const {
    onNodesChange = () => {},
    onNodesDragStop = () => {},
    onEdgesChange = () => {},
    onEdgesConnect = () => {},
    onEdgesDelete = () => {},
  } = reactFlowFunctions;

  // Dispatch
  const dispatch = useDispatch();

  // Selector States
  const nodesMap = useSelector((state) => state.node.nodesMap);
  const nodes = Object.values(nodesMap);

  const edgesMap = useSelector((state) => state.edge.edgesMap);
  const edges = Object.values(edgesMap);

  // Page Data
  const handleUids = useMemo(() => {
    // Create Array of Handle UIDs
    const handleUids = edges?.reduce((arr, edge) => {
      // Edge Info
      const { source, sourceHandle, target, targetHandle } = edge;
      const srcHandleUid = GraphUtil.toHandleUid(source, sourceHandle);
      const tarHandleUid = GraphUtil.toHandleUid(target, targetHandle);

      return [...arr, srcHandleUid, tarHandleUid];
    }, []);

    return handleUids;
  }, [edges]);

  // ID generator related (pre-compute for other utils)

  // Nodes Summary
  // const { nodesCount, lastNodeIdx } = useMemo(() => {
  //   const nodesCount = nodes.length;

  //   const nodeIds = nodes.map((e) => e.id);
  //   const sortedNodeIdx = nodeIds //
  //     .map((sym) => Number(sym.substring(1)))
  //     .sort((a, b) => a - b);

  //   const lastNodeIdx = nodesCount === 0 ? 0 : sortedNodeIdx[sortedNodeIdx.length - 1];

  //   return { nodesCount, lastNodeIdx };
  // }, [nodes]);

  // Page Functions

  // Node :: Add
  // const addNewNode = useCallback(
  //   (nodeType) => {
  //     // Prepare New Node
  //     const newNode = GraphUtil.prepareNewNode(nodeType, lastNodeIdx, nodesCount);
  //     dispatch(NodeActions.createNode({ node: newNode }));
  //   },
  //   [nodesCount, lastNodeIdx, dispatch]
  // );

  // Node :: Delete
  const deleteNode = useCallback(
    (nodes = []) => {
      // Iterate over Nodes
      nodes.forEach(({ id = "" }) => {
        dispatch(NodeActions.deleteNode({ nodeUid: id }));
      });
    },
    [dispatch]
  );

  // Edge :: Delete
  const deleteEdge = useCallback(
    (edges = []) => {
      // Iterate over Nodes
      edges.forEach((deletedEdge) => {
        const { id = "" } = deletedEdge;

        dispatch(EdgeActions.deleteEdge({ edgeUid: id }));

        onEdgesDelete(deletedEdge);
      });
    },
    [dispatch, onEdgesDelete]
  );

  // Edge :: Add
  const onConnect = useCallback(
    (params) => {
      const { source, sourceHandle, target, targetHandle } = params;
      const edgeUid = GraphUtil.toEdgeId(source, sourceHandle, target, targetHandle);
      // edge
      const newEdge = {
        ...params,
        id: edgeUid,
        type: "default",
        animated: true,
      };

      dispatch(EdgeActions.createEdge({ edge: newEdge }));

      onEdgesConnect(newEdge);
    },
    [dispatch, onEdgesConnect]
  );

  // Connection :: Validation
  const isValidConnection = useCallback(
    (connection) => {
      // Connection Related Info
      const { source: srcNodeId, sourceHandle: srcHandleId, target: tarNodeId, targetHandle: tarHandleId } = connection;

      // Source, Target Node Info
      const { type: srcNodeType } = nodesMap[srcNodeId];
      const { type: tarNodeType } = nodesMap[tarNodeId];

      // 01 : Self Connection is not allowed
      if (srcNodeId === tarNodeId) {
        return false;
      }

      // 02 : Input to Output Connection is not allowed
      if (srcNodeType === NODE_TYPE.INPUT && tarNodeType === NODE_TYPE.OUTPUT) {
        return false;
      }

      // 03 : Multiple connections from the same handle are not allowed
      const srcHandleUid = GraphUtil.toHandleUid(srcNodeId, srcHandleId);
      const tarHandleUid = GraphUtil.toHandleUid(tarNodeId, tarHandleId);
      if (handleUids.includes(srcHandleUid) || handleUids.includes(tarHandleUid)) {
        return false;
      }

      // 04: Prevent multiple connections between the same nodes with different handles
      const existingConnection = edges.find(
        (edge) =>
          edge.source === srcNodeId &&
          edge.target === tarNodeId &&
          !(edge.sourceHandle === srcHandleId && edge.targetHandle === tarHandleId)
      );
      if (existingConnection) {
        return false;
      }

      return true;
    },
    [nodesMap, handleUids, edges]
  );

  return (
    <ReactFlowProvider>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={useOnNodesChanges(onNodesChange)}
        onEdgesChange={useOnEdgesChanges(onEdgesChange)}
        onNodesDelete={deleteNode}
        onEdgesDelete={deleteEdge}
        nodeTypes={nodeTypes}
        deleteKeyCode={["Delete"]}
        isValidConnection={isValidConnection}
        onConnect={onConnect}
        onNodeDragStop={onNodesDragStop}
        fitView={true}
        snapGrid={true}
        minZoom={1}
      >
        <Background variant="dots" size={1.2} gap={12} />
        <Controls position={POSITION.BottomRight} />

        {/* Menu Panel */}
        <Panel position={position}>{panelComponent}</Panel>
      </ReactFlow>
    </ReactFlowProvider>
  );
}
