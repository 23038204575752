import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { PlantActions } from "../../slices/plant/plantSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import PlantService from "../../../services/plant/plantService";

// Get Plant List
function* getPlantList(action) {
  try {
    const { orgId, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield PlantService.getPlantList(orgId, pageNumber, pageSize, searchText);

    yield put(
      PlantActions.getPlantListSuccess({
        plantList: items,
        plantListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.getPlantListFailure({ error }));
  }
}

// Get Plant Info
function* getPlantInfo(action) {
  try {
    const { plantId } = action.payload;

    const plantInfo = yield PlantService.getPlantInfo(plantId);

    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.getPlantInfoFailure({ error }));
  }
}

// Add Plant
function* addPlant(action) {
  try {
    const { plantData } = action.payload;

    yield PlantService.addPlant(plantData);

    yield put(PlantActions.addPlantSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.addPlantFailure({ error }));
  }
}

// Update Plant
function* updatePlant(action) {
  try {
    const { plantData, plantId } = action.payload;

    yield PlantService.updatePlant(plantData, plantId);

    const plantInfo = yield PlantService.getPlantInfo(plantId);

    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));

    yield put(PlantActions.updatePlantSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.updatePlantFailure({ error }));
  }
}

// Update Plant Status
function* updatePlantStatus(action) {
  try {
    const { orgId, plantId, status, pageNumber, pageSize } = action.payload;

    yield PlantService.updatePlantStatus(plantId, status);

    const { items, pagination } = yield PlantService.getPlantList(orgId, pageNumber, pageSize);

    yield put(
      PlantActions.getPlantListSuccess({
        plantList: items,
        plantListPagination: pagination,
      })
    );

    yield put(PlantActions.updatePlantStatusSuccess({ plantId }));
  } catch (error) {
    const { plantId } = action.payload;

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.updatePlantStatusFailure({ error, plantId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantActions.getPlantList.type, getPlantList),
    takeLatest(PlantActions.getPlantInfo.type, getPlantInfo),
    takeLatest(PlantActions.addPlant.type, addPlant),
    takeLatest(PlantActions.updatePlant.type, updatePlant),
    takeEvery(PlantActions.updatePlantStatus.type, updatePlantStatus),
  ]);
}
