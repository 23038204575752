import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditFileActions } from "../../slices/audit/fileSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import AuditFileService from "../../../services/audit/fileService";

// Get File List
function* getFileList(action) {
  try {
    const { auditId, pageNumber, pageSize, type = "" } = action.payload;

    const { items, pagination } = yield AuditFileService.getFileList(auditId, pageNumber, pageSize, type);

    yield put(
      AuditFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.getFileListFailure({ error }));
  }
}

// Create File
function* createFile(action) {
  const { auditId, pageNumber, pageSize, formData, type = "" } = action.payload;

  try {
    yield AuditFileService.createFile(auditId, formData);

    const { items, pagination } = yield AuditFileService.getFileList(auditId, pageNumber, pageSize, type);

    yield put(
      AuditFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(AuditFileActions.createFileSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.createFileFailure({ error }));
  }
}

// Update File
function* updateFile(action) {
  const { auditId, fileId, pageNumber, pageSize, formData, type = "" } = action.payload;

  try {
    yield AuditFileService.updateFile(auditId, fileId, formData);

    const { items, pagination } = yield AuditFileService.getFileList(auditId, pageNumber, pageSize, type);

    yield put(
      AuditFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(AuditFileActions.updateFileSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.updateFileFailure({ error }));
  }
}

// Download File
function* downloadFile(action) {
  const { fileId, apiUrl = "" } = action.payload;

  try {
    const downloadFileData = yield AuditFileService.downloadFile(apiUrl);

    yield put(AuditFileActions.downloadFileSuccess({ fileId, downloadFileData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.downloadFileFailure({ fileId, error }));
  }
}

// Delete File
function* deleteFile(action) {
  const { auditId, fileId, pageNumber, pageSize, type = "" } = action.payload;

  try {
    yield AuditFileService.deleteFile(auditId, fileId);

    const { items, pagination } = yield AuditFileService.getFileList(auditId, pageNumber, pageSize, type);

    yield put(
      AuditFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(AuditFileActions.deleteFileSuccess({ fileId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditFileActions.deleteFileFailure({ fileId, error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(AuditFileActions.getFileList.type, getFileList),
    takeLatest(AuditFileActions.createFile.type, createFile),
    takeLatest(AuditFileActions.updateFile.type, updateFile),
    takeLatest(AuditFileActions.downloadFile.type, downloadFile),
    takeLatest(AuditFileActions.deleteFile.type, deleteFile),
  ]);
}
