// Power Connection Transformers
function transformObj(powerConnectionobj = {}) {
  const {
    id = "",
    isActive = true,
    plantId = "",
    serviceNo = "",
    type = "",
    updatedOn = "",
    version = "",
  } = powerConnectionobj;
  const transformedPowerConnection = {
    id: id,
    isActive: isActive,
    plantId: plantId,
    serviceNo: serviceNo,
    type: type,
    updatedOn: updatedOn,
    version: version,
  };
  return transformedPowerConnection;
}

function transformList(powerConnectionList = []) {
  let powerConnectionListMap = {};

  powerConnectionList.forEach((obj) => {
    const { id = "" } = obj;

    powerConnectionListMap[id] = { key: id, ...transformObj(obj) };
  });

  return powerConnectionListMap;
}

// Exports
const PowerConnectionTransformer = {
  transformObj,
  transformList,
};

export default PowerConnectionTransformer;
