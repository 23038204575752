import { createSlice } from "@reduxjs/toolkit";
import InstrumentTransformer from "./instrumentTransformer";

// Initial State
const initialState = {
  // Get Instrument list
  instrumentListMap: [],
  instrumentListLoading: false,
  instrumentListPagination: {},
  instrumentListError: "",

  // Get Instrument Info
  instrumentInfo: {},
  instrumentInfoLoading: false,
  instrumentInfoError: "",

  // Update Instrument Status (Enable/Disable)
  updateInstrumentStatusLoading: {},
  updateInstrumentStatusError: "",

  // Get Data Column Info
  dataColumnInfo: [],
  dataColumnInfoLoading: false,
  dataColumnInfoError: "",
};

// Instrument Slice
const instrumentSlice = createSlice({
  name: "Instrument",
  initialState,

  reducers: {
    // Get Instrument List
    getInstrumentList: (state) => {
      state.instrumentListLoading = true;
    },

    getInstrumentListSuccess: (state, action) => {
      const { instrumentList = [], instrumentListPagination = {} } = action.payload;
      state.instrumentListMap = InstrumentTransformer.transformList(instrumentList);

      state.instrumentListPagination = instrumentListPagination;
      state.instrumentListLoading = false;
    },

    getInstrumentListFailure: (state, action) => {
      state.instrumentListLoading = false;
      state.instrumentListError = action.payload.error;
    },

    // Get Instrument Info
    getInstrumentInfo: (state) => {
      state.instrumentInfoLoading = true;
    },

    getInstrumentInfoSuccess: (state, action) => {
      const { instrumentInfo = [] } = action.payload;
      state.instrumentInfo = InstrumentTransformer.transformObj(instrumentInfo);

      state.instrumentInfoLoading = false;
    },

    getInstrumentInfoFailure: (state, action) => {
      state.instrumentInfoLoading = false;
      state.instrumentInfoError = action.payload.error;
    },

    // Update Instrument Status (Enable/Disable)
    updateInstrumentStatus: (state, action) => {
      const { instrumentId } = action.payload;
      state.updateInstrumentStatusLoading[instrumentId] = true;
      state.updateInstrumentStatusError = "";
    },

    updateInstrumentStatusSuccess: (state, action) => {
      const { instrumentId } = action.payload;
      delete state.updateInstrumentStatusLoading[instrumentId];
    },

    updateInstrumentStatusFailure: (state, action) => {
      const { instrumentId, error } = action.payload;
      delete state.updateInstrumentStatusLoading[instrumentId];
      state.updateInstrumentStatusError = error;
    },

    // Get Data Column Info
    getDataColumnInfo: (state) => {
      state.dataColumnInfoLoading = true;
    },

    getDataColumnInfoSuccess: (state, action) => {
      state.dataColumnInfoLoading = false;
      state.dataColumnInfo = action.payload.dataColumnInfo;
    },

    getDataColumnInfoFailure: (state, action) => {
      state.dataColumnInfoLoading = false;
      state.dataColumnInfoError = action.payload.error;
    },
  },
});

// Reducer
export const InstrumentReducer = instrumentSlice.reducer;

// Actions
export const InstrumentActions = instrumentSlice.actions;
