import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { RoleActions } from "../../slices/role/roleSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Services
import RoleService from "../../../services/role/roleService";

// Get Role List
function* getRoleList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield RoleService.getRoleList(pageNumber, pageSize, searchText);

    yield put(RoleActions.getRoleListSuccess({ roleList: items, roleListPagination: pagination }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.getRoleListFailure({ error }));
  }
}

// Get Privileges List
function* getPrivilegesList() {
  try {
    const privilegesList = yield RoleService.getPrivilegesList();

    yield put(RoleActions.getPrivilegesListSuccess({ privilegesList }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.getPrivilegesListFailure({ error }));
  }
}

// Get Role Information By Id
function* getRoleInfo(action) {
  try {
    const { roleId } = action.payload;

    const roleInfo = yield RoleService.getRoleInfo(roleId);

    yield put(RoleActions.getRoleInfoSuccess({ roleInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.getRoleInfoFailure({ error }));
  }
}

// Create Role By Id
function* createRole(action) {
  try {
    const { roleData } = action.payload;

    yield RoleService.createRole(roleData);

    const { items, pagination } = yield RoleService.getRoleList();

    yield put(RoleActions.getRoleListSuccess({ roleList: items, roleListPagination: pagination }));

    yield put(RoleActions.createRoleSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.createRoleFailure({ error }));
  }
}

// Update Role By Id
function* updateRole(action) {
  try {
    const { roleId, roleData } = action.payload;

    yield RoleService.updateRole(roleId, roleData);

    const { items, pagination } = yield RoleService.getRoleList();

    yield put(RoleActions.getRoleListSuccess({ roleList: items, roleListPagination: pagination }));

    yield put(RoleActions.updateRoleSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(RoleActions.updateRoleFailure());
  }
}

export default function* root() {
  yield all([
    takeLatest(RoleActions.getRoleList.type, getRoleList),
    takeLatest(RoleActions.getPrivilegesList.type, getPrivilegesList),
    takeLatest(RoleActions.getRoleInfo.type, getRoleInfo),
    takeLatest(RoleActions.createRole.type, createRole),
    takeLatest(RoleActions.updateRole.type, updateRole),
  ]);
}
