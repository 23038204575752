import { useSelector } from "react-redux";

// Components
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";

// Helper
import MeasurementUnitsTableHelper from "../../../../helpers/measurement-type/measurementUnitsTableHelper";

// Css
import "./MeasurementUnitsTable.scss";

/**
 * Measurement Units Table
 */
export default function MeasurementUnitsTable() {
  // Measurement Units Selector State
  const measurementUnitsMap = useSelector((state) => state.measurementType.measurementUnitsMap);
  const measurementUnitsList = Object.values(measurementUnitsMap);
  const measurementUnitsListLoading = useSelector((state) => state.measurementType.measurementUnitsListLoading);

  const columns = MeasurementUnitsTableHelper.getHeaders();

  return (
    <>
      {measurementUnitsListLoading && <SkeletonTable columnCount={2} rowCount={1} />}
      {!measurementUnitsListLoading && (
        <DataTable className="measurements-units-table" columns={columns} rows={measurementUnitsList} />
      )}
    </>
  );
}
