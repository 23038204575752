// Constants
import { ContentType } from "../../constants/httpConstants";
import { DefaultPagination } from "../../constants/generalConstants";
import { AuditFileUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Get File List
async function getFileList(auditId = "", pageNumber, pageSize, type = "") {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (type) {
    params.type = type;
  }

  // Api Url
  const apiUrl = URLs.format(AuditFileUrls.getFileList, { auditId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiUrl, params);
}

// Create File
async function createFile(auditId, formData) {
  // Api Url
  const apiUrl = URLs.format(AuditFileUrls.createFile, { auditId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiUrl, {}, formData, ContentType.MULTIPART_FORM_DATA);
}

// Update File
async function updateFile(auditId, fileId, formData) {
  // Api Url
  const apiUrl = URLs.format(AuditFileUrls.updateFile, { auditId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiUrl, {}, formData);
}

// Download File
async function downloadFile(apiUrl) {
  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.downloadPublicFile(apiUrl);
}

// Delete Audit File By Id
export async function deleteFile(auditId, fileId) {
  // Api Url
  const apiUrl = URLs.format(AuditFileUrls.deleteFile, { auditId, fileId });

  // Http Client
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiUrl);
}

const AuditFileService = {
  getFileList,
  createFile,
  updateFile,
  downloadFile,
  deleteFile,
};

export default AuditFileService;
