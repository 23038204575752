import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../hooks/useUpdateQueryParams";

// Constants
import { QueryParamsKeys } from "../../constants/generalConstants";

// Utils
import LocalStorage from "../../utils/localStorageUtils";

// Components
import PageHeader from "../../components/page-header/PageHeader";
import Input from "../../components/input/Input";
import SelectInput from "../../components/select-input/SelectInput";

// Section
import UnitConversionToolTable from "./UnitConversionToolTable";

// Page Constants
const PAGE_TITLE = "Unit Conversion Tool";

// Page Components
function UnitConversionToolTabs({ tabs, unitTypeName, updateQueryParams }) {
  // Function to update search params
  function updateSearchParams(name) {
    const params = { tabName: name };

    updateQueryParams({ params });
  }

  return (
    <ul className="nav nav-pills flex-column">
      {tabs.map((tabName, index) => {
        return (
          <li key={index} className="nav-item" role="presentation">
            <Link
              className={`nav-link ${unitTypeName === tabName ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#tab-comp-1"
              role="tab"
              onClick={() => updateSearchParams(tabName)}
            >
              {tabName}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

function UnitConversionToolInput({ unitList }) {
  // State
  const [srcValue, setSrcValue] = useState(0);
  const [fromUnit, setFromUnit] = useState(unitList[0]);

  // Generating Select Options
  const unitOptions = unitList.map(({ id = "", name = "" }) => ({ id, value: name }));

  // Function to handle Select Options
  function handleSelectChange(e) {
    const selectedUnitId = e.target.value;
    // Finding the selected unit from the unit list by id
    const selectedUnit = unitList.find((unit) => unit.id === parseInt(selectedUnitId));
    setFromUnit(selectedUnit);
  }

  // use Effect
  useEffect(() => {
    setFromUnit(unitList[0]);
    setSrcValue(0);
  }, [unitList]);
  return (
    <div className="row p-5">
      <div className="col-8">
        <h3>From:</h3>
        <div className="d-flex gap-5 mt-4">
          <Input
            type="number"
            placeholder="Enter Number..."
            className="form-control"
            value={srcValue}
            onChange={(e) => setSrcValue(e.target.value || "")}
          />
          <SelectInput
            placeholder="Select Unit..."
            options={unitOptions}
            onChange={(e) => handleSelectChange(e)}
            value={fromUnit.id}
            showDefault={false}
          />
        </div>
        <h3 className="my-4">To:</h3>
        <div className="table-responsive">
          {/* Unit Conversion Tool Table */}
          <UnitConversionToolTable unitList={unitList} fromUnit={fromUnit} srcValue={srcValue} />
        </div>
      </div>
    </div>
  );
}

//
export default function UnitConversionToolPage() {
  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Outlet Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Getting Data from the Local Storage
  const { unitsByMeasurement = {} } = JSON.parse(LocalStorage.get("MEASUREMENTS_AND_UNITS") || "{}") || {};

  const unitsByMeasurementKeys = Object.keys(unitsByMeasurement);

  // Tab Id
  const tabName = searchParams.get(QueryParamsKeys.tabName) ?? unitsByMeasurementKeys[0];

  // Getting unit list for the selected tab name
  const unitList = unitsByMeasurement[tabName];

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeader title={PAGE_TITLE} />);
  }, []);

  return (
    <div className="page-content row gx-0" id="tab-content">
      <div className="col-2 left-tab-content">
        {/* Unit Conversion Tool Tabs */}
        <UnitConversionToolTabs
          tabs={unitsByMeasurementKeys}
          unitTypeName={tabName}
          updateQueryParams={updateQueryParams}
        />
      </div>

      <div className="col-10">
        <div className="right-tab-content">
          <UnitConversionToolInput unitList={unitList} />
        </div>
      </div>
    </div>
  );
}
