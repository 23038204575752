import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { MeasurementTypeActions } from "../../../store/slices/measurement-type/measurementTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/generalConstants";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Page Components
import MeasurementTypeListTable from "./components/MeasurementTypeListTable";

// Page Components
function MeasurementTypeListTableActionsSection({}) {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search  measurement types...`} isSearchInput={true} />
    </div>
  );
}

/**
 * Measurement Type List page
 */
export default function MeasurementTypeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector State
  const measurementTypeMap = useSelector((state) => state.measurementType.measurementTypeMap);
  const measurementTypeList = Object.values(measurementTypeMap);

  const measurementTypeListLoading = useSelector((state) => state.measurementType.measurementTypeListLoading);
  const measurementTypeListPagination = useSelector((state) => state.measurementType.measurementTypeListPagination);

  // Getting Page Number From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(MeasurementTypeActions.getMeasurementTypeList({ pageNumber, searchText }));
  }, [dispatch, pageNumber, searchText]);

  useEffect(() => {
    return () => dispatch(MeasurementTypeActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={"Mesurement Types"} />);
  }, []);

  return (
    <div className="page-content">
      {/* Measurement Type List Table Actions Section */}
      <MeasurementTypeListTableActionsSection />

      {/* Measurement Type List Table */}
      <MeasurementTypeListTable
        measurementTypeList={measurementTypeList}
        measurementTypeListLoading={measurementTypeListLoading}
        measurementTypeListPagination={measurementTypeListPagination}
        pageNumber={pageNumber}
      />
    </div>
  );
}
