import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ConstantActions } from "../../slices/constant/constantSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import ConstantService from "../../../services/constant/constantService";

// Get Constant List
function* getConstantList() {
  try {
    const constantMap = yield ConstantService.getConstantList();

    yield put(ConstantActions.getConstantListSuccess({ constantMap }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ConstantActions.getConstantListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(ConstantActions.getConstantList.type, getConstantList)]);
}
