// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { AuditParameterValuesUrls } from "../../constants/serviceUrls";

// Utils
import URLs from "../../utils/urlUtils";

// Upsert Values
async function upsertValues(valuesData, auditId, componentId) {
  //TODO : Add comp_cals req param
  const apiUrl = URLs.format(AuditParameterValuesUrls.upsertValues, { auditId, componentId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiUrl, {}, valuesData);
}

const AuditParameterValuesService = {
  upsertValues,
};

export default AuditParameterValuesService;
