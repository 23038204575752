// Utils
import TableUtils from "../../utils/tableUtils";

function getHeaders(currentPage = "", pageSize = "") {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (name) => <div>{name}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (description) => <div>{description}</div>,
    },
    {
      title: "Measurement Type",
      dataIndex: "measurementTypeName",
      render: (measurementTypeName) => <div>{measurementTypeName}</div>,
    },
    {
      title: "Unit",
      dataIndex: "unitSymbol",
      render: (unitSymbol) => <div>{unitSymbol}</div>,
    },
  ];
}

const ProductListTableHelper = {
  getHeaders,
};

export default ProductListTableHelper;
