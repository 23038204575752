// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Constants
import { AuditUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/generalConstants";

// Utils
import URLs from "../../utils/urlUtils";

// Get Audit List
async function getAuditList(orgId, plantId, pageNumber, pageSize, filters = {}) {
  let params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    org: orgId,
  };

  if (plantId) {
    params.plant = plantId;
  }

  const isFiltersPresent = Object.keys(filters).length > 0;

  if (isFiltersPresent) {
    params = { ...params, ...filters };
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(AuditUrls.getAuditList, params);
}

// Get Audit List By Auditor
async function getAuditListByAuditor(userId) {
  const url = URLs.format(AuditUrls.getAuditListByAuditor, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Initiate New Audit
async function initiateAudit(auditData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(AuditUrls.initiateAudit, {}, auditData);
}

// Get Audit Info
async function getAuditInfo(auditId) {
  const url = URLs.format(AuditUrls.getAuditInfo, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Detailed Progress
async function getDetailedProgress(auditId) {
  const url = URLs.format(AuditUrls.getDetailedProgress, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update Plant Incharge
async function updatePlantIncharge(auditId, userId) {
  const url = URLs.format(AuditUrls.updatePlantIncharge, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Update Audit Incharge
async function updateAuditIncharge(auditId, userId) {
  const url = URLs.format(AuditUrls.updateAuditIncharge, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Start Pre Assessment
async function startPreAssessment(auditId) {
  const url = URLs.format(AuditUrls.startPreAssessment, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Start Pre Audit
async function startPreAudit(auditId) {
  const url = URLs.format(AuditUrls.startPreAudit, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Add Auditor
async function addAuditor(auditId, userId) {
  const url = URLs.format(AuditUrls.addAuditor, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Remove Auditor
async function removeAuditor(auditId, userId) {
  const url = URLs.format(AuditUrls.removeAuditor, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Update Start Date
async function updateStartDate(auditId, date) {
  const url = URLs.format(AuditUrls.updateStartDate, { auditId, date });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

const AuditService = {
  getAuditList,
  getAuditListByAuditor,
  initiateAudit,
  getAuditInfo,
  getDetailedProgress,
  updatePlantIncharge,
  updateAuditIncharge,
  startPreAssessment,
  startPreAudit,
  addAuditor,
  removeAuditor,
  updateStartDate,
};

export default AuditService;
