// Utils
import URLs from "../../utils/urlUtils";

// URLs
import { PlantPowerConnectionsUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../utils/httpClientFactory";

// Get Power Connections List
async function getPowerConnectionList(plantId, searchText) {
  const params = {};

  if (searchText) {
    params.q = searchText;
  }

  const url = URLs.format(PlantPowerConnectionsUrls.getPowerConnectionList, { plantId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

// Create Power Connection
async function createPowerConnection(formData, plantId) {
  const url = URLs.format(PlantPowerConnectionsUrls.createPowerConnection, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData);
}

// Update Power Connection
async function updatePowerConnection(formData, plantId, connectionId) {
  const url = URLs.format(PlantPowerConnectionsUrls.updatePowerConnection, { plantId, connectionId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, formData);
}

// Update Power Connection Status
async function updatePowerConnectionStatus(plantId, connectionId, status) {
  const params = {
    s: status,
  };

  const url = URLs.format(PlantPowerConnectionsUrls.updatePowerConnectionStatus, { plantId, connectionId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, params);
}

const PlantPowerConnectionsService = {
  getPowerConnectionList,
  createPowerConnection,
  updatePowerConnection,
  updatePowerConnectionStatus,
};

export default PlantPowerConnectionsService;
