import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditObservationAndRecommendationActions } from "../../slices/audit/observationAndRecommendationSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import AuditObservationAndRecommendationService from "../../../services/audit/observationAndRecommendationService";

// Action Functions
const {
  //
  getObsAndRecListSuccess,
  getObsAndRecListFailure,

  //
  updateObsAndRecSuccess,
  updateObsAndRecFailure,

  //
  updateInvestmentSuccess,
  updateInvestmentFailure,
} = AuditObservationAndRecommendationActions;

// Service Functions
const {
  getObsAndRecList: getObsAndRecListService,
  updateObsAndRec: updateObsAndRecService,
  updateInvestment: updateInvestmentService,
} = AuditObservationAndRecommendationService;

// Get Observation And Recommendation List
function* getObsAndRecList(action) {
  try {
    const { auditId, componentId, pageNumber, pageSize } = action.payload;

    const { items, pagination } = yield getObsAndRecListService(auditId, componentId, pageNumber, pageSize);

    yield put(
      getObsAndRecListSuccess({
        auditObsAndRecList: items,
        obsAndRecListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(getObsAndRecListFailure({ error }));
  }
}

// Update Observation And Recommendation
function* updateObsAndRec(action) {
  const { observationData, observationId } = action.payload;

  try {
    const { auditInfoId, elementId } = observationData;

    yield updateObsAndRecService(auditInfoId, observationData);

    const { items, pagination } = yield getObsAndRecListService(auditInfoId, elementId);

    yield put(
      getObsAndRecListSuccess({
        auditObsAndRecList: items,
        obsAndRecListPagination: pagination,
      })
    );

    yield put(updateObsAndRecSuccess({ observationId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(updateObsAndRecFailure({ observationId, error }));
  }
}

// Update Investment
function* updateInvestment(action) {
  const {
    auditId = "",
    recommendationId = "",
    investment = "",
    componentId = "",
    pageNumber = "",
    pageSize = "",
  } = action.payload;

  try {
    yield updateInvestmentService(auditId, recommendationId, investment);

    const { items, pagination } = yield getObsAndRecListService(auditId, componentId, pageNumber, pageSize);

    yield put(
      getObsAndRecListSuccess({
        auditObsAndRecList: items,
        obsAndRecListPagination: pagination,
      })
    );

    yield put(updateInvestmentSuccess({ recommendationId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(updateInvestmentFailure({ recommendationId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditObservationAndRecommendationActions.getObsAndRecList.type, getObsAndRecList),
    takeLatest(AuditObservationAndRecommendationActions.updateObsAndRec.type, updateObsAndRec),
    takeLatest(AuditObservationAndRecommendationActions.updateInvestment.type, updateInvestment),
  ]);
}
