import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { PlantPowerConnectionActions } from "../../../../store/slices/plant/powerConnectionSlice";

// Constants
import { ICON_SIZE } from "../../../../constants/generalConstants";

// Helper
import PowerConnectionListTableHelper from "../../../../helpers/plant/powerConnectionListTableHelper";

// Components
import DataTable from "../../../../components/antd/table/DataTable";
import ToggleButton from "../../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import Icon from "../../../../components/icon/Icon";

// Page Components
function PowerConnectionTypeStatus({ powerConnection = {} }) {
  // Dispatch
  const dispatch = useDispatch();
  // Power Connection Information
  const { id = "", isActive = false, plantId = "" } = powerConnection;

  const status = !isActive;

  // Loading Power Connection Selector State
  const loading = useSelector((state) => state.plantPowerConnection.updatePowerConnectionStatusLoading[id]) || false;

  function updatePowerConnectionStatus() {
    dispatch(
      PlantPowerConnectionActions.updatePowerConnectionStatus({
        plantId,
        connectionId: id,
        status,
      })
    );
  }

  return (
    <ToggleButton
      containerClassname="mt-1"
      isActive={isActive}
      loading={loading}
      onClick={updatePowerConnectionStatus}
    />
  );
}

function TableActions({
  setSelectedPowerConnection,
  powerConnection = {},
  setShowViewPowerConnectionModal = () => {},
}) {
  const { isActive = false, id = "" } = powerConnection;

  const iconClassName = isActive ? "cursor-pointer" : "cursor-disabled";

  // Update Power Connection Status Selector State
  const updatePowerConnectionStatusSuccess =
    useSelector((state) => state.plantPowerConnection.updatePowerConnectionStatusSuccess[id]) || false;

  // Use Effect
  useEffect(() => {
    if (updatePowerConnectionStatusSuccess) {
      toast.success("Power status updated successfully!");
    }
  }, [updatePowerConnectionStatusSuccess]);

  // Function to open the Power Connection Edit Modal
  function openPowerConnectionEditModal() {
    setSelectedPowerConnection(powerConnection);
    setShowViewPowerConnectionModal(true);
  }

  return (
    <div className="d-flex gap-3">
      <Icon
        iconName="eye"
        title="View"
        size={ICON_SIZE.small}
        className={iconClassName}
        onClick={openPowerConnectionEditModal}
      />
    </div>
  );
}

/**
 * Plant Power Connection List Table
 */
export default function PlantPowerConnectionListTable({
  setSelectedPowerConnection,
  setShowViewPowerConnectionModal = () => {},
}) {
  // Fetching power connection list map
  const powerConnectionListMap = useSelector((state) => state.plantPowerConnection.powerConnectionListMap);
  const powerConnectionList = Object.values(powerConnectionListMap);

  // Fetching loading state for power connection list
  const powerConnectionListLoading = useSelector((state) => state.plantPowerConnection.powerConnectionListLoading);

  const actionColumns = {
    title: "Action",
    key: "action",
    render: (record) => {
      return (
        <TableActions
          setSelectedPowerConnection={setSelectedPowerConnection}
          powerConnection={record}
          setShowViewPowerConnectionModal={setShowViewPowerConnectionModal}
        />
      );
    },
  };

  const statusColumn = {
    title: "Status",
    key: "isActive",
    render: (record) => {
      return <PowerConnectionTypeStatus powerConnection={record} />;
    },
  };

  const columns = [...PowerConnectionListTableHelper.getHeaders(), statusColumn, actionColumns];

  // Skeleton Loader
  if (powerConnectionListLoading) {
    return <SkeletonTable columnCount={6} rowCount={9} />;
  }

  return <DataTable rows={powerConnectionList} columns={columns} />;
}
