// Components
import Logo from "../components/logo/Logo";

/**
 * Nav Branding
 * @param {*} subText
 * @param {*} containerClassName
 * @param {*} logoURL
 * @param {*} redirectURL
 * @param {*} height
 */
export default function NavBranding({ subText = "", containerClassName = "", logoURL = "", redirectURL, height = 30 }) {
  return (
    <div className={`navbar-branding ${containerClassName}`}>
      <Logo
        imageUrl={logoURL}
        imageHeight={height}
        redirect={redirectURL && redirectURL.length > 0 ? true : false}
        redirectURL={redirectURL}
      />

      {subText && <div className="navbar-subtext text-white">{subText}</div>}
    </div>
  );
}
