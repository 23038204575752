// Material Transformers
function transformObj(materialObj = {}) {
  const {
    id = "",
    version = "",
    isActive = false,
    code = "",
    name = "",
    description = "",
    type = "",
    measurementTypeId = "",
    measurementTypeName = "",
    unitId = "",
    unitSymbol = "",
    unitName = "",
  } = materialObj;

  const material = {
    id: id,
    version: version,
    isActive: isActive,
    code: code,
    name: name,
    description: description,
    type: type,
    measurementTypeId: measurementTypeId,
    measurementTypeName: measurementTypeName,
    unitId: unitId,
    unitSymbol: unitSymbol,
    unitName: unitName,
  };

  return material;
}

function transformList(materialObjList = []) {
  let materialListMap = {};

  materialObjList.forEach((obj) => {
    const { id = "" } = obj;

    materialListMap[id] = { key: id, ...transformObj(obj) };
  });

  return materialListMap;
}

// Exports
const MaterialTransformer = {
  transformObj,
  transformList,
};

export default MaterialTransformer;
