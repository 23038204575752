import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { PlantElectricityBillActions } from "../../../../../store/slices/plant/electricityBillSlice";

// Constants
import PlantBillsTableActionIcon from "../../../components/PlantBillsTableActionIcon";
import { ICON_SIZE } from "../../../../../constants/generalConstants";

// Utils
import { saveFile } from "../../../../../utils/fileUtils";

// Helper
import ElectrcityBillListTableHelper from "../../../../../helpers/plant/electricityBillListTableHelper";

// Components
import TablePagination from "../../../../../components/table/TablePagination";
import Loader from "../../../../../components/loader/v2/Loader";
import DataTable from "../../../../../components/antd/table/DataTable";

// page components
import SkeletonTable from "../../../../../components/antd/table/SkeletonTable";
import Icon from "../../../../../components/icon/Icon";

// Page Components
function TableActions({
  setSelectedElectricityBill,
  electricityBill = {},
  setShowViewElectricityBillModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Upload Electricity Bill Selector State
  const uploadElectricityBillLoading = useSelector((state) => state.plantElectricityBill.uploadElectricityBillLoading);

  // Download Electricity Bill Selector State
  const downloadElectricityBillLoading = useSelector(
    (state) => state.plantElectricityBill.downloadElectricityBillLoading
  );
  const downloadElectricityBillSuccess = useSelector(
    (state) => state.plantElectricityBill.downloadElectricityBillLoading
  );
  const fileData = useSelector((state) => state.plantElectricityBill.downloadElectricityBillData);

  // Electricity Bill Information
  const { id: electricityBillId = "", fileUrl = "" } = electricityBill;

  // Checking whether file is present or not
  const isFilePresent = fileUrl || "";

  // File Upload Loading Status
  const fileUploadLoadingStatus = uploadElectricityBillLoading[electricityBillId];
  const fileDownloadLoadingStatus = downloadElectricityBillLoading[electricityBillId];
  const fileDownloadStatus = downloadElectricityBillSuccess[electricityBillId];

  // After selecting the file dispatch is called
  function uploadFile(file) {
    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    dispatch(PlantElectricityBillActions.uploadElectricityBill({ formData, plantId, electricityBillId }));
  }

  // Download Electricity Bill Function
  function downloadElectiricityBill() {
    dispatch(PlantElectricityBillActions.downloadElectricityBill({ electricityBillId, apiUrl: fileUrl }));
  }

  // Open Electricity Bill Edit Modal Function
  function openElectricityBillEditModal() {
    setSelectedElectricityBill(electricityBill);
    setShowViewElectricityBillModal(true);
  }

  useEffect(() => {
    if (fileDownloadStatus) {
      saveFile("Electricity Bill", fileData);
    }
  }, [fileData, fileDownloadStatus]);

  return (
    <div className="d-flex gap-3">
      <Icon iconName="eye" title="View" size={ICON_SIZE.small} onClick={openElectricityBillEditModal} />

      {/* Plant Bills Table Action Icon */}
      <PlantBillsTableActionIcon
        uploadFile={uploadFile}
        isFilePresent={isFilePresent}
        fileUploadLoadingStatus={fileUploadLoadingStatus}
      />

      {/* Download Icon */}
      {isFilePresent && (
        <>
          {!fileDownloadLoadingStatus && (
            <Icon
              iconName="arrowDownToLine"
              title="Download File"
              size={ICON_SIZE.small}
              onClick={downloadElectiricityBill}
            />
          )}
          {fileDownloadLoadingStatus && <Loader />}
        </>
      )}
    </div>
  );
}

/**
 * Plant Electricity Bill List Table
 */
export default function PlantElectricityBillListTable({
  setSelectedElectricityBill,
  setShowViewElectricityBillModal = () => {},
}) {
  // Electricity Bill Selector State
  const electricityBillMap = useSelector((state) => state.plantElectricityBill.electricityBillMap);
  const electricityBillList = Object.values(electricityBillMap);

  const electricityBillListLoading = useSelector((state) => state.plantElectricityBill.electricityBillListLoading);
  const electricityBillListPagination = useSelector(
    (state) => state.plantElectricityBill.electricityBillListPagination
  );

  // Power Connection Selector State
  const powerConnectionListLoading = useSelector((state) => state.plantPowerConnection.powerConnectionListLoading);

  const { totalCount, pageNo, pageSize } = electricityBillListPagination;

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (record) => {
      return (
        <TableActions
          setSelectedElectricityBill={setSelectedElectricityBill}
          electricityBill={record}
          setShowViewElectricityBillModal={setShowViewElectricityBillModal}
        />
      );
    },
  };

  const columns = [...ElectrcityBillListTableHelper.getHeders(pageNo, pageSize), actionColumn];

  // Combine loading states for table
  const isLoading = electricityBillListLoading || powerConnectionListLoading;

  // Skeleton Loader
  if (isLoading) {
    return <SkeletonTable columnCount={6} rowCount={9} />;
  }

  return (
    <>
      <DataTable rows={electricityBillList} columns={columns} />

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
