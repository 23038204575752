import { createSlice } from "@reduxjs/toolkit";

// Transformer
import JobTransformer from "./jobTransformer";

function resetApiSuccessState(state) {
  state.runJobSuccess = false;
  state.resumeJobSuccess = false;
  state.pauseJobSuccess = false;
}

// Initial State
const initialState = {
  // Get job list
  jobListMap: [],
  jobListPagination: {},
  jobListLoading: false,
  jobListError: "",

  // Get job information
  jobInfo: {},
  jobInfoLoading: false,
  jobInfoError: "",

  // Get job session
  jobSessions: [],
  jobSessionsLoading: false,
  jobSessionsError: "",

  // Run job
  runJobSuccess: false,
  runJobLoading: false,
  runJobError: "",

  // Resume job
  resumeJobSuccess: false,
  resumeJobLoading: false,
  resumeJobError: "",

  // Pause job
  pauseJobSuccess: false,
  pauseJobLoading: false,
  pauseJobError: "",
};

// Job Slice
const jobSlice = createSlice({
  name: "Job",
  initialState,
  reducers: {
    // Get job list
    getJobList: (state) => {
      state.jobListLoading = true;
    },

    getJobListSuccess: (state, action) => {
      const { jobList, jobListPagination } = action.payload;

      state.jobListMap = JobTransformer.transformList(jobList);
      state.jobListPagination = jobListPagination;

      state.jobListLoading = false;
    },

    getJobListFailure: (state, action) => {
      state.jobListLoading = false;
      state.jobListError = action.payload.error;
    },

    // Get job information
    getJobInfo: (state) => {
      state.jobInfoLoading = true;
    },

    getJobInfoSuccess: (state, action) => {
      const { jobInfo = {} } = action.payload;
      state.jobInfo = JobTransformer.transformObj(jobInfo);

      state.jobInfoLoading = false;
    },

    getJobInfoFailure: (state, action) => {
      state.jobInfoLoading = false;
      state.jobInfoError = action.payload.error;
    },

    // Get job session
    getJobSession: (state) => {
      state.jobSessionsLoading = true;
    },

    getJobSessionSuccess: (state, action) => {
      state.jobSessionsLoading = false;
      state.jobSessions = action.payload.jobSessions;
    },

    getJobSessionFailure: (state, action) => {
      state.jobSessionsLoading = false;
      state.jobSessionsError = action.payload.error;
    },

    // Run job
    runJob: (state) => {
      state.runJobSuccess = false;
      state.runJobLoading = true;
    },

    runJobSuccess: (state) => {
      state.runJobSuccess = true;
      state.runJobLoading = false;
    },

    runJobFailure: (state, action) => {
      state.runJobLoading = false;
      state.runJobError = action.payload.error;
    },

    // Resume job
    resumeJob: (state) => {
      state.resumeJobSuccess = false;
      state.resumeJobLoading = true;
    },

    resumeJobSuccess: (state) => {
      state.resumeJobSuccess = true;
      state.resumeJobLoading = false;
    },

    resumeJobFailure: (state, action) => {
      state.resumeJobLoading = false;
      state.resumeJobError = action.payload.error;
    },

    // Pause job
    pauseJob: (state) => {
      state.pauseJobSuccess = false;
      state.pauseJobLoading = true;
    },

    pauseJobSuccess: (state) => {
      state.pauseJobSuccess = true;
      state.pauseJobLoading = false;
    },

    pauseJobFailure: (state, action) => {
      state.pauseJobLoading = false;
      state.pauseJobError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const JobReducer = jobSlice.reducer;

// Actions
export const JobActions = jobSlice.actions;
