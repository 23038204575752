import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentLinkActions } from "../../slices/plant/componentLinkSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import PlantComponentLinkService from "../../../services/plant/componentLinkService";

// Get Summary
function* getSummary(action) {
  try {
    const { plantId = "" } = action.payload;

    const summary = yield PlantComponentLinkService.getSummary(plantId);

    yield put(PlantComponentLinkActions.getSummarySuccess({ summary }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentLinkActions.getSummaryFailure({ error }));
  }
}

// Create Component Link
function* createComponentLink(action) {
  try {
    const { componentLinkData = {}, plantId = "" } = action.payload;

    yield PlantComponentLinkService.createComponentLink(componentLinkData, plantId);

    yield put(PlantComponentLinkActions.createComponentLinkSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentLinkActions.createComponentLinkFailure({ error }));
  }
}

// Update Component Link
function* updateComponentLink(action) {
  try {
    const { componentLinkData = {}, linkId = "", plantId = "" } = action.payload;

    yield PlantComponentLinkService.updateComponentLink(componentLinkData, linkId, plantId);

    yield put(PlantComponentLinkActions.updateComponentLinkSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentLinkActions.updateComponentLinkFailure({ error }));
  }
}

// Delete Component Link
function* deleteComponentLink(action) {
  try {
    const { linkId = "", plantId = "" } = action.payload;

    yield PlantComponentLinkService.deleteComponentLink(linkId, plantId);

    yield put(PlantComponentLinkActions.deleteComponentLinkSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentLinkActions.deleteComponentLinkFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantComponentLinkActions.getSummary.type, getSummary),
    takeLatest(PlantComponentLinkActions.createComponentLink.type, createComponentLink),
    takeLatest(PlantComponentLinkActions.updateComponentLink.type, updateComponentLink),
    takeLatest(PlantComponentLinkActions.deleteComponentLink.type, deleteComponentLink),
  ]);
}
