import { useState } from "react";
import dayjs from "dayjs";
import DateRangePicker from "react-bootstrap-daterangepicker";

// Utils
import { toTimestamp } from "../../utils/dateUtils";

// Date Format
export const DATE_FORMATS = {
  full_month_with_date_year: "MMM DD-YYYY",
  date_month_year: "DD-MM-YYYY",
};

// Sub-Components
function PlaceHolderText({ isSingleDatePicker, startDate, endDate, placeHolder, dateFormat }) {
  const formattedStartDate = dayjs(startDate).format(dateFormat);
  const formattedEndDate = dayjs(endDate).format(dateFormat);

  if (isSingleDatePicker) {
    return <span className="text-light-emphasis">{startDate ? formattedStartDate : placeHolder}</span>;
  }
  return (
    <span className="text-light-emphasis">
      {startDate && endDate ? `${formattedStartDate} ~ ${formattedEndDate}` : placeHolder}
    </span>
  );
}

function RangePickerButtonLabel({ isSingleDatePicker, startDate, endDate, dateFormat, calenderIcon, placeHolder }) {
  return (
    <div className="d-flex justify-content-between align-items-center px-2">
      <PlaceHolderText
        isSingleDatePicker={isSingleDatePicker}
        startDate={startDate}
        endDate={endDate}
        placeHolder={placeHolder}
        dateFormat={dateFormat}
      />
      {calenderIcon && <i className="fa fa-calendar-alt opacity-50 px-2"></i>}
    </div>
  );
}

/**
 * Component :: DATE-RANGE-PICKER
 * @http://www.daterangepicker.com :: refer this link for "props" reference
 * @https://www.npmjs.com/package/bootstrap-daterangepicker
 * @https://www.npmjs.com/package/react-bootstrap-daterangepicker
 */
function BootstrapDateRangePicker({
  initialStartDate,
  initialEndDate,
  onApplyDates,
  placeHolder,
  minDate,
  maxDate,
  isSingleDatePicker,
  id = "",

  // Style Object
  styleObject,
  ...rest
}) {
  // States
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  // Default Styles to picker
  const {
    buttonClassName = "",
    border = false, // border to picker
    calenderIcon = true,
    dateSeparator = " ~ ", // separator between dates
    popupDrops = "auto", // open on which side
    popupOpens = "right", // popup starts from
    isPickerDisabled = false, // enable/disable picker
    showDropdowns = false, // enable/disable Month and Year selector
    autoApply = false, // Hide/Show submit/cancel button at bottom
    dateFormat = DATE_FORMATS.full_month_with_date_year,
  } = styleObject;

  // classes
  const borderClassName = border ? "border" : "p-0";

  // Function
  function onApply(e, picker) {
    const pickerStartDate = picker.startDate;
    const pickerEndDate = picker.endDate;
    const selectedDate = {
      startDate: pickerStartDate,
      endDate: pickerEndDate,
    };

    // Converting date object to timestamp
    const startDateTimeStamp = toTimestamp(pickerStartDate.toDate()) || "";
    const endDateTimeStamp = toTimestamp(pickerEndDate.toDate()) || "";

    // for "Single-Date-Selector" ------------------------------------------
    if (isSingleDatePicker) {
      const selectedDate = pickerStartDate.toDate();
      setStartDate(selectedDate);

      // Setting the value as selected timeStamp
      e.target.value = startDateTimeStamp;

      // final submit Action
      onApplyDates(e, selectedDate);
      return;
    }

    // For "Date-Range-Picker" ---------------------------------------------
    setStartDate(pickerStartDate);
    setEndDate(pickerEndDate);

    // Setting the value as timeStamp
    e.target.timestamp = { startDateTimeStamp, endDateTimeStamp };

    // final submit Action
    onApplyDates(e, selectedDate);
  }

  return (
    <DateRangePicker
      id={id}
      initialSettings={{
        startDate: startDate || false,
        endDate,
        singleDatePicker: isSingleDatePicker,
        autoApply,
        minDate,
        maxDate,
        showDropdowns,
        locale: { format: dateFormat, separator: dateSeparator },
        drops: popupDrops,
        opens: popupOpens,
      }}
      onApply={onApply}
      {...rest}
    >
      <button
        id={id}
        type="button"
        className={`btn shadow-none rounded-lg ${borderClassName} ${buttonClassName}`}
        disabled={isPickerDisabled}
      >
        <RangePickerButtonLabel
          isSingleDatePicker={isSingleDatePicker}
          startDate={startDate}
          endDate={endDate}
          dateFormat={dateFormat}
          calenderIcon={calenderIcon}
          placeHolder={placeHolder}
        />
      </button>
    </DateRangePicker>
  );
}

BootstrapDateRangePicker.defaultProps = {
  placeHolder: "+ Add Dates",
  minDate: false,
  maxDate: false,
  isSingleDatePicker: false,
  styleObject: {},
};

export default BootstrapDateRangePicker;
