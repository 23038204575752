import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { PlantComponentLinkActions } from "../../../../store/slices/plant/componentLinkSlice";
import { PlantComponentActions } from "../../../../store/slices/plant/componentSlice";
import { NodeActions } from "../../../../reactflow/slice/node/nodeSlice";
import { EdgeActions } from "../../../../reactflow/slice/edge/edgeSlice";

// React Flow
import ReactFlowWrapper from "../../../../reactflow/ReactFlowWrapper";

// Helper
import ComponentLinkHelper from "../../../../helpers/component-link/componentLinkHelper";

/**
 * Plant Component Link Page
 */
export default function PlantComponentLinkPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Selector State
  const { nodes = [], edges = [] } = useSelector((state) => state.plantComponentLink.summary);
  const getSummarySuccess = useSelector((state) => state.plantComponentLink.getSummarySuccess);

  // Functions
  function onNodesDragStop(_, updatedNode) {
    const { id = "", position = {} } = updatedNode || {};

    if (Object.keys(position).length === 0) {
      return;
    }

    dispatch(PlantComponentActions.updateComponentPosition({ componentId: parseFloat(id), plantId, position }));
  }

  function onEdgesDelete(changes) {
    const { id = "" } = changes;
    dispatch(PlantComponentLinkActions.deleteComponentLink({ linkId: id, plantId }));
  }

  function onEdgesConnect(changes) {
    const { source = "", target = "" } = changes;

    const componentLinkData = {
      fromId: parseInt(source),
      toId: parseInt(target),
      typeStr: "FLOW_ENERGY",
      materialId: 1,
    };
    dispatch(PlantComponentLinkActions.createComponentLink({ plantId, componentLinkData }));
  }

  const reactFlowFunctions = { onEdgesDelete, onEdgesConnect, onNodesDragStop };

  // use Effect
  useEffect(() => {
    dispatch(PlantComponentLinkActions.getSummary({ plantId }));
  }, [dispatch, plantId]);

  useEffect(() => {
    if (getSummarySuccess) {
      dispatch(
        NodeActions.setNodes({
          nodes: ComponentLinkHelper.constructNodes(nodes, edges),
        })
      );

      dispatch(
        EdgeActions.setEdges({
          edges: ComponentLinkHelper.constructEdges(edges),
        })
      );
    }
  }, [dispatch, getSummarySuccess]);

  return (
    <div className="page-content h-100 w-100">
      <ReactFlowWrapper reactFlowFunctions={reactFlowFunctions} />
    </div>
  );
}
