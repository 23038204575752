// Constants
import { OrgTypes } from "./generalConstants";

// Utils
import URLs from "../utils/urlUtils";

const PagePaths = {
  Auth: "/auth",
  Error: "/error",
  Definitions: "/definitions",
  Admin: "/admin",
  Industry: "/industry",
  Plant: "/org/:orgId/plant",
  PlantSetup: "/org/:orgId/plant/:plantId/setup",
  Audit: "/audit",
  AuditSetup: "/audit/:auditId",
  User: "/user",
  Organization: "/organization",
};

// URLs
const PageURLs = {
  // Login Page
  LoginPageUrl: `${PagePaths.Auth}/login`,
  ForgotPasswordPageUrl: `${PagePaths.Auth}/forgot-password`,

  // Home Page
  DashboardPageUrl: `/`,

  // Dev Page
  UnitConversionToolPageUrl: `/unit-conversion-tool`,

  // Profile
  ProfilePageUrl: `/profile`,

  // Definitions :: Job Page
  JobListPageUrl: `${PagePaths.Admin}/jobs`,
  JobViewPageUrl: `${PagePaths.Admin}/job/:jobId`,

  // Definitions :: Component-type
  ComponentTypeListPageUrl: `${PagePaths.Definitions}/component-types`,
  ParameterListPageUrl: `${PagePaths.Definitions}/component-type/:componentTypeId/parameters`,
  RecommendationCodeListPageUrl: `${PagePaths.Definitions}/component-type/:componentTypeId/recommendation-codes`,
  ObservationCodeListPageurl: `${PagePaths.Definitions}/component-type/:componentTypeId/observation-codes`,

  // Definitions :: Measurement-type Page
  MeasurementTypeListPageUrl: `${PagePaths.Definitions}/measurement-types`,
  MeasurementTypeViewPageUrl: `${PagePaths.Definitions}/measurement-type/:measurementTypeId`,

  // Definitions :: Instrument Page
  InstrumentListPageUrl: `${PagePaths.Definitions}/instruments`,
  InstrumentViewPageUrl: `${PagePaths.Definitions}/instruments/:instrumentId`,

  // Definitions :: Material Page
  MaterialListPageUrl: `${PagePaths.Definitions}/materials`,

  // Definitions :: Product Page
  ProductListPageUrl: `${PagePaths.Definitions}/products`,

  // Definitions :: Constants Page
  ConstantsListPageUrl: `${PagePaths.Definitions}/constants`,

  // Definitions :: HSN Code Page
  HSNCodeListPageUrl: `${PagePaths.Definitions}/hsn-codes`,

  // Definitions :: NIC Code Page
  NICCodeListPageUrl: `${PagePaths.Definitions}/nic-codes`,

  // Organization Pages
  OrganizationListPageUrl: `${PagePaths.Organization}/:orgType`,
  OrganizationCreatePageUrl: `${PagePaths.Organization}/:orgType/new`,
  OrganizationViewPageUrl: `${PagePaths.Organization}/:orgType/:orgId`,

  // User Pages
  UserListPageUrl: `${PagePaths.Organization}/:orgId/users`,
  UserCreatePageUrl: `${PagePaths.Organization}/:orgId/user/new`,
  UserViewPageUrl: `${PagePaths.Organization}/:orgId/user/:userId`,

  // Role Pages
  RoleListPageUrl: `${PagePaths.Admin}/role`,
  RoleCreatePageUrl: `${PagePaths.Admin}/role/new`,
  RoleEditPageUrl: `${PagePaths.Admin}/role/:roleId`,

  // Plant Pages
  PlantListPageUrl: `${PagePaths.Plant}`,
  PlantCreatePageUrl: `${PagePaths.Plant}/new`,
  PlantViewPageUrl: `${PagePaths.Plant}/:plantId`,

  PlantComponentListPageUrl: `/plant-component-list/:plantName/:plantId`,
  PlantComponentViewPageUrl: `/plant-component-view/:componentName/:componentId`,

  // Plant Setup
  PlantSetupPageUrl: `${PagePaths.PlantSetup}`,
  PlantOverviewPageUrl: `${PagePaths.PlantSetup}/overview`,
  PlantComponentsPageUrl: `${PagePaths.PlantSetup}/components`,
  PlantComponentConnectionPageUrl: `${PagePaths.PlantSetup}/component-connection`,
  PlantPowerConnectionListPageUrl: `${PagePaths.PlantSetup}/power-Connection`,
  PlantElectricityBillPageUrl: `${PagePaths.PlantSetup}/elecBill`,
  PlantThermalBillPageUrl: `${PagePaths.PlantSetup}/therBill`,
  PlantUserListPageUrl: `${PagePaths.PlantSetup}/users`,
  PlantAuditListPageUrl: `${PagePaths.PlantSetup}/audits`,
  PlantUserViewPageUrl: `${PagePaths.PlantSetup}/user/:userId/view`,
  PlantFileListPageUrl: `${PagePaths.PlantSetup}/files`,

  // Audit Pages
  AuditListPageUrl: `${PagePaths.Audit}`,
  AuditListByAuditorPageUrl: `${PagePaths.Audit}/:userId`,
  AuditLibraryPageUrl: `${PagePaths.Audit}/library`,
  AuditCreatePageUrl: `${PagePaths.Audit}/new`,
  AuditViewPageUrl: `${PagePaths.Audit}/:auditId`,

  // Audit Setup
  AuditOverviewPageUrl: `${PagePaths.AuditSetup}/overview`,
  AuditStatusPageUrl: `${PagePaths.AuditSetup}/status`,
  AuditWalkthroughRemarksPageUrl: `${PagePaths.AuditSetup}/walk-through-remarks`,
  AuditMeasurementsPageUrl: `${PagePaths.AuditSetup}/measurements`,
  AuditObservationsPageUrl: `${PagePaths.AuditSetup}/observations`,
  AuditSavingsCalculationPageUrl: `${PagePaths.AuditSetup}/savings-calculation`,
  AuditFileListPageUrl: `${PagePaths.AuditSetup}/files`,
  AuditDataPageUrl: `${PagePaths.AuditSetup}/data`,
  AuditReportsListPageUrl: `${PagePaths.AuditSetup}/reports`,
  AuditReportEditorPageUrl: `${PagePaths.AuditSetup}/report/:reportId`,

  // Error Page
  ErrorPageUrl: `/error/:errorStatusCode`,
};

// Export
export default PageURLs;

// Pre Defined URLs
export const URL_ORG_LIST_AUDIT_FIRM = URLs.format(PageURLs.OrganizationListPageUrl, {
  orgType: OrgTypes.AUDIT.nameLowercase,
});
export const URL_ORG_LIST_INDUSTRIES = URLs.format(PageURLs.OrganizationListPageUrl, {
  orgType: OrgTypes.INDUSTRY.nameLowercase,
});
