import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyNodeChanges } from "@xyflow/react";

// Actions
import { NodeActions } from "../slice/node/nodeSlice";

// Utils
import MapUtils from "../mapUtils";

export function useOnNodesChanges(onNodesChange = () => {}) {
  const dispatch = useDispatch();

  // Selector States
  const nodesMap = useSelector((state) => state.node.nodesMap);
  const nodes = Object.values(nodesMap);

  return useCallback(
    (changes) => {
      // NOTE: We are deepcloning node because Reactflow internally expects the node to be updated
      // to be a new object altogether (As ReactFlow follows immutability)..
      const nodesToUpdate = [];

      // construct changes Map;
      const changesMap = MapUtils.convertArrayToMap(changes, "id");

      for (const node of nodes) {
        const { id: nodeId } = node;
        const changeForNode = changesMap[nodeId];
        changeForNode ? nodesToUpdate.push(structuredClone(node)) : nodesToUpdate.push(node);
      }

      const updatedNodes = applyNodeChanges(changes, nodesToUpdate);
      dispatch(NodeActions.setNodes({ nodes: updatedNodes }));

      onNodesChange(changes, updatedNodes);
    },
    [dispatch, onNodesChange, nodes]
  );
}
