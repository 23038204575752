import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantFileActions } from "../../slices/plant/fileSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import PlantFileService from "../../../services/plant/fileService";

// Get File List
function* getFileList(action) {
  try {
    const { plantId, pageNumber, pageSize, type = "" } = action.payload;

    const { items, pagination } = yield PlantFileService.getFileList(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.getFileListFailure({ error }));
  }
}

// Create File
function* createFile(action) {
  const { plantId, pageNumber, pageSize, formData, type = "" } = action.payload;

  try {
    yield PlantFileService.createFile(plantId, formData);

    const { items, pagination } = yield PlantFileService.getFileList(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(PlantFileActions.createFileSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.createFileFailure({ error }));
  }
}

// Update File
function* updateFile(action) {
  const { plantId, fileId, pageNumber, pageSize, formData, type = "" } = action.payload;

  try {
    yield PlantFileService.updateFile(plantId, fileId, formData);

    const { items, pagination } = yield PlantFileService.getFileList(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(PlantFileActions.updateFileSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.updateFileFailure({ error }));
  }
}

// Download File
function* downloadFile(action) {
  const { fileId, apiUrl = "" } = action.payload;

  try {
    const downloadFileData = yield PlantFileService.downloadFile(apiUrl);

    yield put(PlantFileActions.downloadFileSuccess({ fileId, downloadFileData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.downloadFileFailure({ fileId, error }));
  }
}

// Delete File
function* deleteFile(action) {
  const { plantId, fileId, pageNumber, pageSize, type = "" } = action.payload;

  try {
    yield PlantFileService.deleteFile(plantId, fileId);

    const { items, pagination } = yield PlantFileService.getFileList(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(PlantFileActions.deleteFileSuccess({ fileId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.deleteFileFailure({ fileId, error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(PlantFileActions.getFileList.type, getFileList),
    takeLatest(PlantFileActions.createFile.type, createFile),
    takeLatest(PlantFileActions.updateFile.type, updateFile),
    takeLatest(PlantFileActions.downloadFile.type, downloadFile),
    takeLatest(PlantFileActions.deleteFile.type, deleteFile),
  ]);
}
