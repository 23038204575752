import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { AuditParameterValuesActions } from "../../../../store/slices/audit/parameterValuesSlice";

// Constants
import { DataPreLoadKeys } from "../../../../constants/generalConstants";

// Utils
import { constructParametersFormInputs, getMeasurementFormDataObject } from "../../AuditUtils";
import { constructParameterValuesArray } from "../../../../utils/componentUtils";
import LocalStorage from "../../../../utils/localStorageUtils";

// Components
import Loader from "../../../../components/loader/Loader";
import TabularForm from "../../../../components/form/TabularForm";

// Section
import ComponentSelect from "../ComponentSelect";

// Page Constants
const propertiesTableHeaders = [
  {
    title: {
      displayName: "Design Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

const calculationTableHeaders = [
  {
    title: {
      displayName: "Calculation Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

const measurementTableHeaders = [
  {
    title: {
      displayName: "Measurements Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Units",
      className: "col-2",
    },
  },
];

// Page Constants
function AuditMeasurementsBody({ measurementFormData = {}, parametersFormInputs = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.componentInfo);

  // Selector State
  const upsertParameterValuesLoading = useSelector((state) => state.auditParameterValues.upsertValuesLoading);
  const upsertParameterValuesSuccess = useSelector((state) => state.auditParameterValues.upsertValuesSuccess);

  // Checking whether data is available or not
  const isDataAvailable = Object.keys(componentInfo).length === 0;

  // Getting details of the component
  const { id: componentId = "", componentTypeId: selectedComponentTypeId = "", parts = [] } = componentInfo;

  // Form inputs for property, measurement and calculation
  const { propertyInputs = [], measurementInputs = [], calculationInputs = [] } = parametersFormInputs;

  useEffect(() => {
    if (upsertParameterValuesSuccess) {
      toast.success("Parameters Updated Successfully");
    }
  }, [upsertParameterValuesSuccess]);

  useEffect(() => {
    return () => dispatch(AuditParameterValuesActions.resetAllApiSuccessState());
  }, [dispatch]);

  // Measurement Form Submit
  function handleMeasurementSubmit(formData) {
    // Local Storage Data
    const { parametersByCompType = {} } = JSON.parse(LocalStorage.get(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

    // Getting measurements and property array from local storage for component type
    const { MEASUREMENT: componentMeasurement = [] } = parametersByCompType[selectedComponentTypeId] || {};

    // Constructing part measurement array
    const partsMeasurementArray = parts.reduce((acc, eachPart) => {
      const { id: partId = "", componentTypeId: partComponentTypeId = "" } = eachPart || {};

      const { MEASUREMENT = [] } = parametersByCompType[partComponentTypeId] || [];

      const partMeasurement = constructParameterValuesArray(MEASUREMENT, formData, partId);

      return [...acc, ...partMeasurement];
    }, []);

    // Constructing component measurement array
    const componentMeasurementArray = constructParameterValuesArray(componentMeasurement, formData, componentId);

    // Combining component and parts measurement array
    const measurementArray = [...componentMeasurementArray, ...partsMeasurementArray];

    // Dispatch
    dispatch(AuditParameterValuesActions.upsertValues({ auditId, componentId, parameterValuesData: measurementArray }));
  }

  // If data is not available
  if (isDataAvailable) {
    return <div>Please select a component</div>;
  }

  return (
    <div className="row">
      <div className="col-3">
        <h4>Properties</h4>
        {/* Property  */}
        <TabularForm tableHeaders={propertiesTableHeaders} formInputItems={propertyInputs} showSubmitButton={false} />
      </div>

      <div className="col-5">
        <h4>Measurements</h4>
        {/* Measurement*/}
        <TabularForm
          tableHeaders={measurementTableHeaders}
          data={measurementFormData}
          formInputItems={measurementInputs}
          formSubmit={handleMeasurementSubmit}
          loading={upsertParameterValuesLoading}
        />
      </div>

      <div className="col-4">
        <h4>Calculation</h4>
        {/* Calculation */}
        <TabularForm
          tableHeaders={calculationTableHeaders}
          formInputItems={calculationInputs}
          showSubmitButton={false}
        />
      </div>
    </div>
  );
}

/**
 * Audit Measurements Page
 */
export default function AuditMeasurementsPage() {
  // Audit Component Info Selector State
  const auditComponentInfo = useSelector((state) => state.auditComponent.componentInfo);
  const auditComponentInfoLoading = useSelector((state) => state.auditComponent.componentInfoLoading);

  // Getting details of the component
  const { component: componentInfo = {} } = auditComponentInfo || {};
  const selectedComponentTypeId = componentInfo?.componentTypeId || "";

  // Converting audit component info to string
  const auditComponentInfoStr = JSON.stringify(auditComponentInfo);

  // use Memo
  const parametersFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = {
      propertyTable: {
        showUnits: true,
      },
      measurementTable: {
        isInputPresent: true,
        isUnitConversionPresent: true,
        titleKey: "code",
      },
      calculationTable: {
        showUnits: true,
        titleKey: "formulaStr",
      },
    };

    return constructParametersFormInputs(auditComponentInfo, tableConfig);
  }, [auditComponentInfoStr, selectedComponentTypeId]);

  const measurementFormData = useMemo(() => {
    return getMeasurementFormDataObject(auditComponentInfo);
  }, [auditComponentInfoStr]);

  return (
    <div className="page-content">
      {/* Component Select */}
      <ComponentSelect />

      {/* Loading */}
      {auditComponentInfoLoading && <Loader />}

      {!auditComponentInfoLoading && (
        <AuditMeasurementsBody measurementFormData={measurementFormData} parametersFormInputs={parametersFormInputs} />
      )}
    </div>
  );
}
