// Component Transformers
function transformComponentDetailsObj(componentDetail = {}) {
  const { plantComponents = [], auditComponents = [] } = componentDetail;

  return {
    plantComponents: plantComponents,
    auditComponents: auditComponents,
  };
}

function transformComponentInfoObj(componentInfo = {}) {
  const {
    component = {},
    auditComponent = {},
    properties = [],
    measurements = [],
    calculations = [],
    parts = [],
  } = componentInfo;

  return {
    component: component,
    auditComponent: auditComponent,
    properties: properties,
    measurements: measurements,
    calculations: calculations,
    parts: parts,
  };
}

function transformObj(componentObj = {}) {
  const {
    id = "",
    version = "",
    plantId = "",
    isActive = false,
    componentTypeId = "",
    componentTypeCode = "",
    name = "",
    internalId = "",
    remarks = "",
    imageURL = "",
    parts = [],
  } = componentObj;

  const component = {
    id: id,
    version: version,
    plantId: plantId,
    isActive: isActive,
    componentTypeId: componentTypeId,
    componentTypeCode: componentTypeCode,
    name: name,
    internalId: internalId,
    remarks: remarks,
    imageURL: imageURL,
    parts: parts,
  };

  return component;
}

function transformList(componentList = []) {
  let componentListMap = {};

  componentList.forEach((obj) => {
    const { id = "" } = obj;

    componentListMap[id] = { key: id, ...transformObj(obj) };
  });

  return componentListMap;
}

// Exports
const AuditComponentTransformer = {
  transformComponentDetailsObj,
  transformComponentInfoObj,
  transformObj,
  transformList,
};

export default AuditComponentTransformer;
