// Utils
import TableUtils from "../../utils/tableUtils";

function TextAndSubText({ text, subText }) {
  return (
    <div>
      {text}
      <span className="sub-text">{subText}</span>
    </div>
  );
}

function getHeaders(currentPage = "", pageSize = "") {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Plant",
      dataIndex: "plantName",
      width: 400,
      render: (text, record) => <TextAndSubText text={text} subText={record.plantAddress} />,
    },
    {
      title: "Plant Incharge",
      dataIndex: "plantInchargeName",
      render: (text, record) => <TextAndSubText text={text} subText={record.industryOrgName} />,
    },
    {
      title: "Auditor",
      dataIndex: "auditInchargeName",
      render: (text, record) => <TextAndSubText text={text} subText={record.auditOrgName} />,
    },
    {
      title: "Status",
      dataIndex: "auditStatus",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
    },
  ];
}

const AuditListTableHelper = {
  getHeaders,
};

export default AuditListTableHelper;
