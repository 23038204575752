import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditInstrumentActions } from "../../slices/audit/instrumentSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Services
import AuditInstrumentService from "../../../services/audit/instrumentService";

// Get Instrument Data List
function* getInstrumentDataList(action) {
  try {
    const { pageNumber, pageSize, auditId, componentId } = action.payload;

    const { items, pagination } = yield AuditInstrumentService.getInstrumentDataList(
      auditId,
      componentId,
      pageNumber,
      pageSize
    );

    yield put(
      AuditInstrumentActions.getInstrumentDataListSuccess({
        instrumentDataList: items,
        instrumentDataListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.getInstrumentDataListFailure({ error }));
  }
}

// Get Instrument Data Info
function* getInstrumentDataInfo(action) {
  try {
    const { instrumentDataId } = action.payload;

    const instrumentDataInfo = yield AuditInstrumentService.getInstrumentDataInfo(instrumentDataId);

    yield put(AuditInstrumentActions.getInstrumentDataInfoSuccess({ instrumentDataInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.getInstrumentDataInfoFailure({ error }));
  }
}

// Select Instrument Data
function* selectInstrumentData(action) {
  const { instrumentDataId, sheetNo, lineNo } = action.payload;

  try {
    yield AuditInstrumentService.selectInstrumentData(instrumentDataId, sheetNo, lineNo);

    yield put(AuditInstrumentActions.selectInstrumentDataSuccess({ lineNo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.selectInstrumentDataFailure({ error, lineNo }));
  }
}

// Upload Instrument Data File
function* uploadInstrumentDataFile(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield AuditInstrumentService.uploadInstrumentDataFile(formData);

    const { items, pagination } = yield AuditInstrumentService.getInstrumentDataList(auditId, componentId);

    yield put(
      AuditInstrumentActions.getInstrumentDataListSuccess({
        auditInstrumentDataList: items,
        auditInstrumentDataListPagination: pagination,
      })
    );

    yield put(AuditInstrumentActions.uploadInstrumentDataFileSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditInstrumentActions.uploadInstrumentDataFileFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditInstrumentActions.getInstrumentDataList.type, getInstrumentDataList),
    takeLatest(AuditInstrumentActions.getInstrumentDataInfo.type, getInstrumentDataInfo),
    takeLatest(AuditInstrumentActions.selectInstrumentData.type, selectInstrumentData),
    takeLatest(AuditInstrumentActions.uploadInstrumentDataFile.type, uploadInstrumentDataFile),
  ]);
}
