import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../hooks/useUpdateQueryParams";

// Actions
import { AuditActions } from "../../../../store/slices/audit/auditSlice";

// Constants
import { QueryParamsKeys } from "../../../../constants/generalConstants";

// Components
import AuditListTable from "../../../../components/audit/audit-list/AuditListTable";

/**
 * Plant Audit List Page
 */
export default function PlantAuditListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Page Params
  const [searchParams] = useUpdateQueryParams();

  // Selector State
  const auditMap = useSelector((state) => state.audit.auditMap);
  const auditList = Object.values(auditMap);
  const auditListLoading = useSelector((state) => state.audit.auditListLoading);
  const auditListPagination = useSelector((state) => state.audit.auditListPagination);

  // From url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);

  //use Effect
  useEffect(() => {
    dispatch(AuditActions.getAuditList({ plantId, pageNumber, pageSize }));
  }, [dispatch, plantId, pageNumber, pageSize]);

  return (
    <div className="page-content">
      {/* Header */}
      <h3 className="mb-4">Audits</h3>

      {/* Audit List Table */}
      <AuditListTable items={auditList} pagination={auditListPagination} loading={auditListLoading} />
    </div>
  );
}
