import { createSlice } from "@reduxjs/toolkit";

// Transformer
import AuditTransformer from "./auditTransformer";

function resetApiSuccessState(state) {
  state.initiateAuditSuccess = false;
  state.updatePlantInchargeSuccess = false;
  state.updateAuditInchargeSuccess = false;
  state.startPreAssessmentSuccess = false;
  state.startPreAuditSuccess = false;
  state.addAuditorSuccess = false;
  state.removeAuditorSuccess = {};
  state.updateStartDateSuccess = false;
}

// Initial State
const initialState = {
  // Get Audit List
  auditMap: {},
  auditListPagination: {},
  auditListLoading: false,
  auditListError: "",

  // Get Audit List By Auditor
  auditByAuditorMap: {},
  auditListByAuditorLoading: false,
  auditListByAuditorError: "",

  // Initiate Audit
  initiateAuditLoading: false,
  initiateAuditError: "",
  initiateAuditSuccess: false,

  // Get Audit Information
  auditInfo: {},
  auditInfoLoading: false,
  auditInfoError: "",

  // Get Detailed Progress
  detailedProgress: {},
  detailedProgressLoading: false,
  detailedProgressError: "",

  // Update Plant Incharge
  updatePlantInchargeLoading: false,
  updatePlantInchargeError: "",
  updatePlantInchargeSuccess: false,

  // Update Audit Incharge
  updateAuditInchargeLoading: false,
  updateAuditInchargeError: "",
  updateAuditInchargeSuccess: false,

  // Start Audit Pre Assessment
  startPreAssessmentLoading: false,
  startPreAssessmentError: "",
  startPreAssessmentSuccess: false,

  // Start Pre Audit
  startPreAuditLoading: false,
  startPreAuditError: "",
  startPreAuditSuccess: false,

  // Add Auditor
  addAuditorLoading: false,
  addAuditorError: "",
  addAuditorSuccess: false,

  // Remove Auditor
  removeAuditorLoading: {},
  removeAuditorSuccess: {},
  removeAuditorError: "",

  // Update Start Date
  updateStartDateLoading: false,
  updateStartDateSuccess: false,
  updateStartDateError: {},
};

// Audit Slice
const auditSlice = createSlice({
  name: "AuditSlice",
  initialState,
  reducers: {
    // Get Audit List
    getAuditList: (state) => {
      state.auditListLoading = true;
      state.auditListError = "";
    },

    getAuditListSuccess: (state, action) => {
      const { auditList, auditListPagination } = action.payload;
      state.auditMap = AuditTransformer.transformList(auditList);

      state.auditListPagination = auditListPagination;
      state.auditListLoading = false;
    },

    getAuditListFailure: (state, action) => {
      state.auditListLoading = false;
      state.auditListError = action.payload.error;
    },

    // Get Audit List By Auditor
    getAuditListByAuditor: (state) => {
      state.auditListByAuditorLoading = true;
      state.auditListError = "";
    },

    getAuditListByAuditorSuccess: (state, action) => {
      const { auditListByAuditor } = action.payload;
      state.auditByAuditorMap = AuditTransformer.transformList(auditListByAuditor);

      state.auditListByAuditorLoading = false;
    },

    getAuditListByAuditorFailure: (state, action) => {
      state.auditListByAuditorLoading = false;
      state.auditListError = action.payload.error;
    },

    // Initiate Audit
    initiateAudit: (state) => {
      state.initiateAuditSuccess = false;
      state.initiateAuditLoading = true;
    },

    initiateAuditSuccess: (state) => {
      state.initiateAuditSuccess = true;
      state.initiateAuditLoading = false;
    },

    initiateAuditFailure: (state, action) => {
      state.initiateAuditLoading = false;
      state.initiateAuditError = action.payload.error;
    },

    // Get Audit Information
    getAuditInfo: (state) => {
      state.auditInfoLoading = true;
    },

    getAuditInfoSuccess: (state, action) => {
      const { auditInfo = {} } = action.payload;
      state.auditInfo = AuditTransformer.transformAuditInfoObj(auditInfo);

      state.auditInfoLoading = false;
    },

    getAuditInfoFailure: (state, action) => {
      state.auditInfoLoading = false;
      state.auditInfoError = action.payload.error;
    },

    // Get Detailed Progress
    getDetailedProgress: (state) => {
      state.detailedProgressLoading = true;
    },

    getDetailedProgressSuccess: (state, action) => {
      state.detailedProgress = action.payload.detailedProgress;
      state.detailedProgressLoading = false;
    },

    getDetailedProgressFailure: (state, action) => {
      state.detailedProgressLoading = false;
      state.detailedProgressError = action.payload.error;
    },

    // Update Plant Incharge
    updatePlantIncharge: (state) => {
      state.updatePlantInchargeSuccess = false;
      state.updatePlantInchargeLoading = true;
    },

    updatePlantInchargeSuccess: (state) => {
      state.updatePlantInchargeSuccess = true;
      state.updatePlantInchargeLoading = false;
    },

    updatePlantInchargeFailure: (state, action) => {
      state.updatePlantInchargeLoading = false;
      state.updatePlantInchargeError = action.payload.error;
    },

    // Update Audit Incharge
    updateAuditIncharge: (state) => {
      state.updateAuditInchargeSuccess = false;
      state.updateAuditInchargeLoading = true;
    },

    updateAuditInchargeSuccess: (state) => {
      state.updateAuditInchargeSuccess = true;
      state.updateAuditInchargeLoading = false;
    },

    updateAuditInchargeFailure: (state, action) => {
      state.updateAuditInchargeLoading = false;
      state.updateAuditInchargeError = action.payload.error;
    },

    // Start Pre Assessment
    startPreAssessment: (state) => {
      state.startPreAssessmentSuccess = false;
      state.startPreAssessmentLoading = true;
    },

    startPreAssessmentSuccess: (state) => {
      state.startPreAssessmentSuccess = true;
      state.startPreAssessmentLoading = false;
    },

    startPreAssessmentFailure: (state, action) => {
      state.startPreAssessmentLoading = false;
      state.startPreAssessmentError = action.payload.error;
    },

    // Start Pre Audit
    startPreAudit: (state) => {
      state.startPreAuditSuccess = false;
      state.startPreAuditLoading = true;
    },

    startPreAuditSuccess: (state) => {
      state.startPreAuditSuccess = true;
      state.startPreAuditLoading = false;
    },

    startPreAuditFailure: (state, action) => {
      state.startPreAuditLoading = false;
      state.startPreAuditError = action.payload.error;
    },

    // Add Auditor
    addAuditor: (state) => {
      state.addAuditorSuccess = false;
      state.addAuditorLoading = true;
    },

    addAuditorSuccess: (state) => {
      state.addAuditorSuccess = true;
      state.addAuditorLoading = false;
    },

    addAuditorFailure: (state, action) => {
      state.addAuditorLoading = false;
      state.addAuditorError = action.payload.error;
    },

    // Remove Auditor
    removeAuditor: (state, action) => {
      const { userId } = action.payload;
      state.removeAuditorSuccess[userId] = false;
      state.removeAuditorLoading[userId] = true;
      state.removeAuditorError = "";
    },

    removeAuditorSuccess: (state, action) => {
      const { userId } = action.payload;
      state.removeAuditorSuccess[userId] = true;
      delete state.removeAuditorLoading[userId];
    },

    removeAuditorFailure: (state, action) => {
      const { userId, error } = action.payload;
      delete state.removeAuditorLoading[userId];
      state.removeAuditorError = error;
    },

    // Update Start Date
    updateStartDate: (state) => {
      state.updateStartDateSuccess = false;
      state.updateStartDateLoading = true;
    },

    updateStartDateSuccess: (state) => {
      state.updateStartDateLoading = false;
      state.updateStartDateSuccess = true;
    },

    updateStartDateFailure: (state, action) => {
      state.updateStartDateLoading = false;
      state.updateStartDateError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditReducer = auditSlice.reducer;

// Actions
export const AuditActions = auditSlice.actions;
