import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { ProductActions } from "../../slices/product/productSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import ProductService from "../../../services/product/productService";

// Get Product List
function* getProductList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield ProductService.getProductList(pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(ProductActions.getProductListSuccess({ productList: items, productListPagination: pagination }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ProductActions.getProductListFailure({ error }));
  }
}

// Update Product Status
function* updateProductStatus(action) {
  const { productId, status, pageNumber, pageSize } = action.payload;

  try {
    yield ProductService.updateProductStatus(productId, status);

    const { items, pagination } = yield ProductService.getProductList(pageNumber, pageSize);

    yield put(ProductActions.getProductListSuccess({ productList: items, productListPagination: pagination }));

    yield put(ProductActions.updateProductStatusSuccess({ productId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ProductActions.updateProductStatusFailure({ productId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(ProductActions.getProductList.type, getProductList),
    takeEvery(ProductActions.updateProductStatus.type, updateProductStatus),
  ]);
}
