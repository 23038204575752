import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditObservationAndRecommendationActions } from "../../../../store/slices/audit/observationAndRecommendationSlice";

// Constants
import { DataPreLoadKeys } from "../../../../constants/generalConstants";
import { AUDIT_SAVINGS_RECOMMENDATION_LIST_TABLE_HEADER } from "../../../../constants/auditSavingsRecommendationConstants";

// Utils
import LocalStorage from "../../../../utils/localStorageUtils";

// Components
import DataTable from "../../../../components/antd/table/DataTable";

/**
 * Audit Savings Recommendation List Table
 */
export default function AuditSavingsRecommendationListTable() {
  const dispatch = useDispatch();

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.componentInfo);

  // Audit Observation And Recommendation List Selector State
  const auditObsAndRecListMap = useSelector((state) => state.auditObsAndRec.obsAndRecListMap);
  const auditObsAndRecList = Object.values(auditObsAndRecListMap);

  // Recommendation Codes Data
  const { recommendationCodesByComponent = {} } =
    JSON.parse(LocalStorage.get(DataPreLoadKeys.RECOMMENDATION_CODES || "{}")) || {};

  // Component Info
  const { id: selectedComponentId = "", componentTypeId: selectedComponentTypeId = "" } = componentInfo;

  // use Memo
  const recCodeList = useMemo(() => {
    return recommendationCodesByComponent[selectedComponentTypeId] || [];
  }, [selectedComponentTypeId]);

  // use Memo
  const selectedRecommendationList = useMemo(() => {
    // For each observation there will be recommendations
    // All recommendations are grouped
    const allRecommendations = auditObsAndRecList.reduce((acc, eachObservation) => {
      const { recommendations = [] } = eachObservation || {};

      return [...acc, ...recommendations];
    }, []);

    return allRecommendations.map((eachRecommendation) => {
      const { codeId = "" } = eachRecommendation || {};

      const { description = "", energySavingsFormulaStr = "" } =
        recCodeList.find((recommendation) => recommendation.id === codeId) || {};

      return { ...eachRecommendation, description, energySavingsFormulaStr };
    });
  }, [auditObsAndRecList, recCodeList, selectedComponentId, selectedComponentTypeId]);

  useEffect(() => {
    return () => dispatch(AuditObservationAndRecommendationActions.resetAllApiSuccessState());
  }, [dispatch]);

  return (
    <div className="table-responsive">
      <DataTable rows={selectedRecommendationList} columns={AUDIT_SAVINGS_RECOMMENDATION_LIST_TABLE_HEADER} />
    </div>
  );
}
