import { useState } from "react";
import { Outlet } from "react-router-dom";

// Components
import TopNav from "./TopNav";
import Sidebar from "./Sidebar";

// Configuration object for topbar
const DEFAULT_TOPBAR_CONFIG = {
  // Container Classname
  topBarContainerClassName: "",

  // Menus
  topBarMenuComponent: <></>,
};

// Configuration for sidebar
const DEFAULT_SIDEBAR_CONFIG = {
  // Menu Component
  menuComponent: <></>,

  // Logo Configuration for full and collapsed sidebar
  headerLogo: { height: 40, logoURL: "" },
  collapsedHeaderLogo: { height: 40, logoURL: "" },

  // Redirect URL for Logo
  redirectURL: "",

  // Title below logo
  title: "",
};

/**
 * Layout Component
 *
 * TODO :: Handle the case where we need Sidebar but no Topbar
 */
export default function Layout({
  themeId,
  sidebarCollapsible = false,
  topbarConfig = {},
  sidebarConfig = {},
  outletProps = [],
}) {
  // Page State
  const [showSideBar, setShowSideBar] = useState(true);

  // Checking whether topbar and sidebar is present or not
  const isTopbarPresent = Object.keys(topbarConfig).length !== 0;
  const isSidebarPresent = Object.keys(sidebarConfig).length !== 0;

  // Data
  const layoutTopbarCls = isTopbarPresent && !isSidebarPresent ? "layout-topbar" : "";
  const layoutSidebarCls = isSidebarPresent ? "layout-sidebar" : "";

  const isSidebarCollapsed = isSidebarPresent && !showSideBar;

  return (
    <div className={`page ${layoutTopbarCls} ${layoutSidebarCls}`} data-theme={themeId}>
      {/* Topbar */}
      {isTopbarPresent && ( //
        <TopNav showSideBar={showSideBar} topbarConfig={topbarConfig} />
      )}

      {/* Sidebar */}
      {isSidebarPresent && ( //
        <Sidebar
          showSideBar={showSideBar}
          sidebarConfig={sidebarConfig}
          setShowSideBar={setShowSideBar}
          sidebarCollapsible={sidebarCollapsible}
        />
      )}

      {/* Main Content */}
      <div className={`main-cont ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <div className={"content-wrapper"}>
          <Outlet context={outletProps} />
        </div>
      </div>
    </div>
  );
}
