function transformObj(authObj = {}) {
  const {
    privileges = [],
    firstName = "",
    lastName = "",
    org = {},
    emailId = "",
    userId = "",
    phoneNo = "",
    orgId = "",
    token = "",
  } = authObj;

  return {
    privileges: privileges,
    firstName: firstName,
    lastName: lastName,
    org: org,
    emailId: emailId,
    userId: userId,
    phoneNo: phoneNo,
    orgId: orgId,
    token: token,
  };
}
const AuthTransformer = {
  transformObj,
};
export default AuthTransformer;
