import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/useUpdateQueryParams";

// Actions
import { ComponentTypeActions } from "../../../../store/slices/component-type/componentTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../../constants/generalConstants";

// Helper
import ParameterListTableHelper from "../../../../helpers/component-type/parameterListTableHelper";

// Components
import TablePagination from "../../../../components/table/TablePagination";
import ToggleButton from "../../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";

// Css
import "./ParameterListTable.scss";

function ParameterStatus({ parameter }) {
  // Params
  const params = useParams();
  const { componentTypeId } = params;

  // Dispatch
  const dispatch = useDispatch();
  const { id: parameterId, isActive } = parameter;
  const status = !isActive;

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);
  const tabId = searchParams.get(QueryParamsKeys.tabId) || "PROPERTY";

  // Selector State
  const updateParameterStatusLoading = useSelector(
    (state) => state.componentType.updateParameterStatusLoadingMap[parameterId]
  );
  const updateParameterStatusSuccess = useSelector(
    (state) => state.componentType.updateParameterStatusSuccessMap[parameterId]
  );

  function updateParameterTypeStatus() {
    dispatch(
      ComponentTypeActions.updateParameterTypeStatus({
        componentTypeId,
        parameterType: tabId,
        parameterId,
        status,
        pageNumber,
        pageSize,
        searchText,
      })
    );
  }

  useEffect(() => {
    if (updateParameterStatusSuccess) {
      toast.success("Status Changed Successfully");
    }
  }, [updateParameterStatusSuccess]);

  return (
    <ToggleButton isActive={isActive} loading={updateParameterStatusLoading} onClick={updateParameterTypeStatus} />
  );
}

/**
 * Parameter List Table
 */
export default function ParameterListTable({
  parameterList,
  parameterListLoading,
  parameterListPagination,
  pageNumber,
  parameterType,
}) {
  // Pagination
  const { totalCount, pageNo, pageSize } = parameterListPagination;

  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => {
      return <ParameterStatus parameter={record} />;
    },
  };

  // For calculation tab formula column is added
  const columns = [...ParameterListTableHelper.getHeaders(parameterType, pageNo, pageSize), actionColumn];

  return (
    <>
      {parameterListLoading && <SkeletonTable columnCount={7} rowCount={10} />}
      {!parameterListLoading && <DataTable className="parameter-list-table" columns={columns} rows={parameterList} />}

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
