import { getMonthName } from "../../utils/dateUtils";
import TableUtils from "../../utils/tableUtils";

function getHeders(currentPage, pageSize) {
  const tableHeaders = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => {
        const { month, year } = record;
        return `${getMonthName(month)} , ${year}`;
      },
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
    },
    {
      title: "Fuel GCV",
      dataIndex: "fuelGCV",
    },
    {
      title: "Units Consumed",
      dataIndex: "unitsConsumed",
    },
    {
      title: "Unit Cost",
      dataIndex: "unitCost",
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
    },
  ];

  return tableHeaders;
}

const ThermalBillListTableHelper = { getHeders };

export default ThermalBillListTableHelper;
