import * as yup from "yup";

// Validation Schema
export const PowerConnectionFormValidationSchema = yup.object({
  serviceNo: yup.string().required("Please enter the service number !!"),
  type: yup.string().required("Please select the connection voltage type !!"),
});

// Form Inputs
export const PowerConnectionFormSchema = [
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Service Number",
        key: "serviceNo",
        placeholder: "Enter service number...",
        isMandatory: true,
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Type",
        key: "type",
        type: "select",
        options: [
          { value: "LT", label: "Low Tension (LT)" },
          { value: "HT", label: "High Tension (HT)" },
        ],
        placeholder: "Select type...",
        isMandatory: true,
      },
    ],
  },
];
