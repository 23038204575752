// Component Transformers
function transformObj(componentObj = {}) {
  const { component = {}, properties = [], parts = [] } = componentObj;

  return { component: component, properties: properties, parts: parts };
}

function transformList(componentList = []) {
  let componentListMap = {};

  componentList.forEach((obj) => {
    const { component = {} } = obj;
    const { id = "" } = component;

    componentListMap[id] = { key: id, ...transformObj(obj) };
  });

  return componentListMap;
}

// Exports
const PlantComponentTransformer = {
  transformObj,
  transformList,
};

export default PlantComponentTransformer;
