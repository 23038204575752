// Instrument Transformers
function transformObj(instrumentObj = {}) {
  return { ...instrumentObj };
}

function transformList(instrumentList = []) {
  let instrumentListMap = {};

  instrumentList.forEach((obj) => {
    const { id = "" } = obj;

    instrumentListMap[id] = { key: id, ...transformObj(obj) };
  });

  return instrumentListMap;
}

// Exports
const InstrumentTransformer = {
  transformObj,
  transformList,
};

export default InstrumentTransformer;
