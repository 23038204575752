import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";

// Constants
import { OrgTypes } from "../../../../constants/generalConstants";

// Utils
import { getOrgInfo } from "../../../../utils/authUtils";

// Components
import { Button } from "../../../../components/button/Button";
import SelectInput from "../../../../components/select-input/SelectInput";
import Label from "../../../../components/label/Label";

// Page Constants
const AUDIT_STATUS_OPTIONS = [
  { id: "QUESTIONNAIRE", value: "Questionniare" },
  { id: "PRE_AUDIT", value: "Pre Audit" },
  { id: "AUDIT", value: "Audit" },
  { id: "POST_AUDIT", value: "Post Audit" },
  { id: "COMPLETED", value: "Completed" },
];

// Page Components
function FilterActionsButton({
  resetFilters = () => {},
  applyFilters = () => {},
  selectedFilters = {},
  loading = false,
}) {
  return (
    <div className="d-flex justify-content-end">
      <Button label="Reset" color="secondary" onClick={resetFilters} disabled={loading} />
      <Button
        label="Apply"
        type="submit"
        color="primary"
        className="ms-2"
        loading={loading}
        disabled={loading}
        onClick={() => applyFilters(selectedFilters)}
      />
    </div>
  );
}

// Helper functions
function constructOptionsArray(list = []) {
  return list.map(({ id = "", name = "" }) => ({ id, value: name }));
}

function OrganiztionFields({
  orgList = [],
  name = "",
  heading = "",
  labelName = "",
  handleChange = () => {},
  selectedFilters = {},
  loading = false,
  disabled = false,
}) {
  const { value = "" } = selectedFilters[name] || "";

  return (
    <>
      <h5 className="fw-semibold">{heading}</h5>

      <div className="col-6">
        <Label title={labelName} />
        <SelectInput
          name={name}
          value={value}
          options={constructOptionsArray(orgList)}
          onChange={handleChange}
          disabled={loading || disabled}
        />
      </div>
    </>
  );
}

function DateRange({ handleDateSelection = () => {}, selectedFilters = {}, setSelectedFilters = () => {} }) {
  const { value: selectedFromDate = "", date: selectedFromDateObj = null } = selectedFilters["from_date"] || {};
  const { value: selectedToDate = "", date: selectedToDateObj = null } = selectedFilters["to_date"] || {};

  useEffect(() => {
    if (!selectedFromDate) {
      setSelectedFilters((prev) => ({ ...prev, from_date: {} }));
    }
  }, [selectedFromDate]);

  useEffect(() => {
    if (!selectedToDate) {
      setSelectedFilters((prev) => ({ ...prev, to_date: {} }));
    }
  }, [selectedToDate]);

  return (
    <>
      <div className="col-6">
        <Label title="Start Date" />

        <DatePicker
          className="w-100 border rounded-0 shadow-none cursor-pointer"
          value={selectedFromDateObj}
          placeholder="Audit start date"
          onChange={(date) => handleDateSelection(date, "from_date")}
        />
      </div>

      <div className="col-6">
        <Label title="End Date" />

        <DatePicker
          className="w-100 border rounded-0 shadow-none cursor-pointer"
          value={selectedToDateObj}
          placeholder="Audit end date"
          onChange={(date) => handleDateSelection(date, "to_date")}
        />
      </div>
    </>
  );
}

/**
 * Audit Filter Form
 */
export default function AuditFilterForm({
  selectedFilters = {},
  setSelectedFilters = () => {},
  applyFilters = () => {},
}) {
  const authOrg = getOrgInfo();
  const { id = "", type = "", name = "" } = authOrg;

  const auditListLoading = useSelector((state) => state.audit.auditListLoading);

  const organizationTypeMap = useSelector((state) => state.organization.organizationTypeMap);
  const organizationListLoading = useSelector((state) => state.organization.organizationListLoading);

  const industryMap = organizationTypeMap[OrgTypes.INDUSTRY.id] || [];
  const industryList = Object.values(industryMap);

  const auditFirmMap = organizationTypeMap[OrgTypes.INDUSTRY.id] || [];
  const auditFirmList = Object.values(auditFirmMap);

  const plantListMap = useSelector((state) => state.plant.plantListMap);
  const plantList = Object.values(plantListMap);

  const plantListLoading = useSelector((state) => state.plant.plantListLoading);

  const { value: statusValue = "" } = selectedFilters["status"] || {};

  const isAuditOrg = OrgTypes.AUDIT.id === type;

  function handleChange(e) {
    const { target = "" } = e || {};

    const { name = "", value = "", options = {}, selectedIndex = 0 } = target || {};
    const text = options[selectedIndex].text || "";

    setSelectedFilters((prev) => ({ ...prev, [name]: { value, text } }));
  }

  function handleDateSelection(date = "", name = "") {
    const selectedDate = date?.format("DD-MM-YYYY") || "";
    setSelectedFilters((prev) => ({ ...prev, [name]: { value: selectedDate, date } }));
  }

  function resetFilters() {
    setSelectedFilters({});
    applyFilters();
  }

  useEffect(() => {
    if (isAuditOrg) {
      setSelectedFilters((prev) => ({ ...prev, audit_org: { value: id, text: name } }));
    }
  }, [isAuditOrg, type]);

  return (
    <>
      <div className="row mt-3">
        <OrganiztionFields
          orgList={industryList}
          name="industry_org"
          heading="Industry"
          labelName="Name"
          handleChange={handleChange}
          selectedFilters={selectedFilters}
          loading={organizationListLoading}
        />
      </div>
      <div className="row mt-3">
        <OrganiztionFields
          orgList={plantList}
          name="plant"
          heading="Plant"
          labelName="Name"
          handleChange={handleChange}
          selectedFilters={selectedFilters}
          loading={plantListLoading}
        />
      </div>

      <div className="row mt-3">
        <OrganiztionFields
          orgList={auditFirmList}
          name="audit_org"
          heading="Audit"
          labelName="Audit Firm"
          handleChange={handleChange}
          selectedFilters={selectedFilters}
          loading={organizationListLoading}
          disabled={isAuditOrg}
        />
      </div>

      <div className="row mt-3">
        <DateRange handleDateSelection={handleDateSelection} selectedFilters={selectedFilters} />
      </div>

      <div className="row mt-3">
        <div className="col-6">
          <Label title="Status" />
          <SelectInput name="status" value={statusValue} options={AUDIT_STATUS_OPTIONS} onChange={handleChange} />
        </div>
      </div>

      <div className="row mt-3">
        <FilterActionsButton
          resetFilters={resetFilters}
          applyFilters={applyFilters}
          selectedFilters={selectedFilters}
          loading={auditListLoading}
        />
      </div>
    </>
  );
}
