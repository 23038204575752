// App Components
import ErrorHandler from "../components/error/ErrorHandler";
import ProtectedRoute from "../hoc/ProtectedRoute";

// Constants
import PageURLs from "../constants/pageUrls";
import { LayoutIds } from "../constants/generalConstants";
import { DataPreLoadObj } from "../constants/dataPreLoadConstants";

// Layout
import UserLayout from "../components/layout/UserLayout";

// Sidebar Elements
import ProfilePage from "../pages/profile/ProfilePage";

// User Pages and Layouts
export const UserRoutes = {
  id: LayoutIds.USER,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute dataPreLoadObj={DataPreLoadObj}>
        {/* Layout */}
        <UserLayout />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      index: true,
      path: PageURLs.ProfilePageUrl,
      element: <ProfilePage />,
    },
  ],
};
