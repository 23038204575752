import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Constants
import { DefaultPagination } from "../../constants/generalConstants";

// Utils
import { saveFile } from "../../utils/fileUtils";

// Helper
import FileListTableHelper from "../../helpers/file/fileListTableHelper";

// Components
import { Button } from "../button/Button";
import TablePagination from "../table/TablePagination";
import SkeletonTable from "../antd/table/SkeletonTable";
import DataTable from "../antd/table/DataTable";

// Section
import FileUploadModal from "./FileUploadModal";

// Page Components
function TableActions({ record = {}, setSelectedFile, fileReduceKey, dispatchActions, setShowModal = () => {} }) {
  // File Information
  const { id = "", name = "", url = "" } = record;

  // Selector State
  const downloadFileData = useSelector((state) => state[fileReduceKey].downloadFileData[id]) || "";
  const downloadFileSuccess = useSelector((state) => state[fileReduceKey].downloadFileSuccess[id]) || false;
  const downloadFileLoading = useSelector((state) => state[fileReduceKey].downloadFileLoading[id]);

  const deleteFileLoading = useSelector((state) => state[fileReduceKey].deleteFileLoading[id]) || false;

  // Dispatch Actions
  const { downloadFileFn = () => {}, deleteFileFn = () => {} } = dispatchActions;

  // Open File Upload Modal
  function openFileUploadEditModal() {
    setSelectedFile(record);
    setShowModal(true);
  }

  useEffect(() => {
    if (downloadFileSuccess) {
      saveFile(name, downloadFileData);
    }
  }, [downloadFileSuccess]);

  return (
    <div className="btn-cont">
      {/* View File Button */}
      <Button label="View" size="sm" color="secondary" onClick={openFileUploadEditModal}>
        <i className="fas fa-eye"></i>
      </Button>

      {/* Download File Button */}
      {downloadFileFn && (
        <Button
          label="Download"
          color="secondary"
          size="sm"
          onClick={() => downloadFileFn(id, name, url)}
          loading={downloadFileLoading}
          disabled={downloadFileLoading}
        >
          <i className="fas fa-download"></i>
        </Button>
      )}

      {/* Delete File Button */}
      {deleteFileFn && (
        <Button
          label="Delete"
          color="secondary"
          size="sm"
          onClick={() => deleteFileFn(id)}
          loading={deleteFileLoading}
          disabled={deleteFileLoading}
        >
          <i className="fas fa-trash"></i>
        </Button>
      )}
    </div>
  );
}

function FileListHeader({ setSelectedFile, dispatchActions, setShowModal = () => {} }) {
  const { uploadFileFn = "" } = dispatchActions;

  function openFileUploadCreateModal() {
    setSelectedFile({});
    setShowModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        <h4>Uploaded Files</h4>
      </div>

      {uploadFileFn && (
        <Button label="Upload File" color="primary" onClick={openFileUploadCreateModal}>
          <i className="fa-solid fa-plus me-2"></i>
        </Button>
      )}
    </div>
  );
}

/**
 * File Upload And List Section
 */
export default function FileUploadAndListSection({ fileReduceKey = "", dispatchActions = {}, fileTypes = {} }) {
  // State
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});
  const { id = "" } = selectedFile;

  // Selector State
  const fileListMap = useSelector((state) => state[fileReduceKey].fileListMap) || [];
  const fileList = Object.values(fileListMap);
  const fileListLoading = useSelector((state) => state[fileReduceKey].fileListLoading) || false;
  const fileListPagination = useSelector((state) => state[fileReduceKey].fileListPagination) || {};

  const upsertFileSuccess = useSelector((state) => state[fileReduceKey].upsertFileSuccess);

  // Pagination
  const {
    pageNo = DefaultPagination.pageNumber,
    pageSize = DefaultPagination.pageSize,
    totalCount = DefaultPagination.totalCount,
  } = fileListPagination || {};

  const actionColumn = {
    title: "Action",
    key: "action",
    render: (record) => {
      return (
        <TableActions
          record={record}
          setSelectedFile={setSelectedFile}
          fileReduceKey={fileReduceKey}
          dispatchActions={dispatchActions}
          setShowModal={setShowModal}
        />
      );
    },
  };

  const tableHeaders = FileListTableHelper.getHeaders(pageNo, pageSize, fileTypes);
  const columns = [...tableHeaders, actionColumn];

  useEffect(() => {
    if (upsertFileSuccess && !id) {
      toast.success("File created successfully");
      setShowModal(false);
    }
  }, [upsertFileSuccess]);

  useEffect(() => {
    if (upsertFileSuccess && id) {
      toast.success("File updated successfully");
      setShowModal(false);
    }
  }, [upsertFileSuccess]);

  return (
    <>
      <div className="page-content">
        <FileListHeader
          setSelectedFile={setSelectedFile}
          dispatchActions={dispatchActions}
          setShowModal={setShowModal}
        />

        {fileListLoading && <SkeletonTable columnCount={5} rowCount={10} />}

        {!fileListLoading && <DataTable rows={fileList} columns={columns} applyTableBorder={true} />}

        {/* Table Pagination */}
        <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
      </div>

      <FileUploadModal
        selectedFile={selectedFile}
        reducerKey={fileReduceKey}
        dispatchActions={dispatchActions}
        fileTypes={fileTypes}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
