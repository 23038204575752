import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { MeasurementTypeActions } from "../../../../store/slices/measurement-type/measurementTypeSlice";

// Constants
import PageURLs from "../../../../constants/pageUrls";
import { ICON_SIZE } from "../../../../constants/generalConstants";

// Utils
import URLs from "../../../../utils/urlUtils";

// Helpers
import MeasurementTypeListTableHelper from "../../../../helpers/measurement-type/measurementTypeListTableHelper";

// Components
import TablePagination from "../../../../components/table/TablePagination";
import ToggleButton from "../../../../components/antd/button/ToggleButton";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";
import Icon from "../../../../components/icon/Icon";

// Page Components
function MeasurementTypeStatus({ measurementType, pageNumber, pageSize }) {
  const dispatch = useDispatch();

  const { id, isActive } = measurementType;
  const status = !isActive;

  const loading = useSelector((state) => state.measurementType.updateStatusLoadingMap[id]) || false;

  function updateMeasurementTypeStatus() {
    dispatch(
      MeasurementTypeActions.updateMeasurementTypeStatus({
        measurementTypeId: id,
        status,
        pageNumber,
        pageSize,
      })
    );
  }

  return (
    <ToggleButton
      containerClassname="mt-1"
      isActive={isActive}
      loading={loading}
      onClick={updateMeasurementTypeStatus}
    />
  );
}

function TableActions({ measurementType }) {
  // Navigate
  const navigate = useNavigate();

  const { id, isActive } = measurementType;
  const iconClassName = isActive ? "cursor-pointer" : "cursor-disabled";

  const updateMeasurementTypeStatusSuccess =
    useSelector((state) => state.measurementType.updateStatusSuccessMap[id]) || false;

  // Redirect to measurement type view page
  function navigateToMeasurementTypeViewPage() {
    const redirectUrl = URLs.format(PageURLs.MeasurementTypeViewPageUrl, { measurementTypeId: id });
    navigate(redirectUrl);
  }

  useEffect(() => {
    if (updateMeasurementTypeStatusSuccess) {
      toast.success("Measurement Type Status Updated Successfully");
    }
  }, [updateMeasurementTypeStatusSuccess]);

  return (
    <Icon
      iconName="eye"
      title="View"
      size={ICON_SIZE.small}
      className={iconClassName}
      onClick={navigateToMeasurementTypeViewPage}
    />
  );
}

// Measurement type list table
export default function MeasurementTypeListTable({
  measurementTypeList = [],
  measurementTypeListLoading = false,
  measurementTypeListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize } = measurementTypeListPagination;
  const actionColumn = {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => {
      return <TableActions measurementType={record} />;
    },
  };

  const statusColumn = {
    title: "Status",
    key: "isActive",
    render: (record) => {
      return (
        <MeasurementTypeStatus
          measurementType={record}
          pageSize={pageSize}
          pageNumber={(pageNumber = { pageNumber })}
        />
      );
    },
  };

  const columns = [...MeasurementTypeListTableHelper.getHeaders(), statusColumn, actionColumn];

  return (
    <>
      {measurementTypeListLoading && <SkeletonTable columnCount={7} rowCount={10} />}
      {!measurementTypeListLoading && (
        <DataTable rows={measurementTypeList} columns={columns} applyTableBorder={true} />
      )}

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
