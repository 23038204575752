import { createSlice } from "@reduxjs/toolkit";

// Transformer
import MaterialTransformer from "./materialTransformer";

function resetApiSuccessState(state) {
  state.updateMaterialStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get material list
  materialListMap: [],
  materialListPagination: {},
  materialListLoading: false,
  materialListError: "",

  // Update Material Status (Enable/Disable)
  updateMaterialStatusLoadingMap: {},
  updateMaterialStatusSuccessMap: {},
  updateMaterialStatusError: "",
};

const materialSlice = createSlice({
  name: "Material",

  initialState,

  reducers: {
    // Get Material List
    getMaterialList: (state) => {
      state.materialListLoading = true;
    },

    getMaterialListSuccess: (state, action) => {
      const { materialList = [], materialListPagination = {} } = action.payload;

      state.materialListMap = MaterialTransformer.transformList(materialList);
      state.materialListPagination = materialListPagination;
      state.materialListLoading = false;
    },

    getMaterialListFailure: (state, action) => {
      state.materialListLoading = false;
      state.materialListError = action.payload.error;
    },

    // Update Material Status (Enable/Disable)
    updateMaterialStatus: (state, action) => {
      const { materialId } = action.payload;
      state.updateMaterialStatusSuccessMap[materialId] = false;
      state.updateMaterialStatusLoadingMap[materialId] = true;
      state.updateMaterialStatusError = "";
    },

    updateMaterialStatusSuccess: (state, action) => {
      const { materialId } = action.payload;
      state.updateMaterialStatusSuccessMap[materialId] = true;
      delete state.updateMaterialStatusLoadingMap[materialId];
    },

    updateMaterialStatusFailure: (state, action) => {
      const { materialId, error } = action.payload;
      delete state.updateMaterialStatusLoadingMap[materialId];
      state.updateMaterialStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const MaterialReducer = materialSlice.reducer;

// Actions
export const MaterialActions = materialSlice.actions;
