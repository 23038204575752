import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditActions } from "../../../../store/slices/audit/auditSlice";
import { UserActions } from "../../../../store/slices/user/userSlice";

// Section
import InchargeInfo from "./InchargeInfo";

/**
 * Audit Plant Info Card
 */
export default function AuditPlantInfoCard({ auditInfo = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // User List Selector State
  const industryUserMap = useSelector((state) => state.user.industryUserMap);
  const industryUserList = Object.values(industryUserMap);
  const userListLoading = useSelector((state) => state.user.userListLoading);

  // Update Plant Incharge Selector State
  const updatePlantInchargeLoading = useSelector((state) => state.audit.updatePlantInchargeLoading);
  const updatePlantInchargeSuccess = useSelector((state) => state.audit.updatePlantInchargeSuccess);

  const { industryOrgName = "", industryOrgId = "", plantInchargeName = "" } = auditInfo;

  // use Effect
  useEffect(() => {
    if (industryOrgId) {
      dispatch(UserActions.getUserList({ orgId: industryOrgId, orgType: "INDUSTRY" }));
    }
  }, [dispatch, industryOrgId]);

  return (
    <div className="row">
      {/* Incharge Information */}
      <InchargeInfo
        labelName="Plant Incharge"
        inchargeName={plantInchargeName}
        organizationName={industryOrgName}
        data={industryUserList || []}
        action={AuditActions.updatePlantIncharge}
        loading={updatePlantInchargeLoading || userListLoading}
        toastMessage="Plant incharge updated successfully"
        isApiSuccess={updatePlantInchargeSuccess}
      />
    </div>
  );
}
