import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyEdgeChanges } from "@xyflow/react";

// Actions
import { EdgeActions } from "../slice/edge/edgeSlice";

// Utils
import MapUtils from "../mapUtils";

export function useOnEdgesChanges(onEdgesChange = () => {}) {
  const dispatch = useDispatch();

  // Selector States
  const edgesMap = useSelector((state) => state.edge.edgesMap);
  const edges = Object.values(edgesMap);

  return useCallback(
    (changes) => {
      // NOTE: We are deepcloning Edge because Reactflow internally expects the Edge to be updated
      // to be a new object altogether (As ReactFlow follows immutability)..
      const edgesToUpdate = [];

      // construct changes Map;
      const changesMap = MapUtils.convertArrayToMap(changes, "id");

      for (const edge of edges) {
        const { id: edgeId } = edge;
        const chageForEdge = changesMap[edgeId];
        chageForEdge ? edgesToUpdate.push(structuredClone(edge)) : edgesToUpdate.push(edge);
      }

      const updatedEdges = applyEdgeChanges(changes, edgesToUpdate);
      dispatch(EdgeActions.setEdges({ edges: updatedEdges }));

      onEdgesChange(changes, updatedEdges);
    },
    [dispatch, onEdgesChange, edges]
  );
}
