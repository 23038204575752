import { all, fork } from "redux-saga/effects";

// DataPreLoad Saga
import DataPreLoadSaga from "../sagas/data-pre-load/dataPreLoadSaga";

// Auth Saga
import AuthSaga from "../sagas/auth/authSaga";

// Definitions :: Product Saga
import ProductSaga from "../sagas/product/productSaga";

// Definitions :: Material Saga
import MaterialSaga from "../sagas/material/materialSaga";

// Definitions :: Job Saga
import JobSaga from "../sagas/job/jobSaga";

// Definitions :: Constant Saga
import ConstantSaga from "../sagas/constant/constantSaga";

// Definitions :: Component Type Saga
import ComponentTypeSaga from "../sagas/component-type/componentTypeSaga";

// Definitions :: Measurement Type Saga
import MeasurementTypeSaga from "./measurement-type/measurementTypeSaga";

// Definitions :: Instrument Saga
import InstrumentSaga from "./instrument/instrumentSaga";

// Definitions :: NIC Code Saga
import NICCodeSaga from "./nic-code/NICCodeSaga";

// Definitions :: HSN Code Saga
import HSNCodeSaga from "./hsn-code/HSNCodeSaga";

// Deinitions :: Template Saga
import TemplateSaga from "./template/templateSaga";

// Organization Saga
import OrganizationSaga from "./organization/organizationSaga";

// User Saga
import UserSaga from "./user/userSaga";

// Role Saga
import RoleSaga from "./role/roleSaga";

// Plant Saga
import PlantSaga from "./plant/plantSaga";

// Plant Setup :: Component Saga
import PlantComponentSaga from "./plant/componentSaga";

// Plant Setup :: Component Link Saga
import PlantComponentLinkSaga from "./plant/componentLinkSaga";

// Plant Setup :: Component Parts Saga
import PlantComponentPartsSaga from "./plant/partsSaga";

// Plant Setup :: Power Connection Saga
import PlantPowerConnectionSaga from "./plant/powerConnectionSaga";

// Plant Setup :: Electricity Bill Saga
import PlantElectricityBillSaga from "../sagas/plant/electricityBillSaga";

// Plant Setup :: Thermal Bill Saga
import PlantThermalBillSaga from "../sagas/plant/thermalBillSaga";

// Plant Setup :: File Saga
import PlantFileSaga from "../sagas/plant/fileSaga";

// Audit Saga
import AuditSaga from "./audit/auditSaga";

// Audit Setup :: Component
import AuditComponentSaga from "./audit/componentSaga";

// Audit Setup :: Parameter Values Saga
import AuditParameterValuesSaga from "./audit/parameterValuesSaga";

// Audit Setup :: Observation And Recommendation Saga
import AuditObservationAndRecommendationSaga from "./audit/observationAndRecommendationSaga";

// Audit Setup :: Data Saga
import AuditDataSaga from "./audit/dataSaga";

// Audit Setup :: Report Saga
import AuditReportSaga from "./audit/reportSaga";

// Audit Setup :: File Saga
import AuditFileSaga from "./audit/fileSaga";

// Audit Setup :: Instrument Saga
import AuditInstrumentSaga from "../sagas/audit/instrumentSaga";

export default function* RootSaga() {
  yield all([
    // DataPreLoad Saga

    fork(DataPreLoadSaga),

    // Auth Saga
    fork(AuthSaga),

    // Deinitions :: Product Saga
    fork(ProductSaga),

    // Deinitions :: Material Saga
    fork(MaterialSaga),

    // Deinitions :: Job Saga
    fork(JobSaga),

    // Deinitions :: Constant Saga
    fork(ConstantSaga),

    // Deinitions :: Component Type Saga
    fork(ComponentTypeSaga),

    // Deinitions :: Measurement Type Saga
    fork(MeasurementTypeSaga),

    // Deinitions :: Instrument Saga
    fork(InstrumentSaga),

    // Deinitions :: NIC Code Saga
    fork(NICCodeSaga),

    // Deinitions :: HSN Code Saga
    fork(HSNCodeSaga),

    // Deinitions :: Template Saga
    fork(TemplateSaga),

    // Organization Saga
    fork(OrganizationSaga),

    // User Saga
    fork(UserSaga),

    // Role Saga
    fork(RoleSaga),

    // Plant Saga
    fork(PlantSaga),

    // Plant Setup :: Component Saga
    fork(PlantComponentSaga),

    // Plant Setup :: Component Link Saga
    fork(PlantComponentLinkSaga),

    // Plant Setup :: Component Parts Saga
    fork(PlantComponentPartsSaga),

    // Plant Setup :: Power Connection Saga
    fork(PlantPowerConnectionSaga),

    // Plant Setup :: Electricity Bill Saga
    fork(PlantElectricityBillSaga),

    // Plant Setup :: Thermal Bill Saga
    fork(PlantThermalBillSaga),

    // Plant Setup :: File Saga
    fork(PlantFileSaga),

    // Audit Saga
    fork(AuditSaga),

    // Audit Setup :: Component Saga
    fork(AuditComponentSaga),

    // Audit Setup :: Parameter Values Saga
    fork(AuditParameterValuesSaga),

    // Audit Setup :: Observation And Recommendation Saga
    fork(AuditObservationAndRecommendationSaga),

    // Audit Setup :: Data Saga
    fork(AuditDataSaga),

    // Audit Setup :: Report Saga
    fork(AuditReportSaga),

    // Audit Setup :: File Saga
    fork(AuditFileSaga),

    // Audit Setup :: Instrument Saga
    fork(AuditInstrumentSaga),
  ]);
}
