import { createSlice } from "@reduxjs/toolkit";

// Transformer
import PlantComponentTransformer from "./componentTransformer";

function resetApiSuccessState(state) {
  state.addComponentSuccess = false;
  state.updateComponentSuccess = false;
  state.uploadComponentImageSuccess = false;
  state.downloadComponentImageSuccess = {};
  state.decommissionComponentSuccess = {};
  state.updateComponentPositionSuccess = {};
  state.uploadBulkComponentSuccess = false;
}

// Initial State
const initialState = {
  // Get component list
  componentListMap: {},
  componentListLoading: false,
  componentListError: "",

  // Add component
  addComponentLoading: false,
  addComponentSuccess: false,
  addComponentError: "",

  // Upload component
  updateComponentLoading: false,
  updateComponentSuccess: false,
  updateComponentError: "",

  // Upload component image
  uploadComponentImageLoading: false,
  uploadComponentImageSuccess: false,
  uploadComponentImageError: "",

  // Download component image
  componentImageData: {},
  downloadComponentImageLoading: {},
  downloadComponentImageSuccess: {},
  downloadComponentImageError: "",

  // Decommision Component
  decommissionComponentLoading: {},
  decommissionComponentSuccess: {},
  decommissionComponentError: "",

  // Update Component Position
  updateComponentPositionLoading: {},
  updateComponentPositionSuccess: {},
  updateComponentPositionError: {},

  // Upload Bulk Component
  uploadBulkComponentLoading: false,
  uploadBulkComponentSuccess: false,
  uploadBulkComponentError: "",

  // Reset API Success State
  resetApiSuccessState,
};

// Plant Component Slice
const plantComponentSlice = createSlice({
  name: "PlantComponent",
  initialState,

  reducers: {
    // Get component list
    getComponentList: (state) => {
      state.componentListLoading = true;
      state.componentListError = {};
    },

    getComponentListSuccess: (state, action) => {
      const { componentList = [] } = action.payload;

      state.componentListMap = PlantComponentTransformer.transformList(componentList);
      state.componentListLoading = false;
    },

    getComponentListFailure: (state, action) => {
      state.componentListLoading = false;
      state.componentListError = action.payload.error;
    },

    // Add component
    addComponent: (state) => {
      state.addComponentSuccess = false;
      state.addComponentLoading = true;
      state.addComponentError = {};
    },

    addComponentSuccess: (state) => {
      state.addComponentSuccess = true;
      state.addComponentLoading = false;
    },

    addComponentFailure: (state, action) => {
      state.addComponentLoading = false;
      state.addComponentError = action.payload.error;
    },

    // Update component
    updateComponent: (state) => {
      state.updateComponentSuccess = false;
      state.updateComponentLoading = true;
      state.updateComponentError = {};
    },

    updateComponentSuccess: (state) => {
      state.updateComponentSuccess = true;
      state.updateComponentLoading = false;
    },

    updateComponentFailure: (state, action) => {
      state.updateComponentLoading = false;
      state.updateComponentError = action.payload.error;
    },

    // Upload component image
    uploadComponentImage: (state) => {
      state.uploadComponentImageSuccess = false;
      state.uploadComponentImageLoading = true;
      state.uploadComponentImageError = {};
    },

    uploadComponentImageSuccess: (state) => {
      state.uploadComponentImageSuccess = true;
      state.uploadComponentImageLoading = false;
    },

    uploadComponentImageFailure: (state, action) => {
      state.uploadComponentImageLoading = false;
      state.uploadComponentImageError = action.payload.error;
    },

    // Download component image
    downloadComponentImage: (state, action) => {
      const { componentId = "" } = action.payload;
      state.downloadComponentImageLoading[componentId] = true;
      state.downloadComponentImageSuccess[componentId] = false;
      state.downloadComponentImageError = {};
    },

    downloadComponentImageSuccess: (state, action) => {
      const { componentId = "", componentImageData = {} } = action.payload;
      state.componentImageData[componentId] = componentImageData;
      state.downloadComponentImageSuccess[componentId] = true;
      delete state.downloadComponentImageLoading[componentId];
    },

    downloadComponentImageFailure: (state, action) => {
      const { componentId = "" } = action.payload;
      delete state.downloadComponentImageLoading[componentId];
      state.downloadComponentImageError = action.payload.error;
    },

    // Decommission Component
    decommissionComponent: (state, action) => {
      const { componentId = "" } = action.payload;

      state.decommissionComponentSuccess[componentId] = false;
      state.decommissionComponentLoading[componentId] = true;
      state.decommissionComponentError = {};
    },

    decommissionComponentSuccess: (state, action) => {
      const { componentId = "" } = action.payload;

      state.decommissionComponentSuccess[componentId] = true;
      delete state.decommissionComponentLoading[componentId];
    },

    decommissionComponentFailure: (state, action) => {
      const { componentId = "", error = {} } = action.payload;

      state.decommissionComponentSuccess[componentId] = false;
      delete state.decommissionComponentLoading[componentId];
      state.decommissionComponentError = error;
    },

    // Update Component Position
    updateComponentPosition: (state, action) => {
      const { componentId = "" } = action.payload;

      state.updateComponentPositionSuccess[componentId] = false;
      state.updateComponentPositionLoading[componentId] = true;
      state.updateComponentPositionError = {};
    },

    updateComponentPositionSuccess: (state, action) => {
      const { componentId } = action.payload;

      state.updateComponentPositionSuccess[componentId] = true;
      delete state.updateComponentPositionLoading[componentId];
    },

    updateComponentPositionFailure: (state, action) => {
      const { componentId = "", error = {} } = action.payload;

      state.updateComponentPositionSuccess[componentId] = false;
      delete state.updateComponentPositionLoading[componentId];
      state.updateComponentPositionError = error;
    },

    // Upload Bulk Component
    uploadBulkComponent: (state) => {
      state.uploadBulkComponentLoading = true;
      state.uploadBulkComponentSuccess = false;
      state.uploadBulkComponentError = {};
    },

    uploadBulkComponentSuccess: (state) => {
      state.uploadBulkComponentSuccess = true;
      state.uploadBulkComponentLoading = false;
    },

    uploadBulkComponentFailure: (state, action) => {
      state.uploadBulkComponentLoading = false;
      state.uploadBulkComponentError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantComponentReducer = plantComponentSlice.reducer;

// Actions
export const PlantComponentActions = plantComponentSlice.actions;
