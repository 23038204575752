// Electricity Bill Transformers
function transformObj(electricityBillobj = {}) {
  const {
    id = "",
    version = "",
    plantId = "",
    updatedOn = "",
    isActive = true,
    serviceNumber = "",
    year = "",
    month = "",
    unitsConsumed = "",
    unitCost = "",
    isPowerExported = false,
    powerFactor = "",
    totalCost = "",
    fileType = "",
    fileName = "",
    fileSize = 0,
    fileUrl = "",
  } = electricityBillobj;

  const transformedElectricityBill = {
    id: id,
    version: version,
    plantId: plantId,
    updatedOn: updatedOn,
    isActive: isActive,
    serviceNumber: serviceNumber,
    year: year,
    month: month,
    unitsConsumed: unitsConsumed,
    unitCost: unitCost,
    isPowerExported: isPowerExported,
    powerFactor: powerFactor,
    totalCost: totalCost,
    fileType: fileType,
    fileName: fileName,
    fileSize: fileSize,
    fileUrl: fileUrl,
  };

  return transformedElectricityBill;
}

function transformList(electricityBillList = []) {
  let electricityBillListMap = {};

  electricityBillList.forEach((obj) => {
    const { id = "" } = obj;

    electricityBillListMap[id] = { key: id, ...transformObj(obj) };
  });

  return electricityBillListMap;
}

// Exports
const ElectricityBillTransformer = {
  transformObj,
  transformList,
};

export default ElectricityBillTransformer;
