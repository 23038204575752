import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { InstrumentActions } from "../../../store/slices/instrument/instrumentSlice";

// Constants
import PageURLs from "../../../constants/pageUrls";

// Utils
import TableUtils from "../../../utils/tableUtils";
import URLs from "../../../utils/urlUtils";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableLoader from "../../../components/table/TableLoader";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Model",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "File Type",
    },
  },
  {
    title: {
      displayName: "Content Information",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function InstrumentListStatus({ instrumentId, isActive, pageNumber, pageSize }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.instrument.updateInstrumentStatusLoading[instrumentId]) || false;
  const status = !isActive;

  function updateInstrumentStatus() {
    dispatch(InstrumentActions.updateInstrumentStatus({ instrumentId, status, pageNumber, pageSize }));
  }

  return (
    <td>
      <ToggleButton isActive={isActive} loading={loading} onClick={updateInstrumentStatus} />
    </td>
  );
}
function InstrumentListTableStatusCell({ instrumentId, isActive }) {
  // Navigate
  const navigate = useNavigate();

  // Navigate to instrument view page
  function navigateToInstrumentViewPage() {
    const redirectUrl = URLs.format(PageURLs.InstrumentViewPageUrl, { instrumentId });
    navigate(redirectUrl);
  }

  return (
    <td>
      <Button label="View" color="secondary" size="sm" onClick={navigateToInstrumentViewPage} disabled={!isActive}>
        <i className="fa-solid fa-eye"></i>
      </Button>
    </td>
  );
}

function InstrumentListTableRow({ serialNo, instrument, pageNumber, pageSize }) {
  const { id, name, model, code, fileType, contentInfo, active } = instrument;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>
      <td>{model}</td>
      <td>{code}</td>
      <td>{fileType}</td>
      <td>{contentInfo}</td>
      <InstrumentListStatus instrumentId={id} isActive={active} pageNumber={pageNumber} pageSize={pageSize} />

      {/* Instrument Table Status Cell */}
      <InstrumentListTableStatusCell instrumentId={id} isActive={active} />
    </tr>
  );
}

function InstrumentListTableBody({ instrumentListLoading = false, instrumentList = [], pageNumber, pageSize }) {
  //Loader
  if (instrumentListLoading) {
    return <TableLoader colSpan={8} />;
  }

  //if there is no instrument list
  if (instrumentList.length === 0) {
    return <TableDataNotFound message="No instruments present" colSpan={8} />;
  }

  // Instrument list table row
  return (
    <>
      {instrumentList.map((instrument, index) => {
        // Serial number for pagination table
        const serialNo = TableUtils.tableSerialNumber(pageNumber, pageSize, index);

        return (
          <InstrumentListTableRow
            serialNo={serialNo}
            key={instrument.id}
            instrument={instrument}
            pageNumber={pageNumber}
            pageSize={pageSize}
          />
        );
      })}
    </>
  );
}

/**
 * Instrument List Table
 */
export default function InstrumentListTable() {
  // Selector State
  const instrumentList = useSelector((state) => state.instrument.instrumentListMap);
  const instrumentListLoading = useSelector((state) => state.instrument.instrumentListLoading);
  const instrumentListPagination = useSelector((state) => state.instrument.instrumentListPagination);

  const { totalCount, pageNo, pageSize } = instrumentListPagination || {};

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Instrument List Table Body */}
          <InstrumentListTableBody
            instrumentListLoading={instrumentListLoading}
            instrumentList={instrumentList}
            pageNumber={pageNo}
            pageSize={pageSize}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
