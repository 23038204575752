import { createSlice } from "@reduxjs/toolkit";

// Constants
import { OrgTypes } from "../../../constants/generalConstants";

// Transformer
import UserTransformer from "./userTransformer";

function resetApiSuccessState(state) {
  state.upsertUserSuccess = false;
  state.updateUserStatusSuccessMap = {};
  state.assignRoleSuccess = false;
}

// Initial State
const initialState = {
  // Get user list
  userMap: {},
  userListPagination: {},
  userListLoading: false,
  userListError: "",

  // Industry User List
  industryUserMap: {},

  // Audit User List
  auditUserMap: {},

  // User Info
  userInfo: {},
  userInfoLoading: false,
  userInfoError: "",

  // Add user
  addUserLoading: false,
  addUserError: "",

  // Update user
  updateUserLoading: false,
  updateUserError: "",

  upsertUserSuccess: false,

  // Assign role
  assignRoleSuccess: false,
  assignRoleLoading: false,
  assignRoleError: "",

  // Update user status
  updateUserStatusLoadingMap: {},
  updateUserStatusSuccessMap: {},
  updatetUserStatusError: "",
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    // Get user list
    getUserList: (state) => {
      state.userListLoading = true;
      state.userListError = "";
    },

    getUserListSuccess: (state, action) => {
      // Action Payload
      const { orgType, userList, userListPagination } = action.payload;

      state.userListPagination = userListPagination;
      state.userListLoading = false;

      // Organization Type
      const { id: industry } = OrgTypes.INDUSTRY;
      const { id: audit } = OrgTypes.AUDIT;

      const userMap = UserTransformer.transformList(userList);

      // If organization type is industry
      if (orgType === industry) {
        state.industryUserMap = userMap;
        return;
      }

      // If organization type is audit
      if (orgType === audit) {
        state.auditUserMap = userMap;
        return;
      }

      state.userMap = userMap;
    },

    getUserListFailure: (state, action) => {
      state.userListLoading = false;
      state.userListError = action.payload.error;
    },

    // User Info
    getUserInfo: (state) => {
      state.userInfoLoading = true;
    },

    getUserInfoSuccess: (state, action) => {
      const { userInfo = {} } = action.payload;
      state.userInfo = UserTransformer.transformObj(userInfo);

      state.userInfoLoading = false;
    },

    getUserInfoFailure: (state, action) => {
      state.userInfoLoading = false;
      state.userInfoError = action.payload.error;
    },

    // Add user
    addUser: (state) => {
      state.upsertUserSuccess = false;
      state.addUserLoading = true;
    },

    addUserSuccess: (state) => {
      state.upsertUserSuccess = true;
      state.addUserLoading = false;
    },

    addUserFailure: (state, action) => {
      state.addUserLoading = false;
      state.addUserError = action.payload.error;
    },

    // Assign role
    assignRole: (state) => {
      state.assignRoleSuccess = false;
      state.assignRoleLoading = true;
    },

    assignRoleSuccess: (state) => {
      state.assignRoleSuccess = true;
      state.assignRoleLoading = false;
    },

    assignRoleFailure: (state, action) => {
      state.assignRoleLoading = false;
      state.assignRoleError = action.payload.error;
    },

    // Update user
    updateUser: (state) => {
      state.upsertUserSuccess = false;
      state.updateUserLoading = true;
    },

    updateUserSuccess: (state) => {
      state.upsertUserSuccess = true;
      state.updateUserLoading = false;
    },

    updateUserFailure: (state, action) => {
      state.updateUserLoading = false;
      state.updateUserError = action.payload.error;
    },

    // Update user status
    updateUserStatus: (state, action) => {
      const { userId } = action.payload;
      state.updateUserStatusSuccessMap[userId] = false;
      state.updateUserStatusLoadingMap[userId] = true;
      state.updatetUserStatusError = "";
    },

    updateUserStatusSuccess: (state, action) => {
      const { userId } = action.payload;
      state.updateUserStatusSuccessMap[userId] = true;
      delete state.updateUserStatusLoadingMap[userId];
    },

    updateUserStatusFailure: (state, action) => {
      const { userId, error } = action.payload;
      delete state.updateUserStatusLoadingMap[userId];
      state.updatetUserStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const UserReducer = userSlice.reducer;

// Actions
export const UserActions = userSlice.actions;
