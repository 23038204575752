export const NODE_TYPE = {
  DEFAULT: "default",
  SOURCE: "source",
  TARGET: "target",
  COMPONENT: "component",
};

export const POSITION = {
  Left: "left",
  Top: "top",
  TopLeft: "top-left",
  Right: "right",
  Bottom: "bottom",
  BottomRight: "bottom-right",
};

export const NODE_HANDLE_PADDING = {
  PX_TOP_OFFSET: 30,
  PX_SPACING: 25,
};

export const PREFIX = {
  Node: "n",
  HandleInput: "t", // Target
  HandleOutput: "s", // Source
  DemiliterId: "-",
};
