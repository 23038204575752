// Report Transformers
function transformDataObj(dataObj = {}) {
  const { plantInfo = {}, thermalBills = [], powerBills = [], auditInfo = {}, components = [] } = dataObj;

  const data = {
    plantInfo: plantInfo,
    thermalBills: thermalBills,
    powerBills: powerBills,
    auditInfo: auditInfo,
    components: components,
  };

  return data;
}

function transformObj(reportObj = {}) {
  const {
    id = "",
    version = "",
    plantId = "",
    isActive = false,
    auditInfoId = "",
    reportType = "",
    contentType = "",
    content = "",
    validDays = "",
  } = reportObj;

  const report = {
    id: id,
    version: version,
    plantId: plantId,
    isActive: isActive,
    auditInfoId: auditInfoId,
    reportType: reportType,
    contentType: contentType,
    content: content,
    validDays: validDays,
  };

  return report;
}

function transformList(reportList = []) {
  let reportListMap = {};

  reportList.forEach((obj) => {
    const { id = "" } = obj;

    reportListMap[id] = { key: id, ...transformObj(obj) };
  });

  return reportListMap;
}

// Exports
const ReportTransformer = {
  transformDataObj,
  transformObj,
  transformList,
};

export default ReportTransformer;
