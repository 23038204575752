import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { NICCodeActions } from "../../slices/nic-code/NICCodeSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import NICCodeService from "../../../services/nic-code/NICCodeService";

// Get NIC Code List
function* getNICCodeList(action) {
  try {
    const { pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield NICCodeService.getNICCodeList(pageNumber, pageSize, searchText);

    yield put(
      NICCodeActions.getNICCodeListSuccess({
        nicCodeList: items,
        nicCodeListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(NICCodeActions.getNICCodeListFailure({ error }));
  }
}

export default function* root() {
  yield all([takeLatest(NICCodeActions.getNICCodeList.type, getNICCodeList)]);
}
