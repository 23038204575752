// Components
import AuditDataTable from "./components/AuditDataTable";

/**
 * Audit Data Page
 */
export default function AuditDataPage() {
  return (
    <div className="page-content">
      <h3 className="my-3">Data</h3>

      <AuditDataTable />
    </div>
  );
}
