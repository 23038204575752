import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditDataActions } from "../../slices/audit/dataSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Service
import AuditDataService from "../../../services/audit/dataService";

// Download Performance Analysis Data
function* downloadPerfAnalysisData(action) {
  const { auditId } = action.payload;

  try {
    const downloadPerfAnalysisData = yield AuditDataService.downloadPerfAnalysisData(auditId);

    yield put(AuditDataActions.downloadPerfAnalysisDataSuccess({ auditId, downloadPerfAnalysisData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditDataActions.downloadPerfAnalysisDataFailure({ auditId, error }));
  }
}

// Download Recommendation Data
function* downloadRecommendationData(action) {
  const { auditId } = action.payload;

  try {
    const downloadRecommendationData = yield AuditDataService.downloadRecommendationData(auditId);

    yield put(AuditDataActions.downloadRecommendationDataSuccess({ auditId, downloadRecommendationData }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditDataActions.downloadRecommendationDataFailure({ auditId, error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(AuditDataActions.downloadPerfAnalysisData.type, downloadPerfAnalysisData),
    takeLatest(AuditDataActions.downloadRecommendationData.type, downloadRecommendationData),
  ]);
}
