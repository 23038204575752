// Utils
import TableUtils from "../../utils/tableUtils";
import { formatText } from "../../utils/stringUtils";

function AllowedValues({ allowedValues = [] }) {
  if (allowedValues.length === 0) {
    return "--";
  }

  // Constructing string of allowed values from array
  const allowedValuesString = allowedValues?.reduce((acc, value, index) => {
    // For first value comma is not required
    if (index === 0) {
      return `${value}`;
    }
    return `${acc} , ${value}`;
  }, "");

  return allowedValuesString;
}

function getHeaders(parameterType, currentPage, pageSize) {
  const headers = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
    },

    {
      title: "Data Type",
      dataIndex: "dataValueType",
    },
    {
      title: "Allowed Values",
      dataIndex: "allowedValues",
      render: (text, record) => <AllowedValues allowedValues={record.allowedValues} />,
    },
    {
      title: "Unit",
      dataIndex: "unitSymbol",
      render: (text) => formatText(text),
    },
    {
      title: "Decimals",
      dataIndex: "noOfDecimals",
    },
    {
      title: "Is Mandatory",
      dataIndex: "isMandatory",
      render: (text, record) => (record.isMandatory ? "yes" : "no"),
    },
  ];

  if (parameterType === "CALCULATION") {
    headers.push({
      title: "Formula",
      width: 300,
      dataIndex: "formulaStr",
    });
  }

  return headers;
}

const ParameterListTableHelper = {
  getHeaders,
};

export default ParameterListTableHelper;
