import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { toast } from "react-toastify";

// Actions
import { PlantComponentActions } from "../../../../../store/slices/plant/componentSlice";
import { ComponentTypeActions } from "../../../../../store/slices/component-type/componentTypeSlice";
import { TemplateActions } from "../../../../../store/slices/template/templateSlice";

// Utils
import { getComponentTypeParameters } from "../../../../../utils/dataPreLoadUtils";
import {
  DefaultInputRows,
  RemarksInputRow,
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../../../utils/componentUtils";
import { saveFile, selectFile } from "../../../../../utils/fileUtils";

// Components
import { Button } from "../../../../../components/button/Button";
import { DefaultFormValidationSchema } from "../../../../../components/form/Form";
import TabularForm from "../../../../../components/form/TabularForm";

// Sections
import ComponentTypeListCardView from "./ComponentTypeListCardView";
import ComponentMakeAndModalSelect from "./ComponentMakeAndModalSelect";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Design Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

const UPLOAD_FILE_TYPE = "SPREADSHEET";

// Page Component
function DownloadAndUploadComponentBtn({ componentTypeDetails = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  const { id = "", name = "", code = "" } = componentTypeDetails;

  // Selector State
  const downloadTemplateData = useSelector((state) => state.componentType.downloadComponentTypeTemplateData[code]);
  const downloadTemplateLoading = useSelector(
    (state) => state.componentType.downloadComponentTypeTemplateLoadingMap[code]
  );
  const downloadTemplateSuccess = useSelector(
    (state) => state.componentType.downloadComponentTypeTemplateSuccessMap[code]
  );

  const uploadBulkComponentLoading = useSelector((state) => state.plantComponent.uploadBulkComponentLoading);
  const uploadBulkComponentSuccess = useSelector((state) => state.plantComponent.uploadBulkComponentSuccess);

  function downloadTemplate() {
    dispatch(ComponentTypeActions.downloadComponentTypeTemplate({ componentTypeCode: code }));
  }

  async function selectFilehandler() {
    // Getting files
    const files = await selectFile(false, [".xls", ".xlsx"]);

    // If files are present, upload function is called and the first file is selected
    if (files.length > 0) {
      const [file] = files;

      const formData = new FormData();
      formData.append("fileType", UPLOAD_FILE_TYPE);
      formData.append("files", file);

      dispatch(
        PlantComponentActions.uploadBulkComponent({ formData, plantId, componentTypeCode: code, componentTypeId: id })
      );
    }
  }

  // use Effect
  useEffect(() => {
    if (downloadTemplateSuccess) {
      saveFile(`${name}_Template.xlsx`, downloadTemplateData);
    }
  }, [downloadTemplateSuccess, downloadTemplateData, name]);

  useEffect(() => {
    if (uploadBulkComponentSuccess) {
      toast.success("Bulk Component Uploaded Successfully");
    }
  }, [uploadBulkComponentSuccess]);

  return (
    <>
      <Button
        className="me-2"
        label="Download Template"
        size="sm"
        color="secondary"
        loading={downloadTemplateLoading}
        disabled={downloadTemplateLoading}
        onClick={downloadTemplate}
      >
        <i className="fa-solid fa-download me-2"></i>
      </Button>

      <Button
        className="me-2"
        label="Upload"
        size="sm"
        color="secondary"
        onClick={selectFilehandler}
        loading={uploadBulkComponentLoading}
        disabled={uploadBulkComponentLoading}
      >
        <i className="fa-solid fa-upload me-2"></i>
      </Button>
    </>
  );
}
function ComponentTypeListPageHeader({
  componentTypeDetails = {},
  title = "",
  setSelectedComponentIndex = () => {},
  setShowModal = () => {},
}) {
  // Function
  function openComponentTypeModal() {
    setSelectedComponentIndex("");
    setShowModal(true);
  }

  return (
    <div className="d-flex flex-row-reverse py-3">
      <Button label={`Add ${title}`} color="secondary" size="sm" onClick={openComponentTypeModal}>
        <i className="fa-solid fa-plus me-2"></i>
      </Button>

      <DownloadAndUploadComponentBtn componentTypeDetails={componentTypeDetails} />
    </div>
  );
}

/**
 * Component Type List
 */
export default function ComponentTypeList({ componentTypeId = "", componentTypes = [] }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // State
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // Plant Component List Selector State
  const plantComponentListMap = useSelector((state) => state.plantComponent.componentListMap);
  const plantComponentList = Object.values(plantComponentListMap);

  // Add Plant Component Selector State
  const addPlantComponentLoading = useSelector((state) => state.plantComponent.addComponentLoading);
  const addPlantComponentSuccess = useSelector((state) => state.plantComponent.addComponentSuccess);

  // Template Parameter Selector State
  const templateParamValuesMap = useSelector((state) => state.template.templateParamValuesMap);
  const componentParameters = Object.values(templateParamValuesMap);

  // Selected Component Type Name
  const componentTypeDetails = componentTypes.find(({ id = "" }) => id === parseInt(componentTypeId)) || {};
  const { name = "" } = componentTypeDetails;
  const title = `Add ${name}`;

  // Getting component property list
  const propertyList = getComponentTypeParameters(componentTypeId);
  const addComponentFormData = constructFormDataObject(propertyList, componentParameters);

  // Getting component info
  const { component = {}, properties = [] } = plantComponentList[selectedComponentIndex] || {};
  const { id = "" } = component;

  // Add component form submit function
  function addComponentTypeFormSubmit(componentPropertyData) {
    const { name = "", internalId = "", remarks = "" } = componentPropertyData;

    const propertiesArray = constructParameterValuesArray(propertyList, componentPropertyData);

    const componentData = { componentTypeId, name, internalId, remarks, isParent: true, properties: propertiesArray };

    dispatch(PlantComponentActions.addComponent({ componentData, plantId, componentTypeId }));
  }

  function resetForm() {
    dispatch(TemplateActions.resetParamValues({}));
  }

  function closeAddComponentModal() {
    setShowModal(false);
    resetForm();
  }

  // use Effect
  useEffect(() => {
    if (addPlantComponentSuccess) {
      toast.success("Added Plant Component Successfully");
      setShowModal(false);
    }
  }, [addPlantComponentSuccess]);

  // use Effect
  useEffect(() => {
    dispatch(ComponentTypeActions.resetAllApiSuccessState());
    dispatch(PlantComponentActions.resetAllApiSuccessState());
  }, [id]);

  // Form inputs for view and add component
  const componentFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = { showUnits: false, isInputPresent: true, isUnitConversionPresent: true };

    const formInputs = getParametersFormInputs(propertyList, properties, tableConfig, id);

    return [...DefaultInputRows, ...formInputs, RemarksInputRow];
  }, [propertyList, properties, id]);

  function updateInternalIdField(e, formik) {
    const { name = "", value = "" } = e.target || {};

    if (name === "name") {
      const internalId = value.split(" ").join("_").toUpperCase().trim();
      formik.setFieldValue("internalId", internalId);
    }
  }

  return (
    <>
      {/* Component Type List Page Header */}
      <ComponentTypeListPageHeader
        setSelectedComponentIndex={setSelectedComponentIndex}
        setShowModal={setShowModal}
        componentTypeDetails={componentTypeDetails}
      />

      {/* Component Type List Card View */}
      <ComponentTypeListCardView
        componentTypeId={componentTypeId}
        tableHeaders={tableHeaders}
        formInputItems={componentFormInputs}
        selectedComponentIndex={selectedComponentIndex}
        setSelectedComponentIndex={setSelectedComponentIndex}
      />

      {/* Add Component Modal */}
      <Modal
        title={title}
        width={1000}
        open={showModal}
        onCancel={closeAddComponentModal}
        footer={null}
        destroyOnClose={true}
      >
        <ComponentMakeAndModalSelect componentTypeId={componentTypeId} />

        <div className="mt-3">
          <TabularForm
            tableHeaders={tableHeaders}
            loading={addPlantComponentLoading}
            formInputItems={componentFormInputs}
            formValidationSchema={DefaultFormValidationSchema}
            data={addComponentFormData}
            formSubmit={addComponentTypeFormSubmit}
            showModal={showModal}
            resetFormFunction={resetForm}
            onChange={updateInternalIdField}
          />
        </div>
      </Modal>
    </>
  );
}
