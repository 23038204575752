import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { UserActions } from "../../slices/user/userSlice";
import { ErrorActions } from "../../slices/error/errorSlice";

// Services
import UserService from "../../../services/user/userService";

// Get User List
function* getUserList(action) {
  try {
    const { orgId, orgType, pageNumber, pageSize, searchText } = action.payload;

    const { items, pagination } = yield UserService.getUserList(orgId, pageNumber, pageSize, searchText);

    yield put(
      UserActions.getUserListSuccess({
        orgType,
        userList: items,
        userListPagination: pagination,
      })
    );
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.getUserListFailure({ error }));
  }
}

// Add User
function* addUser(action) {
  try {
    const { userData } = action.payload;

    yield UserService.addUser(userData);

    yield put(UserActions.addUserSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.addUserFailure({ error }));
  }
}

// Assign Role
function* assignRole(action) {
  try {
    const { orgId, orgType, userId, roleId } = action.payload;

    yield UserService.assignRole(userId, roleId);

    const { items, pagination } = yield UserService.getUserList(orgId);

    yield put(
      UserActions.getUserListSuccess({
        orgType,
        userList: items,
        userListPagination: pagination,
      })
    );

    yield put(UserActions.assignRoleSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.assignRoleFailure({ error }));
  }
}

// Get User Info
function* getUserInfo(action) {
  try {
    const { userId } = action.payload;

    const { user } = yield UserService.getUserInfo(userId);

    yield put(UserActions.getUserInfoSuccess({ userInfo: user }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.getUserInfoFailure({ error }));
  }
}

// Update User
function* updateUser(action) {
  try {
    const { userData, userId } = action.payload;

    yield UserService.updateUser(userData, userId);

    const { user } = yield UserService.getUserInfo(userId);

    yield put(UserActions.getUserInfoSuccess({ userInfo: user }));

    yield put(UserActions.updateUserSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.updateUserFailure({ error }));
  }
}

// Update Status
function* updateUserStatus(action) {
  const { orgId, userId, status, pageNumber, pageSize } = action.payload;

  try {
    yield UserService.updateUserStatus(userId, status);

    const { items, pagination } = yield UserService.getUserList(orgId, pageNumber, pageSize);

    yield put(
      UserActions.getUserListSuccess({
        userList: items,
        userListPagination: pagination,
      })
    );

    yield put(UserActions.updateUserStatusSuccess({ userId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.updateUserStatusFailure({ userId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(UserActions.getUserList.type, getUserList),
    takeLatest(UserActions.addUser.type, addUser),
    takeLatest(UserActions.assignRole.type, assignRole),
    takeLatest(UserActions.getUserInfo.type, getUserInfo),
    takeLatest(UserActions.updateUser.type, updateUser),
    takeEvery(UserActions.updateUserStatus.type, updateUserStatus),
  ]);
}
