//
// URLs of Web Service
//

// Data Pre Load URLs
export const DataPreLoadUrls = {
  getMeasurementsAndUnitsData: "/api/v1/app/data/defs/measurement",
  getComponentTypesData: "/api/v1/app/data/defs/component",
  getRecommendationCodesData: "/api/v1/app/data/defs/component/recommendation_code",
  getMaterialsData: "/api/v1/app/data/defs/material",
  getProductsData: "/api/v1/app/data/defs/product",
  getObservationCodesData: "/api/v1/app/data/defs/component/observation_code",
  getConstantData: "/api/v1/app/data/defs/constant",
};

// Auth URLs
export const AuthUrls = {
  login: "/api/v1/auth/token",
  resetPassword: "/api/v1/auth/passwd_reset",
  updateUserPassword: "/api/v1/auth/:userId/password",
};

// Definitions - Product URLs
export const ProductUrls = {
  getProductList: "/api/v1/defs/product/search",
  updateProductStatus: "/api/v1/defs/product/:productId/status",
};

// Definitions - Material URLs
export const MaterialUrls = {
  getMaterialList: "/api/v1/defs/material/search",
  updateMaterialStatus: "/api/v1/defs/material/:materialId/status",
};

// Definitions - Job URls
export const JobUrls = {
  getJobList: "/api/v1/platform/job",
  getJobInfo: "/api/v1/platform/job/:jobId",
  getJobSession: "/api/v1/platform/job/:jobId/sessions",
  runJob: "/api/v1/platform/job/:jobId/run",
  resumeJob: "/api/v1/platform/job/:jobId/resume",
  pauseJob: "/api/v1/platform/job/:jobId/pause",
};

// Definitions - Constants URLs
export const ConstantsUrls = {
  getConstants: "/api/v1/app/data/defs/constant",
};

// Definitions - Instrument URLs
export const InstrumentUrls = {
  getInstrumentList: "/api/v1/instrument/search",
  updateInstrumentStatus: "/api/v1/instrument/:instrumentId/status",
  getInstrumentInfo: "/api/v1/instrument/:instrumentId",
  getDataColumnInfo: "/api/v1/data_col_def/search",
};

// Definitions - Component Type URLs
export const ComponentTypeUrls = {
  getComponentTypeList: "/api/v1/defs/component_type/search",
  downloadComponentTypeTemplate: "/api/v1/defs/component_type/:componentTypeCode/template/xlsx",
  getParameterList: "/api/v1/defs/parameter/search",
  getRecommendationCodeList: "/api/v1/defs/recommendation_code/search",
  getObservationCodeList: "/api/v1/defs/observation_code/search",

  getComponentTypeInfo: "/api/v1/defs/component_type/:componentTypeId",

  updateComponentTypeStatus: "/api/v1/defs/component_type/:componentTypeId/status",
  updateObservationCodeStatus: "/api/v1/defs/observation_code/:obsCodeId/status",
  updateRecommendationCodeStatus: "/api/v1/defs/recommendation_code/:recCodeId/status",
  updateParameterStatus: "/api/v1/defs/parameter/:parameterId/status",
};

// Definitions - Measurement Type URLs
export const MeasurementTypeUrls = {
  getMeasurementTypeList: "/api/v1/defs/measurement_type/search",
  getMeasurementUnitsList: "/api/v1/defs/unit/search",
  getMeasurementTypeInfo: "/api/v1/defs/measurement_type/:measurementTypeId",
  updateMeasurementTypeStatus: "/api/v1/defs/measurement_type/:measurementTypeId/status",
};

// Definitions - NIC Code URLs
export const NICCodeUrls = {
  getNICCodeList: "/api/v1/defs/nic_code/search",
};

// Definitions - HSN Code URLs
export const HSNCodeUrls = {
  getHSNCodeList: "/api/v1/defs/hsn_code/search",
};

// Definitions - Template URLs
export const TemplateUrls = {
  getTemplateList: "/api/v1/defs/element/template/search",
  getTemplateParamValues: "/api/v1/defs/element/template/:templateId/param_value",
};

// Organization URLs
export const OrganizationUrls = {
  getOrganizationList: "/api/v1/org/search",
  addOrganization: "/api/v1/org",
  updateOrganization: "/api/v1/org/:orgId",
  getOrganizationInfo: "/api/v1/org/:orgId",
  updateOrganizationStatus: "/api/v1/org/:orgId/status",
};

// User URLs
export const UserUrls = {
  getUserList: "/api/v1/user/search",
  addUser: "/api/v1/user",
  assignRole: "/api/v1/user/:userId/roles/:roleId",
  getUserInfo: "/api/v1/user/:userId",
  updateUser: "/api/v1/user/:userId",
  updateUserStatus: "/api/v1/user/:userId/status",
};

// Role URLs
export const RoleUrls = {
  getRoleList: "/api/v1/role/search",
  getPrivilegesList: "/api/v1/privilege/list",
  getRoleInfo: "/api/v1/role/:roleId",
  createRole: "/api/v1/role",
  updateRole: "/api/v1/role/:roleId",
};

// Plant URLs
export const PlantUrls = {
  getPlantList: "/api/v1/plant/search",
  addPlant: "/api/v1/plant",
  updatePlant: "/api/v1/plant/:plantId",
  getPlantInfo: "/api/v1/plant/:plantId",
  updatePlantStatus: "/api/v1/plant/:plantId/status",
};

// Plant - Component URLs
export const PlantComponentUrls = {
  getComponentList: "/api/v1/plant/:plantId/component",
  addComponent: "/api/v1/plant/:plantId/component",
  updateComponent: "/api/v1/plant/:plantId/component/:componentId",
  uploadComponentImage: "/api/v1/plant/:plantId/component/:componentId/image",
  decommissionComponent: "/api/v1/plant/:plantId/component/:componentId/decommission",
  updateComponentPosition: "/api/v1/plant/:plantId/component/:componentId/position",
  uploadBulkComponent: "/api/v1/plant/:plantId/component/:componentCode/upload",
};

// Plant - Thermal Bill
export const PlantThermalBillUrls = {
  getThermalBillList: "/api/v1/plant/:plantId/thermal_bill/search",
  createThermalBill: "/api/v1/plant/:plantId/thermal_bill",
  updateThermalBill: "/api/v1/plant/:plantId/thermal_bill/:thermalBillId",
  uploadThermalBill: "/api/v1/plant/:plantId/thermal_bill/:thermalBillId/file",
};

// Plant - Electricity Bill
export const PlantElectricityBillUrls = {
  getElectricityBillList: "/api/v1/plant/:plantId/power_bill/search",
  createElectricityBill: "/api/v1/plant/:plantId/power_bill",
  updateElectricityBill: "/api/v1/plant/:plantId/power_bill/:electricityBillId",
  uploadElectricityBill: "/api/v1/plant/:plantId/power_bill/:electricityBillId/file",
};

// Plant - Files
export const PlantFileUrls = {
  getFileList: "/api/v1/plant/:plantId/file/search",
  createFile: "/api/v1/plant/:plantId/file",
  updateFile: "/api/v1/plant/:plantId/file/:fileId",
  deleteFile: "/api/v1/plant/:plantId/file/:fileId",
};

// Plant - Component Parts URLs
export const PlantComponentPartsUrls = {
  addPart: "/api/v1/plant/:plantId/component/:componentId/part",
  updatePart: "/api/v1/plant/:plantId/component/:componentId/part/:partId",
  deletePart: "/api/v1/plant/:plantId/component/:componentId/part/:partId",
};

// Plant - Power Connections URLs
export const PlantPowerConnectionsUrls = {
  getPowerConnectionList: "/api/v1/plant/:plantId/power_conn/list",
  createPowerConnection: "/api/v1/plant/:plantId/power_conn",
  updatePowerConnection: "/api/v1/plant/:plantId/power_conn/:connectionId",
  updatePowerConnectionStatus: "/api/v1/plant/:plantId/power_conn/:connectionId/status",
};

// Plant - Component Link
export const PlantComponentLinkUrls = {
  getSummary: "/api/v1/plant/:plantId/comp_link/summary",
  createLink: "/api/v1/plant/:plantId/comp_link",
  updateLink: "/api/v1/plant/:plantId/comp_link/:linkId",
  deleteLink: "/api/v1/plant/:plantId/comp_link/:linkId",
};

// Audit URLs
export const AuditUrls = {
  // Audit List
  getAuditList: "/api/v1/audit/search",
  getAuditListByAuditor: "/api/v1/audit/auditor/:userId",

  // Audit
  getAuditInfo: "/api/v1/audit/:auditId",
  updatePlantIncharge: "/api/v1/audit/:auditId/plant_incharge/:userId",
  updateAuditIncharge: "/api/v1/audit/:auditId/audit_incharge/:userId",
  addAuditor: "/api/v1/audit/:auditId/auditor/add/:userId",
  removeAuditor: "/api/v1/audit/:auditId/auditor/remove/:userId",
  getDetailedProgress: "/api/v1/audit/:auditId/progress",
  updateStartDate: "/api/v1/audit/:auditId/starts/:date",

  // Audit - Flow
  initiateAudit: "/api/v1/audit/init",
  startPreAssessment: "/api/v1/audit/:auditId/pre_assessment/start",
  startPreAudit: "/api/v1/audit/:auditId/pre_audit/start",
};

// Audit - Component URLs
export const AuditComponentUrls = {
  getPlantComponentList: "/api/v1/audit/:auditId/components",
  getComponentInfo: "/api/v1/audit/:auditId/element/COMPONENT/:componentId",
  getComponentsDetail: "/api/v1/audit/:auditId/elements",
  addWalkthroughRemarks: "/api/v1/audit/:auditId/element/COMPONENT/:componentId",
  addWalkthroughImage: "/api/v1/audit/:auditId/element/COMPONENT/:componentId/image",
  addRemarks: "/api/v1/audit/:auditId/element/COMPONENT/:componentId/remark",
};

// Audit - Parameter Values URLs
export const AuditParameterValuesUrls = {
  upsertValues: "/api/v1/audit/:auditId/param_val/COMPONENT/:componentId",
};

// Audit - Report URLs
export const AuditReportUrls = {
  getReportList: "/api/v1/audit/:auditId/report",
  createReport: "/api/v1/audit/:auditId/report",
  updateReport: "/api/v1/audit/:auditId/report/:reportId",
  deleteReport: "/api/v1/audit/:auditId/report/:reportId",
  downloadPDF: "/api/v1/audit/:auditId/report/:reportId/pdf",
  updateReportStatus: "/api/v1/audit/:auditId/report/:reportId/status",
  getReportData: "/api/v1/audit/:auditId/report/data",
  getReportHTML: "/api/v1/audit/:auditId/report/:reportId",
};

// Audit - Observation and Recommendation URLs
export const AuditObservationRecommendationUrls = {
  getObservationAndRecommendationList: "/api/v1/audit/:auditId/observation/search",
  updateObservationAndRecommendation: "/api/v1/audit/:auditId/observation/",
  updateInvestment: "/api/v1/audit/:auditId/recommendation/:recommendationId",
};

// Audit - Instrument URLs
export const AuditInstrumentUrls = {
  getInstrumentDataList: "/api/v1/audit/instrument_data/search",
  uploadInstrumentDataFile: "/api/v1/audit/instrument_data/upload",
  getInstrumentDataInfo: "/api/v1/audit/instrument_data/:instrumentDataId",
  selectInstrumentData: "/api/v1/audit/instrument_data/:instrumentDataId/measurements",
};

// Audit - File URLs
export const AuditFileUrls = {
  getFileList: "/api/v1/audit/:auditId/file/search",
  createFile: "/api/v1/audit/:auditId/file",
  updateFile: "/api/v1/audit/:auditId/file/:fileId",
  deleteFile: "/api/v1/audit/:auditId/file/:fileId",
};

// Audit - Data URLs
export const AuditDataUrls = {
  downloadPerfAnalysisData: "/api/v1/audit/:auditId/data/param_val/download/xls",
  downloadRecommendationData: "/api/v1/audit/:auditId/data/recommendation/download/xls",
};
